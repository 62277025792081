import { useIntl } from "react-intl";

const SelfcareAmount = ({ amount }) => {
  const intl = useIntl();

  return intl.formatNumber(amount, {
    style: "currency",
    currency: "CAD",
    currencySign: "standard",
  });
};

export default SelfcareAmount;
