import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import qs from "qs";

import { Text, Button } from "@theme-ui/components";

import AuthorizationSender from "./AuthorizationSender";
import { StyledModalMessage } from "../modals";
import { SelfcareButton } from "../base";

import { getToken } from "../../redux/slices/AuthSlice";

const AuthorizationHandler = ({ hideSignIn = false, errorHandler = () => {} }) => {
  const intl = useIntl();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { token } = useSelector(state => state.auth);
  let { code, error, error_description } = qs.parse(search, { ignoreQueryPrefix: true });

  const [errorDisplay, setErrorDisplay] = useState({ show: error, description: error_description });
  const [authCode, setAuthCode] = useState(code);

  useEffect(() => {
    authCode && dispatch(getToken(authCode));
  }, [authCode, dispatch]);

  useEffect(() => {
    if (token.error) {
      setAuthCode(null);
      setErrorDisplay({
        show: true,
        description: intl.formatMessage({ id: "err.generic" }),
      });
    }
  }, [intl, token.error]);

  return (
    <>
      {!authCode && !hideSignIn && (
        <AuthorizationSender>
          <Button variant="primary" px="4rem" py="0.875rem">
            <FormattedMessage id="lbl.sign_in" />
          </Button>
        </AuthorizationSender>
      )}

      <StyledModalMessage
        isOpen={errorDisplay.show}
        message={errorDisplay.description}
        onRequestClose={() => setErrorDisplay({ show: false })}
        type="error">
        <SelfcareButton
          onClick={() => {
            setErrorDisplay({ show: false });
            errorHandler();
          }}>
          <FormattedMessage id="lbl.ok" />
        </SelfcareButton>
      </StyledModalMessage>

      {token.loading && (
        <Text color="textLight" fontSize="primary" paddingY="small" fontWeight={3}>
          <FormattedMessage id="lbl.please_wait" />
        </Text>
      )}
    </>
  );
};

export default AuthorizationHandler;
