import React from "react";
const DownArrow = ({ fill = "none", stroke = "#fff", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.29"
    height="9.645"
    viewBox="0 0 17.29 9.645"
    {...props}>
    <path
      d="m1800.7 51.119 7.231 7.231 7.231-7.231"
      transform="translate(-1799.291 -49.704)"
      fill={fill}
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default DownArrow;
