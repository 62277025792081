import React from "react";

const AutomaticPaymentDisabled = ({ circle = "#114878", mark = "#318eff", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.4"
    height="19.4"
    viewBox="0 0 19.4 19.4"
    {...props}>
    <path
      fill={circle}
      d="M9.7,1.5A8.2,8.2,0,1,1,1.5,9.7,8.237,8.237,0,0,1,9.7,1.5M9.7,0a9.7,9.7,0,0,0,0,19.4,9.646,9.646,0,0,0,9.7-9.7A9.774,9.774,0,0,0,9.7,0Z"
    />
    <path
      fill={mark}
      d="M15.2,6.3,13,4.1,9.7,7.5,6.3,4.1,4.1,6.3,7.5,9.7,4.1,13l2.2,2.2,3.4-3.4L13,15.2,15.2,13,11.8,9.7Z"
    />
  </svg>
);

export default AutomaticPaymentDisabled;
