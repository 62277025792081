import React, { forwardRef } from "react";

import { Box } from "@theme-ui/components";

const SelfcareSwitch = forwardRef(({ checked, ...props }, ref) => (
  <Box
    ref={ref}
    as="button"
    type="button"
    role="switch"
    aria-checked={checked}
    {...props}
    __css={{
      m: 0,
      p: 0,
      cursor: "pointer",
      width: "3.5rem",
      height: "1.875rem",
      color: "primary",

      bg: checked ? "lightBlue" : "inactive",
      border: "1px solid",
      borderColor: "border",
      borderRadius: 9999,
      "&:focus": {
        outline: "none",
      },
      "&:disabled": {
        bg: "inactive",
      },
    }}>
    <Box
      aria-hidden
      style={{
        transform: checked ? "translateX(2rem)" : "translateX(0)",
      }}
      sx={{
        mt: "-1px",
        ml: "-1px",
        width: "1.8rem",
        height: "1.8rem",
        borderRadius: 9999,
        bg: props.disabled ? "disabled" : checked ? "primary" : "white",
        transitionProperty: "transform",
        transitionTimingFunction: "ease-out",
        transitionDuration: "0.1s",
        border: "1px solid",
        borderColor: "border",
      }}
    />
  </Box>
));
SelfcareSwitch.displayName = "SelfcareSwitch";

export default SelfcareSwitch;
