import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
import UpdateSAInformations from "../components/UpdateSAInformations";

import {
  AccountDetails,
  Error,
  Home,
  Invoices,
  Login,
  NotFound,
  Payments,
  UpdatePackage,
  RequestAssist,
  UsageDetails,
  MakePayment,
  DeviceDetails,
  PaymentCallback,
  ChangeUsername,
  ChangePassword,
  Lines,
  Plan,
} from "../pages";

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import ContractBuyout from "../pages/ContractBuyout";
import TravelPlanPage from "../pages/TravelPlan";

const SelfcareRouter = () => {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <PrivateRoute exact path={["/home", "/account/:accountCode/home"]} component={Home} />
          <PrivateRoute exact path="/lines" component={Lines} />
          <PrivateRoute
            exact
            path={["/payments", "/account/:accountCode/billing/payments"]}
            component={Payments}
          />
          <PrivateRoute
            exact
            path={["/invoices", "/account/:accountCode/billing/invoices"]}
            component={Invoices}
          />
          <PrivateRoute
            exact
            path={["/summary", "/account/:accountCode/summary/account"]}
            component={AccountDetails}
          />
          <PrivateRoute exact path={"/changeUsername"} component={ChangeUsername} />
          <PrivateRoute exact path={"/changePassword"} component={ChangePassword} />
          <PrivateRoute exact path={["/account/:accountCode/plan"]} component={Plan} />
          <PrivateRoute
            exact
            path={["/account/:accountCode/updatePlan"]}
            component={UpdatePackage}
          />
          <PrivateRoute
            exact
            path={"/account/:accountCode/updateInformations"}
            component={UpdateSAInformations}
          />
          <PrivateRoute
            exact
            path={"/account/:accountCode/contractBuyout"}
            component={ContractBuyout}
          />
          <PrivateRoute
            exact
            path={"/account/:accountCode/travelPlan"}
            component={TravelPlanPage}
          />
          <PrivateRoute exact path="/payment-callback" component={PaymentCallback} />
          <PrivateRoute exact path="/makePayment" component={MakePayment} />
          <PrivateRoute
            exact
            path={["/requestAssist", "/account/:accountCode/requestAssist"]}
            component={RequestAssist}
          />
          <PrivateRoute
            exact
            path={["/usageDetails", "/account/:accountCode/usageDetails"]}
            component={UsageDetails}
          />
          <PrivateRoute exact path="/requestUpgrade" component={RequestAssist} />
          <PrivateRoute
            exact
            path="/account/:accountCode/deviceDetails"
            component={DeviceDetails}
          />

          <PublicRoute exact path="/" component={Login} />
          <PublicRoute exact path="/error" component={Error} />
          <PublicRoute component={NotFound} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

export default SelfcareRouter;
