import React from "react";
import { Flex, Heading, Text } from "@theme-ui/components";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import ViewDetails from "../ViewDetails";
import { useHistory } from "react-router-dom";

const DeviceDetailsContainer = () => {
  const { deviceDetails, account } = useSelector(state => state.user);
  const intl = useIntl();
  const history = useHistory();

  const currentDevice =
    deviceDetails?.accountGoodsResponse?.modelDescription ?? intl.formatMessage({ id: "lbl.byod" });

  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Heading variant="heading2">
        <FormattedMessage id="lbl.device_details" />
      </Heading>
      <Flex
        variant="layout.card"
        sx={{
          height: "5.75rem",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Text variant="note" color="textDark" sx={{ width: "60%" }}>
          {currentDevice}
        </Text>

        <ViewDetails
          my="auto"
          icon="viewDeviceDetails"
          label="lbl.details"
          onClick={() => history.push("/account/" + account.accountCode + "/deviceDetails")}
        />
      </Flex>
    </Flex>
  );
};

export default DeviceDetailsContainer;
