import React, { useState, useEffect, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Box, Flex, Input, Text, Progress, Heading } from "@theme-ui/components";

import SharedPlanDropMenu from "../SharedPlanDropMenu/SharedPlanDropMenu";
import { SelfcareAmount, SelfcareTable } from "../base";
import { SACardSkeleton } from "../SACard";
import Spinner from "../Spinner/Spinner";
import { SelfcareIcon } from "../icons";
import GoBack from "../GoBack";
import {
  formatPhoneNumber,
  isMobileView,
  unitDescriptionTranslation,
} from "../../common/Utilities";

import { setSelectedSharedPlan } from "../../redux/slices/PlanSlice";
import {
  setSharedMembersDisplay,
  getAccount,
  setAccountStatus,
} from "../../redux/slices/UserSlice";

const HorizontalRule = () => {
  return <Box sx={{ width: "100%", height: "1px" }} bg="inactive"></Box>;
};

const computeUnits = (baseUnitRate, unitDescription, totalQuantity, usedQuantity) => {
  let result = {
    total: null,
    used: null,
    unit: null,
    baseUnitRate: null,
  };

  result.total = totalQuantity === -1 ? totalQuantity : totalQuantity / baseUnitRate;
  result.used = usedQuantity / baseUnitRate;
  result.unit = unitDescription;
  result.baseUnitRate = baseUnitRate;
  return result;
};

const SharedPlanMembersTable = () => {
  const Intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const searchFilter = useRef();

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [isRedirect, setRedirect] = useState(false);
  const [userCode, setUserCode] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const isMobile = isMobileView();
  const { serviceAgreements, account_status, sharedMembersDisplay } = useSelector(
    state => state.user
  );
  const { selectedSharedPlan, accountsSharedUsageDetails, shared_usage_details_status } =
    useSelector(state => state.plan);

  useEffect(() => {
    if (account_status === "success" && isRedirect) {
      dispatch(setAccountStatus(null));
      history.push(`/account/${userCode}/usageDetails`);
    }
  }, [account_status, dispatch, history, isRedirect, userCode]);

  useEffect(() => {
    if (searchTerm !== "") {
      searchFilter.current.focus();
    }
  }, [searchTerm]);

  if (shared_usage_details_status === "loading" && sharedMembersDisplay === "ShowMembers") {
    return (
      <Flex sx={{ flexDirection: "column" }}>
        <Heading variant="heading2">
          <FormattedMessage id="lbl.shared_plan_heading" />
        </Heading>
        <Flex
          my="default"
          sx={{
            flexDirection: "row",
            gap: 40,
          }}>
          <Box
            bg="contentBg"
            mb="default"
            data-testid="sa-skeleton"
            sx={{
              border: "1px solid",
              borderColor: "border",
              boxShadow: "light",
              borderRadius: "card",
              height: "15.625rem",
              width: "25rem",
            }}>
            <SACardSkeleton />
          </Box>
        </Flex>
      </Flex>
    );
  }

  const onChangeFilter = value => {
    setCurrentPage(1);
    setSearchTerm(value.target.value);
  };

  const formatQuantity = quantity => {
    return quantity.toFixed(2);
  };
  const formatPercentage = quantity => {
    return Number.parseInt(quantity.toFixed(1).replace(/[.,]0$/, ""));
  };

  const getAccountCode = phoneNumber => {
    const ServiceAgreement = serviceAgreements.find(sa => sa.phoneNumber === phoneNumber);

    return ServiceAgreement ? ServiceAgreement.accountCode : null;
  };

  const filteredMembers = () => {
    const search = searchTerm.toLowerCase();
    return accountsSharedUsageDetails.filter(val => {
      if (search === "") {
        return val;
      } else if (val.phoneNumber.includes(search.replace(/[-]/g, ""))) {
        return val;
      } else return null;
    });
  };

  const goBack = () => {
    window.scrollTo(0, 0);
    setCurrentPage(1);
    setSearchTerm("");
    dispatch(setSelectedSharedPlan(null));
    dispatch(setSharedMembersDisplay("default"));
  };

  const gridColumns = [
    "10rem 8rem 8rem 10.5rem",
    "0.27fr 0.2fr 0.25fr 0.2fr ",
    "0.25fr 0.25fr 0.34fr 0.28fr ",
  ];

  if (sharedMembersDisplay === "default") {
    return null;
  }

  const fubUnits = computeUnits(
    selectedSharedPlan.baseUnitRate,
    selectedSharedPlan.actualUnitDescription,
    selectedSharedPlan.total,
    selectedSharedPlan.used
  );

  const SearchBar = () => {
    return (
      <Flex
        py="small"
        px="default"
        sx={{
          borderRadius: "card",
          bg: "contentBg",
          border: "solid 1px",
          borderColor: "border",
          justifyContent: "flex-start",
        }}>
        <Input
          onSubmit={filteredMembers}
          onChange={onChangeFilter}
          defaultValue={searchTerm}
          placeholder={Intl.formatMessage({ id: "lbl.line_number" })}
          ref={searchFilter}
          maxLength="15"
          type="text"
          variant=""
          sx={{
            color: "textDark",
            bg: "contentBg",
            fontSize: [1, 1, 2, 2],
            border: "none",
            ":focus": { outline: "2px solid", outlineColor: "transparent", border: 0 },
          }}
        />
        <SelfcareIcon name="search" />
      </Flex>
    );
  };

  const MemberRow = ({ gridColumns, ani, usage, accountCode, total, formatQuantity }) => {
    if (!accountCode) return null;

    return (
      <Flex bg="contentBg" sx={{ width: "100%" }}>
        <Box
          sx={{
            height: "2.5rem",
            width: "100%",
            display: "grid",
            gridGap: ["large", "large", "huge"],
            gridTemplateColumns: gridColumns,
            alignItems: "center",
            textAlign: "center",
          }}>
          <Text
            sx={{
              textAlign: "left",
              color: "primary",
              fontFeatureSettings: "'tnum'",
            }}>
            {ani}
          </Text>

          <Consumed
            color="textDark"
            sx={{ textAlign: "right", fontWeight: "semiBold" }}
            consumedAmount={formatQuantity(usage)}
          />

          {total > 0 ? (
            <Flex
              sx={{
                alignItems: "center",
                width: ["10rem", "100%"],
              }}>
              <Flex
                p="tiny"
                sx={{
                  width: "75%",
                  height: "1rem",
                  backgroundColor: "progressBg",
                  borderRadius: 10,
                  alignItems: "center",
                }}>
                <Progress max={1} value={usage / total} />
              </Flex>
              <Text
                ml="tiny"
                sx={{
                  color: "primary",
                  width: "15%",
                  textAlign: "right",
                }}>
                {formatPercentage((usage / total) * 100)}%
              </Text>
            </Flex>
          ) : (
            <Flex mb="tiny">
              <Flex
                p="tiny"
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "progressBg",
                  width: "100%",
                  borderRadius: 10,
                  height: "1.375rem",
                }}>
                <SelfcareIcon name="infinity" />
              </Flex>
            </Flex>
          )}

          <Text
            variant="tablelink"
            onClick={() => {
              setUserCode(accountCode);
              setRedirect(true);
              dispatch(getAccount(accountCode));
            }}>
            {Intl.formatMessage({ id: "lbl.link_usage_details" })}
          </Text>
        </Box>
      </Flex>
    );
  };

  const headers = [
    <Flex
      key="headersSearchBar"
      sx={{
        ml: "-0.5rem",
        alignItems: "center",
        justifyContent: "center",
        mb: "-0.6rem",
      }}>
      <SearchBar />
    </Flex>,
    <Flex
      key="headersAmountUsed"
      sx={{
        alignItems: "center",
        justifyContent: "flex-end",
        mb: "-0.7rem",
        height: "3rem",
      }}>
      <FormattedMessage id="lbl.amount_used" />
    </Flex>,
    <Flex
      key="headersPerUsed"
      sx={{
        alignItems: "center",
        justifyContent: "center",
        mb: "-0.7rem",
        height: "3rem",
      }}>
      <FormattedMessage id="lbl.per_used" />
    </Flex>,
    <Flex
      key="headersUsageDetails"
      sx={{
        alignItems: "center",
        justifyContent: "center",
        mb: "-0.7rem",
        height: "3rem",
      }}>
      <FormattedMessage id="lbl.usage_details.header" />
    </Flex>,
  ];

  const Consumed = ({ consumedAmount, consumedUnit = fubUnits.unit, ...props }) => {
    const unitDescription =
      consumedUnit.toUpperCase() !== "MB" &&
      consumedUnit.toUpperCase() !== "GB" &&
      parseFloat(consumedAmount) === 1
        ? consumedUnit.slice(0, consumedUnit.length - 1)
        : consumedUnit;
    if (fubUnits.unit === "$")
      return (
        <Text sx={{ fontWeight: "semiBold", ...props.sx }}>
          <SelfcareAmount amount={consumedAmount} />
        </Text>
      );
    return (
      <Text
        sx={{
          fontWeight: "semiBold",
          ...props.sx,
        }}
        {...props}>
        <FormattedMessage
          id="lbl.consumed"
          values={{
            amount: parseFloat(consumedAmount),
            unit: unitDescriptionTranslation(unitDescription),
          }}
        />
      </Text>
    );
  };

  return (
    <Flex sx={{ maxWidth: ["100%", "100%", "72rem", "78.75rem"], flexDirection: "column" }}>
      <Flex sx={{ alignItems: "center", flexDirection: ["column", "column", "row"] }}>
        <Flex
          sx={{
            flexDirection: ["column", "row", "row"],
            justifyContent: "space-between",
            alignItems: "center",
            width: ["100%", "100%", "fit-content"],
          }}>
          <Heading
            my={["none", "default", "medium"]}
            mr="default"
            sx={{ textWrap: "nowrap", minWidth: "fit-content" }}>
            <FormattedMessage id="lbl.shared_plan_heading" />
          </Heading>

          <SharedPlanDropMenu />
        </Flex>

        <Flex
          mt={["default", 0]}
          mb={[0, "smallish", 0]}
          ml={[0, 0, "default"]}
          px="default"
          color="textDark"
          sx={{
            width: ["100%", "100%", "41rem"],
            border: "solid 1px",
            borderColor: "border",
            borderRadius: "9px",
            height: "3.125rem",
            alignItems: "center",
            justifyContent: "space-between",
          }}>
          <Flex
            sx={{
              fontWeight: "regular",
              fontSize: [2, 2, 3, 3],
              flexDirection: selectedSharedPlan.total > 0 ? ["column", "row", "row"] : "row",
              width: selectedSharedPlan.total > 0 ? ["100%", "fit-content"] : "fit-content",
            }}>
            <Flex mb={["0.1rem", 0]} sx={{ justifyContent: "space-between" }}>
              <Flex>
                <Text sx={{ fontWeight: "medium" }}>
                  <FormattedMessage id="lbl.shared_used" />:
                </Text>
                <Text ml="small">
                  {selectedSharedPlan.total > 0 &&
                    formatPercentage((fubUnits.used / fubUnits.total) * 100) + "%"}
                </Text>
              </Flex>

              {selectedSharedPlan.total > 0 && isMobile && (
                <Flex>
                  <Text sx={{ fontWeight: "medium" }}>
                    <FormattedMessage id="lbl.lines" />:
                  </Text>
                  <Text ml="tiny">{filteredMembers().length}</Text>
                </Flex>
              )}
            </Flex>

            {selectedSharedPlan.total > 0 ? (
              <Flex ml={[0, "tiny", "tiny"]}>
                <Text>
                  (
                  <Consumed consumedAmount={formatQuantity(fubUnits.used)} />
                  <Text mx="tiny">
                    <FormattedMessage id="lbl.out_of" />
                  </Text>
                  <Consumed consumedAmount={formatQuantity(fubUnits.total)} />)
                </Text>
              </Flex>
            ) : (
              <Flex ml={[0, "large", "tiny"]}>
                <Consumed consumedAmount={formatQuantity(fubUnits.used)} />
              </Flex>
            )}
          </Flex>

          {!isMobile && (
            <Flex>
              <Text sx={{ fontWeight: "medium" }}>
                <FormattedMessage id="lbl.lines" />:
              </Text>
              <Text ml="tiny">{filteredMembers().length}</Text>
            </Flex>
          )}
        </Flex>
      </Flex>

      <SelfcareTable
        data_testid="table"
        mt={["default", 0]}
        sx={{ width: ["43rem", "100%", "72rem", "78.75rem"] }}
        px={["largeish", "largeish", "large", "larger"]}
        numRows={filteredMembers().length}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        setCurrentPage={setCurrentPage}
        gridColumns={gridColumns}
        headers={headers}
        isLoading={shared_usage_details_status === "loading"}>
        {filteredMembers().length <= 0 ? (
          <Flex py="default">
            <Flex sx={{ textAlign: ["center", "left", "left"], flexDirection: "column" }}>
              <Text sx={{ color: "primary" }}>
                <FormattedMessage id="lbl.no_visible_services" />
              </Text>
            </Flex>
          </Flex>
        ) : (
          filteredMembers()
            .sort((a, b) => b.used - a.used)
            .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
            .map(
              (member, index) =>
                shared_usage_details_status !== "loading" && (
                  <Box key={member.phoneNumber}>
                    <MemberRow
                      gridColumns={gridColumns}
                      ani={formatPhoneNumber(member.phoneNumber)}
                      usage={member.used / fubUnits.baseUnitRate}
                      accountCode={getAccountCode(member.phoneNumber)}
                      unit={member.actualUnitDescription}
                      total={fubUnits.total}
                      formatQuantity={formatQuantity}
                    />

                    {index !== itemsPerPage - 1 && index !== filteredMembers().length - 1 && (
                      <HorizontalRule key={index} />
                    )}
                  </Box>
                )
            )
        )}
      </SelfcareTable>
      <Spinner isOpen={isRedirect} />

      <GoBack onBack={goBack} />
    </Flex>
  );
};

export default SharedPlanMembersTable;
