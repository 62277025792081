import React, { useRef, useEffect } from "react";

const OutsideClickHandler = ({ children, onClickOutside }) => {
  const refEvent = useRef();

  useEffect(() => {
    const handleMouseDown = e => {
      if (!refEvent.current.contains(e.target)) {
        e.stopPropagation();
        onClickOutside();
      }
    };

    const handleKeyDown = e => {
      if (e.key === "Escape") {
        onClickOutside();
      }
    };

    document.addEventListener("mousedown", handleMouseDown, true);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown, true);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClickOutside]);

  return <div ref={refEvent}>{children}</div>;
};

export default OutsideClickHandler;
