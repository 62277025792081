import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FacadeAPI } from "../../rest/RestClient";
import { semaphore } from "../semaphore";
import { checkAndRefreshToken, REFRESH_DONE, REFRESH_FAIL } from "./AuthSlice";

export const getBillingSummary = createAsyncThunk(
  "billingSlice/getBillingSummary",
  async (accountCode, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("billingSlice/getBillingSummary"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.GET("/account/" + accountCode + "/billingSummary");

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getInvoices = createAsyncThunk(
  "billingSlice/getInvoices",
  async (accountCode, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("billingSlice/getInvoices"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.GET("/account/" + accountCode + "/invoices");

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getInvoiceData = createAsyncThunk(
  "billingSlice/getInvoiceData",
  async ({ id, invoiceImageType }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("billingSlice/getInvoiceData"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.GET(
        "/invoice/" + id + "/image",
        { invoiceImageType },
        {
          noTimeout: true,
          expectedResponse: "blob",
        }
      );

      var file = new Blob([response], { type: "application/pdf" });
      return URL.createObjectURL(file);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBalance = createAsyncThunk(
  "billingSlice/getBalance",
  async (accountCode, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("billingSlice/getBalance"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.GET("/account/" + accountCode + "/balance");

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPayments = createAsyncThunk(
  "billingSlice/getPayments",
  async (accountCode, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("billingSlice/getPayments"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.GET("/account/" + accountCode + "/transactions");

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getInvoiceOptionFeature = createAsyncThunk(
  "billingSlice/getInvoiceOptionFeature",
  async (accountCode, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("billingSlice/getInvoiceOptionFeature"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.GET("/account/" + accountCode + "/invoiceOption");

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const billingSlice = createSlice({
  name: "billing",
  initialState: {
    billingSummary: null,
    balance: null,
    invoices: null,
    payments: null,
    invoiceOptionFeature: null,
    invoiceData: null,
    invoice_data_id: null,

    billingLoading: null,
    billingError: null,

    billing_summary_status: null,
    balance_status: null,
    invoice_status: null,
    payment_status: null,
    invoice_option_status: null,
    invoice_data_status: null,
  },
  reducers: {
    setBillingSummaryStatus: (state, action) => {
      state.billing_summary_status = action.payload;
    },
    setBalanceStatus: (state, action) => {
      state.balance_status = action.payload;
    },
    setInvoiceStatus: (state, action) => {
      state.invoice_status = action.payload;
    },
    setPaymentStatus: (state, action) => {
      state.payment_status = action.payload;
    },
    setInvoiceOptionStatus: (state, action) => {
      state.payment_status = action.payload;
    },
    setInvoiceDataStatus: (state, action) => {
      state.invoice_data_status = action.payload;
    },
    setInvoiceDataId: (state, action) => {
      state.invoice_data_id = action.payload;
    },
  },

  extraReducers: {
    [getInvoiceOptionFeature.pending]: state => {
      state.billingLoading = true;
      state.billingError = null;
      state.invoice_option_status = "loading";
    },
    [getInvoiceOptionFeature.fulfilled]: (state, action) => {
      state.billingLoading = false;
      state.invoiceOptionFeature = action.payload;
      state.invoice_option_status = "success";
    },
    [getInvoiceOptionFeature.rejected]: (state, action) => {
      state.billingLoading = false;
      state.billingError = action.payload;
      state.invoice_option_status = "failed";
    },

    [getBillingSummary.pending]: state => {
      state.billingLoading = true;
      state.billingError = null;
      state.billing_summary_status = "loading";
    },
    [getBillingSummary.fulfilled]: (state, action) => {
      state.billingLoading = false;
      state.billingSummary = action.payload;
      state.billing_summary_status = "success";
    },
    [getBillingSummary.rejected]: (state, action) => {
      state.billingLoading = false;
      state.billingError = action.payload;
      state.billing_summary_status = "failed";
    },

    [getBalance.pending]: state => {
      state.billingLoading = true;
      state.billingError = null;
      state.balance_status = "loading";
    },
    [getBalance.fulfilled]: (state, action) => {
      state.billingLoading = false;
      state.balance = action.payload;
      state.balance_status = "success";
    },
    [getBalance.rejected]: (state, action) => {
      state.billingLoading = false;
      state.billingError = action.payload;
      state.balance_status = "failed";
    },

    [getInvoices.pending]: state => {
      state.billingLoading = true;
      state.billingError = null;
      state.invoice_status = "loading";
    },
    [getInvoices.fulfilled]: (state, action) => {
      state.billingLoading = false;
      state.invoices = action.payload.invoiceList;
      state.invoice_status = "success";
    },
    [getInvoices.rejected]: (state, action) => {
      state.billingLoading = false;
      state.billingError = action.payload;
      state.invoice_status = "failed";
    },

    [getPayments.pending]: state => {
      state.billingLoading = true;
      state.billingError = null;
      state.payment_status = "loading";
    },
    [getPayments.fulfilled]: (state, action) => {
      state.billingLoading = false;
      state.payments = action.payload.transactionList;
      state.payment_status = "success";
    },
    [getPayments.rejected]: (state, action) => {
      state.billingLoading = false;
      state.billingError = action.payload;
      state.payment_status = "failed";
    },

    [getInvoiceData.pending]: state => {
      state.billingLoading = true;
      state.billingError = null;
      state.invoice_data_status = "loading";
    },
    [getInvoiceData.fulfilled]: (state, action) => {
      state.billingLoading = false;
      state.invoiceData = action.payload;
      state.invoice_data_status = "success";
    },
    [getInvoiceData.rejected]: (state, action) => {
      state.billingLoading = false;
      state.billingError = action.payload;
      state.invoice_data_status = "failed";
    },
  },
});

export const {
  setInvoiceOptionStatus,
  setBillingSummaryStatus,
  setBalanceStatus,
  setInvoiceStatus,
  setInvoiceDataStatus,
  setInvoiceDataId,
} = billingSlice.actions;

export default billingSlice.reducer;
