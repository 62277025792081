import React from "react";
import ContentLoader from "react-content-loader";

const DeviceDetailsSkeleton = () => (
  <ContentLoader
    speed={2}
    viewBox="0 0 900 282"
    backgroundColor="#EEEEEE"
    foregroundColor="#d6d3d3">
    <rect x="35" y="40" width="210px" height="210px" />
    <rect x="535" y="65" width="230" height="30" />
    <rect x="285" y="155" width="230" height="25" />
    <rect x="285" y="210" width="230" height="25" />

    <rect x="665" y="155" width="230" height="25" />
    <rect x="665" y="210" width="230" height="25" />
  </ContentLoader>
);

export default DeviceDetailsSkeleton;
