import React from "react";

const Home = ({ fill = "none", stroke = "#fff", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="47.996"
    height="47.493"
    viewBox="0 0 47.996 47.493"
    {...props}>
    <g data-name="home icon">
      <g data-name="Path 8" fill={fill} strokeMiterlimit="10">
        <path
          d="M43.963 19.472 23.789.989a1.943 1.943 0 0 0-2.577 0L1.038 19.472a1.656 1.656 0 0 0 1.288 2.849h2.8v20a1.751 1.751 0 0 0 1.822 1.669h31.107a1.751 1.751 0 0 0 1.822-1.669v-20h2.8a1.656 1.656 0 0 0 1.286-2.849z"
          stroke={fill}
          transform="translate(1.497 1.5)"
        />
        <path
          d="M22.5.5c-.466 0-.932.163-1.288.49L1.038 19.471c-1.148 1.052-.335 2.85 1.288 2.85h2.799v20.002c0 .921.815 1.669 1.821 1.669h31.109c1.006 0 1.822-.748 1.822-1.67V22.323h2.799c1.622 0 2.435-1.798 1.287-2.85L23.79.99A1.905 1.905 0 0 0 22.501.5m0-2c.988 0 1.925.36 2.64 1.014l20.173 18.484c1.108 1.015 1.478 2.56.943 3.937-.564 1.45-1.97 2.387-3.581 2.387h-.8v18.002c0 2.023-1.714 3.669-3.821 3.669H6.946c-2.107 0-3.821-1.646-3.821-3.67V24.323h-.799c-1.612 0-3.017-.937-3.581-2.387-.536-1.377-.166-2.922.942-3.937L19.861-.485a3.892 3.892 0 0 1 2.64-1.015z"
          fill={stroke}
          stroke={fill}
          transform="translate(1.497 1.5)"
        />
      </g>
      <path
        data-name="Path 9"
        d="M25.2 66.724V46.441a1.5 1.5 0 0 1 1.467-1.531h11.555a1.5 1.5 0 0 1 1.467 1.531v20.283"
        transform="translate(-8.447 -20.724)"
        stroke={stroke}
        strokeMiterlimit="10"
        fill={fill}
        strokeWidth="2px"
      />
    </g>
  </svg>
);

export default Home;
