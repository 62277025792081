const buildItemArray = isFulAccess => {
  let items = [];
  if (isFulAccess) {
    items = [
      {
        title: "lbl.menu_home",
        icon: "home",
        location: "/home",
      },
      {
        title: "lbl.my_lines",
        icon: "myServices",
        location: "/lines",
      },
      {
        title: "lbl.my_account",
        icon: "myAccount",
        location: "/summary",
      },
      {
        title: "lbl.my_invoices",
        icon: "myInvoices",
        location: "/invoices",
      },
      {
        title: "lbl.my_payment",
        icon: "myPayments",
        location: "/payments",
      },

      {
        title: "lbl.request_assistance_menu",
        icon: "requestAssist",
        location: "/requestAssist",
      },
    ];
  } else {
    items = [
      {
        title: "lbl.menu_home",
        icon: "home",
        location: "/home",
      },
      {
        title: "lbl.request_assistance_menu",
        icon: "requestAssist",
        location: "/requestAssist",
      },
    ];
  }
  return items;
};
export default buildItemArray;
