import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { ModalContext } from "./ModalProvider";

function callIfDefined(fun, ...args) {
  fun && fun(...args);
}

function Modal({
  WrapperComponent,
  children,
  onBackgroundClick,
  beforeOpen,
  afterOpen,
  beforeClose,
  afterClose,
  backgroundProps,
  isOpen: isOpenProp,
  ...rest
}) {
  const node = useRef(null);
  const isTransitioning = useRef(false);

  const [isOpen, setIsOpen] = useState(false);

  // Handle opening and closing
  useEffect(() => {
    function handleIsOpenChange(value) {
      setIsOpen(value);
      value ? callIfDefined(afterOpen) : callIfDefined(afterClose);
    }

    function handleChange(callback) {
      if (callback) {
        const maybePromise = callback();
        if (typeof maybePromise?.then === "function") {
          isTransitioning.current = true;

          maybePromise.then(() => {
            handleIsOpenChange(isOpenProp);

            isTransitioning.current = false;
          });
        } else {
          handleIsOpenChange(isOpenProp);
        }
      } else {
        handleIsOpenChange(isOpenProp);
      }
    }

    if (isOpen !== isOpenProp && !isTransitioning.current) {
      if (isOpenProp) {
        handleChange(beforeOpen);
      } else {
        handleChange(beforeClose);
      }
    }
  }, [isTransitioning, isOpen, isOpenProp, beforeOpen, beforeClose, afterClose, afterOpen]);

  function handleBackgroundClick(e) {
    if (node.current === e.target) {
      onBackgroundClick && onBackgroundClick(e);
    }
  }

  let content;
  if (WrapperComponent) {
    content = <WrapperComponent {...rest}>{children}</WrapperComponent>;
  } else {
    content = children;
  }

  return (
    <ModalContext.Consumer>
      {({ modalNode, BackgroundComponent }) => {
        if (modalNode && BackgroundComponent && isOpen) {
          return ReactDOM.createPortal(
            <BackgroundComponent {...backgroundProps} onClick={handleBackgroundClick} ref={node}>
              {content}
            </BackgroundComponent>,
            modalNode
          );
        } else {
          return null;
        }
      }}
    </ModalContext.Consumer>
  );
}

Modal.styled = function (...args) {
  const wrap = args ? styled.div(...args) : styled.div();

  // eslint-disable-next-line react/display-name
  return function (props) {
    return <Modal WrapperComponent={wrap} {...props} />;
  };
};

Modal.defaultProps = {
  backgroundProps: {},
};

Modal.propTypes = {
  WrapperComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
  onBackgroundClick: PropTypes.func,
  beforeOpen: PropTypes.func,
  afterOpen: PropTypes.func,
  beforeClose: PropTypes.func,
  afterClose: PropTypes.func,
  backgroundProps: PropTypes.object,
  isOpen: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

export default Modal;
