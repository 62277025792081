import React from "react";
const PhoneMenu = ({ fill = "none", stroke = "#0c3865", innerFill = "#fff", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.826"
    height="30"
    viewBox="0 0 17.826 30"
    {...props}>
    <g data-name="Free-Pack / mobile-phone-2">
      <path
        d="M444.005 283.25a.255.255 0 0 1 .255.255h0a.255.255 0 0 1-.255.255h0a.255.255 0 0 1-.255-.255h0a.255.255 0 0 1 .255-.255"
        transform="translate(-435.092 -257.569)"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M437.5 264.935a2.434 2.434 0 0 1 2.435-2.435h10.957a2.434 2.434 0 0 1 2.435 2.435v23.13a2.434 2.434 0 0 1-2.435 2.435h-10.957a2.434 2.434 0 0 1-2.435-2.435z"
        transform="translate(-436.5 -261.5)"
        fillRule="evenodd"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        data-name="Line 81"
        transform="translate(7.962 2.015)"
        fill={innerFill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        strokeMiterlimit="10"
        d="M0 0h2"
      />
    </g>
  </svg>
);
export default PhoneMenu;
