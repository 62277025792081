import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { Flex, Text, Button, Heading } from "@theme-ui/components";

import { PaymentRow, PaymentSkeleton } from "../../components/PaymentRow";
import GetErrorDescription from "../../components/GetErrorDescription";
import { StyledModalMessage } from "../../components/modals";
import { SelfcareTable } from "../../components/base";
import Layout from "../../containers/Layout";

import { getPayments } from "../../redux/slices/BillingSlice";

const Payments = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { accountCode: accountCodePath } = useParams();

  const { master } = useSelector(state => state.user);
  const { payments, payment_status, billingError } = useSelector(state => state.billing);

  const itemsPerPage = 10;
  const masterAccountCode = master?.accountCode;
  const accountCode = accountCodePath ? accountCodePath : masterAccountCode;
  useEffect(() => {
    if (!payments && accountCode) {
      dispatch(getPayments(accountCode));
    }
  }, [accountCode, dispatch, payments]);

  useEffect(() => {
    if (payment_status === "failed") {
      history.push("/error");
    }
  }, [history, payment_status]);

  const tryAgain = () => {
    dispatch(getPayments(accountCode));
  };

  const initialPage = 1;
  const [currentPage, setCurrentPage] = useState(initialPage);
  const gridColumns = ["7rem 11rem 5rem 8rem", "0.25fr 0.7fr 0.2fr 0.3fr"];
  const gridAlign = ["left", "left", "right", "right"];

  var numberOfPayments;
  var paymentToDisplay;

  if (payment_status === "success") {
    paymentToDisplay = payments.filter(payment => payment.paymentMethod !== 100022);
    numberOfPayments = paymentToDisplay.length;
  }

  return (
    <Layout>
      <Heading>
        <FormattedMessage id="lbl.my_payment" />
      </Heading>

      {payment_status === "loading" && <PaymentSkeleton />}

      {payment_status === "success" && paymentToDisplay && numberOfPayments === 0 && (
        <Flex mt={["0", "default", "default"]} sx={{ flexDirection: "column" }}>
          <Text sx={{ color: "primary" }}>
            <FormattedMessage id="lbl.no_payments" />
          </Text>
          <Text sx={{ color: "note" }}>
            <FormattedMessage id="lbl.no_payments_subnote" />
          </Text>
        </Flex>
      )}
      {payment_status === "success" && paymentToDisplay && numberOfPayments > 0 && (
        <Flex sx={{ flexDirection: "column" }}>
          <Flex sx={{ width: "100%" }}>
            <SelfcareTable
              sx={{ width: ["36rem", "100%", "72rem", "78.75rem"] }}
              px="large"
              headers={[
                "lbl.payment_tbl_paid_on",
                "lbl.payment_tbl_type",
                "lbl.payment_tbl_amount",
                "lbl.blank",
              ]}
              gridAlign={gridAlign}
              gridColumns={gridColumns}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              numRows={numberOfPayments}
              itemsPerPage={itemsPerPage}>
              {paymentToDisplay
                .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                .map((payment, index) => (
                  <PaymentRow
                    key={payment.transactionNumber}
                    payment={payment}
                    displaySeparator={
                      (index + 1) % itemsPerPage !== 0 &&
                      paymentToDisplay.indexOf(payment) !== numberOfPayments - 1
                    }
                    gridColumns={gridColumns}
                  />
                ))}
            </SelfcareTable>
          </Flex>
        </Flex>
      )}

      <StyledModalMessage
        isOpen={payment_status === "failed"}
        message={<GetErrorDescription error={billingError} />}
        onRequestClose={tryAgain}
        type="error">
        <Button variant="secondarySmall" onClick={tryAgain}>
          <FormattedMessage id="lbl.Try_Again" />
        </Button>
      </StyledModalMessage>
      <Flex sx={{ my: [0, "default", "medium"] }} />
    </Layout>
  );
};

export default Payments;
