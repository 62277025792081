import React, { useState } from "react";
import { useThemeUI } from "@theme-ui/core";
import { Text, Flex } from "@theme-ui/components";
import { SelfcareIcon } from "../icons";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";

const NavMenuItem = ({ title, onClick, icon, pathname, ...props }) => {
  const [hover, setHover] = useState(false);
  const { theme } = useThemeUI();
  const location = useLocation();
  const isFocused = location.pathname.includes(pathname);

  return (
    <Flex
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      sx={{
        pt: ["small", "small", "largeish"],
        flexDirection: "column",
        px: ["default", "default", "default", "large"],
        bg: isFocused || hover ? "rgba(255, 255,255, 0.1)" : "none",
        cursor: "pointer",
        alignItems: "center",
        transition: "100ms ease-in",
        width: ["2.5rem", "12rem", "8rem", "12rem"],
      }}
      onClick={onClick}
      {...props}>
      <SelfcareIcon
        stroke={theme.colors.navItemStroke}
        name={icon}
        sx={{
          width: ["1rem", "2rem", "2rem", "2rem"],
          height: ["1rem", "2rem", "2rem", "2rem"],
          cursor: "pointer",
          mb: [0, 0, "tiny"],
        }}
      />
      <Text variant="navigationMenu" color={theme.colors.navItemStroke}>
        <FormattedMessage id={title} />
      </Text>
    </Flex>
  );
};

export default NavMenuItem;
