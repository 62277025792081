import React, { useState } from "react";
import { Box, Flex, Text } from "@theme-ui/components";

const Tooltip = ({ value, position = "default", customProps, children, childrenPY, ...props }) => {
  const [mustDisplay, setMustDisplay] = useState(false);
  let defaultMargins = { ml: "0rem", mt: "0rem" };
  let customPropsMobile = customProps && customProps.mobile ? customProps.mobile : defaultMargins;
  let customPropsTablet = customProps && customProps.tablet ? customProps.tablet : defaultMargins;
  let customPropsDesktop =
    customProps && customProps.desktop ? customProps.desktop : defaultMargins;
  let properties;

  const [cursorXY, setCursorXY] = useState({ x: 0, y: 0 });
  switch (position) {
    case "top":
      properties = {
        flexDirection: "column",
        alignItems: "center",
        mt: "tooltipTop",
      };
      break;
    case "top-noflex":
      properties = {
        alignItems: "center",
        mt: "tooltipTop",
      };
      break;
    case "bottom":
      properties = {
        flexDirection: "column",
        alignItems: "center",
        mt: "medium",
      };
      break;
    case "left":
      properties = {
        flexDirection: "row",
        alignItems: "center",
        ml: "tooltipLeft",
      };
      break;
    case "right":
      properties = {
        flexDirection: "row",
        alignItems: "center",
        ml: "large",
      };
      break;
    case "custom":
      properties = {
        flexDirection: "row",
        alignItems: "center",
        ml: [customPropsMobile.ml, customPropsTablet.ml, customPropsDesktop.ml],
        mt: [customPropsMobile.mt, customPropsTablet.mt, customPropsDesktop.mt],
      };
      break;
    default:
      properties = {
        flexDirection: ["column", "column", "row"],
        alignItems: ["flex-end", "flex-end", "center"],
        ml: ["0", "0", "large"],
        mt: ["large", "large", "0"],
      };
  }

  return (
    <Flex
      sx={{
        flexDirection: properties.flexDirection,
        alignItems: properties.alignItems,
      }}
      onMouseEnter={e => {
        setCursorXY({ x: e.pageX, y: e.clientY });
        setMustDisplay(true);
      }}
      //for tochscreen
      onTouchStart={e => {
        e.cancelable && e.preventDefault();
        e.stopPropagation();
        setMustDisplay(!mustDisplay);
      }}
      onMouseLeave={() => {
        setMustDisplay(false);
      }}
      {...props}>
      <Box py={childrenPY} sx={{ ...props.sx }}>
        {children}
      </Box>
      {value && (
        <Box
          sx={{
            width: "auto",
            display: mustDisplay ? "inline-block" : "none",
            overflowWrap: "break-word",
            position: "fixed",
            top: cursorXY.y,
            left: cursorXY.x,
            zIndex: 111,
            borderRadius: "card",
            boxShadow: "tooltip",
            fontSize: "note",
            backgroundColor: "contentBg",
            paddingY: "0.5em",
            textAlign: "center",
          }}>
          <Text sx={{ color: "textDark", px: "default" }}>{value}</Text>
        </Box>
      )}
    </Flex>
  );
};

export default Tooltip;
