import React from "react";
import { Flex } from "@theme-ui/components";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NavMenuItem from "./NavMenuItem";
import buildItemArray from "./buildItemsArray";
import { setSharedMembersDisplay } from "../../redux/slices/UserSlice";

const NavMenu = props => {
  const history = useHistory();
  const location = useLocation();
  const { master } = useSelector(state => state.user);
  const dispatch = useDispatch();
  if (!master) {
    return null;
  }
  const items = buildItemArray(!master.serviceAgreement);

  const onClickNavItem = (ind, itemLocation) => {
    if (location.pathname !== itemLocation) {
      dispatch(setSharedMembersDisplay("default"));
      history.push(itemLocation);
    }
  };

  return (
    <Flex
      {...props}
      sx={{
        height: "100%",
        justifyContent: ["center", "center", "start", "start"],
        px: [0, 0, 0, "large"],
      }}>
      {items.map((item, index) => (
        <NavMenuItem
          key={index}
          onClick={() => onClickNavItem(index, item.location)}
          pathname={item.location}
          title={item.title}
          icon={item.icon}
        />
      ))}
    </Flex>
  );
};

export default NavMenu;
