import React from "react";
const MailClosed = ({ fill = "none", stroke = "#48c0b6", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.551"
    height="11.732"
    viewBox="0 0 17.551 11.732"
    {...props}>
    <g id="Group_1669" data-name="Group 1669" transform="translate(-47.137 -383.51)">
      <path
        id="Path_851"
        data-name="Path 851"
        className="cls-1"
        d="m-20175-7819.8 7.648 4.344 7.166-4.344"
        transform="translate(20223.5 8204.971)"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Path_853"
        data-name="Path 853"
        className="cls-1"
        d="M-20160.184-7815.461H-20175v9.732h14.816z"
        transform="translate(20223.5 8199.971)"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);
export default MailClosed;
