import React from "react";

const InfinityIcon = ({ fill = "none", stroke = "#0c3865", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33.348"
    height="16.258"
    viewBox="0 0 33.348 16.258"
    {...props}>
    <path
      data-name="Path 862"
      d="M100.058 174.46a6.131 6.131 0 1 0 4.334-10.46 8.47 8.47 0 0 0-4.98 1.794 24.73 24.73 0 0 0-3.563 4.336 24.763 24.763 0 0 1-3.565 4.334 8.467 8.467 0 0 1-4.98 1.794 6.129 6.129 0 1 1 4.334-10.464"
      transform="translate(-79.174 -161.996)"
      fill={fill}
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
  </svg>
);

export default InfinityIcon;
