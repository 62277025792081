import React from "react";

const Members = ({ fill = "#0c3865", stroke = "#fff", ...props }) => (
  <svg
    id="Group_2346"
    data-name="Group 2346"
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="35"
    viewBox="0 0 34 35"
    {...props}>
    <ellipse
      id="Ellipse_46"
      data-name="Ellipse 46"
      cx="17"
      cy="17.5"
      rx="17"
      ry="17.5"
      fill={fill}
      transition="100ms ease-in"
    />
    <g id="Group_2335" data-name="Group 2335" transform="translate(7.821 8.92)">
      <g id="Layer_1" transform="translate(0 5.193)">
        <g id="Group_28" data-name="Group 28">
          <path
            id="Path_76"
            data-name="Path 76"
            fill="none"
            stroke={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2px"
            d="M6.5 23.182a4.758 4.758 0 0 1 9.515 0z"
            transform="translate(-6.5 -13.136)"
          />
          <ellipse
            id="Ellipse_7"
            data-name="Ellipse 7"
            fill="none"
            stroke={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2px"
            cx="2.679"
            cy="2.626"
            rx="2.679"
            ry="2.626"
            transform="translate(2.057)"
          />
        </g>
      </g>
      <g id="Layer_1-2" data-name="Layer_1" transform="translate(9.515)">
        <g id="Group_28-2" data-name="Group 28">
          <path
            id="Path_76-2"
            data-name="Path 76"
            fill="none"
            stroke={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2px"
            d="M6.5 23.182a4.758 4.758 0 0 1 9.515 0z"
            transform="translate(-6.5 -13.136)"
          />
          <ellipse
            id="Ellipse_7-2"
            data-name="Ellipse 7"
            fill="none"
            stroke={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2px"
            cx="2.679"
            cy="2.626"
            rx="2.679"
            ry="2.626"
            transform="translate(2.057)"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default Members;
