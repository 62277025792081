import React from "react";
const Logout = ({ fill = "none", stroke = "#7392bb", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...props}>
    <g id="log-out" transform="translate(-2 -2)">
      <path
        id="Path_814"
        data-name="Path 814"
        className="cls-1"
        d="M8.2 19H4.735A1.757 1.757 0 0 1 3 17.222V4.778A1.757 1.757 0 0 1 4.735 3H8.2"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Path_815"
        data-name="Path 815"
        className="cls-1"
        d="m16 16.061 4.337-4.531L16 7"
        transform="translate(-1.337 -.531)"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Line_111"
        data-name="Line 111"
        className="cls-1"
        transform="translate(8.388 11.199)"
        d="M10.5 0H0"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);
export default Logout;
