import React from "react";
const ViewEye = ({ fill = "#a1b7d1", stroke = "#fff", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="33" height="18" viewBox="0 0 33 18" {...props}>
    <title>{props.tooltip}</title>
    <g data-name="Group 1715">
      <path
        data-name="Intersection 1"
        d="M0 9a19.264 19.264 0 0 1 16.5-9A19.264 19.264 0 0 1 33 9a19.266 19.266 0 0 1-16.5 9A19.266 19.266 0 0 1 0 9z"
        // style="fill:#a1b7d1"
        fill={fill}
      />
      <path
        data-name="Subtraction 5"
        d="M814.961 6563.714a6 6 0 1 1 3.095-11.142l-2.633 4.68h5.521c.012.158.018.313.018.462a6.007 6.007 0 0 1-6.001 6z"
        transform="translate(-797.962 -6548.714)"
        // style="fill:#fff"
        fill={stroke}
      />
    </g>
  </svg>
);
export default ViewEye;
