import * as React from "react";
const SVGComponent = ({ fill = "#000", stroke, ...props }) => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M11.849 22.692a.479.479 0 0 1-.526-.428l-.856-8.19a.48.48 0 0 1 .425-.53l.476-.05a.48.48 0 0 1 .526.428l.856 8.19a.48.48 0 0 1-.425.53l-.476.05Zm4.304-.05a.48.48 0 0 1-.425-.53l.856-8.19a.48.48 0 0 1 .526-.428l.475.05a.48.48 0 0 1 .426.53l-.856 8.19a.479.479 0 0 1-.526.428l-.476-.05Z"
      fill={fill}
      stroke={stroke}
    />
    <path
      clipRule="evenodd"
      d="M11.923 1c-.574 0-1.092.344-1.319.875L9.543 4.37h-4.63A1.92 1.92 0 0 0 3 6.296v2.408c0 .981.73 1.791 1.672 1.91L7.63 26.227c.09.45.482.774.938.774h11.784a.958.958 0 0 0 .946-.82l2.15-15.584A1.923 1.923 0 0 0 25 8.704V6.296a1.92 1.92 0 0 0-1.913-1.926h-4.63l-1.063-2.495A1.435 1.435 0 0 0 16.075 1h-4.152Zm4.452 3.37-.367-.86a.956.956 0 0 0-.879-.584h-2.26a.957.957 0 0 0-.879.584l-.366.86h4.75Zm5.094 6.682a.4.4 0 0 0-.397-.455l-13.958.032a.4.4 0 0 0-.392.473L9.198 24.26a1 1 0 0 0 .983.815h8.475a1 1 0 0 0 .99-.863l1.823-13.16Zm.661-2.348a.96.96 0 0 0 .957-.963v-.482a.96.96 0 0 0-.957-.963H5.87a.96.96 0 0 0-.957.963v.482a.96.96 0 0 0 .957.963h16.26Z"
      fill={fill}
      stroke={stroke}
      fillRule="evenodd"
    />
  </svg>
);
export default SVGComponent;
