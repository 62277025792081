import React, { useEffect, useState, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import { Flex, Text, Button, Heading, Link } from "@theme-ui/components";

import { InvoiceRow, InvoiceSkeleton } from "../../components/InvoiceRow";
import GetErrorDescription from "../../components/GetErrorDescription";
import { StyledModalMessage } from "../../components/modals";
import { SelfcareTable } from "../../components/base";
import Layout from "../../containers/Layout";

import { getInvoices } from "../../redux/slices/BillingSlice";
import { LANGUAGE_ISOCODE } from "../../common/Localization";
import { SelfcareIntlContext } from "../../contexts/SelfcareIntlContext";

const Invoices = () => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const { accountCode: accountCodePath } = useParams();

  const { invoices, invoice_status, billingError } = useSelector(state => state.billing);
  const { master } = useSelector(state => state.user);
  const { locale } = useContext(SelfcareIntlContext);

  const itemsPerPage = 10;
  const masterAccountCode = master?.accountCode;
  const accountCode = accountCodePath ? accountCodePath : masterAccountCode;
  const isFrench = locale == LANGUAGE_ISOCODE.French;

  useEffect(() => {
    if (!invoices && accountCode !== undefined) {
      dispatch(getInvoices(accountCode));
    }
  }, [accountCode, dispatch, invoices]);

  useEffect(() => {
    if (invoice_status === "failed") {
      history.push("/error");
    }
  }, [history, invoice_status]);

  const tryAgain = () => {
    dispatch(getInvoices(accountCode));
  };

  const initialPage = 1;
  const [currentPage, setCurrentPage] = useState(initialPage);

  const gridColumns = [
    "5rem 5rem 5rem 5rem 5rem 5rem",
    "0.25fr 0.25fr 0.2fr 0.2fr 0.2fr 0.2fr",
    "0.2fr 0.2fr 0.25fr 0.25fr 0.2fr 0.2fr",
    "0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr",
  ];
  const gridAlign = ["left", "center", "right", "right", "center", "center"];
  const gridGap = ["large", "large", "large"];
  return (
    <Layout>
      <Flex
        sx={{
          flexDirection: isFrench ? ["column", "row"] : "row",
          justifyContent: "space-between",
          maxWidth: ["100%", "100%", "72rem", "78.75rem"],
          alignItems: "baseline",
        }}>
        <Heading>
          <FormattedMessage id="lbl.my_invoices" />
        </Heading>
        {invoices?.length > 0 && (
          <Link
            href={intl.formatMessage({ id: "link.read_your_bill" })}
            target="_blank"
            variant="nav"
            sx={{
              marginRight: "0.5rem",
              width: "100%",
              fontWeight: "bold",
              fontSize: "1.125rem",
              textAlign: "end",
            }}>
            <FormattedMessage id="lbl.how_to_read_your_bill" />
          </Link>
        )}
      </Flex>

      {invoice_status === "loading" && <InvoiceSkeleton />}

      {invoice_status === "success" && invoices && invoices.length === 0 && (
        <Flex mt={["0", "default", "default"]} sx={{ flexDirection: "column" }}>
          <Text color="primary">
            <FormattedMessage id="lbl.no_invoices" />
          </Text>
          <Text color="note">
            <FormattedMessage id="lbl.no_invoices_subnote" />
          </Text>
        </Flex>
      )}

      {invoice_status === "success" && invoices && invoices.length > 0 && (
        <SelfcareTable
          sx={{ width: ["39.5rem", "100%", "72rem", "78.75rem"] }}
          px="large"
          headers={[
            "lbl.invoice_date",
            "lbl.invoice_due_date",
            "lbl.invoice_amount",
            "lbl.unpaid",
            "cap.view_summary",
            "cap.view_detailed",
          ]}
          gridAlign={gridAlign}
          gridColumns={gridColumns}
          gridGap={gridGap}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          numRows={invoices.length}
          itemsPerPage={itemsPerPage}>
          {invoices
            .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
            .map((invoice, index) => (
              <InvoiceRow
                key={index}
                id={invoice.invoiceId}
                invoiceDate={invoice.invDate}
                dueDate={invoice.dueDate}
                unpaidAmount={invoice.remainingAmount}
                value={invoice.value}
                displaySeparator={
                  (index + 1) % itemsPerPage !== 0 &&
                  invoices.indexOf(invoice) !== invoices.length - 1
                }
                gridColumns={gridColumns}
                gridGap={gridGap}
              />
            ))}
        </SelfcareTable>
      )}
      <Flex sx={{ my: [0, "default", "medium"] }} />
      <StyledModalMessage
        isOpen={invoice_status === "failed"}
        message={<GetErrorDescription error={billingError} />}
        onRequestClose={tryAgain}
        type="error">
        <Button variant="secondarySmall" onClick={tryAgain}>
          <FormattedMessage id="lbl.Try_Again" />
        </Button>
      </StyledModalMessage>
    </Layout>
  );
};

export default Invoices;
