import React from "react";
import { Link, Image, Flex } from "@theme-ui/components";

const ImageContainer = ({ width, image, ...props }) => {
  const imgElement = React.useRef(null);
  const imgUrl = process.env.PUBLIC_URL + image.image;

  return (
    <Flex
      sx={{
        width: width,
        height: imgElement?.current?.clientHeight,
      }}
      {...props}>
      <Link href={image.url} target="_blank">
        <Image
          ref={imgElement}
          src={imgUrl}
          sx={{
            borderRadius: "card",
          }}
        />
      </Link>
    </Flex>
  );
};

export default ImageContainer;
