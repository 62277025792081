import React from "react";
import { FormattedMessage } from "react-intl";

import { PRODUCT_STATUS } from "../UpdatePackage/JSMethods";

export const updateEasyTravelService = (rootProductCode, travelPlan) => {
  // Get the service code for easyTravel from the travel plan
  const easyTravelServiceCode = travelPlan.easyTravelServiceCode;

  // Find active services related to easyTravel based on the service code
  const services = travelPlan.services.find(service => service.code === easyTravelServiceCode);

  // Get the instance ASID of the easyTravel service, or use 0 if the service is on account but not active
  const instanceKey = (services && Object.keys(services?.instances)[0]) ?? 0;

  const rootProduct = {
    code: rootProductCode,
    subProductList: [
      // Deactivate the travel pack subproduct if it is currently active
      {
        code: travelPlan.travelPackCode,
        status: PRODUCT_STATUS.DEACTIVATED,
      },
      // Activate the easyTravel subproduct
      {
        code: travelPlan.easyTravelCode,
        status: PRODUCT_STATUS.ACTIVATED,
        serviceList: [
          {
            code: easyTravelServiceCode,
            // Toggle the presence of the easyTravel service (add or remove)
            instances: { [+instanceKey]: { status: !travelPlan.easyTravel } },
          },
        ],
      },
    ],
  };

  return rootProduct;
};

export const deactivateEasyTravelSubService = travelPlan => {
  if (travelPlan.easyTravelCode === "" || travelPlan.easyTravelServiceCode === "") return;

  // Get the service code for easyTravel from the travel plan
  const easyTravelServiceCode = travelPlan.easyTravelServiceCode;

  // Find active services related to easyTravel based on the service code
  const services = travelPlan.services.find(service => service.code === easyTravelServiceCode);

  // Get the instance ASID of the easyTravel service, or use 0 if the service is on account but not active
  const instanceKey = (services && Object.keys(services?.instances)[0]) ?? 0;

  const easyTravelSubProduct = {
    code: travelPlan.easyTravelCode,
    status: PRODUCT_STATUS.DEACTIVATED,
    serviceList: [
      {
        code: easyTravelServiceCode,
        instances: { [+instanceKey]: { status: false } },
      },
    ],
  };

  return easyTravelSubProduct;
};

export const activateTravelPackService = (
  scheduledDate,
  rootProductCode,
  travelPlan,
  serviceCode
) => {
  const easyTravelSubProduct = deactivateEasyTravelSubService(travelPlan);

  const rootProduct = {
    code: rootProductCode,
    scheduledDate,
    subProductList: [
      {
        code: travelPlan.travelPackCode,
        status: PRODUCT_STATUS.ACTIVATED,
        serviceList: [
          {
            code: serviceCode,
            instances: { [+0]: { status: true } },
          },
        ],
      },
    ],
  };

  if (easyTravelSubProduct) {
    rootProduct.subProductList.push(easyTravelSubProduct);
  }

  return rootProduct;
};

export const getFubsList = (travelPlan, nonSharedBeneficiaryUnits) => {
  if (!travelPlan?.travelPack || !nonSharedBeneficiaryUnits?.beneficiaryUnits) {
    return [];
  }
  let filteredBeneficiaryUnits = nonSharedBeneficiaryUnits?.beneficiaryUnits;

  return travelPlan.services.flatMap(service => {
    // Filter the units with the same serviceCode
    const nonSharedBeneficiaryUnitsList = filteredBeneficiaryUnits.filter(
      beneficiary => beneficiary.serviceCode === service.code
    );

    // Map the list to add service description to each unit
    const updatedUnitsList = nonSharedBeneficiaryUnitsList.map(nonSharedBeneficiaryUnit => ({
      ...nonSharedBeneficiaryUnit,
      description: service.description,
    }));

    // Create a Set with unit IDs for fast lookup
    const unitsSet = new Set(updatedUnitsList.map(unit => unit.id));

    // Filter the original units to exclude the ones present in nonSharedBeneficiaryUnitsList
    filteredBeneficiaryUnits = filteredBeneficiaryUnits.filter(
      beneficiary => !unitsSet.has(beneficiary.id)
    );

    return updatedUnitsList;
  });
};

export const getServiceAvailable = (service, withLine = false) => {
  const { relativeAging, relativeUnit } = service;
  const period = {
    isHours: false,
    isDays: false,
    isMonths: false,
  };

  if (relativeUnit === "") return;

  switch (relativeUnit) {
    case "H":
      period.isHours = true;
      break;
    case "D":
      period.isDays = true;
      break;
    case "M":
      period.isMonths = true;
      break;
  }

  return (
    <>
      {withLine && " - "}
      <FormattedMessage id="lbl.service_available" values={{ number: relativeAging, ...period }} />
    </>
  );
};
