import React, { useState } from "react";
import { Box, Flex, Text } from "@theme-ui/components";
import { FormattedDate, FormattedMessage } from "react-intl";
import { SelfcareAmount } from "../base";
import LoadDocumentModal from "../LoadDocumentModal";
import { SelfcareIcon } from "../icons";
import { useDispatch } from "react-redux";
import { getInvoiceData, setInvoiceDataStatus } from "../../redux/slices/BillingSlice";

const HorizontalRule = () => {
  return <Box sx={{ width: "100%", height: "1px" }} bg="inactive"></Box>;
};

const InvoiceRow = ({
  id,
  invoiceDate,
  dueDate,
  unpaidAmount,
  value,
  displaySeparator,
  gridColumns,
  gridGap = ["large", "large", "huge"],
}) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const getFormattedNumber = value => {
    let formattedValue;
    if (value == null) {
      formattedValue = <FormattedMessage id="lbl.not_available" />;
    } else {
      formattedValue = <SelfcareAmount amount={value} />;
    }

    return formattedValue;
  };

  const getInvoice = invoiceImageType => {
    dispatch(getInvoiceData({ id, invoiceImageType }));
    setLoading(true);
  };

  const closeDownload = () => {
    setLoading(false);
    dispatch(setInvoiceDataStatus(null));
  };

  return (
    <Flex sx={{ flexDirection: "column" }} bg="contentBg">
      <Box
        py="small"
        sx={{
          display: "grid",
          gridGap: gridGap,
          gridTemplateColumns: gridColumns,
          alignItems: "center",
          textAlign: "center",
        }}>
        <Text color="tableDate" sx={{ textAlign: "left", fontFeatureSettings: "'tnum'" }}>
          <FormattedDate value={invoiceDate} dateStyle="medium" />
        </Text>

        <Text color="tableDate" sx={{ textAlign: "center", fontFeatureSettings: "'tnum'" }}>
          <FormattedDate value={dueDate} dateStyle="medium" />
        </Text>
        <Text
          color="primary"
          sx={{ fontWeight: "semiBold", textAlign: "right", fontFeatureSettings: "'tnum'" }}>
          {getFormattedNumber(value)}
        </Text>
        <Text
          color="primary"
          sx={{ fontWeight: "semiBold", textAlign: "right", fontFeatureSettings: "'tnum'" }}>
          {getFormattedNumber(unpaidAmount)}
        </Text>
        <Flex sx={{ justifyContent: "center" }}>
          <SelfcareIcon
            name="viewEye"
            tooltip={<FormattedMessage id="cap.view_summary" />}
            onClick={() => getInvoice("SUMMARY")}
            style={{
              cursor: "pointer",
            }}
          />
        </Flex>
        <Flex sx={{ justifyContent: "center" }}>
          <SelfcareIcon
            name="viewEye"
            tooltip={<FormattedMessage id="cap.view_detailed" />}
            onClick={() => getInvoice("DETAILED")}
            style={{
              cursor: "pointer",
            }}
          />
        </Flex>
      </Box>

      {isLoading && (
        <LoadDocumentModal
          isOpen={isLoading}
          onClose={() => closeDownload()}
          buttonLabel="lbl.view_invoice"
          loadingLabel="lbl.loading_invoice_wait"
        />
      )}

      {displaySeparator && <HorizontalRule />}
    </Flex>
  );
};

export default InvoiceRow;
