import React from "react";
import { useIntl } from "react-intl";
import { useMonth } from "@datepicker-react/hooks";
import { Text, Flex, Box, Button } from "@theme-ui/components";
import Day from "./Day";
import { getISODate } from "../../common/Utilities";

const Month = ({
  nextMonth,
  prevMonth,
  year,
  month,
  firstDayOfWeek,
  index,
  isMobileView = false,
  ...props
}) => {
  const intl = useIntl();
  const { days, weekdayLabels, monthLabel } = useMonth({
    year,
    month,
    firstDayOfWeek,
    monthLabelFormat: date => intl.formatDate(getISODate(date), { month: "long" }),
    weekdayLabelFormat: date => intl.formatDate(getISODate(date), { weekday: "short" }),
  });

  return (
    <Box pb="small" sx={{ width: "100%" }} {...props}>
      <Flex
        my="small"
        sx={{
          height: "2rem",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}>
        {((index ?? 0) === 0 || isMobileView) && (
          <Button
            variant=""
            bg="contentBg"
            onClick={prevMonth}
            sx={{
              borderRadius: "card",
              height: "2rem",
              cursor: "pointer",
              position: "absolute",
              left: "0",
            }}>
            <Text ml="auto" sx={{ fontWeight: "medium" }}>
              {"<"}
            </Text>
          </Button>
        )}

        <Text
          sx={{
            fontWeight: "semiBold",
            fontSize: ["10px", "18px", "18px"],
            position: isMobileView ? "initial" : "absolute",
            left: index === 0 && ["medium", "larger", "larger"],
            right: index === 1 && ["medium", "larger", "larger"],
          }}>
          {monthLabel} {year}
        </Text>

        {((index ?? 1) === 1 || isMobileView) && (
          <Button
            variant=""
            bg="contentBg"
            color="textDark"
            onClick={nextMonth}
            sx={{
              borderRadius: "card",
              height: "2rem",
              cursor: "pointer",
              position: "absolute",
              right: "0px",
            }}>
            <Text sx={{ fontWeight: "medium" }}>{">"}</Text>
          </Button>
        )}
      </Flex>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(7, 1fr)",
          justifyContent: "center",
          fontSize: 0,
        }}>
        {weekdayLabels.map(dayLabel => (
          <Box sx={{ textAlign: "center" }} key={dayLabel}>
            {dayLabel}
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(7, 1fr)",
          justifyContent: "center",
        }}>
        {days.map((day, index) => {
          if (typeof day === "object") {
            return <Day date={day.date} key={day.date.toString()} dayLabel={day.dayLabel} />;
          }

          return <Box key={index} />;
        })}
      </Box>
    </Box>
  );
};

export default Month;
