import React from "react";
import { Box } from "@theme-ui/components";

const SVG = ({ size = 24, ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentcolor"
    {...props}
    sx={{
      width: size,
      height: size,
    }}
  />
);

export { SVG };
