import React from "react";
const Name = ({ body = "#318eff", head = "#114878", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.8"
    height="33.6"
    viewBox="0 0 18.8 33.6"
    {...props}>
    <path
      fill={body}
      d="M11.9,25.5c-.2.1-.3.1-.5.2V27A1.7,1.7,0,1,1,8,27V25.8c-.3-.1-.6-.2-1-.3A10.235,10.235,0,0,0,.3,31.3a19.022,19.022,0,0,0,9.2,2.3,19.309,19.309,0,0,0,9.2-2.3A10.636,10.636,0,0,0,11.9,25.5Z"
    />
    <path
      fill="none"
      stroke={head}
      strokeMiterlimit="10"
      d="M18.3,12.6a2.22,2.22,0,0,0-2.2-2.2V7.2a6.345,6.345,0,0,0-2-4.7A6.745,6.745,0,0,0,9.4.5,6.7,6.7,0,0,0,2.7,7.2v3.3h0a2.2,2.2,0,1,0,0,4.4h0v4.4a6.345,6.345,0,0,0,2,4.7,6.745,6.745,0,0,0,4.7,2,6.7,6.7,0,0,0,6.7-6.7V14.9A2.308,2.308,0,0,0,18.3,12.6Z"
    />
    <path
      fill="none"
      stroke={head}
      strokeMiterlimit="10"
      d="M7.7,25.8V27a1.7,1.7,0,1,0,3.4,0V25.7a6.448,6.448,0,0,1-2,.3A6.753,6.753,0,0,1,7.7,25.8Z"
    />
    <path
      fill={head}
      d="M18.4,7.2l-2-1.3A7.069,7.069,0,0,0,9.5.1,6.866,6.866,0,0,0,3.9,2.9L.5,2.5A5.485,5.485,0,0,0,2.8,7.8v3.7a.908.908,0,0,0,.7-.3.908.908,0,0,0,.3-.7V8.3a5.732,5.732,0,0,0,1.4.4A5.431,5.431,0,0,0,8.7,8L8.2,9.9A3.847,3.847,0,0,0,12.6,8c.1.1.2.2.3.2a3.891,3.891,0,0,0,2.3.7v2.2a1.025,1.025,0,0,0,1,1V8.8A4.817,4.817,0,0,0,18.4,7.2Z"
    />
  </svg>
);
export default Name;
