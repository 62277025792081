import React, { useEffect, useState } from "react";
import _ from "lodash";

import { Checkbox, Flex, Label, Text } from "@theme-ui/components";

import { AddButton } from "../../components/base";
import NumberSpinner from "../../components/base/NumberSpinner";
import { PRODUCT_STATUS } from "./JSMethods";
import * as JSMethods from "./JSMethods";

const ServiceGroup = ({ path, _services, _onServiceUpdate, _subProducts, _onProductUpdate }) => {
  const [state, setState] = useState({ services: _services, subProducts: _subProducts });

  useEffect(() => {
    function onPropsUpdate() {
      if (!_.isEqual(state.services, _services) || !_.isEqual(state.subProducts, _subProducts)) {
        setState({ services: _services, subProducts: _subProducts });
      }
    }

    onPropsUpdate();
  }, [state.services, _services, state.subProducts, _subProducts]);

  /**
   * Render a service row, containing the service name and the switch/checkbox component.
   * @param {Number} index The service index.
   * @param {String} serviceId The service id.
   * @param {Boolean} isActive The service active flag.
   * @param {Object} service The service object.
   * @returns {Flex}
   **/
  const renderServiceRow = (index, serviceId, isActive, service) => {
    const isMultipleInstanceService = JSMethods.isMultipleInstanceService(service);
    const useCheckboxComponent =
      !isMultipleInstanceService &&
      (service.rcFee > 0 ||
        (service.rcFee === 0 && service.setupFee === 0) ||
        service.setupFee > 0);

    const cannotDisableService = JSMethods.cannotDisableService(service, _.toNumber(serviceId) > 0);
    const nrOfNewInstances = _.filter(
      _.keys(service.instances),
      srvId => _.toNumber(srvId) < 0
    ).length;
    const currentNoOfInstances = JSMethods.getCurrentNoOfInstances(service);
    let nrOfNewServicesAfterAdd =
      service.minNrOfInstances > 0 && service.minNrOfInstances - currentNoOfInstances > 0
        ? service.minNrOfInstances - currentNoOfInstances
        : 1;
    return (
      <Flex
        key={index + "_" + serviceId}
        my={["small", "default"]}
        sx={{
          justifyContent: "space-between",
          width: "100%",
        }}>
        <Label sx={{ alignItems: "center" }}>
          <Text
            color="textDark"
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              fontSize: [1, 1, 2, 2],
              whiteSpace: ["initial", "nowrap"],
              flex: 1,
            }}>
            {JSMethods.getServiceLabel(
              service.description,
              service.rcFee,
              service.recurringPeriod,
              service.setupFee
            )}
          </Text>

          {isMultipleInstanceService && nrOfNewInstances === 0 && (
            <AddButton
              onClick={() => {
                for (let i = 1; i <= nrOfNewServicesAfterAdd; i++) {
                  onMultipleInstanceNumberChange(i, index);
                }
              }}
            />
          )}

          {isMultipleInstanceService && nrOfNewInstances > 0 && (
            <NumberSpinner
              defaultValue={
                service.minNrOfInstances > 0 && service.minNrOfInstances > currentNoOfInstances
                  ? service.minNrOfInstances - currentNoOfInstances
                  : 1
              }
              alignSelf="flex-end"
              maxValue={
                (service.maxNrOfInstances !== -1 ? service.maxNrOfInstances : 99) -
                currentNoOfInstances
              }
              minValue={0}
              onNumberChange={value => {
                if (nrOfNewServicesAfterAdd <= value) {
                  onMultipleInstanceNumberChange(value, index);
                } else {
                  for (let i = value; i >= 0; i--) {
                    onMultipleInstanceNumberChange(i, index);
                  }
                }
              }}
            />
          )}

          {useCheckboxComponent && (
            <Checkbox
              disabled={cannotDisableService}
              name="serviceValue"
              checked={isActive}
              onChange={() => !cannotDisableService && onServiceUpdate(index, serviceId, !isActive)}
              sx={{ alignSelf: "flex-end", mr: "default" }}
            />
          )}
        </Label>
      </Flex>
    );
  };

  /**
   * Update service state at service update event.
   * @param {Number} index Selected service index from the service list.
   * @param {String} serviceId The service id.
   * @param {Boolean} isActive New value of the active flag, indicating if the service is active or not.
   **/
  const onServiceUpdate = (index, serviceId, isActive) => {
    let services = state.services;
    services[index].instances[serviceId].status = isActive;

    if (_onServiceUpdate != null) {
      _onServiceUpdate(path, services[index]);
    }
    setState({ services: services });
  };

  /**
   * Update service state when the new number of instances is updated.
   * @param {Number} value The new number of instances selected.
   * @param {Number} index Selected service index from the service list.
   **/
  const onMultipleInstanceNumberChange = (value, index) => {
    // Note: the IDs for the new service instances will be negative numbers
    let services = state.services;
    const negativeValue = value * -1;
    if (
      value >= 0 &&
      services[index].instances[_.toString(negativeValue - 1)] &&
      services[index].instances[_.toString(negativeValue - 1)].status
    ) {
      const serviceIdToRemove = _.toString(negativeValue - 1);
      delete services[index].instances[serviceIdToRemove];
    } else {
      services[index].instances[negativeValue] = { status: true };
    }

    if (_onServiceUpdate != null) {
      _onServiceUpdate(path, services[index], _services[index]);
    }

    setState({ services: services });
  };

  /**
   * Update service or subProduct state at service or subProduct update event.
   * @param {Number} subProductIndex New selected service index from the service list.
   **/
  const onSubProductUpdate = subProductIndex => {
    let services = state.services;
    let subProducts = state.subProducts;

    // add selected/remove unselected subProducts
    _.each(subProducts, (subProduct, index) => {
      // add
      if (subProductIndex !== index) {
        return;
      }

      if (
        !(
          subProduct.status === PRODUCT_STATUS.ENABLED ||
          subProduct.status === PRODUCT_STATUS.ADDED ||
          subProduct.status === PRODUCT_STATUS.ACTIVATED
        )
      ) {
        JSMethods.changeToAddedStatus(subProduct);
      }
      // remove
      else {
        JSMethods.changeToRemovedStatus(subProduct);
      }
    });

    if (_onProductUpdate != null) {
      _onProductUpdate(path, subProducts);
    }

    setState({ ...state, services, subProducts });
  };

  /**
   * Render a subProduct row, containing the subProduct name and the switch/checkbox component.
   * @param {Number} index The subProduct index.
   * @param {Object} subProduct The subProduct object.
   * @returns {Flex}
   **/
  const renderSubProductRow = (index, subProduct) => {
    return (
      <Flex key={subProduct.code + index} my="small" width="100%">
        <Label sx={{ justifyContent: "space-between", width: "100%" }}>
          <Text
            color="textDark"
            fontSize="secondary"
            flex={1}
            sx={{
              textAlign: "left",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}>
            {subProduct.description}
          </Text>

          <Checkbox
            id={"id" + subProduct.code + index}
            name={"name" + subProduct.code + index}
            checked={
              subProduct.status === PRODUCT_STATUS.ENABLED ||
              subProduct.status === PRODUCT_STATUS.ADDED ||
              subProduct.status === PRODUCT_STATUS.ACTIVATED
            }
            onChange={() => onSubProductUpdate(index)}
            sx={{ alignSelf: "flex-end", mr: "default" }}
          />
        </Label>
      </Flex>
    );
  };

  let rows = [];
  _.each(state.services, (service, index) => {
    const serviceId = _.keys(service.instances)[0];
    const isActive = service.instances[serviceId] && service.instances[serviceId].status;
    rows.push(renderServiceRow(index, serviceId, isActive, service));
  });

  _.each(state.subProducts, (subProduct, index) => {
    let now = new Date();
    let isAnExpiredProduct =
      (subProduct.availableTo && new Date(subProduct.availableTo).getTime() < now.getTime()) ||
      (subProduct.availableFrom && new Date(subProduct.availableFrom) > now.getTime());

    if (
      !JSMethods.subProductHasVisibleServicesOrFeatures(subProduct) &&
      subProduct.visible &&
      subProduct.addPermission &&
      !isAnExpiredProduct
    ) {
      rows.push(renderSubProductRow(index, state.subProducts[index]));
    }
  });

  if (rows.length === 0) {
    return null;
  }

  return <Flex sx={{ flexDirection: "column", width: "100%" }}>{rows}</Flex>;
};

export default ServiceGroup;
