import React, { useRef, useState } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";

import { Flex, Text } from "@theme-ui/components";

import { useOverflow } from "../../hooks/useOverflow";
import * as ServiceUtils from "./ServiceUtils";
import ServiceDetails from "./ServiceDetails";
import Tooltip from "../Tooltip";
import { getDaysUntilFromDate } from "../../common/Utilities";

const RegularElement = ({
  description,
  activationDate = "",
  expirationDate = "",
  serviceDetails = null,
}) => {
  const valueRef = useRef();

  const [showServiceDetails, setShowServiceDetails] = useState(false);
  const [isOverflow] = useOverflow(valueRef);
  let isDetailsVisible =
    serviceDetails &&
    (ServiceUtils.isMediationVisible(serviceDetails) ||
      ServiceUtils.isAnyUdfSet(serviceDetails.udfs));

  const displayEndsOf = activationDate && expirationDate;
  const numberOfDaysOfTheMonth = 31;
  const days =
    displayEndsOf &&
    getDaysUntilFromDate(new Date(activationDate).getTime(), new Date(expirationDate).getTime());

  return (
    <Flex
      my="small"
      sx={{
        flexDirection: "column",
        ml: ["default", "large", "large", "large"],
      }}>
      <Flex
        sx={{
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
        }}>
        <Flex sx={{ flexDirection: "row" }}>
          {!isOverflow && (
            <Text
              ref={valueRef}
              color="textDark"
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textAlign: "left",
              }}>
              {description}
            </Text>
          )}
          {isOverflow && (
            <Tooltip position="top-noflex" value={description}>
              <Text
                ref={valueRef}
                color="textDark"
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  wordBreak: "break-word",
                  whiteSpace: ["normal", "nowrap", "nowrap", "normal"],
                  textAlign: "left",
                }}>
                {description}
              </Text>
            </Tooltip>
          )}
          {isDetailsVisible && (
            <Text
              color="textDark"
              ml="small"
              sx={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => {
                setShowServiceDetails(!showServiceDetails);
              }}>
              <FormattedMessage id={"lbl.details"} />
            </Text>
          )}
        </Flex>
        {displayEndsOf && days > numberOfDaysOfTheMonth && (
          <Text
            color="primary"
            sx={{
              textAlign: "end",
              width: ["fit-content", "18rem"],
            }}>
            <FormattedMessage id={"lbl.service_expire_on"} />
            <FormattedDate value={expirationDate} dateStyle="medium" />
          </Text>
        )}
      </Flex>

      {showServiceDetails && <ServiceDetails serviceDetails={serviceDetails} />}
    </Flex>
  );
};

export default RegularElement;
