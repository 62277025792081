import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Heading, Flex, Button } from "@theme-ui/components";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";

import Layout from "../../containers/Layout";
import { FormInput } from "../..//components/base";
import { StyledModalMessage } from "../../components/modals";
import GetErrorDescription from "../../components/GetErrorDescription";

import SubmitButton from "../../components/SubmitButton/SubmitButton";
import {
  getSecurityPolicy,
  setSecurityPolicyStatus,
  changeUsername,
  setChangeUsernameStatus,
  setUserError,
  setUsername,
} from "../../redux/slices/UserSlice";
import { useHistory } from "react-router-dom";
import GoBack from "../../components/GoBack";

const ChangeUsername = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { accountCode } = useSelector(state => state.auth);
  const { loginDetails } = useSelector(state => state.user);
  const [isError, setIsError] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [newUsername, setNewUsername] = useState("");

  const {
    securityPolicy,
    security_policy_status,
    change_username_status,
    userError,
    defaultNavigationPath,
  } = useSelector(state => state.user);

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
  });
  const reactHookFormHandle = { register, errors };

  useEffect(() => {
    if (!securityPolicy && !isError) {
      dispatch(getSecurityPolicy());
    }
  }, [securityPolicy, dispatch, isError]);

  useEffect(() => {
    if (security_policy_status === "success") {
      dispatch(setSecurityPolicyStatus(null));
    }
    if (security_policy_status === "failed") {
      setIsError(true);
    }
  }, [dispatch, security_policy_status]);

  useEffect(() => {
    if (change_username_status === "success") {
      dispatch(setChangeUsernameStatus(null));
      dispatch(setUsername(newUsername));
      setIsComplete(true);
    }
    if (change_username_status === "failed") {
      setIsError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, change_username_status]);

  const closeError = () => {
    setSubmitClicked(false);
    setIsError(false);
    dispatch(setChangeUsernameStatus(null));
    dispatch(setSecurityPolicyStatus(null));
    dispatch(setUserError(null));
  };

  const doAfterSuccessfulSave = () => {
    setSubmitClicked(false);
    setIsComplete(false);
    history.push(defaultNavigationPath);
  };

  const onChangeUsername = values => {
    dispatch(
      changeUsername({
        accountCode: accountCode,
        newUsername: values.newUsername,
      })
    );
    setNewUsername(values.newUsername);
    setSubmitClicked(true);
  };

  return (
    <Layout>
      <Heading>
        <FormattedMessage id="lbl.change_username" />
      </Heading>

      <Flex
        sx={{
          maxWidth: ["100%", "38.0875rem"],
          borderRadius: "card",
          border: "solid 1px ",
          borderColor: "border",
          bg: "contentBg",
          flexDirection: "column",
        }}
        py="larger"
        px="large">
        <form
          style={{ display: "flex", flexDirection: "column" }}
          onSubmit={handleSubmit(onChangeUsername)}>
          <FormInput
            disabled
            name="username"
            label="lbl.username"
            defaultValue={loginDetails?.webLoginName}
            wrapperProps={{ mb: [0, "small"] }}
            {...reactHookFormHandle}
          />
          <FormInput
            name="newUsername"
            label="lbl.new_username"
            maxLength="80"
            wrapperProps={{ mb: [0, "small"] }}
            validations={{
              required: true,
              validate: {
                valid: value =>
                  value.toUpperCase() === loginDetails.webLoginName
                    ? intl.formatMessage({ id: "err.old_new_username_cannot_be_the_same" })
                    : true,
              },
              pattern: {
                value: new RegExp(securityPolicy?.subscriberUserRegEx),
                message: intl.formatMessage({
                  id: "err.subscriber_username_invalid",
                }),
              },
            }}
            {...reactHookFormHandle}
          />
          <FormInput
            name="confirmUsername"
            label="lbl.confirm_username"
            maxLength="80"
            {...reactHookFormHandle}
            validations={{
              required: true,
              validate: {
                confirmUsername: value => {
                  return value !== watch("newUsername")
                    ? intl.formatMessage({ id: "lbl.usernames_dont_match" })
                    : true;
                },
              },
            }}
          />
          <Flex sx={{ justifyContent: ["center", "flex-end"] }}>
            <SubmitButton mt="2rem" type="submit" isLoading={submitClicked} />
          </Flex>
        </form>
      </Flex>

      <GoBack onBack={() => history.goBack()} />

      <StyledModalMessage
        isOpen={isComplete}
        message={<FormattedMessage id="lbl.change_username_success" />}
        onRequestClose={doAfterSuccessfulSave}
        type="error">
        <Button onClick={doAfterSuccessfulSave}>
          <FormattedMessage id="lbl.ok" />
        </Button>
      </StyledModalMessage>

      <StyledModalMessage
        isOpen={isError}
        message={userError && <GetErrorDescription error={userError} />}
        onRequestClose={closeError}
        type="error">
        <Button onClick={closeError}>
          <FormattedMessage id="lbl.Try_Again" />
        </Button>
      </StyledModalMessage>
    </Layout>
  );
};

export default ChangeUsername;
