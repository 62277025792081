import React from "react";
const UserIcon = ({ fill = "none", stroke = "#7392bb", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.154"
    height="18"
    viewBox="0 0 17.154 18"
    {...props}>
    <g id="Layer_1" transform="translate(1 1)">
      <g id="Group_28" data-name="Group 28">
        <path
          id="Path_76"
          data-name="Path 76"
          className="cls-1"
          d="M6.5 25.957a7.578 7.578 0 0 1 15.154 0z"
          transform="translate(-6.5 -9.957)"
          fill={fill}
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <ellipse
          id="Ellipse_7"
          data-name="Ellipse 7"
          className="cls-1"
          cx="4.267"
          cy="4.182"
          rx="4.267"
          ry="4.182"
          transform="translate(3.276)"
          fill={fill}
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </g>
    </g>
  </svg>
);
export default UserIcon;
