import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";

import { Grid, Flex, Button, Heading, Text, Link, Checkbox, Label } from "@theme-ui/components";

import GetErrorDescription from "../../components/GetErrorDescription";
import { StyledModalMessage } from "../../components/modals";
import { SelfcareButton } from "../..//components/base";

import {
  getPaymentSettings,
  setUpdatePaymentSettings,
  updatePaymentSettings,
} from "../../redux/slices/PaymentSlice";
import { AUTOMATIC_PAYMENT_METHODS } from "../../common/Constants";

const noConfirmation = {
  isOpen: false,
  message: "",
  isError: false,
};

const AutomaticPaymentSettings = ({ suppressConfirmation = false }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { master, eastLinkPhoneNumber, isCommercialAccount } = useSelector(state => state.user);
  const {
    paymentError,
    update_payment_settings,
    paymentSettings,
    paymentDetails,
    payment_details_status,
    payment_setting_status,
  } = useSelector(state => state.payment);

  const [isError, setIsError] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [automaticPaymentMethod, setAutomaticPaymentMethod] = useState(
    AUTOMATIC_PAYMENT_METHODS.NONE
  );
  const isMasterActiveOrSuspended = ["ACTIVE", "SUSPENDED"].includes(master?.accountStatus);

  const [hasCreditCardDetails, setHasCreditCardDetails] = useState(false);
  const [hasBankAccountDetails, setHasBankAccountDetails] = useState(false);
  let [confirmation, setConfirmation] = useState({
    ...noConfirmation,
  });

  const [tcAccepted, setTCAccepted] = useState(false);
  const isOff = automaticPaymentMethod === AUTOMATIC_PAYMENT_METHODS.NONE;

  useEffect(() => {
    if (paymentSettings) {
      setAutomaticPaymentMethod(paymentSettings.automaticPaymentMethod);
    }
  }, [paymentSettings]);

  const isCreditCardValid = ccDetails => {
    const expYear = ccDetails.cardExpirationYear;
    const expMonth = ccDetails.cardExpirationMonth - 1;
    const daysInExpMonth = new Date(expYear, ccDetails.cardExpirationMonth, 0).getDate();

    const expDate = Date.parse(new Date(expYear, expMonth, daysInExpMonth));
    const today = Date.parse(
      new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
    );

    return today <= expDate;
  };

  useEffect(() => {
    if (payment_details_status === "success") {
      setHasCreditCardDetails(
        paymentDetails.creditCardDetails &&
          paymentDetails.creditCardDetails.cardNumber !== "" &&
          isCreditCardValid(paymentDetails.creditCardDetails)
      );
      setHasBankAccountDetails(
        paymentDetails.bankAccountDetails &&
          paymentDetails.bankAccountDetails.bankAccountNumber !== ""
      );
    }
    if (payment_details_status === "failed" || payment_setting_status === "failed") {
      setIsError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment_details_status, payment_setting_status]);

  const methods = [AUTOMATIC_PAYMENT_METHODS.NONE];

  {
    hasCreditCardDetails ? methods.push(AUTOMATIC_PAYMENT_METHODS.CREDIT_CARD) : null;
    hasBankAccountDetails ? methods.push(AUTOMATIC_PAYMENT_METHODS.DIRECT_DEBIT) : null;
  }

  useEffect(() => {
    if (update_payment_settings === "success") {
      dispatch(setUpdatePaymentSettings(null));
      if (!suppressConfirmation) {
        setIsComplete(true);
      }
    }

    if (update_payment_settings === "failed") {
      if (!suppressConfirmation) {
        setIsError(true);
      }
    }
  }, [dispatch, suppressConfirmation, update_payment_settings]);

  const closeError = () => {
    setAutomaticPaymentMethod(paymentSettings.automaticPaymentMethod);
    setIsError(false);
    dispatch(setUpdatePaymentSettings(null));
  };

  const doAfterSuccessfulSave = () => {
    dispatch(getPaymentSettings(master.accountCode));
    setIsComplete(false);
    setTCAccepted(false);
  };

  const doAfterConfirmation = () => {
    setConfirmation({ isOpen: false });
    if (confirmation.isError) {
      setAutomaticPaymentMethod(paymentSettings.automaticPaymentMethod);
    } else {
      onUpdateMethod();
    }
  };

  const onCancel = () => {
    setConfirmation({ isOpen: false });
    setAutomaticPaymentMethod(paymentSettings.automaticPaymentMethod);
  };

  const generateConfirmationContent = method => {
    if (!methods.includes(method)) {
      return {
        message: intl.formatMessage({
          id:
            method === AUTOMATIC_PAYMENT_METHODS.CREDIT_CARD
              ? "err.automatic_payment_confirmation_card"
              : "err.automatic_payment_confirmation_bank",
        }),
        isError: true,
      };
    }
    return {
      message: intl.formatMessage({ id: "lbl.automatic_payment_confirmation" }),
      isError: false,
    };
  };

  const onClickHandler = method => {
    setAutomaticPaymentMethod(method);
  };

  const onUpdateMethod = () => {
    const paymentSettings = {
      automaticPayment: automaticPaymentMethod !== AUTOMATIC_PAYMENT_METHODS.NONE,
      automaticPaymentMethod: automaticPaymentMethod,
    };
    dispatch(updatePaymentSettings({ accountCode: master.accountCode, paymentSettings }));
  };

  return (
    <Flex mt="default" sx={{ flexDirection: "column", width: "100%" }}>
      <Heading variant="heading2">
        <FormattedMessage id="lbl.automatic_payment" />
      </Heading>

      <Flex variant="layout.section" sx={{ flexDirection: "column" }}>
        <Heading variant="heading3normal" mb={0} sx={{ mb: 0 }}>
          <FormattedMessage id="lbl.select_payment_method" />
        </Heading>
        <Grid
          sx={{
            gap: 0,
            gridTemplateColumns: "repeat(3, 1fr)",
            py: "small",
            width: ["100%", "100%", "50%"],
          }}>
          <SelfcareButton
            variant={
              automaticPaymentMethod === AUTOMATIC_PAYMENT_METHODS.NONE
                ? "tertiaryNoWidth"
                : "tertiaryWhiteNoWidth"
            }
            sx={{
              border: "1px solid",
              borderColor: "primary",
              borderTopLeftRadius: "card",
              borderBottomLeftRadius: "card",
            }}
            onClick={() => onClickHandler(AUTOMATIC_PAYMENT_METHODS.NONE)}>
            <FormattedMessage id="lbl.automatic_payment_method_off" />
          </SelfcareButton>
          <SelfcareButton
            variant={
              automaticPaymentMethod === AUTOMATIC_PAYMENT_METHODS.CREDIT_CARD
                ? "tertiaryNoWidth"
                : "tertiaryWhiteNoWidth"
            }
            disabled={
              !isMasterActiveOrSuspended &&
              automaticPaymentMethod !== AUTOMATIC_PAYMENT_METHODS.CREDIT_CARD
            }
            sx={{ borderColor: "primary" }}
            onClick={() => onClickHandler(AUTOMATIC_PAYMENT_METHODS.CREDIT_CARD)}>
            <FormattedMessage id="lbl.automatic_payment_method_credit_card" />
          </SelfcareButton>
          <SelfcareButton
            variant={
              automaticPaymentMethod === AUTOMATIC_PAYMENT_METHODS.DIRECT_DEBIT
                ? "tertiaryNoWidth"
                : "tertiaryWhiteNoWidth"
            }
            disabled={
              !isMasterActiveOrSuspended &&
              automaticPaymentMethod !== AUTOMATIC_PAYMENT_METHODS.DIRECT_DEBIT
            }
            sx={{
              border: "1px solid",
              borderColor: "primary",
              borderTopRightRadius: "card",
              borderBottomRightRadius: "card",
            }}
            onClick={() => onClickHandler(AUTOMATIC_PAYMENT_METHODS.DIRECT_DEBIT)}>
            <FormattedMessage id="lbl.automatic_payment_method_direct_debit" />
          </SelfcareButton>
        </Grid>

        <Label mt="large">
          <Checkbox onChange={() => setTCAccepted(!tcAccepted)} checked={tcAccepted} />
          <Text variant="description" px="small" mb={["default", 0, 0]}>
            <FormattedMessage
              id="lbl.pre_authorized_payments_consent"
              values={{
                p: chunks => <Text as="p">{chunks}</Text>,
                a: chunks => (
                  <Link variant="nav" href={"https://" + chunks} target="_blank">
                    {chunks}
                  </Link>
                ),
                email: chunks => (
                  <Link variant="nav" target="_blank" sx={{ lineBreak: "anywhere" }}>
                    {chunks}
                  </Link>
                ),
                eastLinkPhoneNumber,
                isCommercialAccount,
              }}
            />
          </Text>
        </Label>
        <Flex sx={{ justifyContent: ["center", "flex-end"] }}>
          <Button
            variant="primary"
            sx={{
              width: "10.5rem",
            }}
            disabled={
              (!tcAccepted && !isOff) ||
              automaticPaymentMethod === paymentSettings?.automaticPaymentMethod
            }
            onClick={() =>
              setConfirmation({
                isOpen: true,
                ...generateConfirmationContent(automaticPaymentMethod),
              })
            }>
            <FormattedMessage id="lbl.submit" />
          </Button>
        </Flex>
      </Flex>

      <StyledModalMessage
        isOpen={confirmation.isOpen}
        message={confirmation.message}
        onRequestClose={onCancel}
        type="error">
        <Button onClick={doAfterConfirmation}>
          <FormattedMessage id="lbl.ok" />
        </Button>

        {!confirmation.isError && (
          <Button ml="default" variant="secondary" onClick={onCancel}>
            <FormattedMessage id="lbl.cancel" />
          </Button>
        )}
      </StyledModalMessage>

      <StyledModalMessage
        isOpen={isComplete}
        message={<FormattedMessage id="lbl.update_automatic_success" />}
        onRequestClose={doAfterSuccessfulSave}
        type="error">
        <Button onClick={doAfterSuccessfulSave}>
          <FormattedMessage id="lbl.ok" />
        </Button>
      </StyledModalMessage>

      <StyledModalMessage
        isOpen={isError}
        message={paymentError && <GetErrorDescription error={paymentError} />}
        onRequestClose={closeError}
        type="error">
        <Button onClick={closeError}>
          <FormattedMessage id="lbl.Try_Again" />
        </Button>
      </StyledModalMessage>
    </Flex>
  );
};

export default AutomaticPaymentSettings;
