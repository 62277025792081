import { debounce } from "lodash";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Flex, Image } from "@theme-ui/components";
import { SelfcareIcon } from "../icons";
import buildItemArray from "./buildItemsArray";
import { BrandingContext } from "../../contexts/BrandingContext";
import OutsideClickHandler from "../OutsideClickHandler";
import User from "../User";
import { setSharedMembersDisplay } from "../../redux/slices/UserSlice";

export const MobileNavMenu = ({ ...prop }) => {
  const history = useHistory();
  const location = useLocation();
  const { logo } = useContext(BrandingContext);
  const [isCollapsed, setCollapsed] = useState(false);
  const { master } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const debouncedChange = useMemo(
    () => debounce(() => setCollapsed(!isCollapsed), 16),
    [isCollapsed]
  );

  const handleChange = useCallback(value => debouncedChange(value), [debouncedChange]);

  if (!master) {
    return null;
  }

  const items = buildItemArray(!master.serviceAgreement);

  const onClickNavItem = (ind, itemLocation) => {
    if (location.pathname !== itemLocation) {
      dispatch(setSharedMembersDisplay("default"));

      history.push(itemLocation);
    } else {
      setCollapsed(false);
    }
    window.scrollTo(0, 0);
  };

  const MenuItem = ({ item, onClick }) => {
    const isFocused = location.pathname === item.location;
    return (
      <Flex
        color="primary"
        onClick={onClick}
        sx={{
          height: "4.5rem",
          alignItems: "center",
          gap: "medium",
          fontWeight: "semiBold",
          fontSize: [3, 4, 4],
        }}>
        <Flex
          bg={"primary"}
          sx={{
            alignItems: "center",
            justifyContent: "center",
            width: "3rem",
            height: "3rem",
            borderRadius: "circle",
            transition: "100ms ease-in",
            bg: isFocused && "secondary",
          }}>
          <SelfcareIcon
            stroke="white"
            name={item.icon}
            sx={{
              width: "2rem",
              height: "2rem",
            }}
          />
        </Flex>
        <FormattedMessage id={item.title} />
      </Flex>
    );
  };

  const RenderLogo = () => <Image sx={{ width: "7rem" }} src={logo} mr="default" />;

  return (
    <Flex
      {...prop}
      sx={{
        flexDirection: "column",
        display: ["inline", "inline", "none", "none"],
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 999,
      }}>
      <Flex
        color="primary"
        bg="primary"
        sx={{
          height: ["3rem", "4rem", "7rem"],
          width: "100%",
          alignItems: "center",
          px: "default",
        }}>
        <SelfcareIcon
          stroke="white"
          name={isCollapsed ? "close" : "menu-burger-mobile"}
          onClick={handleChange}
        />
        <Flex sx={{ width: "100%", justifyContent: "center" }}>
          <RenderLogo />
        </Flex>
        <User ml={["small", "small", "small", "default"]} />
      </Flex>

      {isCollapsed && (
        <Flex>
          <OutsideClickHandler
            onClickOutside={() => {
              setCollapsed(false);
            }}>
            <Flex
              bg="navMenuBg"
              sx={{
                flexDirection: "column",
                position: "absolute",
                borderRadius: "card",
                borderTopLeftRadius: 0,
                boxShadow: "tooltip",
                zIndex: 999,
                px: "default",
              }}>
              {items.map((item, index) => (
                <MenuItem
                  key={index}
                  item={item}
                  onClick={() => onClickNavItem(index, item.location)}
                />
              ))}
            </Flex>
          </OutsideClickHandler>
        </Flex>
      )}
    </Flex>
  );
};
