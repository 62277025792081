import messages_en from "../resources/translations/en";
import messages_fr from "../resources/translations/fr";
import { IntlMessageFormat } from "intl-messageformat";

const MESSAGES = {
  "en-CA": messages_en,
  "fr-CA": messages_fr,
};

const SUPPORTED_LOCALES = {
  "en-CA": "English",
  "fr-CA": "Français",
};

const LANGUAGE_ISOCODE = {
  English: "en-CA",
  French: "fr-CA",
};

const INITIAL_LOCALE = "en-CA";

class LocalizationApi {
  constructor() {
    this.locale = INITIAL_LOCALE;
  }

  setLocale(locale) {
    this.locale = locale;
  }

  /**
   * To be used only where useIntl() hook is not available. Gets corresponding formatted string for the specified key, using the provided variables.
   * @param {String} key The key of the message you want to format. Can be passed as a string ("lbl_hello_name") where "lbl_hello_name": "Hello {name}"
   * @param {Object} values Variables for replacement/formatting - an object containing keys, e.g. {name: "World"}.
   * @return {String} Corresponding formatted string for the specified key.
   */
  getString(key, values = {}) {
    return new IntlMessageFormat(MESSAGES[this.locale][key], this.locale).format(values);
  }
}

const Localization = new LocalizationApi();

export { MESSAGES, INITIAL_LOCALE, SUPPORTED_LOCALES, LANGUAGE_ISOCODE, Localization };
