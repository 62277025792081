import React from "react";

const UsageDetails = ({
  consumed = "#114878",
  remaining = "#318eff",
  eye = "#346eb3",
  eyeCenter = "#dcdcdc",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="49.68"
    height="50.61"
    viewBox="0 0 49.68 50.61"
    {...props}>
    <g transform="translate(-124.68 -10.42)">
      <path
        fill={remaining}
        d="M162.48,33.19h7.74a22.77,22.77,0,0,0-22.77-22.77v7.74A15.03,15.03,0,0,1,162.48,33.19Z"
      />
      <path
        fill={eye}
        d="M168.24,48.106a9.134,9.134,0,0,0-4.5-1.326c-5.865,0-10.62,7.125-10.62,7.125s.312.465.869,1.155c1.63,2.016,5.381,5.97,9.751,5.97,5.865,0,10.62-7.125,10.62-7.125A21.09,21.09,0,0,0,168.24,48.106Z"
      />
      <path
        fill={consumed}
        d="M150.714,53.907l.742-1.112c.219-.328,5.428-8.015,12.284-8.015a9.924,9.924,0,0,1,3,.5,22.64,22.64,0,0,0,3.39-10.087h-7.8A15.022,15.022,0,1,1,145.45,18.308v-7.8a22.768,22.768,0,1,0,6.381,45.024c-.216-.291-.351-.487-.372-.519Z"
      />
      <path
        fill={eyeCenter}
        d="M163.74,48.865a5.04,5.04,0,1,0,5.04,5.04A5.039,5.039,0,0,0,163.74,48.865Zm0,8.152a3.112,3.112,0,1,1,3.112-3.112A3.112,3.112,0,0,1,163.74,57.017Z"
      />
    </g>
  </svg>
);

export default UsageDetails;
