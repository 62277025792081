import React from "react";

const MANonBillable = ({ accent = "#318eff", paper = "#336eb4", shadow = "#114878", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="65.55"
    height="79.522"
    viewBox="0 0 65.55 79.522"
    {...props}>
    <g transform="translate(7.287 -383.945)">
      <circle
        fill={accent}
        cx="14.093"
        cy="14.093"
        r="14.093"
        transform="translate(30.077 435.281)"
      />
      <path
        fill={paper}
        d="M28.577,449.374a15.559,15.559,0,0,1,18.968-15.215V405.52L25.9,383.945l-33.188.063v69.116l6.632,5.311,5.191-4.156,5.194,4.154,5.194-4.155,5.194,4.157,5.191-4.156,5.194,4.154.641-.512A15.49,15.49,0,0,1,28.577,449.374Z"
      />
      <rect fill={accent} width="8.658" height="2.886" transform="translate(2.814 412.803)" />
      <rect fill={accent} width="20.201" height="2.886" transform="translate(17.243 412.803)" />
      <rect fill={accent} width="8.658" height="2.886" transform="translate(2.814 418.575)" />
      <rect fill={accent} width="20.201" height="2.886" transform="translate(17.243 418.575)" />
      <rect fill={accent} width="8.658" height="2.886" transform="translate(2.814 424.347)" />
      <rect fill={accent} width="8.658" height="2.886" transform="translate(28.787 430.118)" />
      <rect fill={accent} width="20.201" height="2.886" transform="translate(17.243 424.347)" />
      <path fill={shadow} d="M25.9,383.945v21.644l21.644-.068Z" />
      <path
        fill={shadow}
        d="M52.017,444.547,49,441.527l-4.828,4.827-4.828-4.827-3.019,3.019,4.828,4.827L36.323,454.2l3.019,3.019,4.828-4.827L49,457.22l3.019-3.019-4.828-4.827Z"
      />
    </g>
  </svg>
);

export default MANonBillable;
