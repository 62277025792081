import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import PaymentResponseModal from "../../pages/Home/PaymentResponseModal";

import { setIppayParams } from "../../redux/slices/UserSlice";
import { isEmpty } from "../../common/Utilities";

const HostedCcOperationResponse = ({ params, isPayment = true }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isDisplayPaymentResponse, setDisplayPaymentResponse] = useState(true);
  const [response, setResponse] = useState(null);

  const { defaultNavigationPath } = useSelector(state => state.user);

  useEffect(() => {
    function parseParams() {
      if (!params) {
        history.push(defaultNavigationPath);
      }
      let temp = decodeURIComponent(params).split("&");

      let canceledByUser = temp[0].split("=")[1];
      let referenceId = temp[1].split("=")[1];
      let paymentNumber = temp[2].split("=")[1];
      let authCode = temp[3].split("=")[1];
      let amount = temp[4].split("=")[1];
      let paymentMethod = decodeURIComponent(temp[5].split("=")[1]).split("+").join(" ");
      let errorMessage = temp[6].split("=")[1].split("+").join(" ");
      let responseUrl = temp[7].split("=")[1];
      if (canceledByUser === "true") {
        setDisplayPaymentResponse(false);
      }

      return {
        canceledByUser,
        referenceId,
        paymentNumber,
        authCode,
        amount,
        paymentMethod,
        errorMessage,
        responseUrl,
      };
    }
    const getParams = () => {
      let response = parseParams();
      if (response) {
        if (
          response.canceledByUser === "true" ||
          (isPayment && !response.errorMessage && (!response.amount || response.amount === "0.0"))
        ) {
          dispatch(setIppayParams(null));
          history.push(response.responseUrl);
        }
        return response;
      }
    };
    if (!response) {
      setResponse(getParams());
    }
  }, [defaultNavigationPath, dispatch, history, isPayment, params, response]);

  return (
    <PaymentResponseModal
      isPayment={isPayment}
      isDisplayPaymentResponse={isDisplayPaymentResponse}
      onPaymentResponseClose={() => {
        setDisplayPaymentResponse(false);
        dispatch(setIppayParams(null));
      }}
      tryAgain={() => {
        setDisplayPaymentResponse(false);
        dispatch(setIppayParams(null));
      }}
      isError={!isEmpty(response?.errorMessage)}
      response={response}
    />
  );
};

export default HostedCcOperationResponse;
