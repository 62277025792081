import React from "react";
import ContentLoader from "react-content-loader";

const FUBCardSkeleton = () => (
  <ContentLoader
    speed={2}
    viewBox="0 0 492 222"
    backgroundColor="#EEEEEE"
    foregroundColor="#d6d3d3">
    {/* <rect x="300.6" y="40.4" width="150" height="20" /> */}
    <rect x="140.6" y="96.4" width="219.7" height="50" />
    <rect x="40.6" y="187" width="410.7" height="40" />
  </ContentLoader>
);

export default FUBCardSkeleton;
