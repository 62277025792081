import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

import { Box, Button, Flex } from "@theme-ui/components";

import AutomaticPaymentSettings from "../../components/AutomaticPaymentSettings";
import GetErrorDescription from "../../components/GetErrorDescription";
import BankAccountDetails from "../../components/BankAccountDetails";
import CreditCardDetails from "../../components/CreditCardDetails";
import HostedCcOperationRequest from "../HostedCcOperationRequest";
import { StyledModalMessage } from "../../components/modals";
import { AUTOMATIC_PAYMENT_METHODS, PAYMENT_METHOD } from "../../common/Constants";

import {
  getPaymentDetails,
  getPaymentSettings,
  setUpdatePaymentDetails,
  setUpdatePaymentSettings,
  updatePaymentDetails,
  updatePaymentSettings,
} from "../../redux/slices/PaymentSlice";
import UpdateBankAccountDetails from "../../components/BankAccountDetails/UpdateBankAccountDetails";

const noConfirmation = {
  isOpen: false,
  message: "",
  type: "",
};

const PaymentInformation = () => {
  const intl = useIntl();
  const { master } = useSelector(state => state.user);
  const masterAccountCode = master?.accountCode;

  const { accountCode: accountCodePath } = useParams();
  const [isUpdateCreditCard, setUpdateCreditCard] = useState(false);
  const [isUpdateBankInformation, setUpdateBankInformation] = useState(false);
  const [suppressConfirmation, setSuppressConfirmation] = useState(false);
  const [showAutomaticPayment, setShowAutomaticPayment] = useState(true);
  const [isRemoving, setRemoving] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isError, setIsError] = useState(false);
  let [confirmation, setConfirmation] = useState({
    ...noConfirmation,
  });

  const dispatch = useDispatch();
  const {
    paymentSettings,
    paymentDetails,
    payment_details_status,
    paymentError,
    update_payment_details,
    update_payment_settings,
  } = useSelector(state => state.payment);

  const accountCode = accountCodePath ? accountCodePath : masterAccountCode;

  const isAddCreditCard =
    paymentDetails?.creditCardDetails.cardNumber === null ||
    paymentDetails?.creditCardDetails.cardNumber === undefined ||
    paymentDetails?.creditCardDetails.cardNumber === "";

  useEffect(() => {
    if (!paymentDetails && master) {
      dispatch(getPaymentDetails(accountCode));
    }
    if (!paymentSettings && master) {
      dispatch(getPaymentSettings(accountCode));
    }
  }, [accountCode, dispatch, paymentDetails, paymentSettings, master]);

  useEffect(() => {
    if (update_payment_details === "success" && isRemoving) {
      dispatch(setUpdatePaymentDetails(null));
      setIsComplete(true);
    }
    if (update_payment_details === "failed" && isRemoving) {
      dispatch(setUpdatePaymentDetails(null));
      setIsError(true);
    }
  }, [dispatch, isRemoving, update_payment_details]);

  useEffect(() => {
    if (update_payment_settings === "success" && suppressConfirmation) {
      let newDetails = generateNewPaymentDetails();
      dispatch(updatePaymentDetails({ accountCode: master.accountCode, newDetails }));
      setSuppressConfirmation(false);
    }
    dispatch(setUpdatePaymentSettings(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update_payment_settings]);

  const tryAgain = () => {
    setIsError(false);
  };

  const onClose = () => {
    setUpdateCreditCard(false);
    setShowAutomaticPayment(true);
    dispatch(getPaymentDetails(accountCode));
  };

  const generateNewPaymentDetails = () => {
    const creditCardDetails = {};
    const bankAccountDetails = {};
    let newDetails;

    if (confirmation.type === AUTOMATIC_PAYMENT_METHODS.CREDIT_CARD) {
      newDetails = { ...paymentDetails, creditCardDetails };
    } else {
      newDetails = { ...paymentDetails, bankAccountDetails };
    }

    return newDetails;
  };

  const doAfterConfirmation = () => {
    let newDetails = generateNewPaymentDetails();
    const automaticPaymentShouldBeReset =
      confirmation.type === paymentSettings.automaticPaymentMethod;

    if (automaticPaymentShouldBeReset) {
      setSuppressConfirmation(true);
      const paymentSettings = {
        automaticPayment: false,
        automaticPaymentMethod: AUTOMATIC_PAYMENT_METHODS.NONE,
      };
      dispatch(updatePaymentSettings({ accountCode: master.accountCode, paymentSettings }));
    }
    if (!automaticPaymentShouldBeReset) {
      dispatch(updatePaymentDetails({ accountCode: master.accountCode, newDetails }));
    }
    setConfirmation({ ...confirmation, isOpen: false });
  };

  const doAfterSuccessfulSave = () => {
    dispatch(getPaymentSettings(master.accountCode));
    dispatch(getPaymentDetails(master.accountCode));
    setIsComplete(false);
    setRemoving(false);
  };

  const onCancel = () => {
    setConfirmation({ ...confirmation, isOpen: false });
    setRemoving(false);
  };

  const onRemoveCreditCard = () => {
    setRemoving(true);
    setConfirmation({
      isOpen: true,
      message: intl.formatMessage(
        { id: "lbl.remove_credit_card_confirmation" },
        {
          preAuthorizedPaymentsOn:
            paymentSettings.automaticPaymentMethod === AUTOMATIC_PAYMENT_METHODS.CREDIT_CARD,
        }
      ),
      type: AUTOMATIC_PAYMENT_METHODS.CREDIT_CARD,
    });
  };

  const onRemoveBankAccountDetails = () => {
    setRemoving(true);
    setConfirmation({
      isOpen: true,
      message: intl.formatMessage(
        { id: "lbl.remove_bank_details_confirmation" },
        {
          preAuthorizedPaymentsOn:
            paymentSettings.automaticPaymentMethod === AUTOMATIC_PAYMENT_METHODS.DIRECT_DEBIT,
        }
      ),
      type: AUTOMATIC_PAYMENT_METHODS.DIRECT_DEBIT,
    });
  };

  const onUpdateCreditCard = () => {
    setUpdateCreditCard(true);
    setShowAutomaticPayment(false);
  };

  const onUpdateBankAccountDetails = () => {
    setUpdateBankInformation(true);
    setShowAutomaticPayment(false);
  };

  return (
    <Box sx={{ width: "100%" }}>
      {!isUpdateCreditCard && !isUpdateBankInformation && (
        <Flex mt="3.5rem">
          {!isError && (
            <Flex
              sx={{
                width: "100%",
                flexDirection: ["column", "column", "row", "row"],
                gap: ["1.75rem", "2rem", "3.5rem", "3.5rem"],
                justifyContent: "space-between",
              }}>
              <CreditCardDetails
                details={paymentDetails}
                isLoading={payment_details_status === "loading"}
                invalidCard={isAddCreditCard}
                onUpdateCreditCard={onUpdateCreditCard}
                onRemoveCreditCard={onRemoveCreditCard}
              />
              <BankAccountDetails
                details={paymentDetails}
                isLoading={payment_details_status === "loading"}
                onUpdateBankAccountDetails={onUpdateBankAccountDetails}
                onRemoveBankAccountDetails={onRemoveBankAccountDetails}
              />
            </Flex>
          )}
        </Flex>
      )}
      {showAutomaticPayment && (
        <AutomaticPaymentSettings suppressConfirmation={suppressConfirmation} />
      )}

      <Box
        mt="2rem"
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          width: "100%",
        }}>
        {isUpdateCreditCard && (
          <HostedCcOperationRequest
            amount={0}
            card={0}
            responseUrl={encodeURIComponent(window.location.pathname)}
            paymentMessage={isAddCreditCard ? "lbl.success_add_card" : "lbl.success_update_card"}
            onClose={onClose}
            onCancel={() => {
              setUpdateCreditCard(false);
              setShowAutomaticPayment(true);
            }}
            paymentMethod={PAYMENT_METHOD.NEW}
          />
        )}
      </Box>

      {isUpdateBankInformation && (
        <UpdateBankAccountDetails
          onBack={() => {
            setUpdateBankInformation(false);
            setShowAutomaticPayment(true);
          }}
        />
      )}

      <StyledModalMessage
        isOpen={confirmation.isOpen}
        message={confirmation.message}
        onRequestClose={onCancel}
        type="error">
        <Button onClick={doAfterConfirmation}>
          <FormattedMessage id="lbl.ok" />
        </Button>

        <Button ml="default" variant="secondary" onClick={onCancel}>
          <FormattedMessage id="lbl.cancel" />
        </Button>
      </StyledModalMessage>

      <StyledModalMessage
        isOpen={isRemoving && isComplete}
        message={intl.formatMessage(
          { id: "lbl.remove_success" },
          {
            field: intl.formatMessage({
              id:
                confirmation.type === AUTOMATIC_PAYMENT_METHODS.CREDIT_CARD
                  ? "lbl.credit_card_information"
                  : "lbl.bank_account_information",
            }),
          }
        )}
        onRequestClose={doAfterSuccessfulSave}
        type="error">
        <Button onClick={doAfterSuccessfulSave}>
          <FormattedMessage id="lbl.ok" />
        </Button>
      </StyledModalMessage>

      <StyledModalMessage
        isOpen={isError}
        message={<GetErrorDescription error={paymentError} />}
        onRequestClose={tryAgain}
        type="error">
        <Button onClick={tryAgain}>
          <FormattedMessage id="lbl.Try_Again" />
        </Button>
      </StyledModalMessage>
    </Box>
  );
};

export default PaymentInformation;
