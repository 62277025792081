import React from "react";
import { Flex } from "@theme-ui/components";

const SelfcareBox = ({ children, variant, ...props }) => {
  return (
    <Flex variant={"layout." + variant} {...props}>
      {children}
    </Flex>
  );
};

export default SelfcareBox;
