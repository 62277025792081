import React, { useState } from "react";
import { useDatepicker, START_DATE } from "@datepicker-react/hooks";
import { useIntl } from "react-intl";

import { Input, Flex } from "@theme-ui/components";

import OutsideClickHandler from "../../components/OutsideClickHandler";
import DatepickerContext from "../../contexts/DatepickerContext";
import Month from "../../components/Datepicker/Month";
import { getISODate } from "../../common/Utilities";

const Calendar = ({ minDate, maxDate, date, setDate, error, ...props }) => {
  const [calendarVisibility, setCalendarVisiblity] = useState(false);
  const { formatDate } = useIntl();

  const handleDateChange = data => {
    setDate(data.startDate);
    setCalendarVisiblity(!calendarVisibility);
  };

  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonths,
    goToNextMonths,
  } = useDatepicker({
    startDate: date,
    exactMinBookingDays: true,
    numberOfMonths: 1,
    focusedInput: START_DATE,
    onDatesChange: handleDateChange,
    firstDayOfWeek: 0, //configure
    minBookingDate: minDate,
    maxBookingDate: maxDate,
  });

  return (
    <DatepickerContext.Provider
      value={{
        focusedDate,
        isDateFocused,
        isDateSelected,
        isDateHovered,
        isDateBlocked,
        isFirstOrLastSelectedDate,
        onDateSelect,
        onDateFocus,
        onDateHover,
      }}>
      <Flex {...props} sx={{ flexDirection: "column", ...props.sx }}>
        <OutsideClickHandler
          onClickOutside={() => {
            setCalendarVisiblity(false);
          }}>
          <Flex>
            <Input
              {...props}
              readOnly
              variant={error ? "inputCalendarError" : "inputCalendar"}
              onClick={() => {
                setCalendarVisiblity(!calendarVisibility);
              }}
              onMouseDown={() => {
                if (!calendarVisibility) setCalendarVisiblity(true);
              }}
              value={date === null ? "" : formatDate(getISODate(date), { dateStyle: "medium" })}
              sx={{ cursor: "pointer", width: "17rem" }}
            />
          </Flex>

          {calendarVisibility && (
            <Flex
              px="default"
              bg="contentBg"
              sx={{
                width: "17rem",
                border: "1px solid",
                borderRadius: "card",
                borderColor: "border",
                position: "absolute",
                justifyContent: "center",
              }}>
              {activeMonths.map(month => (
                <Month
                  nextMonth={goToNextMonths}
                  prevMonth={goToPreviousMonths}
                  key={`${month.year}-${month.month}`}
                  year={month.year}
                  month={month.month}
                  firstDayOfWeek={firstDayOfWeek}
                />
              ))}
            </Flex>
          )}
        </OutsideClickHandler>
      </Flex>
    </DatepickerContext.Provider>
  );
};

export default Calendar;
