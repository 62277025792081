import React from "react";

const ChangeUsername = ({ color = "white", ...props }) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    {...props}>
    <g />
    <path d="M16 3.205C8.933 3.205 3.205 8.933 3.205 16S8.933 28.795 16 28.795 28.795 23.067 28.795 16 23.067 3.205 16 3.205zm0 1.066c6.467 0 11.729 5.261 11.729 11.729 0 2.845-1.019 5.457-2.711 7.49-1.169-.488-3.93-1.446-5.638-1.951-.146-.046-.169-.053-.169-.66 0-.501.206-1.005.407-1.432a7.34 7.34 0 0 0 .569-1.944c.259-.301.612-.895.839-2.026.199-.997.106-1.36-.026-1.7-.014-.036-.028-.071-.039-.107-.05-.234.019-1.448.189-2.391.118-.647-.03-2.022-.921-3.159-.562-.719-1.638-1.601-3.603-1.724l-1.078.001c-1.932.122-3.008 1.004-3.57 1.723-.89 1.137-1.038 2.513-.92 3.159.172.943.239 2.157.191 2.387-.01.04-.025.075-.04.111-.131.341-.225.703-.025 1.7.226 1.131.579 1.725.839 2.026.092.7.35 1.48.569 1.944.159.339.234.801.234 1.454 0 .607-.023.614-.159.657-1.767.522-4.579 1.538-5.628 1.997A11.673 11.673 0 0 1 4.271 16C4.271 9.533 9.532 4.271 16 4.271zM7.811 24.386a67.644 67.644 0 0 1 5.167-1.808c.914-.288.914-1.058.914-1.677 0-.513-.035-1.269-.335-1.908-.206-.438-.442-1.189-.494-1.776a.526.526 0 0 0-.18-.355c-.151-.132-.458-.616-.654-1.593-.155-.773-.089-.942-.026-1.106.027-.07.053-.139.074-.216.128-.468-.015-2.005-.17-2.858-.068-.371.018-1.424.711-2.311.622-.795 1.563-1.238 2.764-1.315l1.011-.001c1.233.078 2.174.521 2.797 1.316.694.887.778 1.94.71 2.312-.154.852-.298 2.39-.17 2.857.022.078.047.147.074.217.064.163.129.333-.025 1.106-.196.977-.504 1.461-.655 1.593a.537.537 0 0 0-.18.355c-.051.588-.286 1.338-.492 1.776-.236.502-.508 1.171-.508 1.886 0 .619 0 1.389.924 1.68a78.558 78.558 0 0 1 5.18 1.77A11.691 11.691 0 0 1 16 27.73a11.69 11.69 0 0 1-8.188-3.342z" />
  </svg>
);
export default ChangeUsername;
