import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import _ from "lodash";

import { Box, Flex, Label, Switch, Text } from "@theme-ui/components";

const FeatureGroup = ({
  serviceId,
  featureGroupCode,
  featureGroupDescription,
  _featureGroupItems,
  _onFeaturesUpdate,
}) => {
  const intl = useIntl();

  const [state, setState] = useState({
    featureGroupItems: [..._featureGroupItems],
  });

  useEffect(() => {
    function onPropsUpdate() {
      if (!_.isEqual(state.featureGroupItems, _featureGroupItems)) {
        setState({ featureGroupItems: _featureGroupItems });
      }
    }

    onPropsUpdate();
  }, [_featureGroupItems, state.featureGroupItems, intl]);

  const onFeatureUpdate = (index, isMutuallyExclusive, mandatoryToSelectOne) => {
    let featGrpItems = state.featureGroupItems;

    if (isMutuallyExclusive) {
      if (mandatoryToSelectOne) {
        _.each(featGrpItems, (feature, localIndex) => {
          feature.selected = index === localIndex;
        });
      } else {
        _.each(featGrpItems, (feature, localIndex) => {
          if (index === localIndex) {
            feature.selected = !feature.selected;
          } else {
            feature.selected = false;
          }
        });
      }
    } else {
      featGrpItems[index].selected = !featGrpItems[index].selected;
    }
    if (_onFeaturesUpdate) {
      _onFeaturesUpdate(featGrpItems);
    }
    setState({ featureGroupItems: featGrpItems });
  };

  return (
    <Flex key={featureGroupCode} sx={{ flexDirection: "column" }}>
      <Text
        color="textDark"
        fontSize="secondary"
        marginTop="small"
        sx={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}>
        {featureGroupDescription}
      </Text>

      <Flex my="small" sx={{ flexDirection: "column" }}>
        {state.featureGroupItems.map((feature, index) => (
          <Label
            key={feature.code + index}
            my="default"
            sx={{ justifyContent: "space-between", width: "100%", alignItems: "center" }}>
            <Text
              color="textDark"
              ml={["small", "default", "default"]}
              flex={1}
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                fontSize: [1, 1, 2, 2],
                whiteSpace: ["initial", "nowrap"],
                maxWidth: ["15rem", "100%", "100%"],
              }}>
              {feature.description}
            </Text>
            <Box>
              <Switch
                id={"id" + serviceId + feature.code + index}
                name={"name" + +serviceId + feature.code + index}
                checked={feature.idStatusMap[_.keys(feature.idStatusMap)[0]] && feature.selected}
                onChange={() =>
                  onFeatureUpdate(index, feature.mutualExclusive, feature.mandatoryToSelectOne)
                }
                sx={{ alignSelf: "flex-end", mr: ["small", "default"] }}
              />
            </Box>
          </Label>
        ))}
      </Flex>
    </Flex>
  );
};

export default FeatureGroup;
