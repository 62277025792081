const ERROR_CODES = {
  MINDBILL_ERROR: "MINDBILL_ERROR",
  INTERNAL_ERROR: "INTERNAL_ERROR",
  ACCOUNT_NOT_FOUND: "ACCOUNT_NOT_FOUND",
  ALREADY_REGISTERED_TO_SELFCARE: "ALREADY_REGISTERED_TO_SELFCARE",
  BLOCKED_SELFCARE_ACCESS: "BLOCKED_SELFCARE_ACCESS",
  BLOCKED_SELFCARE_REGISTRATION: "BLOCKED_SELFCARE_REGISTRATION",
  INVOICE_NOT_FOUND: "INVOICE_NOT_FOUND",
  AT_LEAST_ONE_SIGN_UP_METHOD_MUST_BE_ENTERED: "AT_LEAST_ONE_SIGN_UP_METHOD_MUST_BE_ENTERED",
  NOTIFICATION_LETTER_DESTINATION_NOT_FOUND: "NOTIFICATION_LETTER_DESTINATION_NOT_FOUND",
  SECURITY_QUESTION_REPEAT_NOT_ALLOWED: "SECURITY_QUESTION_REPEAT_NOT_ALLOWED",
  INVALID_VERIFICATION_CODE: "INVALID_VERIFICATION_CODE",
  ACCOUNT_WEB_LOGIN_NAME_INVALID: "ACCOUNT_WEB_LOGIN_NAME_INVALID",
  CHANGE_PASSWORD_ERROR: "CHANGE_PASSWORD_ERROR",
  CHANGE_PASSWORD_TOO_SIMILAR_ERROR: "CHANGE_PASSWORD_TOO_SIMILAR_ERROR",
  CHANGE_USERNAME_ERROR: "CHANGE_USERNAME_ERROR",
  MISSING_SECURITY_RIGHT: "MISSING_SECURITY_RIGHT",
  DIRECT_DEBIT_PAYMENTS_NOT_ALLOWED: "DIRECT_DEBIT_PAYMENTS_NOT_ALLOWED",
  ACCOUNT_CONTACT_NOT_FOUND: "ACCOUNT_CONTACT_NOT_FOUND",
  NO_ACCOUNT_STATEMENT_BANK_HOLDER_NAME: "NO_ACCOUNT_STATEMENT_BANK_HOLDER_NAME",
  NO_ACCOUNT_STATEMENT_BANK_ACCOUNT_TYPE: "NO_ACCOUNT_STATEMENT_BANK_ACCOUNT_TYPE",
  BANK_ACCOUNT_IS_INVALID: "BANK_ACCOUNT_IS_INVALID",
  ITEM_IS_BLACKLISTED: "ITEM_IS_BLACKLISTED",
  TOKEN_NO_LONGER_VALID: "TOKEN_NO_LONGER_VALID",
  USER_NOT_FOUND: "USER_NOT_FOUND",
  FORGOT_PASSWORD_ERROR: "FORGOT_PASSWORD_ERROR",
  CLOSED_ACCOUNT: "CLOSED_ACCOUNT",
  ABORT_ON_TIMEOUT_ERROR: "ABORT_ON_TIMEOUT_ERROR",
  API_OFFLINE: "API_OFFLINE",
  INVALID_SESSION_ID: "INVALID_SESSION_ID",
  DELETE_ITEM_ERROR: "DELETE_ITEM_ERROR",
  ACCOUNT_CREATION_ERROR: "ACCOUNT_CREATION_ERROR",
  GET_HANDSET_BY_SKU_FAILED: "GET_HANDSET_BY_SKU_FAILED",
  ADD_SA_FAILED: "ADD_SA_FAILED",
  NOT_FOUND_INV_IMAGE_IS_GENERATING_AND_SENT_THROUGH_EMAIL:
    "NOT_FOUND_INV_IMAGE_IS_GENERATING_AND_SENT_THROUGH_EMAIL",
  CANNOT_ADD_AS_WHEN_INSUFFICIENT_ACCT_REMAINING: "CANNOT_ADD_AS_WHEN_INSUFFICIENT_ACCT_REMAINING",
  INVALID_ACCOUNT: "INVALID_ACCOUNT",
  MAX_NUMBER_OF_INSTANCES_EXCEEDED: "MAX_NUMBER_OF_INSTANCES_EXCEEDED",
};

export const IPPAY_ERROR_CODES = {
  DO_NOT_HONOR: "005",
  CREDIT_CARD_FAILURE: "900",
};

export default ERROR_CODES;

export const MESSAGES_CODES = {
  ACTIVE: "ACTIVE",
  ACTIVE_HB: "ACTIVE_HB",
  ACTIVE_DC: "ACTIVE_DC",
  ACTIVE_SAC: "ACTIVE_SAC",
  ACTIVE_SAS: "ACTIVE_SAS",
  SUSPENDED: "SUSPENDED",
  SUSPENDED_DC: "SUSPENDED_DC",
  SUSPENDED_HB: "SUSPENDED_HB",
  CLOSED: "CLOSED",
  CLOSED_DC: "CLOSED_DC",
  TERMINATE: "TERMINATE",
};

export const BUSINESS_CATEGORIES = {
  RESIDENTIAL: 0,
  COMMERCIAL: 1,
};
export const UDF_CATEGORIES = {
  RESIDENTIAL: "Residential",
  COMMERCIAL: "Commercial",
};

export const ACCOUNT_TYPES = {
  POSTPAID: "Postpaid",
  PREPAID: "Prepaid",
  PIA: "PIA",
};

export const PAYMENT_METHOD = {
  NEW: 0,
  SAVED: 1,
  E_CHECK: 2,
};

export const RECURRING_PERIOD = {
  DAILY: 0,
  WEEKLY: 1,
  BI_WEEKLY: 2,
  MONTHLY: 3,
  BI_MONTHLY: 4,
  QUARTERLY: 5,
  FOUR_MONTH: 6,
  SEMI_ANNUAL: 7,
  ANNUAL: 8,
  BIENNIAL: 9,
  TRIENNIAL: 10,
  UNLIMITED: 11,
  BASED_ON_BP: 12,
};

export const WIRELINE_TECHNOLOGIES = {
  FIXEDINTERNET: "FIXEDINTERNET",
  DSL: "DSL",
  VOFW: "VOFW",
  VOOM: "VOOM",
  CATVDSL: "CATVDSL",
  INTERNET: "INTERNET",
  VOBB: "VOBB",
  CATVFIBER: "CATVFIBER",
};

export const AUTOMATIC_PAYMENT_METHODS = {
  NONE: "NONE",
  CREDIT_CARD: "CREDIT_CARD",
  DIRECT_DEBIT: "DIRECT_DEBIT",
};

export const ENGLISH = "English";

export const CBO_RESOLUTION_TYPE_CODE = {
  DSPRCF: {
    TITLE: "lbl.no_cancellation_fees_h",
    CONTENT: "lbl.no_cancellation_fees",
    CODE: "DSPRCF",
  },
  DSPRFI: { TITLE: "lbl.no_final_invoice_h", CONTENT: "lbl.no_final_invoice", CODE: "DSPRFI" },
  DSPRPC: { TITLE: "lbl.plan_change_h", CONTENT: "lbl.plan_change", CODE: "DSPRPC" },
  DSPRDA: { TITLE: "lbl.deliquent_account_h", CONTENT: "lbl.deliquent_account", CODE: "DSPRDA" },
  DSPRNP: { TITLE: "lbl.phone_not_ported_h", CONTENT: "lbl.phone_not_ported", CODE: "DSPRNP" },
  APPR: { TITLE: "lbl.approved", CONTENT: "lbl.cbo_approved_message", CODE: "APPR" },
};

export const STEP1 = "TT_ST1_Record_the_ticket";
