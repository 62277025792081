const OAuthIssuerURL = new URL(
  window._env_ ? window._env_.OAUTH2_TOKEN_ISSUER : "https://eastlinkos880.mindsoft.com:4000"
).toString();

const OAuthCredentials = {
  authorizationURL: "oauth/authorize",
  tokenURL: "oauth/token",
  clientID: "SELFCARE",
  scope: "subscriber registration:phone",
  redirectURI: "/",
};

export { OAuthIssuerURL, OAuthCredentials };
