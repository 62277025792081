import React from "react";

const HomePhone = ({ handset = "#318eff", base = "#336eb4", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28.2"
    height="24.244"
    viewBox="0 0 28.2 24.244"
    {...props}>
    <path
      fill={handset}
      d="M26.4,1.5a27.271,27.271,0,0,0-4.1-1A75.184,75.184,0,0,0,14.1,0,75.184,75.184,0,0,0,5.9.5a27.271,27.271,0,0,0-4.1,1A2.461,2.461,0,0,0,0,3.9V5.8A1.155,1.155,0,0,0,1.2,7H6.6a.738.738,0,0,0,.6-.5,8.265,8.265,0,0,1,.7-1.3A2.194,2.194,0,0,1,9.7,4.1h8.8a2.194,2.194,0,0,1,1.8,1.1A8.265,8.265,0,0,0,21,6.5c.1.2.4.5.6.5H27a1.216,1.216,0,0,0,1.2-1.2V3.9A2.461,2.461,0,0,0,26.4,1.5Z"
    />
    <circle cx="2.9" cy="2.9" r="2.9" transform="translate(11.2 13.2)" />
    <circle fill={base} cx="2.9" cy="2.9" r="2.9" transform="translate(11.2 13.2)" />
    <path
      fill={base}
      d="M26.9,17.6a5.3,5.3,0,0,0-1.7-3.3c-1.9-2.1-3.8-4.1-5.7-6.1a1.135,1.135,0,0,0-.8-.3H9.3c-.1,0-.5.1-.6.3-1.9,2-3.8,4-5.7,6.1a5.853,5.853,0,0,0-1.7,3.3c-.1,1.1,0,2.2,0,3.3A2.979,2.979,0,0,0,3,23.5a6.367,6.367,0,0,0,2.7.7c2.8.1,5.6,0,8.4,0s5.6.1,8.4,0a6.367,6.367,0,0,0,2.7-.7,2.979,2.979,0,0,0,1.7-2.6Q26.9,19.25,26.9,17.6ZM14.1,20.7a4.6,4.6,0,1,1,4.6-4.6A4.653,4.653,0,0,1,14.1,20.7Z"
    />
  </svg>
);

export default HomePhone;
