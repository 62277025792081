import React, { useContext, useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import { Flex, Heading, Text } from "@theme-ui/components";

import GetErrorDescription from "../../components/GetErrorDescription";
import { AuthContext } from "../../contexts/AuthorizationContext";
import SelfcareAmount from "../../components/base/SelfcareAmount";
import { StyledModalMessage } from "../../components/modals";
import { ACCOUNT_TYPES } from "../../common/Constants";
import { SelfcareButton } from "../../components/base";
import EmptyUpdatePackage from "./EmptyUpdatePackage";
import * as JSMethods from "./JSMethods";
import ProductCard from "./ProductCard";

import { getAccount } from "../../redux/slices/UserSlice";
import {
  updateAccountProduct,
  setUpdateAccountProductStatus,
  setPlanError,
  getAdditionalSrv,
} from "../../redux/slices/PlanSlice";
import Spinner from "../../components/Spinner/Spinner";
import SubmitButton from "../../components/SubmitButton";
import GoBack from "../../components/GoBack";

const noConfirmation = {
  isOpen: false,
  message: "",
  onRequestClose: () => {},
  isCloseDisabled: false,
  type: "",
  buttons: [],
};

const UpdatePackage = ({ actualPackage, isChangePackage = false, onCancel, onSubmit }) => {
  const { serviceAgreement: isLoggedInAsSA } = useContext(AuthContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { account } = useSelector(state => state.user);
  const { update_account_product_status, planError } = useSelector(state => state.plan);

  const [prepaidCharges, setPrepaidCharges] = useState({
    servicesCharges: 0,
    servicesTaxes: 0,
    servicesTotalCharges: 0,
  });

  // Map<String, ServiceObject>
  // Key::String the path inside the root product object.
  // Value::ServiceObject the service object with the updated fields.
  const [diffMap, setDiffMap] = useState(new Map());
  const [productsDiffMap, setProductsDiffMap] = useState(new Map());
  const [productCards, setProductCards] = useState(null);

  const [product, setProduct] = useState(actualPackage);

  let [confirmation, setConfirmation] = useState({
    ...noConfirmation,
  });

  const redirectToViewServices = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  const confirm = () => {
    if (diffMap.size === 0 && productsDiffMap.size === 0 && !isChangePackage) {
      let onClose = () => {
        setConfirmation(noConfirmation);
      };

      setConfirmation({
        isOpen: true,
        message: intl.formatMessage({ id: "lbl.no_changes_performed" }),
        type: "info",
        onRequestClose: onClose,
        buttons: [
          <SelfcareButton key="btnOk" onClick={onClose}>
            <FormattedMessage id="lbl.ok" />
          </SelfcareButton>,
        ],
      });
    } else {
      const selectedAccount = account;
      const isDebitAndNeedsRecharge =
        selectedAccount.accountRemaining < prepaidCharges.servicesTotalCharges &&
        [ACCOUNT_TYPES.PIA, ACCOUNT_TYPES.PREPAID].indexOf(selectedAccount?.accountType) > -1;
      if (isDebitAndNeedsRecharge === true) {
        const rechargeAmount =
          prepaidCharges.servicesTotalCharges - selectedAccount.accountRemaining;

        let onClose = () => {
          setConfirmation(noConfirmation);
        };

        setConfirmation({
          isOpen: true,
          message: intl.formatMessage(
            { id: "lbl.insufficient_credit" },
            { amount: <SelfcareAmount amount={rechargeAmount} /> }
          ),
          type: "info",
          onRequestClose: onClose,
          buttons: [
            <SelfcareButton key="btnOk" onClick={onClose}>
              <FormattedMessage id="lbl.ok" />
            </SelfcareButton>,
          ],
        });
      } else {
        let onClose = () => {
          setConfirmation(noConfirmation);
        };

        setConfirmation({
          isOpen: true,
          message: intl.formatMessage({ id: "lbl.add_services_confirmation" }),
          type: "warn",
          onRequestClose: onClose,
          buttons: [
            <SelfcareButton key="btnOk" mr="spaceBetweenButtons" onClick={afterConfirmation}>
              <FormattedMessage id="lbl.ok" />
            </SelfcareButton>,
            <SelfcareButton
              variant="secondary"
              key="btnCancel"
              onClick={() => {
                onClose();
              }}>
              <FormattedMessage id="lbl.cancel" />
            </SelfcareButton>,
          ],
        });
      }
    }
  };

  const afterConfirmation = () => {
    const diffProduct = JSMethods.getDiffProduct(
      _.cloneDeep(product),
      _.cloneDeep(diffMap),
      _.cloneDeep(productsDiffMap)
    );

    const selectedAccount = account;
    const isDebitAndNeedsRecharge =
      selectedAccount.accountRemaining < prepaidCharges.servicesTotalCharges &&
      [ACCOUNT_TYPES.PIA, ACCOUNT_TYPES.PREPAID].indexOf(selectedAccount.accountType) > -1;
    if (isDebitAndNeedsRecharge) {
      // Recharge needed. Redirect to Recharge screen with a predefined amount.
      const rechargeAmount = prepaidCharges.servicesTotalCharges - selectedAccount.accountRemaining;
      let onClose = () => {
        setConfirmation(noConfirmation);
      };

      setConfirmation({
        isOpen: true,
        message: intl.formatMessage(
          { id: "lbl.insufficient_credit" },
          { amount: SelfcareAmount({ amount: rechargeAmount }) }
        ),
        type: "error",
        onRequestClose: onClose,
        buttons: [
          <SelfcareButton key="btnDismiss" mr="spaceBetweenButtons" onClick={onClose}>
            <FormattedMessage id="lbl.dismiss" />
          </SelfcareButton>,
          <SelfcareButton
            key="btnRecharge"
            onClick={() => {
              history.push("/makePayment");
            }}>
            <FormattedMessage id="lbl.recharge" />
          </SelfcareButton>,
        ],
      });
    } else {
      setConfirmation({
        isOpen: false,
      });

      dispatch(
        updateAccountProduct({
          accountCode: account.accountCode,
          product: diffProduct,
        })
      );
    }
  };

  useEffect(() => {
    /**
     * Render product/service entries containing the product services/services features, recursively, through the entire product hierarchy.
     * @param {Object} product The current processed product.
     * @param {String} path The hierarchy path of the currently processed product.
     * @returns {Array<ProductCard>}
     **/
    const renderProductCards = (product, path = "product", isMainProduct = false) => {
      let productServicesCards = [];
      if (product.serviceList && product.serviceList.length > 0) {
        const serviceGroups = _.groupBy(product.serviceList, service => service.exclusivityGroup);
        let serviceGroupsMap = new Map();
        _.each(serviceGroups, (value, key) => {
          const services = JSMethods.getServicesForDisplay(value);
          if (services.length > 0) {
            serviceGroupsMap.set(key, services);
          }
        });
        let servicesWithEditableFeatures = JSMethods.getServicesWithEditableFeatures(
          product.serviceList
        );
        const subproductGroups = _.groupBy(
          product.subProductList,
          subproduct => subproduct.exclusivityGroup
        );
        let subproductGroupsMap = new Map();
        _.each(subproductGroups, (value, key) => {
          subproductGroupsMap.set(key, value);
        });

        if (serviceGroupsMap.size > 0 || servicesWithEditableFeatures.length > 0) {
          productServicesCards.push(
            <ProductCard
              key={product.code}
              path={path}
              product={_.cloneDeep(product)}
              isMainProduct={isMainProduct}
              productDescription={product.description}
              mutualExclusiveServices={product.mutualExclusiveServices}
              serviceGroupsMap={_.cloneDeep(serviceGroupsMap)}
              subproductGroupsMap={_.cloneDeep(subproductGroupsMap)}
              servicesWithEditableFeatures={_.cloneDeep(servicesWithEditableFeatures)}
              onServiceUpdate={onServiceUpdateHandler}
              onMEServicesUpdate={onMEServicesUpdateHandler}
              onSubProductsUpdate={onSubProductsUpdate}
            />
          );
        }
      }

      if (product.subProductList && product.subProductList.length > 0) {
        for (let index = 0; index < product.subProductList.length; index++) {
          const view = renderProductCards(
            product.subProductList[index],
            path + ".subProductList[" + index + "]"
          );
          if (view.length > 0) productServicesCards.push(view);
        }
      }

      return productServicesCards;
    };

    /**
     * Update the diff map and live charges when a service is updated in UI.
     **/
    const onServiceUpdateHandler = (path, service, originalService) => {
      const clonedService = _.cloneDeep(service);
      let updatedCharges = {
        servicesCharges: prepaidCharges.servicesCharges,
        servicesTaxes: prepaidCharges.servicesTaxes,
        servicesTotalCharges: prepaidCharges.servicesTotalCharges,
      };

      updateServiceChanges(path, clonedService, updatedCharges, originalService);
      updateRealTimeChargesState(updatedCharges);
    };

    /**
     * Update the diff map and live charges when a list of mutual exclusive services is updated in UI.
     **/
    const onMEServicesUpdateHandler = (path, serviceList) => {
      const clonedServiceList = _.cloneDeep(serviceList);
      let updatedCharges = {
        servicesCharges: prepaidCharges.servicesCharges,
        servicesTaxes: prepaidCharges.servicesTaxes,
        servicesTotalCharges: prepaidCharges.servicesTotalCharges,
      };

      _.each(clonedServiceList, service => updateServiceChanges(path, service, updatedCharges));
      updateRealTimeChargesState(updatedCharges);
    };

    /**
     * Update the diff map and live charges when a list of mutual exclusive products is updated in UI.
     **/
    const onSubProductsUpdate = (path, productList) => {
      const clonedProductList = _.cloneDeep(productList);
      _.each(clonedProductList, product => updateProductChanges(path, product));
    };

    const updateProductChanges = (path, product) => {
      let productList = productsDiffMap.get(path);

      if (productList) {
        const filteredProductList = _.filter(productList, prod => prod.code === product.code);
        productList = _.filter(productList, prod => prod.code !== product.code);
        // new product added or updated
        if (
          filteredProductList.length === 0 ||
          filteredProductList.some(prod => prod.status !== product.status)
        ) {
          productList.push(_.cloneDeep(product));
        }

        if (productList.length > 0) {
          productsDiffMap.set(path, productList);
        } else {
          productsDiffMap.delete(path);
        }
        setProductsDiffMap(productsDiffMap);
      } else {
        const productsArray = [];
        productsArray.push(_.cloneDeep(product));
        productsDiffMap.set(path, productsArray);
        setProductsDiffMap(productsDiffMap);
      }
    };

    /**
     * Calculate the new real time charges when the state for a service is updated,
     * based on the last known values.
     * Also, update the diff map based on the latest service changes.
     **/
    const updateServiceChanges = (path, service, charges, originalService = null) => {
      const setupFee = parseFloat(service.setupFee),
        rcFee = parseFloat(service.rcFee),
        taxAmount = parseFloat(service.taxAmount);

      const isMultipleInstanceService = JSMethods.isMultipleInstanceService(service);
      let newInstances = service.instances;
      const shouldUpdateCharges =
        account.accountType !== ACCOUNT_TYPES.CREDIT &&
        account.accountType !== ACCOUNT_TYPES.CREDIT_LIMIT &&
        (isMultipleInstanceService || _.toNumber(_.keys(newInstances)[0]) === 0);

      let serviceList = diffMap.get(path);

      if (serviceList) {
        const filteredServiceList = _.filter(serviceList, srv => srv.code === service.code);
        if (filteredServiceList.length > 0) {
          serviceList = _.filter(serviceList, srv => srv.code !== service.code);

          if (isMultipleInstanceService) {
            let previousInstances = filteredServiceList[0].instances;
            const previousInstancesNr = _.filter(
              _.keys(previousInstances),
              srvId => _.toNumber(srvId) < 0
            ).length;

            const newInstancesNr = _.filter(
              _.keys(newInstances),
              srvId => _.toNumber(srvId) < 0
            ).length;

            let instancesWereAddedOrRemoved = newInstancesNr > 0;
            let originalInstances = originalService.instances;
            let featuresWereUpdated =
              !_.isEqual(newInstances, previousInstances) &&
              !_.isEqual(newInstances, originalInstances);

            if (instancesWereAddedOrRemoved || featuresWereUpdated) {
              serviceList.push(_.cloneDeep(service));
            } else {
              // do not push service <=> remove it from updated list
            }

            if (shouldUpdateCharges && newInstancesNr > previousInstancesNr) {
              addCharges(charges, setupFee, rcFee, taxAmount);
            } else if (shouldUpdateCharges && newInstancesNr < previousInstancesNr) {
              removeCharges(charges, setupFee, rcFee, taxAmount);
            }
          } else if (shouldUpdateCharges) {
            removeCharges(charges, setupFee, rcFee, taxAmount);
          }
        } else {
          serviceList.push(_.cloneDeep(service));
          if (shouldUpdateCharges) {
            addCharges(charges, setupFee, rcFee, taxAmount);
          }
        }

        if (serviceList.length > 0) {
          diffMap.set(path, serviceList);
        } else {
          diffMap.delete(path);
        }
        setDiffMap(diffMap);
      } else {
        const serviceArray = [];
        serviceArray.push(_.cloneDeep(service));
        diffMap.set(path, serviceArray);
        setDiffMap(diffMap);
        if (shouldUpdateCharges) {
          addCharges(charges, setupFee, rcFee, taxAmount);
        }
      }
    };

    const addCharges = (charges, setupFee, rcFee, taxAmount) => {
      charges.servicesCharges = charges.servicesCharges + setupFee + rcFee;
      charges.servicesTaxes = charges.servicesTaxes + taxAmount;
      charges.servicesTotalCharges = charges.servicesTotalCharges + setupFee + rcFee + taxAmount;
    };

    const removeCharges = (charges, setupFee, rcFee, taxAmount) => {
      charges.servicesCharges = charges.servicesCharges - setupFee - rcFee;
      charges.servicesTaxes = charges.servicesTaxes - taxAmount;
      charges.servicesTotalCharges = charges.servicesTotalCharges - setupFee - rcFee - taxAmount;
    };

    /**
     * Set states for real time charges values.
     **/
    const updateRealTimeChargesState = updatedCharges => {
      if (
        account.accountType !== ACCOUNT_TYPES.CREDIT &&
        account.accountType !== ACCOUNT_TYPES.CREDIT_LIMIT
      ) {
        setPrepaidCharges({
          servicesCharges: updatedCharges.servicesCharges,
          servicesTaxes: updatedCharges.servicesTaxes,
          servicesTotalCharges: updatedCharges.servicesTotalCharges,
        });
      }
    };

    const isProductSet = !!product;
    if (isProductSet) {
      setProductCards(renderProductCards(product, "product", true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, diffMap, productsDiffMap, account.accountType, setProductsDiffMap]);

  useEffect(() => {
    const resetProductState = () => {
      setDiffMap(new Map());
      setProductsDiffMap(new Map());
      setProduct(actualPackage);
      setPrepaidCharges({
        servicesCharges: 0,
        servicesTaxes: 0,
        servicesTotalCharges: 0,
      });
    };

    if (update_account_product_status === "success") {
      dispatch(setUpdateAccountProductStatus(null));
      dispatch(getAccount(account.accountCode));
      dispatch(getAdditionalSrv(account.accountCode));

      redirectToViewServices();
    }

    if (planError) {
      let modalButtonName = intl.formatMessage({ id: "lbl.ok" });
      let modalButtonAction = () => {
        resetProductState();
        setConfirmation(noConfirmation);
        dispatch(setPlanError(null));
        dispatch(setUpdateAccountProductStatus(null));
      };

      setConfirmation({
        isOpen: true,
        message: <GetErrorDescription error={planError} />,
        type: "error",
        onRequestClose: modalButtonAction,
        buttons: [
          <SelfcareButton key="btnOk" onClick={modalButtonAction}>
            {modalButtonName}
          </SelfcareButton>,
        ],
      });
    }
  }, [
    account,
    history,
    intl,
    isLoggedInAsSA,
    actualPackage,
    update_account_product_status,
    planError,
    redirectToViewServices,
    dispatch,
  ]);

  if (!productCards || productCards.length === 0) {
    return <EmptyUpdatePackage onBack={onCancel} />;
  }

  return (
    <Flex
      mb="default"
      sx={{ width: ["100%", "100%", "55.75rem", "55.75rem"], flexDirection: "column" }}>
      <Flex sx={{ width: "100%", flexDirection: "column", mb: "default" }}>
        <Heading>
          <FormattedMessage id="lbl.services_and_features" />
        </Heading>

        <Flex sx={{ flexDirection: "row", mb: "small" }}>
          <Text variant="informationCaption">
            <b>{"\u2022 "}</b>
            <FormattedMessage id="lbl.data_top_ups_explanation" />
          </Text>
        </Flex>
        <Flex sx={{ flexDirection: "row", mb: "default" }}>
          <Text variant="informationCaption">
            <b>{"\u2022 "}</b>
            <FormattedMessage id="lbl.worry_free_explanation" />
          </Text>
        </Flex>
      </Flex>
      <Flex
        p={["small", "default", "larger", "larger"]}
        sx={{
          flexDirection: "column",
          borderRadius: "card",
          border: "1px solid",
          borderColor: "border",
        }}>
        <StyledModalMessage
          isOpen={confirmation.isOpen}
          message={confirmation.message}
          onRequestClose={confirmation.onRequestClose}
          isCloseDisabled={confirmation.isCloseDisabled}
          type={confirmation.type}>
          {confirmation.buttons}
        </StyledModalMessage>

        <Spinner isOpen={update_account_product_status === "loading"} />
        {productCards}

        <SubmitButton mt={[0, "larger"]} mb={["default", 0]} onSubmit={confirm} />
      </Flex>

      <GoBack onBack={onCancel} />
    </Flex>
  );
};

export default UpdatePackage;
