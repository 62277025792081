import React from "react";
import ContentLoader from "react-content-loader";

const SummaryLayoutSkeleton = props => (
  <ContentLoader
    speed={2}
    viewBox="0 0 492 222"
    {...props}
    backgroundColor="#EEEEEE"
    foregroundColor="#d6d3d3">
    <rect id="Rectangle_1209-2_1_" x="370" y="30" width="101" height="35" />
    <rect x="29.1" y="33.3" width="219.7" height="26.6" />
    <rect x="65.6" y="80.4" width="219.7" height="26.6" />
    <rect x="65.6" y="122.5" width="219.7" height="26.6" />
    <rect x="65.6" y="164.6" width="219.7" height="26.6" />

    <circle cx="46.5" cy="88.6" r="2.3" />
    <path
      d="M42.4,82.4c6.2,0,11.3,5.1,11.3,11.3c0,6.2-5.1,11.3-11.3,11.3S31.1,100,31.1,93.8
  C31.1,87.5,36.2,82.4,42.4,82.4 M42.4,80.4c-7.3,0-13.3,6-13.3,13.3s6,13.3,13.3,13.3s13.3-6,13.3-13.3S49.7,80.4,42.4,80.4
  L42.4,80.4z"
    />
    <path
      d="M45.1,95l-6-4.5c0,0-0.1,0-0.2,0c0,0-3.7,2.8-5.8,4.4c0.5,4.6,4.5,8.2,9.2,8.2c5.1,0,9.3-4.2,9.3-9.3
  c0-1-0.1-1.9-0.4-2.7l-6,4C45.3,95,45.2,95,45.1,95z"
    />

    <circle cx="46.5" cy="130.7" r="2.3" />
    <path
      d="M42.4,124.5c6.2,0,11.3,5.1,11.3,11.3s-5.1,11.3-11.3,11.3s-11.3-5.1-11.3-11.3S36.2,124.5,42.4,124.5
   M42.4,122.5c-7.3,0-13.3,6-13.3,13.3c0,7.3,6,13.3,13.3,13.3s13.3-6,13.3-13.3C55.7,128.5,49.7,122.5,42.4,122.5L42.4,122.5z"
    />
    <path
      d="M45.1,137.1l-6-4.5c0,0-0.1,0-0.2,0c0,0-3.7,2.8-5.8,4.4c0.5,4.6,4.5,8.2,9.2,8.2c5.1,0,9.3-4.2,9.3-9.3
  c0-1-0.1-1.9-0.4-2.7l-6,4C45.3,137.1,45.2,137.1,45.1,137.1z"
    />

    <circle cx="46.5" cy="172.7" r="2.3" />
    <path
      d="M42.4,166.6c6.2,0,11.3,5.1,11.3,11.3s-5.1,11.3-11.3,11.3s-11.3-5.1-11.3-11.3S36.2,166.6,42.4,166.6
   M42.4,164.6c-7.3,0-13.3,6-13.3,13.3s6,13.3,13.3,13.3s13.3-6,13.3-13.3S49.7,164.6,42.4,164.6L42.4,164.6z"
    />
    <path
      d="M45.1,179.1l-6-4.5c0,0-0.1,0-0.2,0c0,0-3.7,2.8-5.8,4.4c0.5,4.6,4.5,8.2,9.2,8.2c5.1,0,9.3-4.2,9.3-9.3
  c0-1-0.1-1.9-0.4-2.7l-6,4C45.3,179.2,45.2,179.2,45.1,179.1z"
    />
  </ContentLoader>
);

export default SummaryLayoutSkeleton;
