import React from "react";

const Bell = ({ color = "userMenu", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21.996"
    viewBox="0 0 20 21.996"
    {...props}>
    <g transform="translate(-2 -1)">
      <path
        d="M18,8A6,6,0,0,0,6,8c0,7-3,9-3,9H21s-3-2-3-9"
        fill={color}
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M13.73,21a2,2,0,0,1-3.46,0"
        fill={color}
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export default Bell;
