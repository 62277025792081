import React from "react";

const PDFIcon = ({ fill = "#fff", stroke = "#bf0000", stroke2 = "#939598", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.207"
    height="19.212"
    viewBox="0 0 17.207 19.212"
    {...props}>
    <g id="Group_1653" data-name="Group 1653" transform="translate(-5.631 -3.431)">
      <g id="Group_151" data-name="Group 151" transform="translate(6.339 4.138)">
        <g id="Group_149" data-name="Group 149">
          <path
            id="Path_105"
            data-name="Path 105"
            fill={fill}
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke={stroke2}
            d="M6.5 11.133v11.01h16v-18H10.864V8.5H6.5z"
            transform="translate(-6.5 -4.138)"
          />
          <path
            id="Line_63"
            data-name="Line 63"
            fill={fill}
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke={stroke2}
            d="M4.419 0 0 4.419"
          />
        </g>
      </g>
      <path
        id="Path_107"
        data-name="Path 107"
        d="M17.546 14.694c-1.659 2.464-9.129-7.195-6.258-8.9C14.307 4 8.373 20.366 6.475 17.01c-1.626-2.874 13.182-5.451 11.071-2.316z"
        transform="translate(2.717 2.086)"
        stroke={stroke}
        fill={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
export default PDFIcon;
