import React, { useRef, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { buildAddress, getFullName, getDaysUntilNextBP, addDays } from "../../common/Utilities";
import { SelfcareIntlContext } from "../../contexts/SelfcareIntlContext";
import SummaryRow from "../../components/SummaryRow/SummaryRow";
import { LANGUAGE_ISOCODE } from "../../common/Localization";
import SummaryLayout from "../../containers/SummaryLayout";
import { UDF_CATEGORIES } from "../../common/Constants";
import { useOverflow } from "../../hooks/useOverflow";
import Tooltip from "../Tooltip";
import { Flex, Link } from "@theme-ui/components";

const BillingDetails = ({
  billingDetails,
  shipmentContact,
  invoiceOptionFeature,
  isLoading,
  fieldWidth = "7.5rem",
}) => {
  const intl = useIntl();
  const { locale } = useContext(SelfcareIntlContext);

  const { master } = useSelector(state => state.user);

  const componentsRefs = { invoiceOptionFeature: useRef() };
  const componentsIsOverflow = {
    invoiceOptionFeature: useOverflow(componentsRefs.invoiceOptionFeature)[0],
  };
  const width = locale == LANGUAGE_ISOCODE.French ? "12.5rem" : fieldWidth;

  if (isLoading) {
    return (
      <SummaryLayout
        title="lbl.billing_information"
        isLoading
        sx={{ width: "100%", maxWidth: "100%" }}
      />
    );
  }
  const buildBillingCycleSummaryDescription = billingDetails =>
    intl.formatMessage(
      { id: "lbl.billing_cycle_execution_summary_details" },
      {
        bcDate: intl.formatDate(
          addDays(new Date(Date.parse(billingDetails.billingPeriod.toDate)), 1),
          { dateStyle: "medium" }
        ),
        noOfDays: getDaysUntilNextBP(billingDetails.billingPeriod.toDate),
      }
    );

  const invoiceOptionsComponentsDetails = {
    invoiceOptionFeature: {
      value: invoiceOptionFeature ? invoiceOptionFeature.description : "",
      ref: componentsRefs.invoiceOptionFeature,
    },
  };

  const isResidential = master?.udfCategory !== UDF_CATEGORIES.COMMERCIAL;

  return (
    <SummaryLayout title="lbl.billing_information" sx={{ width: "100%" }}>
      <SummaryRow
        tooltip={intl.formatMessage({ id: "lbl.name" })}
        value={getFullName(shipmentContact)}
        fieldWidth={width}
      />
      <SummaryRow
        tooltip={intl.formatMessage({ id: "lbl.address" })}
        value={buildAddress(shipmentContact)}
        fieldWidth={width}
      />
      <SummaryRow
        tooltip={intl.formatMessage({ id: "lbl.billing_cycle" })}
        value={buildBillingCycleSummaryDescription(billingDetails)}
        fieldWidth={width}
      />
      <SummaryRow tooltip={intl.formatMessage({ id: "lbl.invoice_type" })} fieldWidth={width}>
        <Flex sx={{ flexDirection: "column" }}>
          {componentsIsOverflow.invoiceOptionFeature ? (
            <Tooltip
              value={invoiceOptionsComponentsDetails.invoiceOptionFeature.value}
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: ["initial", "initial", "nowrap", "nowrap"],
              }}>
              {SummaryRow.buildValueTextComponent(
                invoiceOptionsComponentsDetails.invoiceOptionFeature
              )}
            </Tooltip>
          ) : (
            SummaryRow.buildValueTextComponent(invoiceOptionsComponentsDetails.invoiceOptionFeature)
          )}
          {isResidential && (
            <Link
              href={intl.formatMessage({ id: "link.paper_bill" })}
              target="_blank"
              variant="nav"
              sx={{
                flexGrow: 0,
                color: "blue",
              }}>
              <FormattedMessage id="lbl.change_bill_type" />
            </Link>
          )}
        </Flex>
      </SummaryRow>
    </SummaryLayout>
  );
};

BillingDetails.initBillingDetails = () => {
  return {
    billingCycle: { code: null, description: null },
    billingPeriod: { fromDate: null, toDate: null },
  };
};

export default BillingDetails;
