import React from "react";
import { useSelector } from "react-redux";
import Spinner from "../../components/Spinner/Spinner";
import ActiveTravelPlan from "./ActiveTravelPlan";
import Layout from "../../containers/Layout";
import TravelPacks from "./TravelPacks";
import EasyTravel from "./EasyTravel";
import GoBack from "../../components/GoBack";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SubHeader from "../../components/SubHeader";

const TravelPlanPage = () => {
  const {
    update_account_product_status,
    travel_plan_status,
    non_shared_beneficiary_unit_status,
    cancel_SPC_status,
    additional_srv_status,
    travelPlan,
  } = useSelector(state => state.plan);
  const history = useHistory();

  const isLoading =
    travel_plan_status === "loading" ||
    update_account_product_status === "loading" ||
    additional_srv_status === "loading" ||
    cancel_SPC_status === "loading" ||
    non_shared_beneficiary_unit_status === "loading";

  const showEasyTravel =
    travelPlan && travelPlan.easyTravelCode !== "" && travelPlan.easyTravelServiceCode !== "";

  return (
    <Layout>
      <SubHeader />
      <Spinner isOpen={isLoading} />
      <ActiveTravelPlan />
      {showEasyTravel && <EasyTravel />}

      <TravelPacks />

      <GoBack mb="default" onBack={() => history.goBack()} />
    </Layout>
  );
};

export default TravelPlanPage;
