import React from "react";

const CheckBox = ({ color = "#b5b5b5", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" {...props}>
    <g fill="none" stroke={color}>
      <rect stroke="none" width="20" height="20" />
      <rect fill="none" x="0.5" y="0.5" width="19" height="19" />
    </g>
  </svg>
);

export default CheckBox;
