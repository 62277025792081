import React from "react";

const FiberInternet = ({ sleeve = "#114878", tip = "#318eff", core = "#336eb4", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="43.83"
    height="81.177"
    viewBox="0 0 43.83 81.177"
    {...props}>
    <g transform="translate(6.2 1)">
      <rect fill={sleeve} width="2.352" height="30.895" transform="translate(2.886 5.88)" />
      <circle
        fill={tip}
        stroke={sleeve}
        strokeMiterlimit="10"
        strokeWidth="2.0px"
        cx="4.062"
        cy="4.062"
        r="4.062"
      />
      <g transform="translate(23.305)">
        <rect fill={sleeve} width="2.352" height="30.895" transform="translate(2.886 5.88)" />
        <circle
          fill={tip}
          stroke={sleeve}
          strokeMiterlimit="10"
          strokeWidth="2.0px"
          cx="4.062"
          cy="4.062"
          r="4.062"
        />
      </g>
    </g>
    <rect fill={sleeve} width="2.352" height="30.895" transform="translate(20.739 16.073)" />
    <circle
      fill={tip}
      stroke={sleeve}
      strokeMiterlimit="10"
      strokeWidth="2.0px"
      cx="4.062"
      cy="4.062"
      r="4.062"
      transform="translate(17.853 10.194)"
    />
    <path
      fill={core}
      d="M38.367,29.131c-.107-1.069-7.59-2.031-16.784-2.031S4.907,28.062,4.8,29.131h0V57.888H38.474l-.107-28.757Z"
      transform="translate(0.331 1.802)"
    />
    <path
      fill={sleeve}
      d="M43.83,41.173c-.214-1.5-9.942-2.673-21.915-2.673S.214,39.783,0,41.173H0V78.588H43.83V41.173Z"
      transform="translate(0 2.588)"
    />
  </svg>
);

export default FiberInternet;
