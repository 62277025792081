import React from "react";
const RequestUpgrade = ({ fill = "none", stroke = "#fff", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30.411"
    height="30.438"
    viewBox="0 0 30.411 30.438"
    {...props}>
    <path
      d="M145.1 422.651a1.238 1.238 0 0 1 .089-2.166L172.5 406.5l-6.607 27.041a1.254 1.254 0 0 1-.758.865 1.293 1.293 0 0 1-1.158-.1z"
      transform="translate(-143.499 -405.089)"
      fill={fill}
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      fillRule="evenodd"
    />
    <path
      d="m155.717 431.065-3.2 3.252a.57.57 0 0 1-.646.135.624.624 0 0 1-.366-.577v-6.33l18.487-21.045"
      transform="translate(-140.991 -405.089)"
      fill={fill}
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);
export default RequestUpgrade;
