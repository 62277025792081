import logo from "../resources/images/logo.svg";
import logo_white from "../resources/images/logo_white.svg";
import el_logo from "../resources/images/eastlink-logo-white.svg";
import Theme from "./themes";
import docomo_logo from "../resources/images/logos/docomopacific.svg";
import docomo_logo_white from "../resources/images/logos/docomopacific_white.svg";

export const Providers = {
  base: {
    theme: Theme,
    logo: logo_white,
    logoAlt: logo,
    config: {
      darkMode: false,
      phoneNumber: "+972-4-993-6666",
      website: "https://www.mindcti.com",
      terms_and_conditions: "https://www.mindcti.com/terms-and-conditions/",
      limitAccessByProvider: false,
      themeSwitch: true,
      updatePackage: true,
      eCheckVisible: true,
      includeNBCallsVisible: true,
      identityTypeAndNoVisible: true,
      receiveMarketingDetailsVisible: true,
      viewPostpaidUnbilledSummary: true,
      mobilePhoneNumberVisible: true,
      troubleTicket: {
        assistance: {
          area: "SELFCARE_ASSIST",
          role: "BILLING",
          type: "SELF_AGENT",
        },
        service: {
          area: "SELFCARE_SRVC",
          role: "BILLING",
          type: "SELF_SGENT",
        },
        upgrade: {
          area: "SELFCARE_UPGRD",
          role: "BILLING",
          type: "SELF_UGENT",
        },
      },
    },
  },
  el: {
    theme: Theme,
    logo: el_logo,
    logoAlt: el_logo,
    config: {
      darkMode: window._env_ && window._env_.darkMode,
      copyright: "© Eastlink",
      residentialPhoneNumber: "1-888-345-1111",
      commercialPhoneNumber: "1-877-813-1727",
      website: "https://www.eastlink.ca",
      terms_and_conditions: "https://www.eastlink.ca/about/terms-and-conditions",
      change_bill_type_link: "https://my.eastlink.ca/customer-support/billing/faq?version=2",
      how_to_read_your_bill:
        "https://my.eastlink.ca/customer-support/billing/understand-your-bill/version/2",
      phoneNumberMask: {
        mask: ["999-999-9999", "1-999-999-9999"],
        placeholder: "___-___-____",
        pattern: /^[1]?[0-9]{3}[0-9]{3}[0-9]{4}$/,
      },
      limitAccessByProvider: true, // forgot password
      themeSwitch: true,
      updatePackage: false,
      includeNBCallsVisible: false, // usage details
      identityTypeAndNoVisible: false, // contact details
      receiveMarketingDetailsVisible: false, // cpni details
      mobilePhoneNumberVisible: false, // contact details
      troubleTicket: {
        assistance: {
          area: "SELFCARE_ASSIST",
          role: "BILLING",
          type: "SELF_AGENT",
        },
        service: {
          area: "SELFCARE_SRVC",
          role: "BILLING",
          type: "SELF_SGENT",
        },
        upgrade: {
          area: "SELFCARE_UPGRD",
          role: "BILLING",
          type: "SELF_UGENT",
        },
      },
    },
  },
  docomo: {
    theme: {
      ...Theme,
      colors: {
        ...Theme.colors,
        primary: "rgba(74, 77, 78, 1)",
        headerBg: "white",
        navItemBg: "rgba(74, 77, 78, 1)",
        secondary: "rgba(204, 0, 51, 1)",
        navMenuFocusedBg: "rgba(74, 77, 78, 1)",
        userMenu: "rgba(74, 77, 78, 1)",
        darkenHome: "rgba(216,67,91,1)",
      },
    },
    logo: docomo_logo,
    logoAlt: docomo_logo_white,
    config: {
      darkMode: false,
      copyright: "© DOCOMO PACIFIC Guam",
      phoneNumber: "Guam: +1.671.688.2273\nCNMI: +1.670.488.2273",
      website: "https://www.docomopacific.com/",
      terms_and_conditions: "https://www.docomopacific.com/about-us/terms-and-conditions",
      limitAccessByProvider: true, // forgot password
      themeSwitch: true,
      updatePackage: false,
      includeNBCallsVisible: false, // usage details
      identityTypeAndNoVisible: false, // contact details
      receiveMarketingDetailsVisible: false, // cpni details
      mobilePhoneNumberVisible: false, // contact details
      troubleTicket: {
        assistance: {
          area: "SELFCARE_ASSIST",
          role: "BILLING",
          type: "SELF_AGENT",
        },
        service: {
          area: "SELFCARE_SRVC",
          role: "BILLING",
          type: "SELF_SGENT",
        },
        upgrade: {
          area: "SELFCARE_UPGRD",
          role: "BILLING",
          type: "SELF_UGENT",
        },
      },
    },
  },
};

// export const defaultProvider = window._env_ ? window._env_.PROVIDER : "base";
export const defaultProvider = "el";
