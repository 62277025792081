import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";

import { Flex, Button, Grid, Heading, Box } from "@theme-ui/components";

import GetErrorDescription from "../GetErrorDescription";
import { StyledModalMessage } from "../modals";
import Layout from "../../containers/Layout";
import SubmitButton from "../SubmitButton";
import { FormInput } from "../base";
import {
  getContacts,
  clearUpdateContactsStatus,
  setUserError,
  updateContacts,
  setDisplayName,
} from "../../redux/slices/UserSlice";
import { PaymentSkeleton } from "../PaymentRow";
import GoBack from "../GoBack";
import SubHeader from "../SubHeader";

const UpdateSAInformations = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { accountCode: accountCodePath } = useParams();
  const { contacts, update_contacts_status, userError, account } = useSelector(state => state.user);
  const { accountCode: rootAccountCode } = useSelector(state => state.auth);

  const intl = useIntl();
  const [noFieldsChanged, setNoFieldsChanged] = useState(true);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
  });
  const reactHookFormHandle = { register, errors };

  useEffect(() => {
    if (!contacts && !userError) {
      dispatch(getContacts(accountCodePath));
    }
    if (userError) {
      setIsError(true);
    }
  }, [accountCodePath, contacts, dispatch, userError]);

  useEffect(() => {
    if (update_contacts_status === "success") {
      setIsCompleted(true);
      dispatch(getContacts(accountCodePath));
      dispatch(clearUpdateContactsStatus());
    }

    if (update_contacts_status === "failed") {
      setIsError(true);
      dispatch(clearUpdateContactsStatus());
    }
  }, [dispatch, accountCodePath, update_contacts_status]);

  const onClose = () => {
    history.goBack();
  };

  const closeError = () => {
    setIsError(false);
    dispatch(setUserError(null));
    if (!submitClicked) {
      onClose();
    }
  };

  if (!contacts) {
    return (
      <Layout>
        <PaymentSkeleton />
        <StyledModalMessage
          isOpen={isError}
          message={userError && <GetErrorDescription error={userError} />}
          onRequestClose={closeError}
          type="error">
          <Button onClick={closeError}>
            <FormattedMessage id="lbl.Try_Again" />
          </Button>
        </StyledModalMessage>
      </Layout>
    );
  }

  const primaryContact = contacts?.accountContacts?.find(element => element.primary === true);

  const onInputChange = () => {
    setNoFieldsChanged(false);
  };

  const onSubmit = values => {
    let accountContact = {
      ...primaryContact,
      name: {
        first: values.firstName,
        last: values.lastName,
      },
      email: values.emailAddress,
    };
    if (account.accountCode === rootAccountCode) {
      dispatch(setDisplayName(values.firstName));
    }

    dispatch(clearUpdateContactsStatus());
    dispatch(
      updateContacts({ accountCode: accountCodePath, id: primaryContact.id, accountContact })
    );
    setSubmitClicked(true);
  };

  return (
    <Layout>
      <SubHeader />
      <Box mt={[0, "large"]}>
        <Heading>
          <FormattedMessage id="lbl.sa.subscriber_information" />
        </Heading>
        <Flex sx={{ maxWidth: ["100%", "100%", "75rem", "85.5rem"], flexDirection: "column" }}>
          <Flex
            py="larger"
            px={["small", "default", "large"]}
            sx={{
              flexDirection: "column",
              border: "solid 1px",
              borderColor: "border",
              borderRadius: "card",
            }}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                flexDirection: "column",
                display: "flex",
              }}>
              <Grid
                sx={{
                  gridGap: [2, 6, 12],
                  gridTemplateColumns: ["repeat(1, 1fr)", "repeat(3, 1fr)", "repeat(3, 1fr)"],
                  gridAutoRows: "min-content",
                  width: ["100%", "100%", "100%", "80%"],
                }}>
                <FormInput
                  name="firstName"
                  label="lbl.first_name"
                  defaultValue={primaryContact.name.first}
                  maxLength="100"
                  {...reactHookFormHandle}
                  onChange={onInputChange}
                  validations={{ required: true }}
                />

                <FormInput
                  name="lastName"
                  label="lbl.last_name"
                  defaultValue={primaryContact.name.last}
                  maxLength="60"
                  {...reactHookFormHandle}
                  onChange={onInputChange}
                  validations={{ required: true }}
                />

                <FormInput
                  name="emailAddress"
                  label="lbl.email_address"
                  defaultValue={primaryContact.email}
                  maxLength="80"
                  {...reactHookFormHandle}
                  onChange={onInputChange}
                  validations={{
                    required: true,
                    pattern: {
                      value: /^([A-Za-z0-9._%-]+)@([A-Za-z0-9.-]+\.[a-zA-Z]{2,})$/i,
                      message: intl.formatMessage({ id: "err.SA_email_address_is_invalid" }),
                    },
                  }}
                />
              </Grid>

              <SubmitButton
                mt="2rem"
                type="submit"
                text="lbl.SA_submit"
                isLoading={submitClicked}
                isdisabled={noFieldsChanged}
              />
            </form>
          </Flex>

          <GoBack onBack={onClose} />
        </Flex>
      </Box>

      <StyledModalMessage
        isOpen={isCompleted}
        message={intl.formatMessage({ id: "lbl.update_contacts_successful" })}
        onRequestClose={onClose}
        type="error">
        <Button onClick={onClose}>
          <FormattedMessage id="lbl.ok" />
        </Button>
      </StyledModalMessage>

      <StyledModalMessage
        isOpen={isError}
        message={userError && <GetErrorDescription error={userError} />}
        onRequestClose={closeError}
        type="error">
        <Button onClick={closeError}>
          <FormattedMessage id="lbl.Try_Again" />
        </Button>
      </StyledModalMessage>
    </Layout>
  );
};

export default UpdateSAInformations;
