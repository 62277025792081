import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import { Grid, Heading, Text, Flex, Button, Image } from "@theme-ui/components";

import GetErrorDescription from "../../components/GetErrorDescription";
import SubmitButton from "../../components/SubmitButton/SubmitButton";
import { StyledModalMessage } from "../../components/modals";
import { FormInput } from "../..//components/base";

import {
  getPaymentDetails,
  setPaymentError,
  setUpdatePaymentDetails,
  updatePaymentDetails,
} from "../../redux/slices/PaymentSlice";

import img from "../../resources/images/voidCheque.png";
import GoBack from "../GoBack";

const UpdateBankAccountDetails = ({ onBack }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { master } = useSelector(state => state.user);

  const [isError, setIsError] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);

  const { paymentError, paymentDetails, update_payment_details } = useSelector(
    state => state.payment
  );

  const institutionIdLength = 3;
  const tranzitLength = 5;

  const {
    handleSubmit,
    register,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      institutionID: paymentDetails.bankAccountDetails.institutionID,
      transitNumber: paymentDetails.bankAccountDetails.transitNumber,
      bankAccountNumber: paymentDetails.bankAccountDetails.bankAccountNumber,
      bankHolderName: paymentDetails.bankAccountDetails.bankHolderName,
    },
  });
  const reactHookFormHandle = { register, errors };

  const isAdd =
    paymentDetails.bankAccountDetails.bankAccountNumber === null ||
    paymentDetails.bankAccountDetails.bankAccountNumber === undefined ||
    paymentDetails.bankAccountDetails.bankAccountNumber === "";

  if (!paymentDetails && master) {
    dispatch(getPaymentDetails(master.accountCode));
  }

  const closeError = () => {
    setSubmitClicked(false);
    setIsError(false);
    // reset flag to enable the button
    dispatch(setPaymentError(null));
  };

  const doAfterSuccessfulSave = () => {
    dispatch(getPaymentDetails(master.accountCode));
    setSubmitClicked(false);
    setIsComplete(false);
    onBack();
  };

  const onChangeBankDetails = values => {
    const newDetails = {
      ...paymentDetails,
      bankAccountDetails: {
        ...paymentDetails.bankAccountDetails,
        institutionID: values.institutionID,
        transitNumber: values.transitNumber,
        bankAccountNumber: values.bankAccountNumber,
        bankHolderName: values.bankHolderName,
      },
    };
    dispatch(updatePaymentDetails({ accountCode: master.accountCode, newDetails }));
    setSubmitClicked(true);
  };

  useEffect(() => {
    if (update_payment_details === "success") {
      dispatch(setUpdatePaymentDetails(null));
      setIsComplete(true);
    }
    if (update_payment_details === "failed") {
      dispatch(setUpdatePaymentDetails(null));
      setIsError(true);
    }
  }, [dispatch, update_payment_details]);

  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Heading variant="heading2">
        <FormattedMessage id="lbl.update_bank_account_information" />
      </Heading>

      <Flex
        sx={{
          mt: ["medium", 0, 0],
          width: "100%",
          borderRadius: "card",
          border: "solid 1px ",
          borderColor: "border",
          bg: "contentBg",
          flexDirection: "column",
        }}
        py="larger"
        px="large">
        <form
          style={{ display: "flex", flexDirection: "column" }}
          onSubmit={handleSubmit(onChangeBankDetails)}>
          <Flex sx={{ width: "100%", flexDirection: ["column", "column", "row", "row"] }}>
            <Grid sx={{ rowGap: 12, minWidth: ["100%", "25rem"] }}>
              <FormInput
                name="institutionID"
                label="lbl.bank_institution_id"
                {...reactHookFormHandle}
                maxLength={institutionIdLength}
                validations={{
                  required: true,
                  pattern: {
                    value: /^[0-9]*$/,
                    message: intl.formatMessage(
                      { id: "err.institution_id_min_digits" },
                      {
                        length: institutionIdLength,
                      }
                    ),
                  },
                  validate: {
                    length: value => {
                      return (
                        (value.length < institutionIdLength &&
                          intl.formatMessage(
                            { id: "err.institution_id_min_digits" },
                            {
                              length: institutionIdLength,
                            }
                          )) ||
                        true
                      );
                    },
                  },
                }}
              />

              <FormInput
                name="transitNumber"
                label="lbl.bank_transit_number"
                {...reactHookFormHandle}
                maxLength={tranzitLength}
                validations={{
                  required: true,
                  pattern: {
                    value: /^[0-9]*$/,
                    message: intl.formatMessage(
                      { id: "err.transit_number_min_digits" },
                      {
                        length: tranzitLength,
                      }
                    ),
                  },
                  validate: {
                    length: value => {
                      return (
                        (value.length < tranzitLength &&
                          intl.formatMessage(
                            { id: "err.transit_number_min_digits" },
                            {
                              length: tranzitLength,
                            }
                          )) ||
                        true
                      );
                    },
                  },
                }}
              />

              <FormInput
                name="bankAccountNumber"
                label="lbl.bank_account"
                maxLength="25"
                {...reactHookFormHandle}
                validations={{
                  required: true,
                  pattern: {
                    value: /^[0-9]*$/,
                    message: intl.formatMessage({
                      id: "err.bank_account_number_should_contain_digits",
                    }),
                  },
                }}
              />

              <FormInput
                name="bankHolderName"
                label="lbl.bank_account_holder_name"
                maxLength="80"
                {...reactHookFormHandle}
                validations={{ required: true }}
              />
            </Grid>
            <Flex
              mt={["2rem", "2rem", 0, 0]}
              sx={{ flexDirection: "column", alignItems: "center", width: "100%" }}>
              <Text
                my="default"
                sx={{
                  fontWeight: "medium",
                  fontSize: [3, 4, 5, 6],
                  letterSpacing: "0.48px",
                  maxWidth: ["25rem", "25rem", "31rem", "40rem"],
                }}>
                <FormattedMessage id="lbl.update_bank_account_hint" />
              </Text>
              <Image sx={{ width: ["25rem", "25rem", "31rem", "34rem"] }} src={img} />
            </Flex>
          </Flex>
          <SubmitButton
            mt="2rem"
            type="submit"
            isLoading={submitClicked}
            isdisabled={!isDirty || paymentError}
          />
        </form>
      </Flex>

      <GoBack mb="default" onBack={onBack} />

      <StyledModalMessage
        isOpen={isComplete}
        message={
          <FormattedMessage
            id={isAdd ? "lbl.added_bank_details_success" : "lbl.update_bank_details_success"}
          />
        }
        onRequestClose={doAfterSuccessfulSave}
        type="error">
        <Button onClick={doAfterSuccessfulSave}>
          <FormattedMessage id="lbl.ok" />
        </Button>
      </StyledModalMessage>

      <StyledModalMessage
        isOpen={isError}
        message={paymentError && <GetErrorDescription error={paymentError} />}
        onRequestClose={closeError}
        type="error">
        <Button onClick={closeError}>
          <FormattedMessage id="lbl.Try_Again" />
        </Button>
      </StyledModalMessage>
    </Flex>
  );
};

export default UpdateBankAccountDetails;
