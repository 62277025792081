import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import jwt from "jwt-decode";
import Storage from "store2";
import { OAuthCredentials } from "../../common/Deployment";
import { AuthAPI } from "../../rest/RestClient";

export const REFRESH_DONE = "refresh/done";
export const REFRESH_FAIL = "refresh/fail";

const generateCodeVerifier = () => {
  if (!window.crypto) {
    return null;
  }
  let salt = new Uint8Array(32);
  window.crypto.getRandomValues(salt);

  const codec = "1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-._~";

  return salt.reduce((code, i) => code + codec[i % 66]);
};

if (!Storage.get("logged_in") && !Storage.get("code_verifier")) {
  Storage.set("code_verifier", generateCodeVerifier(), true);
}

const INITIAL_STATE = {
  canLogin: false,
  loggedIn: Storage.get("logged_in") ?? false,
  error: null,
  accountCode: Storage.get("account_code"),
  codeVerifier: Storage.get("code_verifier"),
  refreshLock: false,
  token: {
    loading: false,
    success: false,
    error: null,
    response: null,
  },
};

const decodeToken = token => {
  let accountCode, error;
  try {
    const claims = jwt(token);
    accountCode = claims.account_code;
  } catch (err) {
    error = err;
  }
  return { accountCode, error };
};

export const getToken = createAsyncThunk(
  "getToken",
  async (authCode, { dispatch, getState, rejectWithValue }) => {
    const { tokenURL, clientID, clientSecret, redirectURI } = OAuthCredentials;

    try {
      let response = await AuthAPI.POST(
        tokenURL,
        {
          grant_type: "authorization_code",
          code: authCode,
          redirect_uri: window.location.origin + redirectURI,
          client_id: clientID,
          code_verifier: getState().auth.codeVerifier,
        },
        {
          isForm: true,
          authorization: { type: "Basic", token: btoa(clientID + ":" + clientSecret) },
        }
      );

      dispatch(
        doLogin({
          accessToken: response.access_token,
          refreshToken: response.refresh_token,
          expirationDate: new Date().getTime() + response.expires_in * 1000,
        })
      );
    } catch (err) {
      dispatch(doLogout());
      return rejectWithValue(err);
    }
  }
);

export const checkAndRefreshToken = createAsyncThunk(
  "checkAndRefreshToken",
  async (args, { dispatch, getState }) => {
    console.log("refresh from " + args);
    const { tokenURL, clientID, clientSecret } = OAuthCredentials;

    const expirationDate = new Date(Storage.get("expiration_date"));
    //token is 1 minute before expiration, perform refresh
    if (expirationDate - 60000 < new Date()) {
      console.log("token is 1 minute before expiration, perform refresh");
      if (getState().auth.refreshLock) {
        //await semaphore("refresh/end");
        console.log("token is refreshing");
        return;
      }

      console.log("start refreshing");
      dispatch({ type: "refresh/start" });

      const refreshToken = Storage.get("refresh_token");

      // get a new one using the refresh token
      try {
        let response = await AuthAPI.POST(
          tokenURL,
          {
            grant_type: "refresh_token",
            refresh_token: refreshToken,
            client_id: clientID,
          },
          {
            isForm: true,
            authorization: { type: "Basic", token: btoa(clientID + ":" + clientSecret) },
          }
        );

        //store it
        Storage.set("access_token", response.access_token, true);
        Storage.set("expiration_date", new Date().getTime() + response.expires_in * 1000, true);
        Storage.set("refresh_token", response.refresh_token, true);

        console.log("end refreshing");
        dispatch({ type: REFRESH_DONE });
      } catch (error) {
        console.log("Refresh failed: " + JSON.stringify(error));
        dispatch({ type: REFRESH_FAIL });
        dispatch(doLogout());
      }
    } else {
      console.log("no refreshing");
      setTimeout(() => {
        dispatch({ type: REFRESH_DONE });
      }, 5);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: INITIAL_STATE,
  reducers: {
    allowLogin(state) {
      return { ...state, canLogin: true };
    },
    doLogin(state, action) {
      let { accessToken, expirationDate, refreshToken } = action.payload;

      Storage.set("access_token", accessToken, true);
      Storage.set("expiration_date", expirationDate, true);
      Storage.set("refresh_token", refreshToken, true);

      const { accountCode, error } = decodeToken(accessToken);
      let loggedIn = !error && !!accountCode;
      Storage.set("logged_in", loggedIn, true);
      Storage.set("account_code", accountCode, true);
      Storage.remove("code_verifier");
      return {
        ...state,
        canLogin: true,
        loggedIn: loggedIn,
        error,
        accountCode,
        token: INITIAL_STATE.token,
      };
    },
    doLogout(state) {
      Storage.remove("access_token");
      Storage.remove("expiration_date");
      Storage.remove("refresh_token");
      Storage.remove("logged_in");
      Storage.remove("account_code");
      Storage.remove("language");
      let codeVerifier = generateCodeVerifier();
      Storage.set("code_verifier", codeVerifier);
      return {
        ...state,
        canLogin: false,
        loggedIn: false,
        accountCode: null,
        codeVerifier,
      };
    },
    startRefresh(state) {
      return { ...state, refreshLock: true };
    },
  },
  extraReducers: {
    [getToken.pending]: state => {
      state.token.loading = true;
      state.token.success = false;
      state.token.error = null;
      state.token.response = null;
    },
    [getToken.fulfilled]: state => {
      state.token.loading = false;
      state.token.success = true;
    },
    [getToken.rejected]: (state, action) => {
      state.token.loading = false;
      state.token.success = false;
      state.token.error = action.payload;
    },
    "refresh/start": state => {
      state.refreshLock = true;
    },
    "refresh/done": state => {
      state.refreshLock = false;
    },
    "refresh/fail": state => {
      state.refreshLock = false;
    },
  },
});

export const { allowLogin, doLogin, doLogout } = authSlice.actions;

export default authSlice.reducer;
