import React, { useState } from "react";
import { useThemeUI } from "@theme-ui/core";
import { keyframes } from "@emotion/react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

import { Box, Flex, Text, Grid } from "@theme-ui/components";

import OutsideClickHandler from "../OutsideClickHandler";
import { UDF_CATEGORIES } from "../../common/Constants";
import { SelfcareIcon } from "../icons";

const Option = ({ index, isPrimary, isOpen, label, icon, onOptionClick = null }) => {
  const { theme } = useThemeUI();

  const fadeInOut = keyframes`
  from {
    scale: ${isOpen ? 0 : 1};
  }
  to {
    scale: ${isOpen ? 1 : 0};
  }
`;

  return (
    <Flex
      sx={{
        alignItems: "center",
        width: "fit-content",
        animation: `${fadeInOut} 0.3s both`,
      }}>
      {label && (
        <Flex
          p="small"
          sx={{
            width: "auto",
            position: "fixed",
            zIndex: 111,
            borderRadius: "card",
            boxShadow: "tooltip",
            backgroundColor: "contentBg",
            right: ["huge", "mobileNavigationMenuOffset"],
            ":hover": {
              cursor: "default",
            },
          }}>
          <Text
            sx={{
              color: "primary",
              width: "max-content",
            }}>
            {label}
          </Text>
        </Flex>
      )}

      <Grid
        p={["small", "smallish"]}
        m="small"
        onClick={onOptionClick}
        sx={{
          width: "max-content",
          borderRadius: "50%",
          opacity: "80%",
          boxShadow: "chatWithUs",
          backgroundColor: isPrimary ? "primary" : "textLight",
          ":hover": {
            cursor: "pointer",
          },
        }}
        key={index}>
        <SelfcareIcon
          name={icon}
          color={isPrimary ? theme.colors.textLight : theme.colors.primary}
          sx={{
            ":hover": {
              cursor: "pointer",
            },
            size: isPrimary
              ? ["inputHeightMedium", "inputHeightDefault"]
              : ["iconMedium", "inputHeightSmall"],
          }}
        />
      </Grid>
    </Flex>
  );
};

const ChatWithUs = ({ ...props }) => {
  const { master, eastLinkPhoneNumber } = useSelector(state => state.user);
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);

  const isResidential = master?.udfCategory !== UDF_CATEGORIES.COMMERCIAL;

  if (!isResidential) return null;

  const content = [
    {
      label: intl.formatMessage({ id: "lbl.chat_uc" }),
      icon: "chat",
      onClick: () => window.open(intl.formatMessage({ id: "link.chat" })),
    },
    {
      label: eastLinkPhoneNumber,
      icon: "chatPhone",
    },
    {
      label: intl.formatMessage({ id: "lbl.need_help" }),
      icon: "question-mark",
      onClick: () => window.open(intl.formatMessage({ id: "link.support" })),
    },
  ];

  const rotation = keyframes`
  from {
    transform: rotate(${isOpen ? 0 : 180}deg);
  }
  to {
    transform: rotate(${isOpen ? 180 : 0}deg);
  }
`;

  return (
    <OutsideClickHandler
      onClickOutside={() => {
        setIsOpen(false);
      }}>
      <Flex
        {...props}
        sx={{
          flexDirection: "column-reverse",
          maxHeight: isOpen ? "max-content" : "inputHeightDefault",
          position: "fixed",
          right: "small",
          bottom: ["default", "huge"],
          alignItems: "center",
          width: "huge",
          ":hover": {
            cursor: "pointer",
          },
        }}>
        <Box sx={{ animation: `${rotation} 0.3s ` }}>
          <Option
            isPrimary
            isOpen
            icon={isOpen ? "close" : "chat"}
            onOptionClick={() => setIsOpen(!isOpen)}
          />
        </Box>

        {isOpen &&
          content.map((element, index) => (
            <Option
              key={index}
              index={index}
              isOpen={isOpen}
              label={element.label}
              icon={element.icon}
              onOptionClick={element.onClick}
            />
          ))}
      </Flex>
    </OutsideClickHandler>
  );
};
export default ChatWithUs;
