import React from "react";
const PaymentInformation = ({ fill = "#fff", stroke = "#0c3865", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="69.038"
    height="47.962"
    viewBox="0 0 69.038 47.962"
    {...props}>
    {/* <defs>
        <style>
            .cls-1,.cls-2{fill:#fff;stroke:#0c3865;stroke-miterlimit:10;strokeWidth:3px}.cls-2{strokeLinecap:round}
        </style>
    </defs> */}
    <g id="Layer_1" transform="translate(-4.4 -29.538)">
      <g id="Group_275" data-name="Group 275" transform="translate(5.9 31.038)">
        <path
          id="Path_789"
          data-name="Path 789"
          className="cls-1"
          d="M71.938 54.444v-35.36a4.7 4.7 0 0 0-4.684-4.684h-56.67A4.7 4.7 0 0 0 5.9 19.084v35.594a4.7 4.7 0 0 0 4.684 4.684h56.67a5.069 5.069 0 0 0 4.684-4.918z"
          transform="translate(-5.9 -14.4)"
          fill={fill}
          stroke={stroke}
          strokeWidth="3px"
          strokeMiterlimit="10"
        />
        <g id="Group_274" data-name="Group 274" transform="translate(9.835 27.399)">
          <path
            id="Line_98"
            data-name="Line 98"
            className="cls-2"
            d="M0 0h10.07"
            fill={fill}
            stroke={stroke}
            strokeLinecap="round"
            strokeWidth="3px"
            strokeMiterlimit="10"
          />
          <path
            id="Line_99"
            data-name="Line 99"
            className="cls-2"
            transform="translate(15.221)"
            d="M0 0h5.386"
            fill={fill}
            stroke={stroke}
            strokeLinecap="round"
            strokeWidth="3px"
            strokeMiterlimit="10"
          />
          <path
            id="Line_100"
            data-name="Line 100"
            className="cls-2"
            transform="translate(25.759)"
            d="M0 0h10.07"
            fill={fill}
            stroke={stroke}
            strokeLinecap="round"
            strokeWidth="3px"
            strokeMiterlimit="10"
          />
          <path
            id="Line_101"
            data-name="Line 101"
            className="cls-2"
            transform="translate(41.215)"
            d="M0 0h5.152"
            fill={fill}
            stroke={stroke}
            strokeLinecap="round"
            strokeWidth="3px"
            strokeMiterlimit="10"
          />
        </g>
        <path
          id="Line_102"
          data-name="Line 102"
          className="cls-2"
          transform="translate(9.835 35.361)"
          d="M0 0h20.607"
          fill={fill}
          stroke={stroke}
          strokeLinecap="round"
          strokeWidth="3px"
          strokeMiterlimit="10"
        />
        <path
          id="Path_790"
          data-name="Path 790"
          className="cls-1"
          d="M17.728 28.6h-5.152A2.6 2.6 0 0 1 10 26.028v-5.152a2.6 2.6 0 0 1 2.576-2.576h5.152a2.6 2.6 0 0 1 2.572 2.576v5.152a2.6 2.6 0 0 1-2.572 2.572z"
          transform="translate(-.399 -9.167)"
          fill={fill}
          stroke={stroke}
          strokeWidth="3px"
          strokeMiterlimit="10"
        />
      </g>
    </g>
  </svg>
);
export default PaymentInformation;
