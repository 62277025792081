import React from "react";
const ContactSales = ({ fill = "none", stroke = "#0c3865", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="113" height="113" viewBox="0 0 113 113" {...props}>
    {/* <defs>
        <style>
            .cls-1{
            fill:none;
            stroke:#0c3865;
            stroke-linecap:round;
            stroke-linejoin:round;
            stroke-width:3px}
        </style>
    </defs> */}
    <g id="Group_2350" data-name="Group 2350" transform="translate(-502.999 -555.999)">
      <g id="support" transform="translate(530.716 580)">
        <path
          id="Path_2349"
          data-name="Path 2349"
          className="cls-1"
          d="M51.568 25.784h-5.73a5.73 5.73 0 0 0-5.73 5.73v5.73a5.73 5.73 0 0 0 5.73 5.73h0a5.73 5.73 0 0 0 5.73-5.73v-11.46a25.784 25.784 0 0 0-51.568 0v11.46a5.73 5.73 0 0 0 5.73 5.73h0a5.73 5.73 0 0 0 5.73-5.73v-5.73a5.73 5.73 0 0 0-5.73-5.73H0"
          transform="translate(3 3)"
          fill={fill}
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <path
          id="Path_2350"
          data-name="Path 2350"
          className="cls-1"
          d="M34.054 14v11.46q0 8.595-5.73 8.595H14"
          transform="translate(20.514 20.514)"
          fill={fill}
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
      </g>
      <path
        id="Union_14"
        data-name="Union 14"
        className="cls-1"
        d="m-1673.374 6627.834-20.057 10.245 9.416-21.269a54.751 54.751 0 0 1-10.485-32.31 55 55 0 0 1 55-55 55 55 0 0 1 55 55 55 55 0 0 1-55 55 54.759 54.759 0 0 1-33.874-11.666z"
        transform="translate(2199 -5972)"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </g>
  </svg>
);

export default ContactSales;
