import React from "react";
import ContentLoader from "react-content-loader";

const AdditionalSrvSkeleton = () => {
  return (
    <ContentLoader
      speed={2}
      viewBox="0 0 95 54"
      backgroundColor="#EEEEEE"
      foregroundColor="#d6d3d3">
      <rect y="0" width="100%" height="50" />
    </ContentLoader>
  );
};

export default AdditionalSrvSkeleton;
