import React from "react";

const NoDetails = ({ fill = "#fff", stroke = "#0c3865", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" {...props}>
    <g id="ok-circle" transform="translate(-1 -1)">
      <circle
        id="Ellipse_48"
        data-name="Ellipse 48"
        cx="21"
        cy="21"
        r="21"
        transform="translate(2 2)"
        strokeLinejoin="round"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeWidth="2px"
      />
      <g id="Group_183" data-name="Group 183" transform="translate(16.739 16.565)">
        <path
          id="Path_72"
          data-name="Path 72"
          fill={fill}
          stroke={stroke}
          strokeLinecap="round"
          strokeWidth="2px"
          d="m518 745.5 12.509 12.856"
          transform="translate(-518 -745.5)"
        />
        <path
          id="Path_73"
          data-name="Path 73"
          fill={fill}
          stroke={stroke}
          strokeLinecap="round"
          strokeWidth="2px"
          d="m518 759.22 12.509-12.856"
          transform="translate(-518 -746.363)"
        />
      </g>
    </g>
  </svg>
);

export default NoDetails;
