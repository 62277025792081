import React from "react";
const MobilePhone = ({ color = "#114878", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.8"
    height="30.9"
    viewBox="0 0 15.8 30.9"
    {...props}>
    <path
      fill={color}
      d="M15.8,28.4V2.4A2.433,2.433,0,0,0,13.4,0H2.4A2.433,2.433,0,0,0,0,2.4V28.5a2.433,2.433,0,0,0,2.4,2.4H13.5A2.586,2.586,0,0,0,15.8,28.4ZM9.1,29.6H6.7a.67.67,0,0,1-.7-.4A.833.833,0,0,1,6.7,28H9.1a.67.67,0,0,1,.7.4A.833.833,0,0,1,9.1,29.6Zm5.5-2.7H1.2V3.9H14.6v23Z"
    />
  </svg>
);
export default MobilePhone;
