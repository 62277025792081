import React from "react";
const DarkTheme = ({ fill = "none", stroke = "#7392bb", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.001"
    height="18.001"
    viewBox="0 0 18.001 18.001"
    {...props}>
    <path
      d="M19.038 11.721A8.017 8.017 0 1 1 10.317 3a6.235 6.235 0 0 0 8.721 8.721z"
      transform="translate(-2.038 -2)"
      fill={fill}
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default DarkTheme;
