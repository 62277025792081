import React, { useState } from "react";

import { Flex, Box } from "@theme-ui/components";
import { useThemeUI } from "@theme-ui/core";

import OutsideClickHandler from "../OutsideClickHandler";
import { SelfcareIcon } from "../icons";
import UserMenu from "../UserMenu";

const User = ({ ...props }) => {
  const [showMenu, setShowMenu] = useState(false);
  const { theme } = useThemeUI();
  return (
    <Box
      display={["none", "flex", "flex"]}
      {...props}
      sx={{
        height: "2.2rem",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        top: 0,
        left: 0,
        borderTopRightRadius: "1.1rem",
        borderTopLeftRadius: "1.1rem",
        ...props.sx,
      }}>
      <OutsideClickHandler
        onClickOutside={() => {
          setShowMenu(false);
        }}>
        <UserMenu showMenu={showMenu} setShowMenu={setShowMenu}>
          <Flex
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => setShowMenu(showMenu => !showMenu)}>
            <SelfcareIcon
              fill={"none"}
              stroke={theme.colors.userMenu}
              name="user"
              pl="0.75rem"
              py="tiny"
              sx={{
                cursor: "pointer",
              }}
            />
            <SelfcareIcon
              fill={"none"}
              stroke={theme.colors.userMenu}
              name={"downArrow"}
              justifycontent="center"
              alignself="center"
              sx={{
                cursor: "pointer",
              }}
            />
          </Flex>
        </UserMenu>
      </OutsideClickHandler>
    </Box>
  );
};

export default User;
