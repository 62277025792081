import React, { useEffect, useState } from "react";
import { useThemeUI } from "@theme-ui/core";
import { Text, Flex } from "@theme-ui/components";
import { SelfcareIcon } from "../icons";
import { FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const PaymentItem = ({ ...props }) => {
  const { theme } = useThemeUI();
  const history = useHistory();
  const [hover, setHover] = useState(false);
  const location = useLocation();
  const { billing_summary_status } = useSelector(state => state.billing);
  const isFocused = location.pathname.includes("/makePayment");

  const colorScheme = {
    font: theme.colors.primary,
    button: theme.colors.secondary,
    buttonFont: theme.colors.textLight,

    fontFocused: theme.colors.secondary,
    buttonFocused: theme.colors.secondary,
    buttonFontFocused: theme.colors.textLight,
  };

  const [style, setStyle] = useState({
    buttonColor: colorScheme.button,
    buttonFontColor: colorScheme.buttonFont,
    fontColor: colorScheme.font,
  });

  useEffect(() => {
    if (!isFocused) {
      setStyle({
        buttonColor: colorScheme.button,
        buttonFontColor: colorScheme.buttonFont,
        fontColor: colorScheme.font,
      });
    } else {
      setStyle({
        buttonColor: colorScheme.buttonFocused,
        buttonFontColor: colorScheme.buttonFontFocused,
        fontColor: colorScheme.fontFocused,
      });
    }
  }, [
    colorScheme.button,
    colorScheme.buttonFocused,
    colorScheme.buttonFont,
    colorScheme.buttonFontFocused,
    colorScheme.font,
    colorScheme.fontFocused,
    isFocused,
  ]);
  if (billing_summary_status !== "success") {
    return (
      <Flex
        {...props}
        py="small"
        sx={{
          width: ["100%", "100%", "13rem", "14rem"],
          justifyContent: "center",
          alignItems: "center",
          height: "6.375rem",
          flexDirection: "column",
          cursor: "pointer",
          ...props.sx,
        }}
      />
    );
  }

  return (
    <Flex
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...props}
      py="small"
      sx={{
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        flexDirection: "column",
        ...props.sx,
      }}>
      <Flex
        onClick={() => history.push("/makePayment")}
        px={
          isFocused
            ? ["small", "default", "medium", "larger"]
            : ["large", "large", "smallish", "large"]
        }
        py={["smallish", "small", "0.6725rem", "smallish"]}
        sx={{
          borderRadius: "circle",
          cursor: "pointer",
          boxShadow: "default",
        }}
        bg={style.buttonColor}>
        <Text variant="navigationMenu" color={style.buttonFontColor} sx={{ whiteSpace: "pre" }}>
          <FormattedMessage id="lbl.make_payment" />
        </Text>

        {!isFocused && (
          <Text
            mt="-0.25rem"
            ml="small"
            color="sunshine"
            sx={{
              fontWeight: "semiBold",
              fontSize: [4, 4, 6, 6],
              transition: "100ms ease-in",
            }}>
            {"›"}
          </Text>
        )}
      </Flex>

      <Flex mt="default" sx={{ alignItems: "center", justifyContent: "center" }}>
        <SelfcareIcon
          name={"lock"}
          stroke={hover ? theme.colors.secondary : style.fontColor}
          sx={{ width: "1rem", height: "1rem" }}
        />
        <Text variant="note" color={hover ? theme.colors.secondary : style.fontColor}>
          <FormattedMessage id="lbl.secure_transaction" />
        </Text>
      </Flex>
    </Flex>
  );
};

export default PaymentItem;
