import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useThemeUI } from "@theme-ui/core";
import { useHistory } from "react-router-dom";

import { Flex, Text, Button, Heading } from "@theme-ui/components";

import { StyledModalMessage } from "../../components/modals";
import GetErrorDescription from "../../components/GetErrorDescription";
import Layout from "../../containers/Layout";
import { PaymentInformation, HostedCcOperationResponse } from "..";
import { SelfcareIcon } from "../../components/icons";
import { getContacts } from "../../redux/slices/UserSlice";
import { getInvoiceOptionFeature } from "../../redux/slices/BillingSlice";
import ContactDetails from "../../components/ContactDetails";
import BillingDetails from "../../components/BillingDetails";
import EditAccountDetails from "./EditAccountDetails";

const AccountDetails = () => {
  const dispatch = useDispatch();
  const { maContacts, account_status, contacts_status, userError, ippay_params } = useSelector(
    state => state.user
  );
  const { invoiceOptionFeature, invoice_option_status, billingError } = useSelector(
    state => state.billing
  );

  const { theme } = useThemeUI();
  const history = useHistory();
  const { master } = useSelector(state => state.user);
  const masterAccountCode = master?.accountCode;
  const [renderInfo, setRenderInfo] = useState("none");
  const accountCode = masterAccountCode;
  const masterBillingDetails = master
    ? {
        billingCycle: {
          code: master.billingDetails.billingCycleCode,
          description: master.billingDetails.billingCycleDescription,
        },
        billingPeriod: {
          fromDate: master.billingPeriod.fromDate,
          toDate: master.billingPeriod.toDate,
        },
      }
    : BillingDetails.initBillingDetails();

  const [updateAccountDetails, setUpdateAccountDetails] = useState(false);
  const [primaryContact, setPrimaryContact] = useState(null);
  const [shipmentContact, setShipmentContact] = useState(null);
  const [error, setError] = useState({ isError: false, msg: null });

  useEffect(() => {
    const loadInitialData = () => {
      if (accountCode !== undefined) {
        dispatch(getContacts(accountCode));
        dispatch(getInvoiceOptionFeature(accountCode));
      }
    };

    loadInitialData();
  }, [accountCode, dispatch]);

  useEffect(() => {
    if (ippay_params) {
      setRenderInfo("payment");
    }
  }, [ippay_params]);

  useEffect(() => {
    if (account_status === "failed" || contacts_status === "failed") {
      setError({
        isError: true,
        msg: userError,
      });
    } else if (invoice_option_status === "failed") {
      setError({
        isError: true,
        msg: billingError,
      });
    }
    if (maContacts && contacts_status === "success") {
      setPrimaryContact(maContacts.accountContacts.find(element => element.primary === true));
      setShipmentContact(maContacts.accountContacts.find(element => element.shipment === true));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account_status, contacts_status, history, invoice_option_status]);

  const tryAgain = () => {
    closeModal();
  };

  const closeModal = () => {
    setError({
      isError: false,
      msg: null,
    });
  };

  const RenderDetails = ({ icon, onClick, text, isFocused, ...props }) => {
    const [isHover, setHover] = useState(false);
    return (
      <Flex
        {...props}
        sx={{
          width: ["100%", "16rem", "22.5rem"],
          flexDirection: "column",
          px: ["default", 0],
          ...props.sx,
        }}>
        <Flex
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={onClick}
          bg="navMenuBg"
          my="largeish"
          sx={{
            height: "7rem",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "card",
            flexDirection: "row",
            cursor: "pointer",
            ":hover": {
              opacity: "90%",
            },
          }}>
          <Flex
            sx={{
              px: ["1rem", 0, 0],
              height: "3rem",
              justifyContent: "center",
              alignItems: "flex-end",
            }}>
            <SelfcareIcon
              name={icon}
              stroke={
                isHover
                  ? theme.colors.secondary
                  : isFocused
                  ? theme.colors.secondary
                  : theme.colors.navMenuFocusedBg
              }
              sx={{
                width: "3rem",
                height: "3rem",
                cursor: "pointer",
              }}
            />
          </Flex>
          <Text
            variant="subheadline"
            color={isHover ? "secondary" : isFocused ? "secondary" : "navMenuFocusedBg"}
            ml={[0, "medium"]}>
            <FormattedMessage id={text} />
          </Text>
        </Flex>
        {isFocused && (
          <Flex sx={{ alignContent: "center", justifyContent: "center" }}>
            <Flex
              mt="-1rem"
              bg="contentBg"
              sx={{
                display: ["none", "flex"],
                position: "absolute",
                width: "2rem",
                height: "2rem",
                border: "solid 1px",
                borderColor: "border",
                transform: "rotate(45deg)",
                borderTop: "transparent",
                borderLeft: "transparent",
              }}
            />
          </Flex>
        )}
      </Flex>
    );
  };

  return (
    <Layout>
      <StyledModalMessage
        isOpen={error.isError}
        message={<GetErrorDescription error={error.msg} />}
        onRequestClose={() =>
          setError({
            isError: false,
            msg: null,
          })
        }
        type="error">
        <Button onClick={tryAgain}>
          <FormattedMessage id="lbl.Try_Again" />
        </Button>
      </StyledModalMessage>

      <Heading>
        <FormattedMessage id="lbl.my_account" />
      </Heading>

      <Flex
        px={["tiny", "2.5rem", "2rem"]}
        sx={{
          maxWidth: ["100%", "100%", "72rem", "78.75rem"],
          flexDirection: ["column", "column", "row", "row"],
          border: "solid 1px",
          borderColor: "border",
          borderRadius: "card",
          alignItems: "center",
        }}>
        <Flex
          px={[0, "5rem"]}
          sx={{
            width: "100%",
            flexDirection: ["column", "row", "row", "row"],
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <RenderDetails
            icon={"accountDetails"}
            text={"lbl.account_details"}
            onClick={() => {
              setRenderInfo("account");
              setUpdateAccountDetails(false);
            }}
            isFocused={renderInfo === "account"}
          />

          <RenderDetails
            icon={"paymentInformation"}
            text={"lbl.payment_information"}
            onClick={() => {
              setRenderInfo("payment");
              setUpdateAccountDetails(false);
            }}
            isFocused={renderInfo === "payment"}
            ml="0.5rem"
          />
        </Flex>
      </Flex>

      {renderInfo === "account" &&
        (updateAccountDetails ? (
          <EditAccountDetails
            contact={primaryContact}
            onCancel={() => setUpdateAccountDetails(false)}
          />
        ) : (
          <Flex
            my={["default", "3.5rem", "3.5rem"]}
            sx={{
              maxWidth: ["100%", "100%", "72rem", "78.75rem"],
              justifyContent: "space-between",
              flexDirection: ["column", "column", "row", "row"],
              gap: ["1.75rem", "3.5rem", "3.5rem", "3.5rem"],
            }}>
            <ContactDetails
              isLoading={!primaryContact}
              primaryContact={primaryContact}
              onUpdateClick={() => setUpdateAccountDetails(true)}
            />

            <BillingDetails
              billingDetails={masterBillingDetails}
              isLoading={
                account_status === "loading" ||
                invoice_option_status === "loading" ||
                contacts_status === "loading" ||
                !shipmentContact ||
                !invoiceOptionFeature
              }
              shipmentContact={shipmentContact}
              invoiceOptionFeature={invoiceOptionFeature}
            />
          </Flex>
        ))}

      {renderInfo === "payment" && (
        <Flex
          sx={{
            maxWidth: ["100%", "100%", "72rem", "78.75rem"],
            flexDirection: ["column", "column", "row", "row"],
          }}>
          <PaymentInformation />
        </Flex>
      )}

      {ippay_params && <HostedCcOperationResponse params={ippay_params} isPayment={false} />}
    </Layout>
  );
};

export default AccountDetails;
