import React from "react";

const AutomaticPaymentEnabled = ({ mark = "#318eff", circle = "#114878", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.4"
    height="19.4"
    viewBox="0 0 19.4 19.4"
    {...props}>
    <path fill={mark} d="M7.4,15.3,2.9,11.2,4.9,9l2.3,2.1L13.6,4l2.2,2Z" />
    <path
      fill={circle}
      d="M9.7,1.5A8.2,8.2,0,1,1,1.5,9.7,8.237,8.237,0,0,1,9.7,1.5M9.7,0a9.7,9.7,0,0,0,0,19.4,9.646,9.646,0,0,0,9.7-9.7A9.774,9.774,0,0,0,9.7,0Z"
    />
  </svg>
);

export default AutomaticPaymentEnabled;
