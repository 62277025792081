import React from "react";
import ContentLoader from "react-content-loader";

const ServiceFeaturesSkeleton = ({ ...props }) => (
  <ContentLoader
    {...props}
    speed={2}
    viewBox="0 0 492 222"
    backgroundColor="#EEEEEE"
    foregroundColor="#d6d3d3">
    <rect x="250" y="40" width="200" height="20" />
    <rect x="80.6" y="110.4" width="319.7" height="46.6" />
  </ContentLoader>
);

export default ServiceFeaturesSkeleton;
