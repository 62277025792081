import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { FormattedMessage } from "react-intl";

import { Button } from "@theme-ui/components";

import ServiceAgreementPostpaid from "../ServiceAgreementPostpaid";
import StatusMessage from "../StatusMessage/StatusMessage";
import GetErrorDescription from "../GetErrorDescription";
import { StyledModalMessage } from "../modals";
import SubHeader from "../SubHeader";
import { MESSAGES_CODES } from "../../common/Constants";

import { getConfiguration } from "../../redux/slices/PlanSlice";

const ServiceAgreementLayout = () => {
  // try to read account code from path (full access)
  // if exists use it, else take account from the nba response (restricted)
  const { accountCode: accountCodePath } = useParams();
  const history = useHistory();

  const dispatch = useDispatch();
  const { account, serviceAgreements, master, userError, account_list_status } = useSelector(
    state => state.user
  );
  const { configuration, planError } = useSelector(state => state.plan);
  const [noAccounts, setNoAccounts] = useState(false);

  const [error, setError] = useState({
    isError: false,
    msg: null,
  });

  const statusMessage =
    master && (!master.serviceAgreement ? master.statusMessage : account.statusMessage);

  const isActive = [
    MESSAGES_CODES.ACTIVE,
    MESSAGES_CODES.ACTIVE_SAC,
    MESSAGES_CODES.ACTIVE_SAS,
  ].includes(statusMessage);

  useEffect(() => {
    if (account_list_status === "success" && serviceAgreements.length === 0) {
      setNoAccounts(true);
    }
  }, [account, accountCodePath, account_list_status, dispatch, master, serviceAgreements]);

  useEffect(() => {
    if (!configuration) {
      dispatch(getConfiguration());
    }
  }, [configuration, dispatch]);

  useEffect(() => {
    if (planError || userError) {
      setError({
        isError: true,
        msg: planError || userError,
      });
    }
  }, [planError, userError]);

  const onClose = () => {
    setError({
      isError: false,
      msg: "",
    });
  };

  const onTryAgain = () => {
    onClose();
    history.push("/home");
  };

  return (
    <>
      <SubHeader />
      {!isActive && <StatusMessage accountStatus={statusMessage} />}

      {!noAccounts && <ServiceAgreementPostpaid />}

      <StyledModalMessage
        isOpen={error.isError}
        message={<GetErrorDescription error={error.msg} />}
        onRequestClose={onClose}
        type="error">
        <Button variant="primary" onClick={onTryAgain}>
          <FormattedMessage id="lbl.Try_Again" />
        </Button>
      </StyledModalMessage>
    </>
  );
};

export default ServiceAgreementLayout;
