import React, { useEffect } from "react";
import MasterAccountLayout from "../../components/MasterAccountLayout";
import Layout from "../../containers/Layout";
import { getApiStatus } from "../../redux/slices/ApiStatusSlice";
import { useDispatch, useSelector } from "react-redux";

const Lines = () => {
  const dispatch = useDispatch();
  const { master } = useSelector(state => state.user);
  const { apiStatus } = useSelector(state => state.apiStatus);
  const accountCode = master?.accountCode;

  useEffect(() => {
    if (!apiStatus) {
      dispatch(getApiStatus());
    }
  }, [dispatch, accountCode, apiStatus]);

  return (
    <Layout>
      <MasterAccountLayout accountCode={accountCode} />
    </Layout>
  );
};

export default Lines;
