import React from "react";

const Wirelines = ({ cable = "#114878", tip = "#318eff", core = "#336eb4", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35.225"
    height="79.177"
    viewBox="0 0 35.225 79.177"
    {...props}>
    <rect fill={cable} width="3.785" height="13.354" transform="translate(15.667 65.823)" />
    <rect fill={cable} width="11.461" height="11.671" transform="translate(11.882 57.621)" />
    <path
      fill={tip}
      d="M31.254,27.654H3.6V2.944A2.939,2.939,0,0,1,6.544,0H28.31a2.939,2.939,0,0,1,2.944,2.944Z"
      transform="translate(0.185)"
    />
    <path
      fill={core}
      d="M19.768,61.069H15.352A15.46,15.46,0,0,1,0,45.612V22.9H35.225V45.717A15.46,15.46,0,0,1,19.768,61.069Z"
      transform="translate(0 1.179)"
    />
    <g transform="translate(8.938 8.938)">
      <rect fill={cable} width="6.309" height="6.309" />
      <rect fill={cable} width="6.309" height="6.309" transform="translate(11.566)" />
    </g>
  </svg>
);

export default Wirelines;
