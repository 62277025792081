import React from "react";
const Phone = ({ fill = "none", stroke = "#0c3865", innerFill = "#fff", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.277"
    height="25.49"
    viewBox="0 0 15.277 25.49"
    {...props}>
    <g data-name="Free-Pack / mobile-phone-2">
      <path
        d="M444.005 283.25a.255.255 0 0 1 .255.255h0a.255.255 0 0 1-.255.255h0a.255.255 0 0 1-.255-.255h0a.255.255 0 0 1 .255-.255"
        transform="translate(-436.367 -262.079)"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M437.5 264.543a2.042 2.042 0 0 1 2.043-2.043h9.192a2.042 2.042 0 0 1 2.043 2.043v19.4a2.042 2.042 0 0 1-2.043 2.043h-9.192a2.042 2.042 0 0 1-2.043-2.043z"
        transform="translate(-436.5 -261.5)"
        fillRule="evenodd"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        data-name="Line 81"
        transform="translate(6.679 2.015)"
        fill={innerFill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        strokeMiterlimit="10"
        d="M0 0h2"
      />
    </g>
  </svg>
);
export default Phone;
