import React from "react";
import { FormattedMessage } from "react-intl";

import { IPPAY_ERROR_CODES } from "../../common/Constants";

const GetIPPAYErrorDescription = ({ errorCode }) => {
  // messages for ippay errors
  switch (errorCode) {
    case IPPAY_ERROR_CODES.CREDIT_CARD_FAILURE:
    case IPPAY_ERROR_CODES.DO_NOT_HONOR:
      return <FormattedMessage id="err.credit_card_failure" />;

    default:
      return <FormattedMessage id="err.generic_ippay" />;
  }
};

export default GetIPPAYErrorDescription;
