import React from "react";

const Overview = ({
  paper = "#e6e6e6",
  text = "#114878",
  shadow = "#DCDCDC",
  icon = "#346EB3",
  ...props
}) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 47 57"
    {...props}>
    <g>
      <path
        fill={paper}
        d="M23.7,44.7c0-6.3,5.1-11.5,11.5-11.5c1.1,0,2.2,0.2,3.3,0.5l0-18.8l-15-15l-23,0l0,51.8h25.8
		C24.6,49.9,23.7,47.4,23.7,44.7z"
      />
      <g>
        <path
          fill={text}
          d="M44.6,57c-0.5,0-1-0.2-1.4-0.6l-3.7-3.7c-0.8-0.8-0.8-2,0-2.8s2-0.8,2.8,0l3.7,3.7c0.8,0.8,0.8,2,0,2.8
			C45.6,56.8,45.1,57,44.6,57z"
        />
      </g>
      <rect x="7.4" y="20.3" fill={text} width="24" height="2" />
      <rect x="7.4" y="24.2" fill={text} width="24" height="2" />
      <rect x="7.4" y="28.1" fill={text} width="24" height="2" />
      <rect x="7.4" y="32" fill={text} width="24" height="2" />
      <polygon fill={shadow} points="23.4,0 23.4,15 38.4,15 	" />
      <g>
        <path
          fill={icon}
          d="M35.1,38.3c3.6,0,6.5,2.9,6.5,6.5c0,3.6-2.9,6.5-6.5,6.5c-3.6,0-6.5-2.9-6.5-6.5
			C28.7,41.2,31.6,38.3,35.1,38.3 M35.1,35.3c-5.2,0-9.5,4.2-9.5,9.5c0,5.2,4.2,9.5,9.5,9.5c5.2,0,9.5-4.2,9.5-9.5
			C44.6,39.5,40.4,35.3,35.1,35.3L35.1,35.3z"
        />
      </g>
    </g>
  </svg>
);
export default Overview;
