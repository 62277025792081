import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Layout from "../../containers/Layout";
import SubHeader from "../../components/SubHeader";
import ServiceAgreementPackage from "../../components/ServiceAgreementPackage";

const Plan = () => {
  const history = useHistory();
  const { configuration, additionalSrv } = useSelector(state => state.plan);

  return (
    <Layout>
      <SubHeader />
      <ServiceAgreementPackage
        additionalSrv={additionalSrv}
        configuration={configuration}
        onBack={() => history.goBack()}
      />
    </Layout>
  );
};

export default Plan;
