import React from "react";
import { Flex, Text } from "@theme-ui/components";
import { SelfcareIcon } from "../icons";
import { FormattedMessage } from "react-intl";

const ViewDetails = ({ icon, label, onClick, ...props }) => {
  return (
    <Flex
      {...props}
      sx={{
        justifyContent: "flex-end",
        alignItems: "center",
        width: "fit-content",
        alignSelf: "flex-end",
        cursor: "pointer",
      }}
      onClick={onClick}>
      <SelfcareIcon
        name={icon}
        strokeWidth={5}
        sx={{
          width: "26px",
          height: "26px",
          ":hover": {
            cursor: "pointer",
          },
        }}
      />
      <Text variant="link" ml="small" sx={{ fontWeight: "semiBold" }}>
        <FormattedMessage id={label ?? "tooltip.details"} />
      </Text>
    </Flex>
  );
};

export default ViewDetails;
