import React from "react";

const Close = ({ stroke = "white", ...props }) => (
  <svg
    width={30}
    height={30}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path fillOpacity={0.01} d="M0 0h48v48H0z" />
    <path
      d="m8 8 32 32M8 40 40 8"
      stroke={stroke}
      strokeWidth={6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Close;
