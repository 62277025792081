import React from "react";
const PaymentSuccess = ({ fill = "#48c0b6", stroke = "#fff", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="118" height="118" viewBox="0 0 118 118" {...props}>
    <circle data-name="Ellipse 26" cx="59" cy="59" r="59" fill={fill} />
    <svg xmlns="http://www.w3.org/2000/svg" width="118" height="118" viewBox="0 0 118 118">
      <circle
        data-name="Ellipse 48"
        cx="28"
        cy="28"
        r="28"
        transform="translate(31 31)"
        fill={"none"}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
      />

      <svg xmlns="http://www.w3.org/2000/svg" width="118" height="118" viewBox="0 0 118 118">
        <path
          data-name="Path 816"
          d="m7 20.036 8.277 8.277L34.589 9"
          transform="translate(38 42)"
          fill={"none"}
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
      </svg>
    </svg>
  </svg>
);
export default PaymentSuccess;
