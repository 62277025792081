import React from "react";
import { FormattedMessage } from "react-intl";

import { Text, Flex, Spinner } from "@theme-ui/components";
import { SelfcareButton } from "../../components/base";

const SubmitButton = ({
  onSubmit = null,
  isLoading,
  isdisabled = false,
  text = "lbl.submit",
  ...props
}) => {
  return (
    <SelfcareButton
      disabled={isLoading || isdisabled}
      bg="secondary"
      onClick={onSubmit}
      variant="primary"
      sx={{
        width: props.width ? props.width : "10rem", // default 10rem
        alignSelf: ["center", "flex-end", "flex-end"],
        userSelect: "auto",
        justifySelf: "end",
      }}
      {...props}>
      {!isLoading ? (
        <Text color="textLight">
          <FormattedMessage id={text} />
        </Text>
      ) : (
        <Flex
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Spinner role="status" color="white" size={30} />
        </Flex>
      )}
    </SelfcareButton>
  );
};

export default SubmitButton;
