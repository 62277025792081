import React, { useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Spinner, Button } from "@theme-ui/components";
import { StyledModalMessage, StyledModalWindow } from "../modals";
import { useSelector, useDispatch } from "react-redux";
import GetErrorDescription from "../../components/GetErrorDescription";

import { SelfcareButton } from "../base";
import { setUsageDetailsStatus } from "../../redux/slices/UserSlice";
import { setInvoiceDataStatus } from "../../redux/slices/BillingSlice";

const LoadDocumentModal = ({
  isOpen,
  onClose,
  buttonLabel = "lbl.viewNow",
  loadingLabel = "lbl.loading_document",
}) => {
  const { invoice_data_status, invoiceData, billingError } = useSelector(state => state.billing);
  const { usage_details_status, usageDetails, userError } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const intl = useIntl();
  const [isError, setError] = useState(false);
  const [url, setUrl] = useState(null);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (invoice_data_status === "failed" || usage_details_status === "failed") {
      setError(true);
      dispatch(setUsageDetailsStatus(null));
      dispatch(setInvoiceDataStatus(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice_data_status, usage_details_status]);

  useEffect(() => {
    if (invoice_data_status === "success") {
      var fileURL = invoiceData;
      setMessage("lbl.loading_invoice_success");
      setUrl(fileURL);
      dispatch(setInvoiceDataStatus(null));
    }
    if (usage_details_status === "success") {
      let fileURL = usageDetails;
      setMessage("lbl.loading_document_success");
      setUrl(fileURL);
      dispatch(setUsageDetailsStatus(null));
    }
  }, [dispatch, invoiceData, invoice_data_status, url, usageDetails, usage_details_status]);

  const onClickClose = () => {
    onClose();
    setError(false);
    setUrl(null);
  };
  return isError ? (
    <StyledModalMessage
      isOpen={isError}
      message={<GetErrorDescription error={userError || billingError} />}
      onRequestClose={onClickClose}
      isCloseDisabled={false}
      type="error">
      <Button onClick={onClickClose}>
        <FormattedMessage id="lbl.ok" />
      </Button>
    </StyledModalMessage>
  ) : (
    <StyledModalWindow
      isOpen={isOpen}
      message={url ? intl.formatMessage({ id: message }) : intl.formatMessage({ id: loadingLabel })}
      onRequestClose={() => {
        setUrl(null);
        onClose();
      }}
      isCloseDisabled={false}
      type={!url ? "loading_success" : "loading"}>
      {url ? (
        <SelfcareButton
          onClick={() => {
            window.open(url);
            setUrl(null);
            onClose();
          }}
          sx={{ textWrap: "nowrap", width: "fit-content", px: "default" }}>
          {intl.formatMessage({ id: buttonLabel })}
        </SelfcareButton>
      ) : (
        <Spinner />
      )}
    </StyledModalWindow>
  );
};

export default LoadDocumentModal;
