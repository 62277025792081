import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Text, Button, Flex } from "@theme-ui/components";
import Layout from "../../containers/Layout";
import { doLogout } from "../../redux/slices/AuthSlice";
import GetErrorDescription from "../../components/GetErrorDescription/GetErrorDescription";

const Error = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userError } = useSelector(state => state.user);

  return (
    <Layout displayOnlyBurgerMenu={true}>
      <Flex
        pt="15rem"
        sx={{ justifyContent: "center", alignItems: "center", height: "100%" }}
        mt="huge">
        <Flex
          bg="contentBg"
          px="large"
          py="large"
          sx={{
            boxShadow: "light",
            borderRadius: "card",

            alignItems: "center",
            flexDirection: "column",
            border: "1px solid",
            borderColor: "border",
          }}>
          <Text color="primary" sx={{ fontSize: 4, lineHeight: 1, textAlign: "center" }}>
            {userError ? (
              <GetErrorDescription error={userError} />
            ) : (
              <FormattedMessage id="err.generic" />
            )}
          </Text>

          <Flex mt="largeish" sx={{ flexWrap: "wrap" }}>
            <Button
              onClick={() => {
                history.replace("/");
                dispatch(doLogout());
              }}>
              <FormattedMessage id="lbl.ok" />
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Layout>
  );
};

export default Error;
