import React from "react";

import { FormattedMessage, useIntl } from "react-intl";
import { Flex, Text } from "@theme-ui/components";

import SubmitButton from "../../components/SubmitButton";
import { getServiceAvailable } from "./Utilities";

const TravelPackCard = ({ service, onAdd }) => {
  const intl = useIntl();
  const serviceFee = service.setupFee > 0 ? service.setupFee : service.rcFee;
  return (
    <Flex variant="layout.card" sx={{ minHeight: "10rem" }}>
      <Flex>
        <Text
          variant="heading3"
          sx={{
            whiteSpace: "normal",
            overflowWrap: "break-word",
            fontSize: [3, 4, 4, 5],
            height: "5rem",
          }}>
          {service.description}
        </Text>
      </Flex>
      <Text mt="small" variant="heading3normal">
        {getServiceAvailable(service)}
      </Text>

      <Flex
        sx={{ mt: "small", justifyContent: "space-between", alignItems: "center", height: "100%" }}>
        <Text variant="subheadline">
          {intl.formatNumber(serviceFee, {
            style: "currency",
            currency: "CAD",
            currencySign: "standard",
          })}
          {service.setupFee === 0 && <FormattedMessage id="lbl.monthly_short" />}
        </Text>

        <SubmitButton
          text="lbl.add"
          data-testid="TravelPack"
          onSubmit={onAdd}
          sx={{ alignSelf: "center" }}
        />
      </Flex>
    </Flex>
  );
};

export default TravelPackCard;
