import React from "react";
const BankAccountType = ({ stone = "#114878", brick = "#318eff", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23.135"
    height="24.333"
    viewBox="0 0 23.135 24.333"
    {...props}>
    <path
      fill={stone}
      d="M14.264,1.558,11.627,0,8.87,1.558,0,6.713V9.829L11.627,3.117,23.135,9.709v-3Z"
    />
    <rect fill={brick} width="19.659" height="3.596" transform="translate(1.798 20.737)" />
    <rect fill={stone} width="17.861" height="1.678" transform="translate(2.637 10.069)" />
    <rect fill={stone} width="23.135" height="1.678" transform="translate(0 22.535)" />
    <rect fill={stone} width="23.135" height="1.678" transform="translate(0 7.312)" />
    <rect fill={stone} width="1.798" height="10.668" transform="translate(2.637 10.069)" />
    <rect fill={stone} width="1.798" height="10.668" transform="translate(18.7 10.069)" />
    <rect fill={stone} width="1.798" height="10.668" transform="translate(8.031 10.069)" />
    <rect fill={stone} width="1.798" height="10.668" transform="translate(13.306 10.069)" />
    <rect fill={brick} width="23.135" height="1.678" transform="translate(0 8.271)" />
  </svg>
);

export default BankAccountType;
