import React from "react";
const UserClosedStatus = ({ st1_fill = "#FFFFFF", st2_fill = "#F15749", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" {...props}>
    <circle fill={st2_fill} cx="7" cy="7" r="7" />
    <polygon
      fill={st1_fill}
      points="10.2,5.2 8.8,3.8 7,5.6 5.2,3.8 3.8,5.2 5.6,7 3.8,8.8 5.2,10.2 7,8.4 8.8,10.2 10.2,8.8 8.4,7"
    />
  </svg>
);
export default UserClosedStatus;
