import React from "react";

const Prev = ({ stroke = "#8e8d8d", fill = "#f3f3f3", ...props }) => (
  <svg
    data-name="Group 164"
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    {...props}>
    <g data-name="Ellipse 1" stroke={stroke} fill={fill}>
      <circle cx="20" cy="20" r="20" stroke="none" />
      <circle cx="20" cy="20" r="19.5" fill="none" />
    </g>
    <path
      data-name="Path 18"
      d="M0 8.624 8.624 0l8.624 8.624"
      transform="rotate(-90 21.441 7.281)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3px"
      fill="none"
      stroke={stroke}
    />
  </svg>
);
export default Prev;
