import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Box, Flex, Text } from "@theme-ui/components";
import { useHistory } from "react-router";
import { StyledModalWindow } from "../modals";
import DeviceDetailsCard from "../DeviceDetails/DeviceDetailsCard";
import { useDispatch } from "react-redux";
import { getDeviceDetails, setAccount, setDeviceDetailsStatus } from "../../redux/slices/UserSlice";
import { formatPhoneNumber, isMobileView, parsePackageDescription } from "../../common/Utilities";
import { MapAccountStatus } from "../SubscriberInformation/SubscriberInformation";

const SARow = ({ account, gridColumns, ...props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showDeviceDetails, setShowDeviceDetails] = useState(false);
  const isMobile = isMobileView();

  return (
    <Box
      py="small"
      sx={{
        display: "grid",
        gridGap: ["large", "large", "huge"],
        gridTemplateColumns: gridColumns,
        alignItems: "center",
      }}
      {...props}>
      <Flex>
        <MapAccountStatus status={account.accountStatus} />
      </Flex>

      <Text sx={{ color: "textDark", whiteSpace: "pre", fontFeatureSettings: "'tnum'" }}>
        {account.phoneNumber !== "" ? formatPhoneNumber(account.phoneNumber) : account.accountCode}
      </Text>
      <Flex sx={{ flexDirection: "column" }}>
        <Flex sx={{ alignItems: "center" }}>
          <Text
            sx={{
              textAlign: "left",
              overflowWrap: "break-word",
            }}>
            {parsePackageDescription(account.packageDescription)}
          </Text>
        </Flex>
      </Flex>
      <Flex>
        <Flex
          sx={{
            cursor: "pointer",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "left",
            "&:hover": {
              opacity: "90%",
            },
            whiteSpace: "pre",
          }}
          onClick={() => {
            dispatch(getDeviceDetails(account.accountCode));
            setShowDeviceDetails(true);
          }}>
          <Text variant="tablelink">
            <FormattedMessage id="lbl.device_details" />
          </Text>
        </Flex>
      </Flex>
      <Flex
        sx={{
          cursor: "pointer",
          justifyContent: "center",
          alignItems: "center",
          pr: "default",
          width: "fit-content",
          textAlign: "left",
          "&:hover": {
            opacity: "90%",
          },
          whiteSpace: "pre",
        }}
        onClick={() => {
          dispatch(getDeviceDetails(account.accountCode));
          dispatch(setAccount(account));
          history.push("/account/" + account.accountCode + "/home");
        }}>
        <Text variant="tablelink">
          <FormattedMessage id="lbl.line_details" />
        </Text>
      </Flex>

      <StyledModalWindow
        data-testid="device-details-modal"
        isOpen={showDeviceDetails}
        onRequestClose={() => {
          setShowDeviceDetails(false);
          dispatch(setDeviceDetailsStatus(null));
        }}>
        <Flex
          px={["default", "default", "large", "larger"]}
          sx={{
            flexDirection: "column",
            width: "69rem",
          }}>
          <Text variant="headline" my={["default", "default", "default", "medium"]}>
            {account.phoneNumber !== ""
              ? formatPhoneNumber(account.phoneNumber)
              : account.accountCode}
            {isMobile ? <br /> : " - "}
            <FormattedMessage id="lbl.device_details" />
          </Text>
          <DeviceDetailsCard account={account} />
        </Flex>
      </StyledModalWindow>
    </Box>
  );
};

export default SARow;
