import React from "react";
const ServiceAgreement = ({ text = "#318eff", edge = "#336eb4", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.6"
    height="29.4"
    viewBox="0 0 21.6 29.4"
    {...props}>
    <rect fill={text} width="13.5" height="1.7" transform="translate(4.1 10.2)" />
    <rect fill={text} width="13.5" height="1.7" transform="translate(4.1 13.8)" />
    <rect fill={text} width="13.5" height="1.7" transform="translate(4.1 17.5)" />
    <path fill={edge} d="M6.5,0,0,6.5V29.4H21.6V0ZM20.4,28.2H1.2V7.7H7.7V1.2H20.4Z" />
  </svg>
);
export default ServiceAgreement;
