import React, { useEffect, useState, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Flex, Heading, Box } from "@theme-ui/components";
import Carousel from "../Carousel";
import { SACard, SACardSkeleton } from "../SACard";
import ServicesTable from "../ServicesTable/ServicesTable";
import SearchBar from "./SearchBar";
import { getBreakpoints } from "../../common/Utilities";
import useWindowSize from "../../hooks/useWindowSize";

const browserFontSize = parseFloat(
  getComputedStyle(document.body, null).getPropertyValue("font-size")
);
let itemsPerRow = 4;
if (window.innerWidth <= 96.1 * browserFontSize) {
  itemsPerRow = 3;
}
if (window.innerWidth <= 64.1 * browserFontSize) {
  itemsPerRow = 2;
}
if (window.innerWidth <= 40 * browserFontSize) {
  itemsPerRow = 1;
}

const ServiceAgreement = ({ data, isLoading, showTable, setShowTable, ...props }) => {
  const Intl = useIntl();
  const [searchTerm, setSearchTerm] = useState("");
  const [startIndex, setStartIndex] = useState(0);
  const [paddedArray, setPaddedArray] = useState([]);
  const cardRef = useRef();
  const pageRef = useRef();
  const containerRef = useRef();
  const size = useWindowSize();
  const [itemsToRender, setItemsToRender] = useState(itemsPerRow);
  // each time the filter changes we should reset the table paging
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (cardRef.current) {
      const itemsToRender = Math.floor(
        pageRef?.current?.getBoundingClientRect().width /
          cardRef?.current?.getBoundingClientRect().width
      );
      if (itemsToRender > 0) setItemsToRender(itemsToRender);
    }
  }, [size]);

  const filtering = searchFilter => {
    paddedArray.splice(0);
    if (searchFilter === "") {
      paddedArray.push(...data);
      if (data.length > itemsToRender) {
        for (let i = 0; i < itemsToRender; i++) {
          paddedArray.push(data[i]);
        }
      }
      setPaddedArray([...paddedArray]);
    } else {
      const filterArr = data.filter(val => {
        if (
          (val.phoneNumber
            ? val.phoneNumber.includes(searchFilter.replace(/[-]/g, ""))
            : val.accountCode.includes(searchFilter)) ||
          (val.packageDescription &&
            val.packageDescription.toLowerCase().includes(searchFilter.toLowerCase()))
        ) {
          return val;
        } else return null;
      });
      paddedArray.push(...filterArr);
      if (filterArr && filterArr.length > itemsToRender) {
        for (let i = 0; i < itemsToRender; i++) {
          paddedArray.push(filterArr[i]);
        }
        setPaddedArray(paddedArray);
      }
    }
  };

  useEffect(() => {
    paddedArray.splice(0);
    setPaddedArray([...paddedArray]);
    if (data && data.length > itemsToRender) {
      paddedArray.push(...data);
      for (let i = 0; i < itemsToRender; i++) paddedArray.push(data[i]);
      setPaddedArray([...paddedArray]);
    } else if (data) {
      paddedArray.push(...data);
      setPaddedArray([...paddedArray]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, itemsToRender]);

  const onChangeFilter = event => {
    setCurrentPage(1);
    setStartIndex(0);
    setSearchTerm(event.target.value);
    filtering(event.target.value);
  };

  const clearFilter = () => {
    setSearchTerm("");
    filtering("");
  };

  const onClickViewAll = () => {
    window.scrollTo(0, 0);
    setStartIndex(0);
    setShowTable(true);
    clearFilter();
  };

  const goBack = () => {
    window.scrollTo(0, 0);
    setShowTable(false);
    setCurrentPage(1);
    clearFilter();
  };

  const displayCards = () => {
    const total = paddedArray.length;
    if (total <= itemsToRender) {
      return paddedArray;
    }
    return paddedArray.slice(startIndex, startIndex + itemsToRender);
  };

  if (isLoading) {
    return (
      <Flex
        ref={pageRef}
        mt="huge"
        sx={{
          flexDirection: "row",
          gap: 40,
        }}>
        {[...Array(itemsToRender).keys()].map(index => (
          <Box
            key={index}
            bg="contentBg"
            mb="default"
            data-testid="sa-skeleton"
            sx={{
              border: "1px solid",
              borderColor: "border",
              boxShadow: "light",
              borderRadius: "card",
              height: "15.625rem",
              width: ["100%", "21rem", "22rem", "24.25rem"],
            }}>
            <SACardSkeleton />
          </Box>
        ))}
      </Flex>
    );
  }

  if (!Array.isArray(data) || data.length <= 0) {
    return (
      <Flex
        sx={{
          textAlign: ["center", "left", "left"],
          flexDirection: "column",
        }}
      />
    );
  }

  const gridGap = 2.5;
  const defaultCardWidth = [22, 21, 24.25, 24.25];
  const cardWidth = cardRef?.current
    ? cardRef?.current.offsetWidth / 16
    : defaultCardWidth[getBreakpoints() - 1];

  const carouselWidth = cardWidth * itemsToRender + gridGap * (itemsToRender - 1) + "rem";

  return (
    <Flex ref={pageRef} sx={{ flexDirection: "column" }} {...props} data-testid="service-agreement">
      <Flex
        mb={["small", "default", 0]}
        sx={{ flexDirection: ["column", "column", "row"], alignItems: ["center", "left"] }}>
        <Heading variant="heading2">
          <FormattedMessage id="lbl.sa_list_heading" /> (
          {paddedArray.length > itemsToRender
            ? paddedArray.length - itemsToRender
            : paddedArray.length}
          )
        </Heading>

        <SearchBar
          onClick={clearFilter}
          onChangeFilter={onChangeFilter}
          searchTerm={searchTerm}
          ml={[0, 0, "huge"]}
        />
      </Flex>

      {!Array.isArray(paddedArray) || paddedArray.length <= 0 ? (
        <></>
      ) : showTable ? (
        <ServicesTable
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          goBack={goBack}
          services={paddedArray.slice(
            0,
            paddedArray.length > itemsToRender
              ? paddedArray.length - itemsToRender
              : paddedArray.length
          )}
        />
      ) : (
        <Carousel
          width={carouselWidth}
          isPaging={paddedArray.length > itemsToRender}
          onViewAll={onClickViewAll}
          carouselType={Intl.formatMessage({ id: "lbl.lines" }).toLocaleLowerCase()}
          length={
            paddedArray.length > itemsToRender
              ? paddedArray.length - itemsToRender
              : paddedArray.length
          }
          startIndex={startIndex}
          setStartIndex={setStartIndex}>
          <Flex ref={containerRef} sx={{ gap: "larger", width: "100%" }}>
            {displayCards().map((account, index) => {
              return (
                <SACard
                  ref={index === displayCards().length - 1 ? cardRef : null}
                  key={account.accountCode + index}
                  account={account}
                />
              );
            })}
          </Flex>
        </Carousel>
      )}
    </Flex>
  );
};

export default ServiceAgreement;
