import React from "react";

const BankAccountNumber = ({
  hash = "#346eb3",
  brick = "#318eff",
  stone = "#114878",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29.583"
    height="28.959"
    viewBox="0 0 29.583 28.959"
    {...props}>
    <path
      fill={hash}
      d="M25.211,12H23.089l-.749,3.62h-2L21.092,12H18.97l-.749,3.62H15.6v2h2.247l-.5,2.746H15.725v2h1.248L16.1,25.98h2.122l.749-3.62h2l-.749,3.62H22.34l.749-3.62h2.621v-2H23.464l.5-2.746h1.623v-2H24.338Zm-3.87,8.363h-2l.5-2.746h2Z"
      transform="translate(3.872 2.979)"
    />
    <rect fill={brick} width="16.726" height="2.746" transform="translate(1.872 21.594)" />
    <path
      fill={stone}
      d="M14.854,1.623,12.108,0,9.237,1.623,0,6.99v3.245H24.091V6.99ZM4.369,7.614l7.739-4.494,7.739,4.494Z"
    />
    <rect fill={brick} width="24.091" height="1.748" transform="translate(0 8.613)" />
    <rect fill={stone} width="18.474" height="1.748" transform="translate(0 23.467)" />
    <path
      fill={stone}
      d="M18.926,14.891H20.8V8.4H2.2v1.748h0v9.362H4.072V10.148H7.817v9.362H9.689V10.148h3.62v9.362h2V10.148h3.62Z"
      transform="translate(0.546 2.085)"
    />
  </svg>
);

export default BankAccountNumber;
