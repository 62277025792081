import React from "react";

const Download = ({ fill = "none", stroke = "#0c3865", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.514"
    height="15.078"
    viewBox="0 0 18.514 15.078"
    {...props}>
    <g id="Group_1654" data-name="Group 1654" transform="translate(-1225.5 -538.152)">
      <path
        id="Path_775"
        data-name="Path 775"
        d="m17 2-5 5-5-5"
        transform="translate(1223.5 542)"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
      />
      <path
        id="Path_824"
        data-name="Path 824"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeWidth="2px"
        d="M1236.063 547.147v-9.164"
        transform="translate(-.563 1.169)"
      />
      <path
        id="Path_825"
        data-name="Path 825"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeWidth="2px"
        d="M1227.405 552.23h16.514"
        transform="translate(-.905)"
      />
    </g>
  </svg>
);

export default Download;
