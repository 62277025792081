import React from "react";

const Next = ({ stroke = "#8e8d8d", fill = "#f3f3f3", ...props }) => (
  <svg
    data-name="Group 164"
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    {...props}>
    <g data-name="Ellipse 1" fill={fill} stroke={stroke}>
      <circle cx="20" cy="20" r="20" stroke="none" />
      <circle cx="20" cy="20" r="19.5" fill="none" />
    </g>
    <path
      data-name="Path 18"
      d="m0 0 8.624 8.624L17.248 0"
      transform="rotate(-90 22.969 5.753)"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3px"
      fill="none"
    />
  </svg>
);
export default Next;
