import React from "react";
const MyPayments = ({
  fill = "none",
  stroke = "#fff",
  width = "30px",
  height = "30px",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 30 21.064"
    {...props}>
    <g id="Layer_1" transform="translate(-142.9 -15.4)">
      <g id="Group_275" data-name="Group 275" transform="translate(138 2)">
        <path
          id="Path_789"
          data-name="Path 789"
          className="cls-1"
          d="M33.9 31.379V16.386a1.992 1.992 0 0 0-1.986-1.986H7.886A1.992 1.992 0 0 0 5.9 16.386v15.092a1.992 1.992 0 0 0 1.986 1.986h24.028a2.149 2.149 0 0 0 1.986-2.085z"
          fill={fill}
          stroke={stroke}
          strokeMiterlimit="10"
          strokeWidth="1.4"
        />
        <g id="Group_274" data-name="Group 274" transform="translate(10.1 26.017)">
          <path
            id="Line_98"
            data-name="Line 98"
            className="cls-2"
            d="M0 0h4.3"
            fill={fill}
            stroke={stroke}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
          <path
            id="Line_99"
            data-name="Line 99"
            className="cls-2"
            transform="translate(6.426)"
            d="M0 0h2.3"
            fill={fill}
            stroke={stroke}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
          <path
            id="Line_100"
            data-name="Line 100"
            className="cls-2"
            transform="translate(10.858)"
            d="M0 0h4.3"
            fill={fill}
            stroke={stroke}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
          <path
            id="Line_101"
            data-name="Line 101"
            className="cls-2"
            transform="translate(17.4)"
            d="M0 0h2.2"
            fill={fill}
            stroke={stroke}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
        </g>
        <path
          id="Line_102"
          data-name="Line 102"
          className="cls-2"
          transform="translate(10.1 29.393)"
          d="M0 0h8.8"
          fill={fill}
          stroke={stroke}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          id="Path_790"
          data-name="Path 790"
          className="cls-1"
          d="M13.3 22.7h-2.2a1.11 1.11 0 0 1-1.1-1.1v-2.2a1.11 1.11 0 0 1 1.1-1.1h2.2a1.11 1.11 0 0 1 1.1 1.1v2.2a1.11 1.11 0 0 1-1.1 1.1z"
          transform="translate(0 -.036)"
          fill={fill}
          stroke={stroke}
          strokeMiterlimit="10"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
);
export default MyPayments;
