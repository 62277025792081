import React from "react";
const Mail = ({ envelope = "#318eff", line = "#114878", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29.1"
    height="16.512"
    viewBox="0 0 29.1 16.512"
    {...props}>
    <rect fill={envelope} width="29.1" height="16.512" />
    <path
      fill={line}
      d="M24.212,5.536l-.654-.654-7.6,7.6L8.354,4.8,7.7,5.536l3.6,3.6-3.6,3.6.654.654,3.6-3.6,3.6,3.6a.62.62,0,0,0,.327.163.62.62,0,0,0,.327-.163l3.6-3.6,3.6,3.6.654-.654-3.6-3.6Z"
      transform="translate(-1.406 -0.876)"
    />
  </svg>
);
export default Mail;
