import React from "react";

import { Text, Label } from "@theme-ui/components";

const SelfcareLabel = ({ children, variant = "labelLarge", isMandatory, ...props }) => {
  return (
    <Label variant={variant}>
      <Text
        sx={{
          whiteSpace: ["initial", "nowrap"],
          overflow: "hidden",
          textOverflow: "ellipsis",
          mb: "tiny",
        }}
        {...props}>
        {children}
      </Text>

      {isMandatory && (
        <Text
          color="error"
          sx={{
            fontSize: 1,
          }}>
          *
        </Text>
      )}
    </Label>
  );
};
export default SelfcareLabel;
