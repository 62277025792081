import React from "react";

const ChatPhone = ({ color = "#6670af", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="20 0 116 100" {...props}>
    <path
      fill={color}
      d="M67.4 67.6c10.9 10.9 21.1 9.6 25.2 7.9 3.9-1.7 5.9-3.6 7.7-5.3 1.7-1.7 3.6-2.3 5.6-.4 2.1 1.8 15 14.9 17.3 17.3 2.4 2.4 1.7 4.8-.1 6.2-4.8 3.7-15.6 8.8-27.3 5.6-11.5-3.1-23.2-10.2-36.4-23.4-13.1-13.2-20.1-24.9-23.2-36.4-3.2-11.7 2-22.4 5.6-27.3 1.4-1.8 3.8-2.5 6.2-.1C50.3 14.1 63.4 27 65.3 29c1.8 2.1 1.3 3.9-.4 5.6-1.7 1.7-3.6 3.8-5.3 7.7-1.8 4.1-3.1 14.3 7.8 25.3z"></path>
  </svg>
);
export default ChatPhone;
