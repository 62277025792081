import React from "react";

const Address = ({ pin = "#114878", circle = "#346eb3", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24.126"
    height="27.513"
    viewBox="0 0 24.126 27.513"
    {...props}>
    <g transform="translate(0.013)">
      <path
        fill={pin}
        d="M12.1,0A8.173,8.173,0,0,0,3.9,8.2c0,6.6,8.2,16,8.2,16s8.2-9.3,8.2-16A8.3,8.3,0,0,0,12.1,0Zm0,11.7A3.691,3.691,0,0,1,8.4,8a3.7,3.7,0,0,1,7.4,0A3.757,3.757,0,0,1,12.1,11.7Z"
      />
      <path
        fill="none"
        stroke={circle}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.026px"
        d="M17.4,19.8c3.7.6,6.2,1.9,6.2,3.4,0,2.1-5.2,3.8-11.5,3.8S.5,25.3.5,23.2c0-1.5,2.5-2.8,6.3-3.4"
      />
    </g>
  </svg>
);

export default Address;
