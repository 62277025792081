import React, { useState } from "react";
import PropTypes from "prop-types";
import Storage from "store2";
import { IntlProvider } from "react-intl";
import { INITIAL_LOCALE, MESSAGES, SUPPORTED_LOCALES, Localization } from "../common/Localization";

const SelfcareIntlContext = React.createContext();

const formats = {
  // number: {
  //   USD: {
  //     style: "currency",
  //     currency: "USD",
  //   },
  // },
};

function SelfcareIntlProvider({ children }) {
  // Method to dynamically change the language
  const nextLanguage = locale => {
    let localeList = Object.keys(SUPPORTED_LOCALES);
    let idx = localeList.indexOf(locale);
    return localeList[(idx + 1) % localeList.length];
  };

  const switchLanguageTo = language => {
    Storage.set("language", language, true);
    setIntlParams({
      ...intlParams,
      locale: language,
      messages: MESSAGES[language],
    });
    Localization.setLocale(language);
  };

  const storedLocale = Storage.get("language", INITIAL_LOCALE);

  const initLocale = Object.prototype.hasOwnProperty.call(SUPPORTED_LOCALES, storedLocale)
    ? storedLocale
    : INITIAL_LOCALE;

  if (initLocale !== storedLocale) {
    Storage.set("language", initLocale, true);
  }

  const [intlParams, setIntlParams] = useState({
    locale: initLocale,
    messages: MESSAGES[initLocale],
    nextLanguage: nextLanguage,
    switchLanguageTo: switchLanguageTo,
  });

  return (
    <SelfcareIntlContext.Provider value={intlParams}>
      <IntlProvider
        key={intlParams.locale}
        locale={intlParams.locale}
        messages={MESSAGES[intlParams.locale]}
        defaultLocale={INITIAL_LOCALE}
        formats={formats}>
        {children}
      </IntlProvider>
    </SelfcareIntlContext.Provider>
  );
}
SelfcareIntlProvider.propTypes = {
  children: PropTypes.node,
};
export { SelfcareIntlProvider, SelfcareIntlContext, INITIAL_LOCALE };
