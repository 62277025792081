import React, { useState } from "react";
import { IconButton, Flex } from "@theme-ui/components";

const showPassword = (
  <svg xmlns="http://www.w3.org/2000/svg" width="30.688" height="28" viewBox="0 0 30.688 16">
    <path
      d="M16,8C7.664,8,1.25,15.344,1.25,15.344L.656,16l.594.656S7.1,23.324,14.875,23.938a10.156,10.156,0,0,0,2.25,0c7.777-.613,13.625-7.281,13.625-7.281L31.344,16l-.594-.656S24.336,8,16,8Zm0,2a14.485,14.485,0,0,1,6,1.406A6.891,6.891,0,0,1,23,15a7,7,0,0,1-6.219,6.969c-.02,0-.043,0-.062,0C16.48,21.98,16.242,22,16,22c-.266,0-.523-.016-.781-.031a7.019,7.019,0,0,1-5.25-10.531H9.938A14.52,14.52,0,0,1,16,10Zm0,2a3,3,0,1,0,3,3A3,3,0,0,0,16,12Zm-8.75.938a8.892,8.892,0,0,0,1.125,6.844A23.2,23.2,0,0,1,3.531,16,23.928,23.928,0,0,1,7.25,12.938Zm17.5,0A23.933,23.933,0,0,1,28.469,16a23.2,23.2,0,0,1-4.844,3.781,8.892,8.892,0,0,0,1.125-6.844Z"
      transform="translate(-0.656 -8)"
      fill="#a1b7d1"
    />
  </svg>
);

const hidePassword = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30.688"
    height="27.438"
    viewBox="0 0 30.688 27.438">
    <path
      d="M3.719,2.281,2.281,3.719,8.5,9.906,19.594,21,21.5,22.938l6.781,6.781,1.438-1.437L23.5,22.063a26.464,26.464,0,0,0,7.25-5.406L31.344,16l-.594-.656C30.48,15.043,24.086,8,16,8a15.71,15.71,0,0,0-5.5,1.063ZM16,10a14.448,14.448,0,0,1,6,1.438,6.913,6.913,0,0,1-.844,8.281l-2.844-2.844A2.961,2.961,0,0,0,19,15a3,3,0,0,0-3-3,2.961,2.961,0,0,0-1.875.688l-2.031-2.031A13.223,13.223,0,0,1,16,10Zm-9.312.906A26.468,26.468,0,0,0,1.25,15.344L.656,16l.594.656c.258.289,6.18,6.77,13.813,7.281A9.274,9.274,0,0,0,16,24a9.274,9.274,0,0,0,.938-.062,15.143,15.143,0,0,0,2.406-.344l-1.781-1.781A6.9,6.9,0,0,1,16,22a6.958,6.958,0,0,1-6.812-8.562Zm.563,2.031a8.86,8.86,0,0,0,1.094,6.781A25.837,25.837,0,0,1,3.406,16,26.107,26.107,0,0,1,7.25,12.938Zm17.5,0A26.325,26.325,0,0,1,28.594,16a26.012,26.012,0,0,1-4.969,3.75,8.816,8.816,0,0,0,1.125-6.812Z"
      transform="translate(-0.656 -2.281)"
      fill="#a1b7d1"
    />
  </svg>
);

const ShowPassword = ({ refInput }) => {
  const [icon, setIcon] = useState(showPassword);

  function handleClick() {
    if (refInput.current && refInput.current.type) {
      if (refInput.current.type === "password") {
        refInput.current.type = "text";
        setIcon(hidePassword);
      } else {
        refInput.current.type = "password";
        setIcon(showPassword);
      }
    }
  }

  return (
    <Flex sx={{ position: "relative", zIndex: 2, alignItems: "center" }}>
      <Flex ml="-3rem" sx={{ position: "absolute" }}>
        <IconButton type="button" sx={{ cursor: "pointer" }} onClick={handleClick}>
          {icon}
        </IconButton>
      </Flex>
    </Flex>
  );
};

const ShowInputPassword = ({ type, onToggle }) => {
  const [icon, setIcon] = useState(showPassword);

  function handleClick() {
    if (type === "password") {
      onToggle("text");
      setIcon(hidePassword);
    } else {
      onToggle("password");
      setIcon(showPassword);
    }
  }

  return (
    <Flex sx={{ position: "relative", zIndex: 2, alignItems: "center" }}>
      <Flex ml="-3rem" sx={{ position: "absolute" }}>
        <IconButton type="button" sx={{ cursor: "pointer" }} onClick={handleClick}>
          {icon}
        </IconButton>
      </Flex>
    </Flex>
  );
};

export { ShowPassword, ShowInputPassword };
