import React from "react";
import { useIntl } from "react-intl";

import { Flex, Input, Text } from "@theme-ui/components";

import { SelfcareIcon } from "../icons";

const SearchBar = ({ onChangeFilter, searchTerm, clearFilter, ...props }) => {
  const intl = useIntl();

  return (
    <Flex
      py={["small", "tiny"]}
      px="default"
      sx={{
        borderRadius: "card",
        alignItems: "center",
        justifyContent: "center",
        width: ["100%", "80%", "30rem"],
        bg: "searchBarBg",
        border: "1px solid",
        borderColor: "searchBarBorder",
      }}
      {...props}>
      <Input
        onChange={onChangeFilter}
        value={searchTerm}
        placeholder={intl.formatMessage({ id: "lbl.search_service" })}
        maxLength="30"
        type="text"
        variant=""
        sx={{
          color: "textDark",
          bg: "searchBarBg",
          fontFamily: "body",
          fontSize: [1, 1, 2, 2],
          height: "2.5rem",
          border: "none",
          ":focus": { outline: "2px solid", outlineColor: "transparent", border: 0 },
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "block", // this sometimes helps in certain browsers
          maxWidth: "100%", // this also sometimes aids in achieving the desired effect
        }}
      />
      {searchTerm === "" ? (
        <SelfcareIcon name="search" ml="small" />
      ) : (
        <Text
          sx={{
            color: "primary",
            cursor: "pointer",
            ":hover": { color: "secondary" },
          }}
          onClick={clearFilter}>
          &#10006;
        </Text>
      )}
    </Flex>
  );
};

export default SearchBar;
