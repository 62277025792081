import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { semaphore } from "../semaphore";
import { FacadeAPI } from "../../rest/RestClient";
import { checkAndRefreshToken, REFRESH_DONE, REFRESH_FAIL } from "./AuthSlice";

export const getPaymentDetails = createAsyncThunk(
  "paymentSlice/getPaymentDetails",
  async (accountCode, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("paymentSlice/getPaymentDetails"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.GET("/account/" + accountCode + "/payment/details");

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPaymentSettings = createAsyncThunk(
  "paymentSlice/getPaymentSettings",
  async (accountCode, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("paymentSlice/getPaymentSettings"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.GET("/account/" + accountCode + "/payment/settings");

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getMaxAllowedAmount = createAsyncThunk(
  "paymentSlice/getMaxAllowedAmount",
  async (args, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("paymentSlice/getMaxAllowedAmount"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.GET("/configuration/maxAllowedAmount");

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBankAccountTypeMandatory = createAsyncThunk(
  "paymentSlice/getBankAccountTypeMandatory",
  async (args, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("paymentSlice/getBankAccountTypeMandatory"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.GET("/configuration/bankAccountTypeMandatory");

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBankAccountHolderNameMandatory = createAsyncThunk(
  "paymentSlice/getBankAccountHolderNameMandatory",
  async (args, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("paymentSlice/getBankAccountHolderNameMandatory"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.GET("/configuration/bankAccountHolderNameMandatory");

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAllowOverrideBankNumberOnAccountLevel = createAsyncThunk(
  "paymentSlice/getAllowOverrideBankNumberOnAccountLevel",
  async (args, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("paymentSlice/getAllowOverrideBankNumberOnAccountLevel"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.GET("/configuration/allowOverrideBankNumberOnAccountLevel");

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updatePaymentSettings = createAsyncThunk(
  "paymentSlice/updatePaymentSettings",
  async (args, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("paymentSlice/updatePaymentSettings"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const { accountCode, paymentSettings } = args;

      await FacadeAPI.PUT(`/account/${accountCode}/payment/settings`, paymentSettings, {
        expectedResponse: "none",
      });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updatePaymentDetails = createAsyncThunk(
  "paymentSlice/updatePaymentDetails",
  async (args, { dispatch, rejectWithValue }) => {
    const { accountCode, newDetails } = args;

    try {
      dispatch(checkAndRefreshToken("paymentSlice/updatePaymentDetails"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      await FacadeAPI.PUT("/account/" + accountCode + "/payment/details", newDetails, {
        expectedResponse: "none",
      });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const makePayments = createAsyncThunk(
  "paymentSlice/makePayments",
  async (args, { dispatch, rejectWithValue }) => {
    const accountCode = args[0];
    const amount = args[1];
    const responseUrl = args[2];
    try {
      dispatch(checkAndRefreshToken("paymentSlice/makePayments"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.POST("/ippay/makePayment", {
        accountCode,
        amount,
        responseUrl,
      });

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getIppayRequest = createAsyncThunk(
  "paymentSlice/getIppayRequest",
  async (args, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("paymentSlice/getIppayRequest"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.GET("/ippay/initiateHostedCcOperation", args, {
        noTimeout: true,
      });

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    paymentLoading: null,
    paymentError: null,

    paymentDetails: null,
    paymentSettings: null,
    maxAmount: null,
    bankAccountType: null,
    bankAccountHolderName: null,
    overrideBankNumber: null,
    paymentResult: null,

    payment_details_status: null,
    update_payment_settings: null,
    update_payment_details: null,
    max_amount_status: null,
    bank_account_type_status: null,
    bank_account_name_status: null,
    override_bank_number_status: null,
    payment_result_status: null,
    payment_setting_status: null,
    ippayRequestData: null,
    ippay_request_data_status: null,
  },
  reducers: {
    setPaymentError: (state, action) => {
      state.paymentError = action.payload;
    },
    setBankAccountType: (state, action) => {
      state.payment_details_status = action.payload;
    },
    setPaymentDetailsStatus: (state, action) => {
      state.payment_details_status = action.payload;
    },
    setMaxAmountStatus: (state, action) => {
      state.max_amount_status = action.payload;
    },
    setBankAccountTypeStatus: (state, action) => {
      state.bank_account_type_status = action.payload;
    },
    setBankAccountHolderNameStatus: (state, action) => {
      state.bank_account_name_status = action.payload;
    },
    setOverrideBankNumberStatus: (state, action) => {
      state.override_bank_number_status = action.payload;
    },
    setPaymentSettingStatus: (state, action) => {
      state.payment_setting_status = action.payload;
    },
    setPaymentResultStatus: (state, action) => {
      state.payment_result_status = action.payload;
    },
    setPaymentResult: (state, action) => {
      state.paymentResult = action.payload;
    },
    setUpdatePaymentSettings: (state, action) => {
      state.update_payment_settings = action.payload;
    },
    setUpdatePaymentDetails: (state, action) => {
      state.update_payment_details = action.payload;
    },
    setIppayRequestDataStatus: (state, action) => {
      state.ippay_request_data_status = action.payload;
    },
  },

  extraReducers: {
    [getPaymentDetails.pending]: state => {
      state.userLoading = true;
      state.paymentError = null;
      state.payment_details_status = "loading";
    },
    [getPaymentDetails.fulfilled]: (state, action) => {
      state.userLoading = false;
      state.paymentDetails = action.payload;
      state.payment_details_status = "success";
    },
    [getPaymentDetails.rejected]: (state, action) => {
      state.userLoading = false;
      state.paymentError = action.payload;
      state.payment_details_status = "failed";
    },

    [getPaymentSettings.pending]: state => {
      state.userLoading = true;
      state.paymentError = null;
      state.payment_setting_status = "loading";
    },
    [getPaymentSettings.fulfilled]: (state, action) => {
      state.userLoading = false;
      state.paymentSettings = action.payload;
      state.payment_setting_status = "success";
    },
    [getPaymentSettings.rejected]: (state, action) => {
      state.userLoading = false;
      state.paymentError = action.payload;
      state.payment_setting_status = "failed";
    },

    [getMaxAllowedAmount.pending]: state => {
      state.userLoading = true;
      state.paymentError = null;
      state.max_amount_status = "loading";
    },
    [getMaxAllowedAmount.fulfilled]: (state, action) => {
      state.userLoading = false;
      state.maxAmount = action.payload;
      state.max_amount_status = "success";
    },
    [getMaxAllowedAmount.rejected]: (state, action) => {
      state.userLoading = false;
      state.paymentError = action.payload;
      state.max_amount_status = "failed";
    },

    [getBankAccountTypeMandatory.pending]: state => {
      state.userLoading = true;
      state.paymentError = null;
      state.bank_account_type_status = "loading";
    },
    [getBankAccountTypeMandatory.fulfilled]: (state, action) => {
      state.userLoading = false;
      state.paymentDetails = action.payload;
      state.bank_account_type_status = "success";
    },
    [getBankAccountTypeMandatory.rejected]: (state, action) => {
      state.userLoading = false;
      state.paymentError = action.payload;
      state.bank_account_type_status = "failed";
    },

    [getBankAccountHolderNameMandatory.pending]: state => {
      state.userLoading = true;
      state.paymentError = null;
      state.bank_account_name_status = "loading";
    },
    [getBankAccountHolderNameMandatory.fulfilled]: (state, action) => {
      state.userLoading = false;
      state.paymentDetails = action.payload;
      state.bank_account_name_status = "success";
    },
    [getBankAccountHolderNameMandatory.rejected]: (state, action) => {
      state.userLoading = false;
      state.paymentError = action.payload;
      state.bank_account_name_status = "failed";
    },

    [getAllowOverrideBankNumberOnAccountLevel.pending]: state => {
      state.userLoading = true;
      state.paymentError = null;
      state.override_bank_number_status = "loading";
    },
    [getAllowOverrideBankNumberOnAccountLevel.fulfilled]: (state, action) => {
      state.userLoading = false;
      state.paymentDetails = action.payload;
      state.override_bank_number_status = "success";
    },
    [getAllowOverrideBankNumberOnAccountLevel.rejected]: (state, action) => {
      state.userLoading = false;
      state.paymentError = action.payload;
      state.override_bank_number_status = "failed";
    },

    [makePayments.pending]: state => {
      state.userLoading = true;
      state.paymentError = null;
      state.payment_result_status = "loading";
    },
    [makePayments.fulfilled]: (state, action) => {
      state.userLoading = false;
      state.paymentResult = action.payload;
      state.payment_result_status = "success";
    },
    [makePayments.rejected]: (state, action) => {
      state.userLoading = false;
      state.paymentError = action.payload;
      state.payment_result_status = "failed";
    },

    [getIppayRequest.pending]: state => {
      state.userLoading = true;
      state.paymentError = null;
      state.ippay_request_data_status = "loading";
    },
    [getIppayRequest.fulfilled]: (state, action) => {
      state.userLoading = false;
      state.ippayRequestData = action.payload;
      state.ippay_request_data_status = "success";
    },
    [getIppayRequest.rejected]: (state, action) => {
      state.userLoading = false;
      state.paymentError = action.payload;
      state.ippay_request_data_status = "failed";
    },

    [updatePaymentSettings.pending]: state => {
      state.paymentLoading = true;
      state.paymentError = null;
      state.update_payment_settings = "loading";
    },
    [updatePaymentSettings.fulfilled]: state => {
      state.paymentLoading = false;
      state.update_payment_settings = "success";
    },
    [updatePaymentSettings.rejected]: (state, action) => {
      state.paymentLoading = false;
      state.paymentError = action.payload;
      state.update_payment_settings = "failed";
    },

    [updatePaymentDetails.pending]: state => {
      state.paymentLoading = true;
      state.paymentError = null;
      state.update_payment_details = "loading";
    },
    [updatePaymentDetails.fulfilled]: state => {
      state.paymentLoading = false;
      state.update_payment_details = "success";
    },
    [updatePaymentDetails.rejected]: (state, action) => {
      state.paymentLoading = false;
      state.paymentError = action.payload;
      state.update_payment_details = "failed";
    },
  },
});

export const {
  setPaymentError,
  setBankAccountType,
  setPaymentDetailsStatus,
  setMaxAmountStatus,
  setBankAccountTypeStatus,
  setBankAccountHolderNameStatus,
  setOverrideBankNumberStatus,
  setPaymentSettingStatus,
  setPaymentResultStatus,
  setPaymentResult,
  setIppayRequestDataStatus,
  setUpdatePaymentSettings,
  setUpdatePaymentDetails,
} = paymentSlice.actions;
export default paymentSlice.reducer;
