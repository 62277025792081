import * as React from "react";

const ViewDeviceDetails = ({ fill = "none", stroke = "#fff", ...props }) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill={stroke}
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <rect
      x={6}
      y={2}
      width={12}
      height={20}
      rx={3}
      stroke={fill}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 18H12.01"
      stroke={fill}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ViewDeviceDetails;
