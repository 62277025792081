import { Flex, Text } from "@theme-ui/components";
import React from "react";
import { BaseIcon } from "../../components/icons";

const CarrierDrop = ({ selectedCarrier, isMenuOpen }) => {
  return (
    <Flex
      sx={{
        alignItems: "center",
        textAlign: "center",
        height: "3.125rem",
        border: "solid 1px",
        borderColor: "border",
        borderRadius: "card",
        userSelect: "none",
        bg: "contentBg",
        px: ["small", "small", "default"],
        justifyContent: "space-between",
      }}>
      <Text
        variant="cardtitle"
        sx={{
          fontWeight: "regular",
          fontFeatureSettings: "'tnum'",
          cursor: "pointer",
        }}
        color={"primary"}>
        {selectedCarrier.name}
      </Text>

      {<BaseIcon name={isMenuOpen ? "upArrow" : "downArrow"} />}
    </Flex>
  );
};

export default CarrierDrop;
