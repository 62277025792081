import * as React from "react";
const ServiceFeatures = ({ stroke = "#000000", ...props }) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    data-name="Line Color"
    xmlns="http://www.w3.org/2000/svg"
    className="icon line-color"
    {...props}>
    <path
      fill="none"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M7.45 8h.1"
    />
    <path
      fill="none"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M7.45 12h.1m-.1 4h.1"
    />
    <path
      d="M12 8h5m-5 4h5m-5 4h5"
      fill="none"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <rect
      x={3}
      y={3}
      width={18}
      height={18}
      rx={1}
      fill="none"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);
export default ServiceFeatures;
