import React from "react";
const InvoiceAndPaymentsMobile = ({ color = "#fff", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.7"
    height="21.366"
    viewBox="0 0 17.7 21.366"
    {...props}>
    <path
      fill={color}
      d="M17.6,7.1V6.8a.349.349,0,0,0-.1-.2L11,.2c-.1-.1-.1-.1-.2-.1H.8A.661.661,0,0,0,.2.2C0,.3,0,.6,0,.8V17a.764.764,0,0,0,.2.5L4,21.2a.668.668,0,0,0,1,0L8.6,18l3.5,3.2a.764.764,0,0,0,.5.2c.2,0,.3-.1.5-.2l4.3-3.7a.6.6,0,0,0,.3-.6l-.1-9.8ZM11.2,2.6,15,6.4H11.2Zm4.9,14-3.5,3L9.1,16.4a.725.725,0,0,0-1,0L4.6,19.6l-3.1-3V1.4H9.7V7.1a.789.789,0,0,0,.8.8h5.7Z"
      transform="translate(0 -0.059)"
    />
  </svg>
);
export default InvoiceAndPaymentsMobile;
