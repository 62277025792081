import React from "react";
const LightTheme = ({ fill = "none", stroke = "#eaeaea", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30.913"
    height="30.912"
    viewBox="0 0 23.913 23.912"
    {...props}>
    <g id="icon_light-mode_small" transform="translate(-2.543 -2.544)">
      <circle
        id="Ellipse_62"
        data-name="Ellipse 62"
        cx="8"
        cy="8"
        r="7"
        transform="translate(6.5 6.5)"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        id="Path_2498"
        data-name="Path 2498"
        d="M-167.5-16215.5v-2.956"
        transform="translate(182 16222)"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        id="Path_2499"
        data-name="Path 2499"
        d="M-167.5-16215.5v-2.956"
        transform="translate(182 16240.956)"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        id="Path_2500"
        data-name="Path 2500"
        d="M-167.5-16215.5v-2.956"
        transform="translate(-16193 182) rotate(90)"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        id="Path_2501"
        data-name="Path 2501"
        d="M-167.5-16215.5v-2.956"
        transform="translate(-16211.957 182) rotate(90)"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        id="Path_2502"
        data-name="Path 2502"
        d="M-167.5-16215.5v-2.956"
        transform="translate(-11327.493 11593.374) rotate(45)"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        id="Path_2503"
        data-name="Path 2503"
        d="M-167.5-16215.5v-2.956"
        transform="translate(-11340.896 11606.778) rotate(45)"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        id="Path_2504"
        data-name="Path 2504"
        d="M-167.5-16215.5v-2.956"
        transform="translate(-11564.374 -11327.492) rotate(135)"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        id="Path_2505"
        data-name="Path 2505"
        d="M-167.5-16215.5v-2.956"
        transform="translate(-11577.778 -11340.898) rotate(135)"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </g>
  </svg>
);

export default LightTheme;
