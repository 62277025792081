import React from "react";

const BYODDetails = ({ fill = "#fff", strokeWidth = "3px", stroke = "#0c3865", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="90.105"
    height="66.366"
    viewBox="0 0 90.105 66.366"
    {...props}>
    <g id="Group_1834" data-name="Group 1834" transform="translate(-528.165 -577.134)">
      <g id="Group_1830" data-name="Group 1830" transform="translate(587 578.634)">
        <path
          id="Path_85"
          data-name="Path 85"
          d="M32.569 57.82H9a3.11 3.11 0 0 1-3.1-3.1V5.1A3.11 3.11 0 0 1 9 2h23.569a3.11 3.11 0 0 1 3.1 3.1v49.618a3.11 3.11 0 0 1-3.1 3.102z"
          transform="translate(-5.9 -2)"
          strokeMiterlimit="10"
          strokeLinejoin="round"
          strokeLinecap="round"
          fill={fill}
          stroke={stroke}
          strokeWidth={strokeWidth}
        />
        <path
          id="Line_26"
          data-name="Line 26"
          fill={fill}
          stroke={stroke}
          strokeLinecap="round"
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
          transform="translate(11.319 3.566)"
          d="M0 0h7.133"
        />
        <path
          id="Line_27"
          data-name="Line 27"
          fill={fill}
          stroke={stroke}
          strokeLinecap="round"
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
          transform="translate(13.18 51.788)"
          d="M0 0h3.411"
        />
        <path
          id="Line_28"
          data-name="Line 28"
          fill={fill}
          stroke={stroke}
          strokeLinecap="round"
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
          transform="translate(0 47.912)"
          d="M0 0h29.771"
        />
      </g>
      <g id="user" transform="translate(527.797 575.626)">
        <path
          id="Path_757"
          data-name="Path 757"
          fill={fill}
          stroke={stroke}
          strokeLinecap="round"
          strokeWidth={strokeWidth}
          strokeLinejoin="round"
          d="M68.211 38.79v-7.93A15.957 15.957 0 0 0 52.158 15H20.053A15.957 15.957 0 0 0 4 30.86v7.93z"
          transform="translate(-2.132 27.583)"
        />
        <circle
          id="Ellipse_38"
          data-name="Ellipse 38"
          fill={fill}
          stroke={stroke}
          strokeLinecap="round"
          strokeWidth={strokeWidth}
          strokeLinejoin="round"
          cx="15.842"
          cy="15.842"
          r="15.842"
          transform="translate(18.132 3.007)"
        />
      </g>
    </g>
  </svg>
);

export default BYODDetails;
