import React from "react";
const PhoneNumber = ({ handset = "#318eff", cable = "#114878", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="34.829"
    viewBox="0 0 15 34.829"
    {...props}>
    <g transform="translate(0.011)">
      <path
        fill={handset}
        d="M8.6,21.866a3.944,3.944,0,0,1-2.825-3.931c0-1.474.123-3.194,0-4.791A3.993,3.993,0,0,1,8.6,8.722c.246-.123.369-.246.369-.491V.614A.58.58,0,0,0,8.356,0H2.582a.568.568,0,0,0-.614.369A55.045,55.045,0,0,0,0,15.232,54.687,54.687,0,0,0,1.968,30.219a.816.816,0,0,0,.614.491h5.9a.58.58,0,0,0,.614-.614V22.48A.865.865,0,0,0,8.6,21.866Z"
        transform="translate(0)"
      />
      <path
        fill="none"
        stroke={cable}
        strokeMiterlimit="10"
        strokeWidth="1.112px"
        d="M3.9,24.9v1.228a2.464,2.464,0,0,0,2.457,2.457H14.1"
        transform="translate(0.893 5.688)"
      />
    </g>
  </svg>
);
export default PhoneNumber;
