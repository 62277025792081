import React from "react";
import Layout from "../../containers/Layout";
import ServiceAgreementLayout from "../../components/ServiceAgreementLayout";

const Home = () => {
  return (
    <Layout>
      <ServiceAgreementLayout />
    </Layout>
  );
};

export default Home;
