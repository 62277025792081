import React from "react";

const AccountDetails = ({ fill = "#fff", stroke = "#0c3865", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="77" height="77" viewBox="0 0 77 77" {...props}>
    {/* <defs>
        <style>
            .cls-1{fill:#fff;stroke:#0c3865;strokeLinecap:round;strokeLinejoin:round;strokeWidth:3px;fill-rule:evenodd}
        </style>
    </defs> */}
    <g id="Group_231" data-name="Group 231" transform="translate(1.2 1.5)">
      <path
        id="Oval_18"
        className="cls-1"
        d="M308.71 165.5a18 18 0 1 0-18.209-18 18.1 18.1 0 0 0 18.209 18z"
        transform="translate(-272.373 -129.5)"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        fillRule="evenodd"
      />
      <path
        id="Shape_108"
        d="M290.915 130.977c7.888 8.952 20.01 11.622 30.444 6.7"
        transform="translate(-269.604 -122.115)"
        // style="fill:#fff;stroke:#0c3865;strokeLinecap:round;strokeLinejoin:round;strokeWidth:3px"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        id="Shape_112"
        className="cls-1"
        d="M325.5 136.5c-20.431 0-37 14.56-37 32.514h74c0-17.954-16.569-32.514-37-32.514z"
        transform="translate(-288.2 -95.014)"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        fillRule="evenodd"
      />
    </g>
  </svg>
);

export default AccountDetails;
