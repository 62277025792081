import React from "react";
import { Box } from "@theme-ui/components";

const FormErrorMessage = props => {
  return (
    <Box
      py="0.2rem"
      {...props}
      sx={{
        fontSize: 1,
        fontWeight: "medium",
        wordWrap: "break-word",
        color: "textError",
        ...props.sx,
      }}>
      {props.children}
    </Box>
  );
};

export default FormErrorMessage;
