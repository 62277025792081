import React from "react";
import ContentLoader from "react-content-loader";

const SACardSkeleton = () => (
  <ContentLoader
    speed={2}
    viewBox="0 0 232 232"
    backgroundColor="#EEEEEE"
    foregroundColor="#d6d3d3">
    <rect x="20.6" y="15.4" width="40" height="10" />
    <rect x="145.6" y="15.4" width="65" height="10" />
    <rect x="52.8" y="55.8" width="136.9" height="21.9" />
    <rect x="30.8" y="87.9" width="176.9" height="11.9" />
    <rect x="30.8" y="100.9" width="176.9" height="11.9" />
  </ContentLoader>
);

export default SACardSkeleton;
