import React, { useRef, useState } from "react";

import { Text, Flex } from "@theme-ui/components";

import { useOverflow } from "../../hooks/useOverflow";

const DescriptionRow = ({ text, list, numRows }) => {
  const flowRef = useRef();
  const [isOverflow] = useOverflow(flowRef);
  const [isHover, setHover] = useState(false);

  var listText;
  if (list) {
    listText = list.slice(numRows).join("\n• ");
  }

  const MyTooltip = ({ text }) => {
    return (
      (list || isOverflow) && (
        <Flex
          sx={{
            position: "relative",
            zIndex: isHover ? 12 : 0,
          }}>
          <Text
            ml="default"
            variant="note"
            mt="-2rem"
            py="tiny"
            px="small"
            color="textDark"
            bg="contentBg"
            sx={{
              maxWidth: "25rem",
              position: "absolute",
              display: isHover ? "block" : "none",
              border: "1px solid",
              borderColor: "border",
              borderRadius: "card",
              overflowWrap: "break-word",
              whiteSpace: "pre-line",
            }}>
            {list ? "• " + listText : text}
          </Text>
        </Flex>
      )
    );
  };
  return (
    <>
      <Flex
        mt="tiny"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        //for tochscreen
        onTouchStart={e => {
          e.cancelable && e.preventDefault();
          e.stopPropagation();
          setHover(true);
        }}
        sx={{ flexDirection: "column" }}>
        <Text
          ref={flowRef}
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            flexGrow: 0,
          }}>
          {"• " + text}
        </Text>
        <MyTooltip text={text} />
      </Flex>
    </>
  );
};

export default DescriptionRow;
