import { createSlice } from "@reduxjs/toolkit";
import { Providers } from "../../common/Providers";
import Storage from "store2";

const BRANDING_INITIAL_STATE = {
  provider: Storage.get("provider") ? Storage.get("provider") : Object.keys(Providers)[0],
  data: {
    ...Providers[Storage.get("provider") ? Storage.get("provider") : Object.keys(Providers)[0]],
  },
};

const brandingSlice = createSlice({
  name: "branding",
  initialState: {
    ...BRANDING_INITIAL_STATE,
  },
  reducers: {
    changeProvider(state) {
      let prvList = Object.keys(Providers);
      let idx = prvList.indexOf(state.provider);
      let nextProvider = prvList[(idx + 1) % prvList.length];
      Storage.set("provider", nextProvider, true);
      return {
        ...BRANDING_INITIAL_STATE,
        provider: nextProvider,
        data: { ...Providers[nextProvider] },
      };
    },
  },
});

export const { changeProvider } = brandingSlice.actions;

export default brandingSlice.reducer;
