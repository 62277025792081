import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SelfcareAmount } from "../base";
import { FormattedMessage } from "react-intl";
import { getBillingSummary } from "../../redux/slices/BillingSlice";
import { Spinner, Text, Flex } from "@theme-ui/components";
import { useHistory } from "react-router-dom";

const BalanceItem = ({ ...props }) => {
  const history = useHistory();
  const { master } = useSelector(state => state.user);
  const accountCode = master?.accountCode;

  const dispatch = useDispatch();
  const { billingSummary, billing_summary_status, billingError } = useSelector(
    state => state.billing
  );
  const [hasInvoice, setHasInvoice] = useState(false);
  const [daysOverdue, setdaysOverdue] = useState(0);

  useEffect(() => {
    if (!billingSummary && !billing_summary_status && accountCode !== undefined) {
      dispatch(getBillingSummary(accountCode));
    }
  }, [accountCode, billingSummary, billing_summary_status, dispatch]);

  useEffect(() => {
    if (billing_summary_status === "success") {
      setHasInvoice(
        billingSummary.lastInvoiceFullNumber &&
          billingSummary.lastInvoiceFullNumber !== "" &&
          billingSummary.lastInvoiceDate != null &&
          billingSummary.lastInvoiceDate !== undefined
      );
      setdaysOverdue(billingSummary.daysDelinquent);
    }
    if (billingError && billingError.errorCode === "REFRESH_FAILED") {
      history.push("/error");
    }
  }, [billing_summary_status, billingSummary, billingError, history]);

  if (billing_summary_status === "failed") {
    return (
      <Flex
        px="small"
        sx={{
          justifyContent: "center",
          width: ["100%", "100%", "15rem", "23rem"],
          height: "100%",
        }}
        {...props}></Flex>
    );
  }
  if (billing_summary_status !== "success") {
    return (
      <Flex
        px="small"
        sx={{
          alignItems: "center",
          justifyContent: "center",
          width: ["100%", "100%", "20rem", "20rem"],
          height: "100%",
        }}
        {...props}>
        <Spinner color="primary" />
      </Flex>
    );
  }
  const BalanceContainer = ({ amount, text, pastDue }) => {
    const showPastDue =
      pastDue && hasInvoice && daysOverdue > 0 && billingSummary.overdueAmount > 0;

    return (
      <Flex
        py={["default", "small", 0]}
        px={["small", "small", "default"]}
        sx={{
          alignItems: "center",
          flexDirection: "column",
        }}>
        <Text color="primary" variant="navigationMenu">
          <FormattedMessage id={text} />
        </Text>
        <Flex
          color="primary"
          sx={{
            fontSize: [6, 7, 7, 8],
            fontWeight: "semiBold",
          }}>
          <SelfcareAmount amount={amount} />
        </Flex>

        {showPastDue && (
          <Text
            color="textDark"
            sx={{
              fontSize: [0, 0, 1, 1],
              fontWeight: "regular",
              position: "absolute",
              mt: "3.5rem",
            }}>
            <FormattedMessage
              id="lbl.overdue_days"
              values={{
                days: daysOverdue,
              }}
            />
          </Text>
        )}
      </Flex>
    );
  };

  let balance = billingSummary.balance * -1;
  // prevent the result from being negative zero
  if (Object.is(balance, -0)) balance = 0;

  return (
    <Flex
      sx={{
        justifyContent: ["center", "space-evenly", "space-evenly", "center"],
        alignItems: "center",
        flexDirection: "row",
        height: "100%",
        width: "100%",
      }}
      {...props}>
      <BalanceContainer text={"lbl.balance"} amount={balance} />

      <BalanceContainer
        pastDue
        text={"lbl.amount_past"}
        amount={
          daysOverdue > 0 && billingSummary.overdueAmount > 0 ? billingSummary.overdueAmount : 0
        }
      />
    </Flex>
  );
};
export default BalanceItem;
