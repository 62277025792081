import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

import { Flex, Text, Heading } from "@theme-ui/components";

import { parsePackageDescription, parseInvoiceDescription } from "../../common/Utilities";
import ServiceFeaturesSkeleton from "./ServiceFeaturesSkeleton";
import DescriptionRow from "../SACard/DescriptionRow";
import Update from "../Update";

const LoadingSkeleton = props => {
  return (
    <Flex sx={{ flexDirection: "column", ...props.sx }}>
      <Heading variant="heading2">
        <FormattedMessage id="lbl.services_and_features" />
      </Heading>

      <Flex variant="layout.card" sx={{ padding: 0 }}>
        <ServiceFeaturesSkeleton />
      </Flex>
    </Flex>
  );
};

const ServiceFeaturesCard = props => {
  const intl = useIntl();
  const history = useHistory();
  const { account } = useSelector(state => state.user);
  const { additional_srv_status, additionalSrv } = useSelector(state => state.plan);

  if (additional_srv_status !== "success" || !additionalSrv || !account) {
    return <LoadingSkeleton {...props} />;
  }

  const packageDescription = parsePackageDescription(account.packageDescription);
  const descriptionList = parseInvoiceDescription(account.packageInvoiceDescription);
  const numDescriptionRows = 4;

  return (
    <Flex sx={{ flexDirection: "column", ...props.sx }}>
      <Heading variant="heading2">
        <FormattedMessage id="lbl.services_and_features" />
      </Heading>

      <Flex variant="layout.card">
        <Update
          pt="largeish"
          icon="serviceFeatures"
          text="tooltip.details"
          onUpdate={() => history.push("/account/" + account.accountCode + "/plan")}
        />

        {packageDescription && (
          <Flex mt="larger" sx={{ flexDirection: "column", height: "5rem" }}>
            <Text sx={{ fontWeight: "semiBold", textAlign: "center" }}>{packageDescription}</Text>
            {descriptionList.map((item, index) => {
              if (index < numDescriptionRows || descriptionList.length <= numDescriptionRows + 1)
                return <DescriptionRow key={index} text={item} />;
              else if (index === numDescriptionRows && descriptionList.length > numDescriptionRows)
                return (
                  <DescriptionRow
                    key={index}
                    text={intl.formatMessage({ id: "lbl.more_items" })}
                    list={descriptionList}
                    numRows={numDescriptionRows}
                  />
                );
              return null;
            })}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default ServiceFeaturesCard;
