import { Flex, Text } from "@theme-ui/components";
import React from "react";
import { FormattedMessage } from "react-intl";
import { SelfcareIcon } from "../icons";

const GoBack = ({ onBack, ...props }) => {
  return (
    <Flex my="default" sx={{ alignItems: "center" }} {...props}>
      <SelfcareIcon
        name="back-mobile"
        onClick={onBack}
        sx={{ cursor: "pointer", size: "1rem", mr: "tiny" }}
      />
      <Text variant="link" sx={{ width: "fit-content" }} onClick={onBack}>
        <FormattedMessage id="lbl.go_back" />
      </Text>
    </Flex>
  );
};

export default GoBack;
