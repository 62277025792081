function FacadeException(status, description, errorCode) {
  this.name = "FacadeException";
  this.status = status;
  this.description = description;
  this.errorCode = errorCode;
  this.stack = new Error().stack;
}

FacadeException.prototype = Object.create(Error.prototype);
FacadeException.prototype.constructor = FacadeException;

export default FacadeException;
