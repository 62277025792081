import React from "react";

const Chat = ({ color = "#000000", width = "30px", height = "30px", ...props }) => (
  <svg x="0px" y="0px" width={width} height={height} viewBox="0 0 109.6 87.5" {...props}>
    <g>
      <g>
        <path
          fill={color}
          d="M43.2,56.9c0-16.3,16-29.6,35.7-29.6c0.5,0,1,0,1.5,0.1C77.5,11.9,60.7,0,40.5,0
			C18.1,0,0,14.5,0,32.4c0,11.8,7.9,22.1,19.7,27.8v12.7l14.3-8.6c2.1,0.3,4.2,0.4,6.4,0.4c1.3,0,2.7-0.1,4-0.2
			C43.7,62.1,43.2,59.5,43.2,56.9z"
        />
      </g>
      <path
        fill={color}
        d="M94.6,77.9v9.6L83.8,81c-1.6,0.2-3.2,0.3-4.9,0.3c-16.9,0-30.6-11-30.6-24.5
		c0-13.5,13.7-24.5,30.6-24.5c16.9,0,30.6,11,30.6,24.5C109.6,65.8,103.6,73.6,94.6,77.9z"
      />
    </g>
  </svg>
);

export default Chat;
