import React from "react";

const AccountSummaryMobile = ({ color = "#fff", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17.7" height="22" viewBox="0 0 17.7 22" {...props}>
    <path
      fill={color}
      d="M11.7,11.5a6.146,6.146,0,0,0,3.2-5.4,6.1,6.1,0,1,0-12.2,0,6.267,6.267,0,0,0,3.2,5.4A8.784,8.784,0,0,0,0,19.8v1.4a.789.789,0,0,0,.8.8H16.9a.789.789,0,0,0,.8-.8V19.8A9.01,9.01,0,0,0,11.7,11.5ZM4.2,6.1A4.653,4.653,0,0,1,8.8,1.5a4.653,4.653,0,0,1,4.6,4.6,4.653,4.653,0,0,1-4.6,4.6A4.653,4.653,0,0,1,4.2,6.1ZM16.1,20.4H1.5v-.6a7.3,7.3,0,1,1,14.6,0Z"
    />
  </svg>
);

export default AccountSummaryMobile;
