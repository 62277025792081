import React from "react";
import { Box, Flex, Heading } from "@theme-ui/components";
import Modal from "./Modal";
import { SelfcareIcon } from "../icons";

const StyledModal = Modal.styled`
  height: ${({ height }) => height ?? "fit-content"};
  max-height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid #F0F0F0;
  border-radius: 10px;
  box-shadow: 0px 6px 6px #02257528;
  overflow: auto;
  margin: 150px 20px 100px 20px;
  transition: opacity ease 500ms;
`;

const StyledModalWindow = ({
  children,
  isOpen,
  onRequestClose,
  isCloseDisabled,
  message,
  verticalDirection,
  ...props
}) => {
  return (
    <StyledModal
      {...props}
      isOpen={isOpen}
      onBackgroundClick={onRequestClose}
      opacity={1}
      sx={{ my: "default" }}>
      <Flex
        bg="modalBg"
        py="medium"
        px="tiny"
        sx={{
          flexDirection: "column",
          minWidth: ["100%", "32rem"],
          maxWidth: ["100%", "40rem", "100%"],
        }}>
        <Flex sx={{ justifyContent: "flex-end" }}>
          <Flex
            as="span"
            bg="modalBg"
            sx={{
              color: "primary",
              float: "left",
              fontSize: 10,
              fontWeight: "normal",
              marginTop: "-2rem",
              marginRight: "-1rem",
              opacity: isCloseDisabled ? "70%" : "100%",
              ":hover": {
                cursor: isCloseDisabled ? "default" : "pointer",
                opacity: "70%",
              },
              border: "solid 1px",
              borderRadius: "circle",
              position: "absolute",
            }}
            onClick={onRequestClose}>
            <SelfcareIcon name="modalClose" sx={{ cursor: "pointer" }} />
          </Flex>
        </Flex>
        <Box bg="modalBg">
          {message && (
            <Heading
              px={["small", "large"]}
              variant="heading3normal"
              sx={{
                textAlign: "center",
                wordWrap: "break-work",
                whiteSpace: "pre-line",
              }}>
              {message}
            </Heading>
          )}
          {children && (
            <Flex
              bg="modalBg"
              sx={{
                mt: "default",
                textAlign: "center",
                justifyContent: "center",
                flexDirection: verticalDirection ? "column" : "row",
              }}>
              {children}
            </Flex>
          )}
        </Box>
      </Flex>
    </StyledModal>
  );
};

export default StyledModalWindow;
