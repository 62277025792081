import React from "react";
const MailOpen = ({ fill = "none", stroke = "#7392bb", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.551"
    height="16.076"
    viewBox="0 0 17.551 16.076"
    {...props}>
    <g id="Group_1666" data-name="Group 1666" transform="translate(-25.137 -379.166)">
      <path
        id="Path_850"
        data-name="Path 850"
        className="cls-1"
        d="m-20175-7819.8 7.648 4.344 7.166-4.344"
        transform="translate(20201.5 8204.971)"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Path_852"
        data-name="Path 852"
        className="cls-1"
        d="m-20160.184-7815.461-7.166-4.344-7.65 4.344v9.732h14.816z"
        transform="translate(20201.5 8199.971)"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);
export default MailOpen;
