import React, { useState, useRef, useContext } from "react";

import { useDay } from "@datepicker-react/hooks";
import DatepickerContext from "../../contexts/DatepickerContext";
import { useThemeUI } from "@theme-ui/core";
import { Button, Text } from "@theme-ui/components";

function getColor(isSelected, isSelectedStartOrEnd, isWithinHoverRange, isDisabled) {
  return ({
    selectedFirstOrLastColor,
    normalColor,
    selectedColor,
    rangeHoverColor,
    disabledColor,
  }) => {
    if (isSelectedStartOrEnd) {
      return selectedFirstOrLastColor;
    } else if (isSelected) {
      return selectedColor;
    } else if (isWithinHoverRange) {
      return rangeHoverColor;
    } else if (isDisabled) {
      return disabledColor;
    } else {
      return normalColor;
    }
  };
}

function Day({ dayLabel, date }) {
  const { theme } = useThemeUI();
  const [isHover, setHover] = useState(false);
  const dayRef = useRef(null);
  const {
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateSelect,
    onDateFocus,
    onDateHover,
  } = useContext(DatepickerContext);
  const {
    isSelected,
    isSelectedStartOrEnd,
    isWithinHoverRange,
    disabledDate,
    onClick,
    onKeyDown,
    onMouseEnter,
    tabIndex,
  } = useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,
    dayRef,
  });

  if (!dayLabel) {
    return <div />;
  }

  const getColorFn = getColor(isSelected, isSelectedStartOrEnd, isWithinHoverRange, disabledDate);

  return (
    <Button
      disabled={disabledDate}
      variant=""
      onClick={() => {
        onClick();
      }}
      onKeyDown={onKeyDown}
      onMouseEnter={() => {
        if (!disabledDate) {
          onMouseEnter();
          setHover(true);
        }
      }}
      onMouseLeave={() => {
        if (!disabledDate) {
          setHover(false);
        }
      }}
      tabIndex={tabIndex}
      type="button"
      ref={dayRef}
      sx={{
        cursor: !disabledDate ? "pointer" : "",
        borderRadius: 0,
        padding: ["0px", "small", "small"],
        fontFamily: "body",
        color:
          isHover && !isSelectedStartOrEnd
            ? theme.colors.primary
            : getColorFn({
                selectedFirstOrLastColor: theme.colors.textLight,
                normalColor: theme.colors.textDark,
                selectedColor: theme.colors.textLight,
                rangeHoverColor: theme.colors.textLight,
                disabledColor: theme.colors.inactive,
              }),
        background:
          isHover && !isSelectedStartOrEnd
            ? theme.colors.primaryLight
            : getColorFn({
                selectedFirstOrLastColor: theme.colors.primary,
                normalColor: theme.colors.contentBg,
                selectedColor: theme.colors.primaryLight,
                rangeHoverColor: theme.colors.primaryLight,
                disabledColor: theme.colors.contentBg,
              }),
      }}>
      <Text
        variant=""
        sx={{
          fontSize: [0, 0, 1],
          justifyContent: "center",
        }}>
        {dayLabel}
      </Text>
    </Button>
  );
}

export default Day;
