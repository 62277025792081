import React, { useState, useMemo, useEffect, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Flex, Grid, Text } from "@theme-ui/components";

import SubmitButton from "../../components/SubmitButton";
import { FormInput } from "../../components/base";
import { SelfcareIcon } from "../../components/icons";
import { StyledModalMessage } from "../../components/modals";
import GetErrorDescription from "../../components/GetErrorDescription";

import {
  validateAddress,
  getCountryStates,
  clearValidateAddressApiResponse,
  clearValidateAddressStatus,
  clearUpdateContactsStatus,
  setUserError,
  updateContacts,
  getContacts,
} from "../../redux/slices/UserSlice";
import GoBack from "../../components/GoBack";
import { BrandingContext } from "../../contexts/BrandingContext";

const EditAccountDetails = ({ isBilling = false, contact, onCancel }) => {
  const { config } = useContext(BrandingContext);
  const intl = useIntl();
  const dispatch = useDispatch();

  const {
    master,
    provinces,
    userError,
    validateAddressInfo,
    validate_address_status,
    update_contacts_status,
  } = useSelector(state => state.user);

  const primaryAddress = useMemo(() => {
    let result = {};

    if (!contact.address) {
      return result;
    }

    if (contact.address.lines) {
      result.address = contact.address.lines.slice(0, 2).filter(Boolean).join(", ");
    }

    result.city = contact.address.city;
    result.stateDescription = contact.address.stateDescription;
    result.postalCode = contact.address.zip;
    result.country = contact.address.country;
    result.province = contact.address.state;

    return result;
  }, [contact.address]);

  const [addressValidationMessage, setAddressValidationMessage] = useState(null);
  const [submitClicked, setSubmitClicked] = useState(false);

  const [address, setAddress] = useState(primaryAddress.address);
  const [city, setCity] = useState(primaryAddress.city);
  const [province, setProvince] = useState({
    iso: primaryAddress.province,
    description: primaryAddress.province,
  });
  const [postalCode, setPostalCode] = useState(primaryAddress.postalCode);

  const [formValidated, setFormValidated] = useState(false);
  const [errorAddressValidation, setErrorAddressValidation] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [noFieldsChanged, setNoFieldsChanged] = useState(true);

  const {
    control,
    handleSubmit,
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      homePhone: contact.phone.home.replace(/[\\(\\)-\s]/g, ""),
      businessPhone: contact.phone.business.replace(/[\\(\\)-\s]/g, ""),
      emailAddress: contact.email,
    },
  });
  const reactHookFormHandle = { register, errors };

  useEffect(() => {
    if (!provinces && !userError) dispatch(getCountryStates(contact.address.country));

    if (provinces && province) {
      setProvince(
        provinces.find(
          prov =>
            prov.iso.toUpperCase() === province.iso.toUpperCase() ||
            prov.description.toUpperCase() === province.description.toUpperCase()
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, contact.address.country, provinces]);

  useEffect(() => {
    if (update_contacts_status === "success") {
      setIsCompleted(true);
      dispatch(getContacts(master.accountCode));
      dispatch(clearUpdateContactsStatus());
    }

    if (update_contacts_status === "failed") {
      dispatch(clearUpdateContactsStatus());
    }
  }, [dispatch, master.accountCode, update_contacts_status]);

  const getFormattedSuggestedAddressValue = (isValueValid, value) => {
    return isValueValid ? value : <b>{value}</b>;
  };

  const onSubmit = values => {
    const isModifyAddress = primaryAddress.address.toUpperCase() !== address.toUpperCase();
    const isModifyCity = primaryAddress.city.toUpperCase() !== city.toUpperCase();
    const isModifyPostalCode = primaryAddress.postalCode.toUpperCase() !== postalCode.toUpperCase();
    const isModifyProvince = primaryAddress.province.toUpperCase() !== province.iso.toUpperCase();
    if (
      !formValidated &&
      (isModifyAddress || isModifyCity || isModifyPostalCode || isModifyProvince)
    ) {
      onValidateAddress(values);
    } else {
      let accountContact = {
        ...contact,
        address: {
          lines: [address],
          city: city,
          state: province.iso,
          zip: postalCode,
          country: primaryAddress.country,
        },
      };

      const phone = {
        business: values.businessPhone,
        home: values.homePhone,
      };

      if (!isBilling) {
        accountContact.phone = phone;
        accountContact.email = values.emailAddress;
      }

      dispatch(updateContacts({ accountCode: master.accountCode, id: contact.id, accountContact }));
      dispatch(clearValidateAddressApiResponse());
      setSubmitClicked(true);
    }
  };

  const onAddressInputChange = () => {
    setErrorAddressValidation(false);
    setAddressValidationMessage("");
    setFormValidated(false);
    onInputChange();
  };

  const onInputChange = () => {
    setNoFieldsChanged(false);
  };

  useEffect(() => {
    if (validate_address_status === "success") {
      //exception received from address validation
      if (validateAddressInfo.response !== "") {
        setErrorAddressValidation(true);
        setAddressValidationMessage(
          <Text variant="subtitle1" mt={[2, 8]} ml={0} sx={{ whiteSpace: "normal" }}>
            {intl.formatMessage({ id: "lbl.address-validation-error-message" })}
          </Text>
        );
        dispatch(clearValidateAddressApiResponse());
        return;
      }

      const isValidAddress = validateAddressInfo.address.toUpperCase() === address.toUpperCase();
      const isValidCity = validateAddressInfo.city.toUpperCase() === city.toUpperCase();
      const isValidPostalCode =
        validateAddressInfo.postalCode.toUpperCase() === postalCode.toUpperCase();
      const isValidProvince =
        validateAddressInfo.province.toUpperCase() === province.iso.toUpperCase();

      const suggestedAddressMessage = intl.formatMessage(
        {
          id: "lbl.suggested-address",
        },
        {
          address: getFormattedSuggestedAddressValue(isValidAddress, validateAddressInfo.address),
          city: getFormattedSuggestedAddressValue(isValidCity, validateAddressInfo.city),
          province: getFormattedSuggestedAddressValue(
            isValidProvince,
            validateAddressInfo.province
          ),
          pcode: getFormattedSuggestedAddressValue(
            isValidPostalCode,
            validateAddressInfo.postalCode
          ),
        }
      );

      if (!isValidAddress || !isValidCity || !isValidProvince || !isValidPostalCode) {
        setAddressValidationMessage(suggestedAddressMessage);
      } else {
        onSubmit(getValues());
        setAddressValidationMessage("");
        setFormValidated(true);
        dispatch(clearValidateAddressApiResponse());
      }
      dispatch(clearValidateAddressStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, validate_address_status]);

  const onValidateAddress = values => {
    setAddressValidationMessage("");
    let request = {
      street: [values.address],
      city: values.city,
      state: province.iso,
      zip: values.postalCode,
      country: primaryAddress.country,
    };
    dispatch(clearValidateAddressApiResponse());
    dispatch(validateAddress({ request }));
  };

  const acceptSuggestedAddress = () => {
    if (address.toUpperCase() !== validateAddressInfo.address.toUpperCase()) {
      setAddress(validateAddressInfo.address);
    }

    if (city.toUpperCase() !== validateAddressInfo.city.toUpperCase()) {
      setCity(validateAddressInfo.city);
    }

    if (province?.iso.toUpperCase() !== validateAddressInfo.province.toUpperCase()) {
      setProvince({
        iso: validateAddressInfo.province,
      });
    }

    if (postalCode.toUpperCase() !== validateAddressInfo.postalCode.toUpperCase()) {
      setPostalCode(validateAddressInfo.postalCode);
    }
  };

  const onCloseAction = () => {
    dispatch(clearValidateAddressApiResponse());
    onCancel();
  };

  const closeError = () => {
    dispatch(clearValidateAddressStatus);
    dispatch(setUserError(null));
  };

  return (
    <Flex
      mt="1rem"
      sx={{ flexDirection: "column", maxWidth: ["100%", "100%", "75rem", "85.5rem"] }}>
      <Text variant="cardtitle">
        <FormattedMessage id={isBilling ? "lbl.billing_information" : "lbl.contact_details"} />
      </Text>
      <Flex
        mt="1rem"
        py="larger"
        px="large"
        sx={{
          flexDirection: "column",
          border: "solid 1px",
          borderColor: "border",
          borderRadius: "card",
        }}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            flexDirection: "column",
            display: "flex",
          }}>
          {!isBilling && (
            <Grid
              sx={{
                gridGap: [2, 4, 6],
                gridTemplateColumns: ["repeat(1, 1fr)", "repeat(3, 1fr)", "repeat(4, 1fr)"],
                gridAutoRows: "min-content",
              }}>
              <FormInput
                type="tel"
                name="homePhone"
                label="lbl.primary-number"
                {...reactHookFormHandle}
                onChange={event => {
                  onInputChange();
                  setValue("homePhone", event.target.value.replaceAll(/[_-]/g, ""));
                }}
                validations={{
                  required: true,
                  pattern: {
                    value: config.phoneNumberMask.pattern,
                    message: intl.formatMessage({ id: "err.invalid_phone_number" }),
                  },
                }}
              />

              <FormInput
                type="tel"
                name="businessPhone"
                label="lbl.alternate-phone-number"
                {...reactHookFormHandle}
                onChange={event => {
                  onInputChange();
                  setValue("businessPhone", event.target.value.replaceAll(/[_-]/g, ""));
                }}
                validations={{
                  required: false,
                  pattern: {
                    value: config.phoneNumberMask.pattern,
                    message: intl.formatMessage({ id: "err.invalid_phone_number" }),
                  },
                }}
              />

              <FormInput
                name="emailAddress"
                label="lbl.email"
                maxLength="80"
                {...reactHookFormHandle}
                onChange={onInputChange}
                validations={{
                  required: true,
                  pattern: {
                    value: /^([A-Za-z0-9._%-]+)@([A-Za-z0-9.-]+\.[a-zA-Z]{2,})$/i,
                    message: intl.formatMessage({ id: "err.email_address_is_invalid" }),
                  },
                }}
              />
            </Grid>
          )}

          <Grid
            mt={[0, "default", "default"]}
            sx={{
              gridGap: [2, 4, 6],
              gridTemplateColumns: ["repeat(1, 1fr)", "repeat(3, 1fr)", "repeat(6, 1fr)"],
              gridAutoRows: "min-content",
            }}>
            <FormInput
              name="address"
              label="lbl.address"
              wrapperProps={{ sx: { gridColumnStart: 1, gridColumnEnd: [1, 3, 4] } }}
              value={address}
              maxLength="100"
              {...reactHookFormHandle}
              validations={{ required: true }}
              onChange={event => {
                onAddressInputChange();
                setAddress(event.target.value);
              }}
            />

            <FormInput
              name="city"
              label="lbl.city"
              value={city}
              maxLength="50"
              {...reactHookFormHandle}
              validations={{ required: true }}
              onChange={event => {
                onAddressInputChange();
                setCity(event.target.value);
              }}
            />

            <FormInput
              name="province"
              label="lbl.province"
              value={province.iso}
              defaultValue={province.iso}
              control={control}
              type="select"
              {...reactHookFormHandle}
              validations={{ required: true }}
              onChange={event => {
                onAddressInputChange();
                setProvince({ iso: event.target.value });
              }}>
              {provinces?.map(state => (
                <option key={state.iso} value={state.iso}>
                  {state.description}
                </option>
              ))}
            </FormInput>

            <FormInput
              name="postalCode"
              label="lbl.postal_code"
              value={postalCode}
              maxLength="10"
              onChange={event => {
                onAddressInputChange();
                setPostalCode(event.target.value);
              }}
              {...reactHookFormHandle}
              validations={{ required: true }}
            />
          </Grid>
          {/* Address validation message */}
          {addressValidationMessage && addressValidationMessage !== "" && (
            <Flex
              mt="large"
              py="default"
              px={["small", "medium", "large"]}
              sx={{
                backgroundColor: "inactive",
                borderRadius: "card",
                justifyContent: "space-between",
                flexDirection: ["column", "column", "row"],
                alignItems: "center",
              }}>
              <Flex sx={{ alignItems: "center" }}>
                <SelfcareIcon name="dialog-bubble" />
                <Box mx="small">
                  {!errorAddressValidation && (
                    <Text
                      variant="attribute"
                      color="primary"
                      sx={{ lineHeight: "relaxed", fontSize: [5, 4, 5] }}>
                      {intl.formatMessage({ id: "lbl.suggested-address-message" })}
                    </Text>
                  )}
                  {errorAddressValidation ? (
                    <React.Fragment>{addressValidationMessage}</React.Fragment>
                  ) : (
                    <Box sx={{ maxWidth: "53rem" }}>
                      <Text
                        variant="attribute"
                        color="secundary"
                        sx={{
                          lineHeight: "relaxed",
                          fontSize: [5, 4, 5],
                          overflowWrap: "break-word",
                        }}>
                        {addressValidationMessage}
                      </Text>
                    </Box>
                  )}
                </Box>
              </Flex>
              <Flex mt={["small", "small", 0]} sx={{ flexDirection: "column", minWidth: "auto" }}>
                {!errorAddressValidation && (
                  <Button
                    variant="addressValidationAction"
                    mb="1rem"
                    onClick={() => {
                      acceptSuggestedAddress();
                      setAddressValidationMessage("");
                      setFormValidated(true);
                    }}
                    sx={{ color: "primary" }}>
                    {intl.formatMessage({ id: "btn.select-suggested" })}
                  </Button>
                )}
                <Button
                  variant="addressValidationAction"
                  onClick={() => {
                    setErrorAddressValidation(false);
                    setAddressValidationMessage("");
                    setFormValidated(true);
                  }}
                  sx={{ color: "disabledColor" }}>
                  {intl.formatMessage({ id: "btn.keep-my-own" })}
                </Button>
              </Flex>
            </Flex>
          )}

          <SubmitButton
            mt="2rem"
            type="submit"
            isLoading={submitClicked || validate_address_status === "loading"}
            isdisabled={addressValidationMessage || noFieldsChanged}
          />
        </form>
      </Flex>

      <GoBack mb="default" onBack={onCloseAction} />

      <StyledModalMessage
        isOpen={isCompleted}
        message={intl.formatMessage({ id: "lbl.update_contacts_successful" })}
        onRequestClose={onCancel}
        type="error">
        <Button onClick={onCancel}>
          <FormattedMessage id="lbl.ok" />
        </Button>
      </StyledModalMessage>

      <StyledModalMessage
        isOpen={validate_address_status === "failed"}
        message={<GetErrorDescription error={userError} />}
        onRequestClose={closeError}
        type="error">
        <Button onClick={closeError}>
          <FormattedMessage id="lbl.Try_Again" />
        </Button>
      </StyledModalMessage>
    </Flex>
  );
};

export default EditAccountDetails;
