import { Box } from "@theme-ui/components";
import * as React from "react";
const NotificationBell = ({ fill = "contentBg", stroke = "primary", ...props }) => {
  return (
    <Box
      as="svg"
      viewBox="-15 -10 55 55"
      preserveAspectRatio="xMidYMid meet"
      sx={{
        width: "2.3rem",
        height: "2.3rem",
      }}
      fill={fill}
      stroke={stroke}
      strokeWidth="4"
      {...props}>
      <path d="M32.51,27.83A14.4,14.4,0,0,1,30,24.9a12.63,12.63,0,0,1-1.35-4.81V15.15A10.81,10.81,0,0,0,19.21,4.4V3.11a1.33,1.33,0,1,0-2.67,0V4.42A10.81,10.81,0,0,0,7.21,15.15v4.94A12.63,12.63,0,0,1,5.86,24.9a14.4,14.4,0,0,1-2.47,2.93,1,1,0,0,0-.34.75v1.36a1,1,0,0,0,1,1h27.8a1,1,0,0,0,1-1V28.58A1,1,0,0,0,32.51,27.83.13,28.94a16.17,16.17,0,0,0,2.44-3,14.24,14.24,0,0,0,1.65-5.85V15.15a8.74,8.74,0,1,1,17.47,0v4.94a14.24,14.24,0,0,0,1.65,5.85,16.17,16.17,0,0,0,2.44" />
      <path d="M18,34.28A2.67,2.67,0,0,0,20.58,32H15.32A2.67,2.67,0,0,0,18,34.28Z" />
    </Box>
  );
};
export default NotificationBell;
