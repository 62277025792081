import React, { forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSelect } from "downshift";
import { Box, Flex, Text } from "@theme-ui/components";

import { getFUBCardDescription } from "../../common/Utilities";
import SharedPlanDrop from "./SharedPlanDrop";
import Tooltip from "../Tooltip/Tooltip";

import { setSelectedSharedPlan, getAccountsSharedUsageDetails } from "../../redux/slices/PlanSlice";

const MenuOption = forwardRef(({ text, isHighlighted, ...props }, ref) => {
  return (
    <Flex
      ref={ref}
      sx={{ cursor: "pointer", alignItems: "center", userSelect: "none" }}
      py="tiny"
      {...props}>
      <Tooltip value={Array.from(text).length > 25 && text}>
        <Text
          mx="default"
          variant="cardtitle"
          sx={{
            fontWeight: "regular",
            overflow: "hidden",
            display: "block",
            textOverflow: "ellipsis",
            whiteSpace: ["initial", "nowrap"],
            color: isHighlighted ? "secondary" : "primary",
            transition: "100ms ease-in",
            py: "tiny",
          }}>
          {text}
        </Text>
      </Tooltip>
    </Flex>
  );
});

MenuOption.displayName = "MenuOption";

const SharedPlanDropMenu = () => {
  const dispatch = useDispatch();

  const { bucketsDetails, selectedSharedPlan } = useSelector(state => state.plan);
  const { master } = useSelector(state => state.user);

  const onSelect = event => {
    const sp = event.selectedItem;
    dispatch(setSelectedSharedPlan(sp));
    closeMenu();
    dispatch(
      getAccountsSharedUsageDetails({
        accountCode: master.accountCode,
        actualUnitCode: sp.actualUnitCode,
        bduType: sp.bduType,
        overage: sp.overage,
      })
    );
  };

  const data = bucketsDetails.filter(fub => !fub.overage || (fub.used > 0 && fub.overage));
  const defaultHighlightedIndex = data?.findIndex(sp => sp === selectedSharedPlan);

  const { isOpen, getToggleButtonProps, getMenuProps, highlightedIndex, getItemProps, closeMenu } =
    useSelect({
      items: data,
      defaultHighlightedIndex,
      onSelectedItemChange: onSelect,
    });

  return (
    <Flex
      sx={{
        useSelect: "none",
        width: ["100%", "30rem", "21.6875rem"],
        mt: ["default", 0],
      }}>
      <Box
        sx={{
          display: "inline-block",
          position: "relative",
          width: "100%",
        }}
        {...getToggleButtonProps()}>
        <Flex sx={{ alignItems: "center" }}>
          <SharedPlanDrop isMenuOpen={isOpen} />
        </Flex>
        <Flex {...getMenuProps()}>
          {isOpen && (
            <Box
              bg="contentBg"
              mt="tiny"
              sx={{
                width: "100%",
                zIndex: 10,
                position: "absolute",
                right: "0%",
                boxShadow: "light",
                borderRadius: "card",
                overflowX: "hidden",
                maxHeight: "19rem",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                border: "solid 1px",
                borderColor: "border",
              }}>
              {data?.map((sp, index) => {
                let description = getFUBCardDescription(sp.bduTypeDescription, sp.overage);
                return (
                  <MenuOption
                    key={index}
                    isHighlighted={highlightedIndex === index}
                    text={description}
                    {...getItemProps({ item: sp, index })}
                  />
                );
              })}
            </Box>
          )}
        </Flex>
      </Box>
    </Flex>
  );
};

export default SharedPlanDropMenu;
