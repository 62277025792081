import React from "react";

const CreditCard = ({ stripe = "#318eff", edge = "#114878", brand = "#346eb3", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27.532"
    height="16.18"
    viewBox="0 0 27.532 16.18"
    {...props}>
    <rect fill={stripe} width="26.252" height="3.485" transform="translate(0.748 3.047)" />
    <rect fill={brand} width="11.272" height="1.038" transform="translate(2.306 12.391)" />
    <path
      fill="none"
      stroke={edge}
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.2px"
      d="M25.221,15.58H2.38A1.8,1.8,0,0,1,.6,13.8V2.38A1.8,1.8,0,0,1,2.38.6H25.147a1.8,1.8,0,0,1,1.78,1.78V13.726A1.717,1.717,0,0,1,25.221,15.58Z"
    />
    <circle fill={brand} cx="1.78" cy="1.78" r="1.78" transform="translate(19.288 10.611)" />
    <circle fill={brand} cx="1.78" cy="1.78" r="1.78" transform="translate(21.958 10.611)" />
  </svg>
);

export default CreditCard;
