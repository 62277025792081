import React, { useState, useEffect } from "react";
import _ from "lodash";

import { Box, Switch, Label } from "@theme-ui/components";

import HighlightedElement from "../../components/ServiceAgreementPackage/HighlightedElement";
import MutualExclusiveServiceGroup from "./MutualExclusiveServiceGroup";
import ServiceInstanceFeatures from "./ServiceInstanceFeatures";
import { SelfcareBox } from "../../components/base";
import * as Utilities from "../../common/Utilities";
import ServiceGroup from "./ServiceGroup";
import * as JSMethods from "./JSMethods";

const ProductCard = ({
  path,
  product,
  isMainProduct,
  productDescription,
  mutualExclusiveServices,
  serviceGroupsMap,
  subproductGroupsMap,
  servicesWithEditableFeatures,
  onServiceUpdate,
  onMEServicesUpdate,
  onSubProductsUpdate,
}) => {
  const [isSelected, setSelected] = useState();

  useEffect(() => {
    setSelected(JSMethods.checkIfSelected(product));
  }, [product]);

  const renderServiceGroups = () => {
    let serviceGroupsContainers = [];
    for (let [key, value] of serviceGroupsMap.entries()) {
      let subProductsFromSameGroup = subproductGroupsMap.get(key);
      if (Utilities.isEmpty(key)) {
        serviceGroupsContainers.push(
          <ServiceGroup
            key={key}
            path={path}
            _services={value}
            _subProducts={subProductsFromSameGroup}
            _onServiceUpdate={onServiceUpdate}
            _onProductUpdate={onSubProductsUpdate}
          />
        );
      } else {
        serviceGroupsContainers.push(
          <MutualExclusiveServiceGroup
            key={key}
            path={path}
            exclusivityGroup={key}
            mandatoryExclusiveItem={mutualExclusiveServices}
            _services={value}
            _subProducts={subProductsFromSameGroup}
            _onMEServicesUpdate={onMEServicesUpdate}
            _onMEProductsUpdate={onSubProductsUpdate}
          />
        );
      }
    }
    return serviceGroupsContainers;
  };

  const renderServiceInstancesFeatures = () => {
    let serviceInstancesFeatures = [];
    for (let service of servicesWithEditableFeatures) {
      _.each(service.instances, (srvInstanceValue, srvIdKey) => {
        if (
          srvInstanceValue.status &&
          srvInstanceValue.features.find(feature => {
            return feature.editPermission;
          })
        ) {
          serviceInstancesFeatures.push(
            <Label
              sx={{
                flex: 1,
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                py: "small",
              }}>
              <HighlightedElement
                description={service.description}
                sx={{ color: "textDark", fontWeight: "bold" }}
              />
            </Label>
          );

          serviceInstancesFeatures.push(
            <SelfcareBox
              variant="serviceCard"
              key={"featuresFor" + srvIdKey + "values"}
              sx={{ mt: "small" }}>
              <ServiceInstanceFeatures
                path={path}
                originalService={_.cloneDeep(service)}
                serviceId={srvIdKey}
                serviceInstance={srvInstanceValue}
                _onServiceUpdate={onServiceUpdate}
              />
            </SelfcareBox>
          );
        }
      });
    }
    return serviceInstancesFeatures;
  };

  const onSelect = product => {
    JSMethods.changeSubProductStatus(product, isSelected);
    setSelected(!isSelected);
    onSubProductsUpdate(path, [product]);
  };

  return (
    <Box>
      {serviceGroupsMap.size > 0 && (
        <>
          <Label
            sx={{
              flex: 1,
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              pt: "small",
            }}>
            <HighlightedElement
              description={
                isMainProduct
                  ? Utilities.parsePackageDescription(productDescription)
                  : productDescription
              }
              sx={{ color: "textDark", fontWeight: "bold" }}
            />

            <Box>
              {!isMainProduct &&
                ((product.addPermission && !JSMethods.checkIfSelected(product)) ||
                  (JSMethods.checkIfSelected(product) && product.editPermission)) && (
                  <Switch
                    checked={isSelected || false}
                    onChange={() => onSelect(product)}
                    sx={{ mr: ["small", "larger"] }}
                  />
                )}
            </Box>
          </Label>

          {isSelected && (
            <SelfcareBox sx={{ flexDirection: "column", mt: "small" }} variant="serviceCard">
              {renderServiceGroups()}
            </SelfcareBox>
          )}
        </>
      )}

      {renderServiceInstancesFeatures()}
    </Box>
  );
};
export default ProductCard;
