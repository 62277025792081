/* eslint-disable no-unused-vars */
import React from "react";

import { Flex, Box } from "@theme-ui/components";

import MenuOption from "../../components/MenuOption";
import { useSelect } from "downshift";
import CarrierDrop from "./CarrierDrop";

export const carriers = [
  { id: 1, name: "Bell Mobility", msg: "lbl.bell_mobility" },
  { id: 2, name: "Virgin Mobile", msg: "lbl.virgin_mobile" },
  { id: 3, name: "TELUS", msg: "lbl.telus" },
  { id: 4, name: "Koodo", msg: "lbl.koodo" },
  { id: 5, name: "Rogers", msg: "lbl.rogers" },
  { id: 6, name: "Fido", msg: "lbl.fido" },
];

const CarriersMenu = ({ selectedCarrier, setSelectedCarrier }) => {
  const onSelect = event => {
    const carrier = event.selectedItem;
    setSelectedCarrier(carrier);
  };

  const defaultHighlightedIndex = carriers.findIndex(carrier => carrier.id === selectedCarrier.id);

  const { isOpen, getToggleButtonProps, getMenuProps, highlightedIndex, getItemProps } = useSelect({
    items: carriers,
    defaultHighlightedIndex,
    onSelectedItemChange: onSelect,
  });

  return (
    <Box
      display="inline-block"
      sx={{ position: "relative", cursor: "pointer" }}
      {...getToggleButtonProps()}>
      <CarrierDrop selectedCarrier={selectedCarrier} isMenuOpen={isOpen} />
      <Flex {...getMenuProps()}>
        {isOpen && (
          <Box
            bg="contentBg"
            mt="tiny"
            sx={{
              minHeight: "2rem",
              border: "solid 1px",
              borderColor: "border",
              width: "100%",
              zIndex: 112,
              position: "absolute",
              right: "0%",
              boxShadow: "light",
              borderRadius: "card",
              whiteSpace: "nowrap",
              maxHeight: "19rem",
              overflow: "auto",
              overflowX: "hidden",
            }}>
            {carriers.map((carrier, index) => {
              return (
                <MenuOption
                  key={index}
                  isHighlighted={highlightedIndex === index}
                  text={carrier.name}
                  {...getItemProps({ item: carrier, index })}
                />
              );
            })}
          </Box>
        )}
      </Flex>
    </Box>
  );
};

export default CarriersMenu;
