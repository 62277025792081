import React from "react";

const Visa = ({ fill = "#3957a7", ...props }) => (
  <svg
    id="visa-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="48.692"
    height="16.383"
    viewBox="0 0 48.692 16.383"
    {...props}>
    {/* <defs>
        <style>
            .cls-1{fill:#3957a7}
        </style>
    </defs> */}
    <g id="Group_1638" data-name="Group 1638" transform="translate(4.158)">
      <path
        id="Path_794"
        data-name="Path 794"
        className="cls-1"
        d="M51.073 38.508H47.1L49.595 22.7h3.973z"
        transform="translate(-34.119 -22.413)"
        fill={fill}
      />
      <path
        id="Path_795"
        data-name="Path 795"
        className="cls-1"
        d="m19 24.708 3.465 13.748h4.158L32.952 22.6l-4.3.048-3.785 10.874-.462-2.347-1.755-6.611s-1.94.144-3.65.144"
        transform="translate(-19 -22.36)"
        fill={fill}
      />
      <path
        id="Path_796"
        data-name="Path 796"
        className="cls-1"
        d="M95.287 38.456h3.65L95.749 22.6h-3.188a1.861 1.861 0 0 0-1.848 1.2L84.8 38.456h4.158l.832-2.347h5.036zm-4.343-5.6 2.079-5.94 1.155 5.94z"
        transform="translate(-54.402 -22.36)"
        fill={fill}
      />
      <path
        id="Path_797"
        data-name="Path 797"
        className="cls-1"
        d="m72.318 26.172.554-3.4a11.406 11.406 0 0 0-3.557-.671c-1.986 0-6.652.91-6.652 5.221 0 4.072 5.5 4.12 5.5 6.275s-4.943 1.772-6.56.431L61 37.573a10.53 10.53 0 0 0 4.481.91c2.726 0 6.791-1.437 6.791-5.413 0-4.12-5.544-4.5-5.544-6.275 0-1.821 3.881-1.581 5.59-.623z"
        transform="translate(-41.597 -22.1)"
        fill={fill}
      />
    </g>
    <path
      id="Path_798"
      data-name="Path 798"
      d="m19.563 31.175-1.34-7.138a1.757 1.757 0 0 0-1.894-1.437h-6.283l-.046.287a15.767 15.767 0 0 1 5.913 3.066 12.883 12.883 0 0 1 3.65 5.222z"
      transform="translate(-10 -22.36)"
      fill="#f9a533"
      // style="fill:#f9a533"
    />
  </svg>
);
export default Visa;
