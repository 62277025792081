import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { Flex, Text, Heading } from "@theme-ui/components";

import SubscriberInformationSkeleton from "./SubscriberInformationSkeleton";
import Update from "../Update";
import { MESSAGES_CODES } from "../../common/Constants";

export const MapAccountStatus = ({ status }) => {
  var text, bg, font;
  if (String(status).startsWith(MESSAGES_CODES.ACTIVE)) {
    text = "lbl.active";
    bg = "activeStatus";
    font = "navMenuFocusedBg";
  } else if (String(status).startsWith(MESSAGES_CODES.SUSPENDED)) {
    text = "lbl.suspended";
    bg = "alertBg";
    font = "alert";
  } else if (String(status).startsWith(MESSAGES_CODES.CLOSED)) {
    text = "lbl.closed";
    bg = "alertBg";
    font = "alert";
  } else if (String(status).startsWith(MESSAGES_CODES.TERMINATE)) {
    text = "lbl.closed";
    bg = "alertBg";
    font = "alert";
  }
  return (
    <Text variant="note" py="tiny" px="small" bg={bg} sx={{ borderRadius: "circle", color: font }}>
      <FormattedMessage id={text} />
    </Text>
  );
};
const SubscriberInformation = props => {
  const history = useHistory();
  const { master, contacts, account, contacts_status } = useSelector(state => state.user);
  const isActive = String(account?.accountStatus).startsWith(MESSAGES_CODES.ACTIVE);

  if (contacts_status === "loading" || !account) {
    return (
      <Flex sx={{ flexDirection: "column", ...props.sx }}>
        <Heading variant="heading2">
          <FormattedMessage id="lbl.sa.subscriber_information" />
        </Heading>

        <Flex variant="layout.card" sx={{ padding: 0 }}>
          <SubscriberInformationSkeleton />
        </Flex>
      </Flex>
    );
  }

  const primaryContact = contacts?.accountContacts.find(element => element.primary === true);
  const fullName = primaryContact ? primaryContact.name.first + " " + primaryContact.name.last : "";

  if (!primaryContact) return null;

  return (
    <Flex
      sx={{
        flexDirection: "column",
        maxWidth: ["100%", "100%", "26rem", "26rem"],
        ...props.sx,
      }}>
      <Heading variant="heading2">
        <FormattedMessage id="lbl.sa.subscriber_information" />
      </Heading>

      <Flex variant="layout.card">
        <Flex sx={{ justifyContent: "space-between" }}>
          <Flex>
            <MapAccountStatus status={account.accountStatus} />
          </Flex>
          {isActive && !master?.serviceAgreement && (
            <Update
              onUpdate={() =>
                history.push("/account/" + account.accountCode + "/updateInformations")
              }
            />
          )}
        </Flex>

        <Flex
          sx={{
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}>
          <Text
            variant="headline"
            sx={{
              lineBreak: "anywhere",
              fontSize: fullName.length > 100 ? [2, 2, 3, 3] : [6, 6, 6, 6],
            }}>
            {fullName}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SubscriberInformation;
