import React from "react";

const Prepaid = ({ screen = "#318eff", bezel = "#114878", icon = "#336eb4", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="55.698"
    height="79.177"
    viewBox="0 0 55.698 79.177"
    {...props}>
    <g transform="translate(-1075 -272)">
      <path
        fill={icon}
        d="M24,7.67A16.627,16.627,0,1,0,40.624,24.3,16.629,16.629,0,0,0,24,7.67Z"
        transform="translate(1090.074 287.687)"
      />
      <path
        fill={screen}
        d="M18.484,21.607c-2.71-2.162-3.441-3.441-3.441-5.3,0-1.827.731-2.528,2.01-2.528s2.01.7,2.01,2.528v1.066h3.807v-.822c0-3.5-1.584-5.695-4.446-6.273V8.36H15.377v1.919c-2.832.609-4.416,2.832-4.416,6.273,0,2.923,1.127,4.994,4.446,7.613,2.71,2.132,3.441,3.441,3.441,5.3,0,1.827-.822,2.5-2.1,2.5s-2.1-.67-2.1-2.5v-2.01H10.84V29.22c0,3.5,1.614,5.725,4.507,6.3v1.888h3.045V35.524c2.863-.579,4.477-2.832,4.477-6.3C22.9,26.266,21.772,24.226,18.484,21.607Z"
        transform="translate(1097.171 289.098)"
      />
      <path
        fill={screen}
        d="M20.611,35.894A17.794,17.794,0,0,1,36.873,18.2V2H.33V72.041H36.873V53.587A17.794,17.794,0,0,1,20.611,35.894Z"
        transform="translate(1075.675 276.091)"
      />
      <path
        fill={bezel}
        d="M39.071,57.769c-.518,0-1-.03-1.492-.061V69.036H3.045v-58.9h34.5v12.12c.487-.03,1-.061,1.492-.061a13.281,13.281,0,0,1,1.553.091V6.091A6.108,6.108,0,0,0,34.5,0H6.091A6.108,6.108,0,0,0,0,6.091v67a6.108,6.108,0,0,0,6.091,6.091H34.5a6.108,6.108,0,0,0,6.091-6.091V57.677A12.4,12.4,0,0,1,39.071,57.769ZM23.357,76.132H17.236a1.972,1.972,0,0,1-1.766-1,2.053,2.053,0,0,1,1.8-3.045h6.121a1.972,1.972,0,0,1,1.766,1A2.074,2.074,0,0,1,23.357,76.132Z"
        transform="translate(1075 272)"
      />
    </g>
  </svg>
);
export default Prepaid;
