import React from "react";
const PaymentFailed = ({ fill = "none", stroke = "#fff", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="118" height="118" viewBox="0 0 118 118" {...props}>
    <circle data-name="Ellipse 26" cx="59" cy="59" r="59" fill={fill} />
    <svg xmlns="http://www.w3.org/2000/svg" width="118" height="118" viewBox="0 0 118 118">
      <circle
        data-name="Ellipse 48"
        cx="28"
        cy="28"
        r="28"
        transform="translate(31 31)"
        fill={"none"}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
      />
      <path
        d="M 48 48 L 70 70"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
      />
      <path
        d="M 70 48 L 48 70"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
      />
    </svg>
  </svg>
);
export default PaymentFailed;
