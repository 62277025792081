import React from "react";

const OkCircle = ({ stroke = "#fff", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" {...props}>
    <g id="ok-circle" transform="translate(-1 -1)">
      <path
        id="Path_816"
        data-name="Path 816"
        className="cls-1"
        d="m7 14.611 4.209 4.209L21.029 9"
        transform="translate(2.014 2.82)"
        fill={"none"}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <circle
        id="Ellipse_48"
        data-name="Ellipse 48"
        className="cls-1"
        cx="14"
        cy="14"
        r="14"
        transform="translate(2 2)"
        fill={"none"}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export default OkCircle;
