import React, { useState, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Controller } from "react-hook-form";
import { useHookFormMask } from "use-mask-input";

import { Flex, Box, Input, Select, Label, Button, Checkbox } from "@theme-ui/components";

import { FormErrorMessage } from "../../components/FormErrorMessage";
import { BrandingContext } from "../../contexts/BrandingContext";
import { ShowInputPassword } from "../ShowPassword";
import SelfcareLabel from "./SelfcareLabel";

/**
 * Generates a label and an form-input with branding enhancements such as validations, length limitations
 *
 * @param type - text, password, date, select
 * @param control - from react hook form
 * @param name - old form-input name
 * @param label - a label id
 * @param defaultValue - default value for input
 * @param errors - from react hook form
 * @param register - from react hook form
 * @param validations - an object containing form-input validations in the same format sent to react hook form
 * @param maxLength
 * @param minLength
 * @param errorLabel - specific label in case of error
 * @param labelVariant - variant for label
 * @param children - children of the form-input
 * @param errorProps - props of the error element
 * @param wrapperProps - props of the wrapper element
 * @param inputProps - props for form-input
 * @returns {JSX.Element|null}
 * @constructor
 */

const FormInput = ({
  type = "text",
  control,
  name,
  label,
  defaultValue,
  onChange,
  disabled,
  errors,
  register = () => {},
  validations = {},
  maxLength,
  minLength,
  errorLabel,
  labelVariant = "cardtitle",
  children = null,
  errorProps = {},
  wrapperProps = {},
  labelProps = {},
  autoComplete = "new-password",
  ...inputProps
}) => {
  const { config } = useContext(BrandingContext);
  const intl = useIntl();
  let minMaxLength = { minLength, maxLength };
  const [inputType, setInputType] = useState(type);
  const registerWithMask = useHookFormMask(register);

  return (
    <Box sx={{ width: "100%" }} {...wrapperProps}>
      {label && (
        <SelfcareLabel isMandatory={validations.required} variant={labelVariant} {...labelProps}>
          <FormattedMessage id={label} />
        </SelfcareLabel>
      )}

      <Flex sx={{ flexDirection: "column", height: "auto" }}>
        {type === "text" && (
          <Input
            {...inputProps}
            autoComplete={autoComplete}
            variant={errors && errors[name] ? "inputError" : "input"}
            name={name}
            type={inputType}
            disabled={disabled}
            defaultValue={defaultValue}
            {...minMaxLength}
            {...register(name, { onChange, ...validations })}
          />
        )}

        {type === "password" && (
          <Flex>
            <Input
              {...inputProps}
              autoComplete={autoComplete}
              variant={errors && errors[name] ? "inputError" : "input"}
              name={name}
              type={inputType}
              disabled={disabled}
              defaultValue={defaultValue}
              {...minMaxLength}
              {...register(name, { onChange, ...validations })}
            />
            <ShowInputPassword type={inputType} onToggle={setInputType} />
          </Flex>
        )}

        {type === "tel" && (
          <Input
            {...inputProps}
            variant={errors && errors[name] ? "inputError" : "input"}
            placeholder={config.phoneNumberMask.placeholder}
            name={name}
            type={inputType}
            disabled={disabled}
            defaultValue={defaultValue}
            {...registerWithMask(name, config.phoneNumberMask.mask, {
              autoUnmask: true,
              onChange,
              ...validations,
            })}
          />
        )}

        {type === "select" && (
          <Controller
            {...inputProps}
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={validations}
            render={({ field: { onChange: onChangeHook, onBlur, name, ref } }) => (
              <Select
                {...inputProps}
                onBlur={onBlur}
                name={name}
                ref={ref}
                variant={errors && errors[name] ? "selectError" : "select"}
                onChange={event => {
                  onChangeHook(event);
                  onChange(event);
                }}>
                {children}
              </Select>
            )}
          />
        )}

        {type === "checkbox" && (
          <Controller
            {...inputProps}
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={validations}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <Label sx={{ width: "fit-content" }}>
                <Checkbox
                  {...inputProps}
                  onBlur={onBlur}
                  name={name}
                  ref={ref}
                  variant={errors && errors[name] ? "checkboxError" : "checkbox"}
                  onChange={event => {
                    onChange(event);
                  }}
                  checked={value}
                />
                {children}
              </Label>
            )}
          />
        )}

        {type === "file" && (
          <Label
            {...inputProps}
            mt="default"
            sx={{ width: ["100%", "fit-content", "fit-content"] }}>
            <Flex
              sx={{
                flexDirection: ["column", "row", "row"],
                width: ["100%", "fit-content", "fit-content"],
              }}>
              <Button
                as="span"
                variant="secondary"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  bg: "white",
                  px: "default",
                  borderColor: "primary",
                  border: "1px solid",
                  width: ["100%", "fit-content", "fit-content"],
                }}>
                <FormattedMessage id="lbl.browse" />
              </Button>
              <input
                name={name}
                type="file"
                accept=".pdf, .png, .jpeg, .jpg"
                {...register(name, { onChange, ...validations })}
                style={{ display: "none" }}
              />

              {errors && errors[name] && errors[name]?.message && (
                <FormErrorMessage {...errorProps}>{errors[name].message}</FormErrorMessage>
              )}
            </Flex>
          </Label>
        )}

        {errors && errors[name] && errors[name].type === "required" && (
          <FormErrorMessage mr="0.4rem">
            {!errors[name]?.message && (
              <FormattedMessage
                id="err.mandatory_field"
                values={{ field: intl.formatMessage({ id: errorLabel ? errorLabel : label }) }}
              />
            )}
          </FormErrorMessage>
        )}
        {errors && errors[name] && errors[name].type === "minLength" && (
          <FormErrorMessage {...errorProps}>
            <FormattedMessage id="err.value_too_short" />
          </FormErrorMessage>
        )}
        {errors && errors[name] && errors[name].type === "maxLength" && (
          <FormErrorMessage {...errorProps}>
            <FormattedMessage id="err.value_too_long" />
          </FormErrorMessage>
        )}
        {type !== "file" && errors && errors[name] && errors[name]?.message && (
          <FormErrorMessage {...errorProps}>{errors[name].message}</FormErrorMessage>
        )}
      </Flex>
    </Box>
  );
};

export default FormInput;
