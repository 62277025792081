import React, { useRef } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";

import { Text } from "@theme-ui/components";

import { useOverflow } from "../../hooks/useOverflow";
import { getDaysUntilFromDate } from "../../common/Utilities";
import Tooltip from "../Tooltip";

const HighlightedElement = ({
  description,
  activationDate = "",
  expirationDate = "",
  ...props
}) => {
  const valueRef = useRef();
  const [isOverflow] = useOverflow(valueRef);

  const displayEndsOf = activationDate && expirationDate;
  const numberOfDaysOfTheMonth = 31;
  const days =
    displayEndsOf &&
    getDaysUntilFromDate(new Date(activationDate).getTime(), new Date(expirationDate).getTime());

  return (
    <>
      {isOverflow ? (
        <Tooltip value={description} sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>
          <Text
            ref={valueRef}
            my="small"
            sx={{
              color: "textDark",
              whiteSpace: "nowrap",
              fontWeight: "bold",
              ...props.sx,
            }}>
            {description}
          </Text>
        </Tooltip>
      ) : (
        <Text
          ref={valueRef}
          mt="small"
          sx={{
            color: "textDark",
            textOverflow: "ellipsis",
            overflow: "hidden",
            fontSize: [1, 1, 4, 4],
            whiteSpace: ["initial", "nowrap"],
            fontWeight: "bold",
            ...props.sx,
          }}>
          {description}
        </Text>
      )}
      {displayEndsOf && days > numberOfDaysOfTheMonth && (
        <Text
          color="textDark"
          sx={{
            alignSelf: "flex-end",
            width: ["fit-content", "18rem"],
          }}>
          <FormattedMessage id={"lbl.service_expire_on"} />
          <FormattedDate value={expirationDate} dateStyle="medium" />
        </Text>
      )}
    </>
  );
};

export default HighlightedElement;
