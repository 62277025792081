import React from "react";
import ContentLoader from "react-content-loader";

const MemebersCardSkeleton = () => (
  <ContentLoader
    speed={2}
    viewBox="0 0 492 222"
    backgroundColor="#EEEEEE"
    foregroundColor="#d6d3d3">
    <rect x="20.6" y="0" width="440.7" height="25" />
    <rect x="20.6" y="40" width="440.7" height="25" />
    <rect x="20.6" y="80" width="440.7" height="25" />
    <rect x="20.6" y="120" width="440.7" height="25" />
    <rect x="20.6" y="160" width="440.7" height="25" />
  </ContentLoader>
);

export default MemebersCardSkeleton;
