import React from "react";
import { Box, Flex, Text, Input } from "@theme-ui/components";
import { ShowPassword } from "../ShowPassword";
import * as ServiceUtils from "./ServiceUtils";

const ServiceDetails = ({ serviceDetails }) => {
  let accessKeyRef = React.useRef();

  if (!serviceDetails) {
    return null;
  }

  return (
    <Box mx={"large"} my={"small"}>
      {ServiceUtils.isShowUserCode(serviceDetails) && (
        <Flex sx={{ flexDirection: ["column", "row", "row"], width: "100%" }}>
          <Text color="textDark" sx={{ marginY: "small", width: "17rem" }}>
            {serviceDetails.keyLabel}
          </Text>
          <Box sx={{ width: "17rem !important" }}>
            <Text
              color="textDark"
              sx={{ wordWrap: "break-word", maxLength: "100" }}
              name="userCode">
              {serviceDetails.key}
            </Text>
          </Box>
        </Flex>
      )}

      {serviceDetails.basicType === "MAIL" && (
        <Flex sx={{ flexDirection: ["column", "row", "row"], width: "100%" }}>
          <Text color="textDark" sx={{ marginY: "small", width: "17rem" }}>
            {serviceDetails.emailUsernameLabel}
          </Text>
          <Box sx={{ width: "17rem !important" }}>
            <Text
              color="textDark"
              sx={{ wordWrap: "break-word", maxLength: "100" }}
              name="userCode">
              {serviceDetails.emailUsername}
            </Text>
          </Box>
        </Flex>
      )}

      {ServiceUtils.isShowAccessKey(serviceDetails) && (
        <Flex sx={{ flexDirection: ["column", "row", "row"], width: "100%" }}>
          <Text color="textDark" sx={{ marginY: "small", width: "17rem" }}>
            {serviceDetails.accessKeyLabel}
          </Text>

          <Box sx={{ width: "17rem !important" }}>
            <Input
              defaultValue={serviceDetails.accessKey}
              ref={accessKeyRef}
              disabled={true}
              variant="input"
              maxLength="100"
              name="accessKey"
              type="password"
            />
            <ShowPassword refInput={accessKeyRef} mt={"-1.6rem"} />
          </Box>
        </Flex>
      )}

      {serviceDetails.udfs && (
        <>
          {serviceDetails.udfs.map((udf, index) => {
            if (!udf.value) {
              return null;
            }
            return (
              <Flex
                key={udf.name + index}
                sx={{ flexDirection: ["column", "row", "row"], width: "100%" }}>
                <Text color="textDark" sx={{ marginY: "small", width: "17rem" }}>
                  {udf.label}
                </Text>
                <Box sx={{ width: "17rem !important" }}>
                  <Text color="textDark" sx={{ wordWrap: "break-word" }}>
                    {udf.value}
                  </Text>
                </Box>
              </Flex>
            );
          })}
        </>
      )}
    </Box>
  );
};

export default ServiceDetails;
