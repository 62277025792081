import React from "react";
import { FormattedMessage } from "react-intl";

import { Box, Flex, Heading } from "@theme-ui/components";

import { SelfcareBox } from "../../components/base";
import Update from "../../components/Update";
import Remove from "../../components/Remove";
import SummaryLayoutSkeleton from "./SummaryLayoutSkeleton";

const SummaryLayout = ({
  title,
  isLoading = false,
  isAdd = false,
  showUpdate = false,
  onUpdate = null,
  showRemove = false,
  onRemove = null,
  skeleton: Skeleton = SummaryLayoutSkeleton,
  children,
  ...props
}) => {
  const isUpdateOrRemove = showUpdate || showRemove;

  if (isLoading) {
    return (
      <Flex
        sx={{
          flexDirection: "column",
          height: "25rem",
          ...props.sx,
        }}>
        <Heading variant="heading2">{<FormattedMessage id={title} />}</Heading>
        <SelfcareBox
          variant="serviceCard"
          px="0 !important"
          py="0 !important"
          sx={{
            width: ["17rem", "20rem", "23rem", "26.5rem"],
            maxWidth: "40.25rem",
            borderRadius: "card",
            mt: 0,
            ...props.sx,
          }}>
          <Skeleton height="320px" />
        </SelfcareBox>
      </Flex>
    );
  }

  return (
    <Flex
      sx={{
        flexDirection: "column",
        ...props.sx,
      }}>
      <Heading variant="heading2">{<FormattedMessage id={title} />}</Heading>

      <Flex
        variant="layout.card"
        sx={{
          padding: 0,
          width: ["100%", "100%", "100%", "100%"],
          height: "100%",
        }}>
        <Flex sx={{ alignSelf: "flex-end" }}>
          {showUpdate && (
            <Update
              onUpdate={onUpdate}
              isAdd={isAdd}
              sx={{
                mt: ["medium", "small", "default", 0],
                mr: ["medium", "default", "default"],
              }}
            />
          )}
          {showRemove && <Remove onRemove={onRemove} sx={{ mt: !showUpdate ? "default" : 0 }} />}
        </Flex>

        <Flex
          py={[
            "small",
            isUpdateOrRemove
              ? [0, 0, "medium", "medium"]
              : ["default", "default", "larger", "1.65rem"],
          ]}
          px="large"
          mt={isUpdateOrRemove ? 0 : ["large", "default", "default"]}
          sx={{ flexDirection: "column", height: "100%" }}>
          <Box>{children}</Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SummaryLayout;
