import React from "react";

const HidePassword = ({ color = "#a1b7d1", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30.688"
    height="27.438"
    viewBox="0 0 30.688 27.438"
    {...props}>
    <path
      d="M3.719,2.281,2.281,3.719,8.5,9.906,19.594,21,21.5,22.938l6.781,6.781,1.438-1.437L23.5,22.063a26.464,26.464,0,0,0,7.25-5.406L31.344,16l-.594-.656C30.48,15.043,24.086,8,16,8a15.71,15.71,0,0,0-5.5,1.063ZM16,10a14.448,14.448,0,0,1,6,1.438,6.913,6.913,0,0,1-.844,8.281l-2.844-2.844A2.961,2.961,0,0,0,19,15a3,3,0,0,0-3-3,2.961,2.961,0,0,0-1.875.688l-2.031-2.031A13.223,13.223,0,0,1,16,10Zm-9.312.906A26.468,26.468,0,0,0,1.25,15.344L.656,16l.594.656c.258.289,6.18,6.77,13.813,7.281A9.274,9.274,0,0,0,16,24a9.274,9.274,0,0,0,.938-.062,15.143,15.143,0,0,0,2.406-.344l-1.781-1.781A6.9,6.9,0,0,1,16,22a6.958,6.958,0,0,1-6.812-8.562Zm.563,2.031a8.86,8.86,0,0,0,1.094,6.781A25.837,25.837,0,0,1,3.406,16,26.107,26.107,0,0,1,7.25,12.938Zm17.5,0A26.325,26.325,0,0,1,28.594,16a26.012,26.012,0,0,1-4.969,3.75,8.816,8.816,0,0,0,1.125-6.812Z"
      transform="translate(-0.656 -2.281)"
      sx={{
        fill: color,
      }}
    />
  </svg>
);

export default HidePassword;
