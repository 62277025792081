import React, { forwardRef } from "react";

import { Box } from "@theme-ui/components";

import { SVG } from "./ComponentsUtilities";

const CheckboxChecked = props => (
  <SVG {...props}>
    <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
  </SVG>
);

const CheckboxUnchecked = props => (
  <SVG {...props}>
    <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" />
  </SVG>
);

const CheckboxIcon = props => (
  <>
    <CheckboxChecked
      {...props}
      __css={{
        display: "none",
        "input:checked ~ &": {
          display: "block",
        },
      }}
    />
    <CheckboxUnchecked
      {...props}
      __css={{
        display: "block",
        "input:checked ~ &": {
          display: "none",
        },
      }}
    />
  </>
);

const SelfcareCheckbox = forwardRef(
  ({ className, sx, variant = "checkbox", onChange, ...props }, ref) => (
    <Box
      onClick={onChange}
      sx={{
        ":hover": {
          cursor: "pointer",
        },
      }}>
      <Box
        onChange={onChange}
        ref={ref}
        as="input"
        type="checkbox"
        {...props}
        sx={{
          position: "absolute",
          opacity: 0,
          zIndex: -1,
          width: 1,
          height: 1,
          overflow: "hidden",
        }}
      />
      <Box
        as={CheckboxIcon}
        aria-hidden="true"
        tx="forms"
        variant={variant}
        className={className}
        sx={sx}
        __css={{
          mr: 2,
          borderRadius: 4,
          color: "border",
          height: "2rem !important",
          width: "2rem !important",
          "input:checked ~ &": {
            color: "primary",
          },
          "input:disabled ~ &": {
            cursor: "default",
            bg: "#DCDCDC",
            color: "#B5B5B5",
          },
        }}
      />
    </Box>
  )
);
SelfcareCheckbox.displayName = "SelfcareCheckbox";

export default SelfcareCheckbox;
