import React from "react";
const Unlimited = ({ color = "#336eb4", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="39"
    height="18.991"
    viewBox="0 0 39 18.991"
    {...props}>
    <path
      fill={color}
      d="M36.21,2.8a9.56,9.56,0,0,0-13.419,0L20.885,4.706l3.373,3.373,1.906-1.906.073-.073h0a4.715,4.715,0,0,1,6.6.073h0a4.666,4.666,0,0,1-6.6,6.6l-3.3-3.3h0L19.565,6.1h0L16.118,2.8A9.56,9.56,0,0,0,2.7,2.8a9.56,9.56,0,0,0,0,13.419,9.56,9.56,0,0,0,13.419,0l1.906-1.906-3.373-3.373-1.98,1.98h0a4.715,4.715,0,0,1-6.6-.073,4.666,4.666,0,0,1,6.6-6.6h0l1.98,1.98,4.766,4.766h0l3.3,3.3A9.54,9.54,0,0,0,36.21,2.8Z"
      transform="translate(0.05 -0.05)"
    />
  </svg>
);
export default Unlimited;
