import React from "react";

const Edit = ({ fill = "none", stroke = "#0c3865", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.311"
    height="22.121"
    viewBox="0 0 22.311 22.121"
    {...props}>
    {/* <defs>
        <style>
            .cls-1{fill:none;stroke:#0c3865;strokeLinecap:round;strokeLinejoin:round;strokeWidth:2px}
        </style>
    </defs> */}
    <g id="edit" transform="translate(-1 -.879)">
      <path
        id="Path_812"
        data-name="Path 812"
        className="cls-1"
        d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
      />
      <path
        id="Path_813"
        data-name="Path 813"
        className="cls-1"
        d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4z"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
      />
    </g>
  </svg>
);

export default Edit;
