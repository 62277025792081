import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { Button, Flex, Heading, Link, Text } from "@theme-ui/components";

import GetErrorDescription from "../../components/GetErrorDescription";
import { StyledModalMessage } from "../../components/modals";
import TravelPackFUBCard from "./TravelPackFUBCard";
import EasyTravelCard from "./EasyTravelCard";
import { getFubsList } from "./Utilities";
import { cancelSPC, getTravelPlan, setCancelSPCStatus } from "../../redux/slices/PlanSlice";
import { UDF_CATEGORIES } from "../../common/Constants";

const noConfirmation = {
  isOpen: false,
  message: "",
  cancelConfirmation: false,
  isSecondConfirmation: false,
};

const ActiveTravelPlan = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { account, master } = useSelector(state => state.user);
  const { nonSharedBeneficiaryUnits, travelPlan, cancel_SPC_status, planError } = useSelector(
    state => state.plan
  );
  const isCommercialAccount = master?.udfCategory === UDF_CATEGORIES.COMMERCIAL;
  const [isError, setIsError] = useState(false);
  let [confirmation, setConfirmation] = useState({
    ...noConfirmation,
  });

  const fubs = getFubsList(travelPlan, nonSharedBeneficiaryUnits);
  const moreDetailsLink = isCommercialAccount
    ? "link.commercial_travel_packs"
    : "link.travel_plans";

  useEffect(() => {
    if (cancel_SPC_status === "success") {
      dispatch(setCancelSPCStatus(null));
      setConfirmation({
        isOpen: true,
        message: <FormattedMessage id="lbl.remove_success" />,
        isSecondConfirmation: true,
      });
    }
    if (cancel_SPC_status === "failed") {
      setIsError(true);
    }
  }, [account?.accountCode, cancel_SPC_status, dispatch]);

  const onCancel = () => {
    if (confirmation.isSecondConfirmation) {
      dispatch(getTravelPlan(account.accountCode));
    }
    setConfirmation(noConfirmation);
  };

  const onCancelSPC = () => {
    setConfirmation({
      isOpen: true,
      cancelConfirmation: true,
      message: (
        <FormattedMessage
          id="lbl.spc_confirmation"
          values={{
            p: chunks => <Heading variant="heading3normal">{chunks}</Heading>,
          }}
        />
      ),
    });
  };

  const doAfterConfirmation = () => {
    if (confirmation.isSecondConfirmation) {
      dispatch(getTravelPlan(account.accountCode));
    } else {
      dispatch(cancelSPC({ accountCode: account.accountCode, processId: travelPlan.spcProcessId }));
    }
    setConfirmation(noConfirmation);
  };

  const closeError = () => {
    setIsError(false);
    dispatch(setCancelSPCStatus(null));
  };

  return (
    <Flex
      sx={{
        width: "100%",
        flexDirection: "column",
      }}>
      {travelPlan && (
        <>
          <Heading variant="heading2">
            <FormattedMessage id="lbl.your_travel_plan" />
          </Heading>

          {travelPlan.easyTravel && (
            <>
              {travelPlan.easyTravelDetails.length > 0 ? (
                <Flex
                  sx={{
                    gap: ["1rem", "2rem", "3rem"],
                    display: "grid",
                    gridTemplateColumns: [
                      "100%",
                      `repeat(2, 21rem)`,
                      `repeat(3, 22rem)`,
                      `repeat(4, 24.5rem)`,
                    ],
                  }}>
                  {travelPlan.easyTravelDetails.map((details, index) => (
                    <EasyTravelCard key={index} easyTravelDetails={details} />
                  ))}
                </Flex>
              ) : (
                <Flex variant="layout.section" sx={{ width: "100%" }}>
                  <FormattedMessage id="lbl.on_easyTravel_not_active" />
                </Flex>
              )}
            </>
          )}

          {travelPlan.travelPack && fubs.length > 0 && (
            <Flex
              sx={{
                gap: ["1rem", "2rem", "3rem"],
                display: "grid",
                gridTemplateColumns: [
                  "100%",
                  "repeat(2, 21rem)",
                  "repeat(3, 22rem)",
                  "repeat(4, 24.5rem)",
                ],
              }}>
              {fubs.map((fub, index) => (
                <TravelPackFUBCard key={index} fub={fub} />
              ))}
            </Flex>
          )}

          {!travelPlan.easyTravel && !travelPlan.travelPack && (
            <Flex variant="layout.section">
              <Text>
                <FormattedMessage
                  id="lbl.no_active_travel_plans"
                  values={{
                    a: chunks => (
                      <Link
                        variant="support"
                        href={intl.formatMessage({ id: moreDetailsLink })}
                        target="_blank">
                        {chunks}
                      </Link>
                    ),
                  }}
                />
              </Text>
            </Flex>
          )}

          {travelPlan.withSpc && travelPlan.spcService.description !== "" && (
            <Flex mt="large" sx={{ flexDirection: "column" }}>
              <Heading variant="heading2">
                <FormattedMessage id="lbl.scheduled_service" />
              </Heading>

              <Flex
                sx={{
                  flexDirection: ["column", "row", "row"],
                  textAlign: ["center", "left", "left"],
                }}>
                <Text>
                  <FormattedMessage
                    id="lbl.scheduled_service_message"
                    values={{
                      description: travelPlan.spcService.description,
                      date: intl.formatDate(travelPlan.spcService.availableFrom, {
                        dateStyle: "medium",
                      }),
                      color: chunks => <Text color="primary">{chunks}</Text>,
                      b: value => <b>{value}</b>,
                    }}
                  />
                </Text>

                <Text
                  variant="tablelink"
                  ml={[0, "default", "default"]}
                  onClick={onCancelSPC}
                  sx={{ fontWeight: "semiBold", mt: ["small", 0, 0] }}>
                  <FormattedMessage id="lbl.cancel" />
                </Text>
              </Flex>
            </Flex>
          )}
        </>
      )}

      <StyledModalMessage
        isOpen={confirmation.isOpen}
        message={confirmation.message}
        onRequestClose={onCancel}
        type="error">
        <Flex sx={{ flexDirection: "column" }}>
          <Flex sx={{ mx: "auto", mt: "default" }}>
            {!confirmation.isSecondConfirmation && (
              <Button variant="secondary" onClick={onCancel}>
                <FormattedMessage id={confirmation.cancelConfirmation ? "lbl.no" : "lbl.cancel"} />
              </Button>
            )}

            <Button
              ml="default"
              onClick={doAfterConfirmation}
              sx={{ whiteSpace: "nowrap", px: "default" }}>
              <FormattedMessage id={confirmation.isSecondConfirmation ? "lbl.ok" : "lbl.yes"} />
            </Button>
          </Flex>
        </Flex>
      </StyledModalMessage>

      <StyledModalMessage
        isOpen={isError && !!planError}
        message={planError && <GetErrorDescription error={planError} />}
        onRequestClose={closeError}
        type="error">
        <Button onClick={closeError}>
          <FormattedMessage id="lbl.Try_Again" />
        </Button>
      </StyledModalMessage>
    </Flex>
  );
};

export default ActiveTravelPlan;
