import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { Button, Box } from "@theme-ui/components";

import { PAYMENT_METHOD } from "../../common/Constants";
import { LANGUAGE_ISOCODE } from "../../common/Localization";
import { isEmpty } from "../../common/Utilities";
import { StyledModalMessage } from "../../components/modals";
import { SelfcareIntlContext } from "../../contexts/SelfcareIntlContext";
import PaymentResponseModal from "../../pages/Home/PaymentResponseModal";

import {
  getIppayRequest,
  makePayments,
  setIppayRequestDataStatus,
} from "../../redux/slices/PaymentSlice";
import { getBillingSummary, getInvoices, getPayments } from "../../redux/slices/BillingSlice";

const IFrame = ({ srcdoc }) => {
  return (
    <Box px={[5, 5, 5, 9]} sx={{ height: ["650px", "550px", "570px", "475px"] }}>
      <iframe srcDoc={srcdoc} width="100%" height="100%" style={{ border: 0 }} />
    </Box>
  );
};

const HostedCcOperationRequest = ({
  amount,
  card,
  responseUrl,
  paymentMessage = null,
  onCancel,
  onClose,
  paymentMethod,
}) => {
  const { master } = useSelector(state => state.user);
  const accountCode = master?.accountCode;

  const [iPPayForm, setIPPayForm] = useState(null);
  const [isDisplayPaymentResponse, setDisplayPaymentResponse] = useState(false);
  const [errorOnIppay, setErrorOnIppay] = useState(false);
  const { ippayRequestData, ippay_request_data_status } = useSelector(state => state.payment);
  const dispatch = useDispatch();

  const { locale } = useContext(SelfcareIntlContext);
  const isFrench = locale == LANGUAGE_ISOCODE.French;

  useEffect(() => {
    if (paymentMethod === PAYMENT_METHOD.SAVED) {
      dispatch(makePayments([accountCode, amount, responseUrl]));
    } else if (paymentMethod === PAYMENT_METHOD.NEW) {
      dispatch(
        getIppayRequest({ accountCode, amount, responseUrl: window.location.origin + responseUrl })
      );
    }
  }, [accountCode, amount, responseUrl, dispatch, paymentMethod]);

  useEffect(() => {
    if (ippay_request_data_status === "success") {
      setIPPayForm(`<meta name="viewport" content="width=device-width, initial-scale=1.0">
      <script nonce="randomsecret9122022">window.onload=function(){document.forms.payform.submit()}</script>
      <body>
        <p><i>Loading...</i></p>
        <form name="payform" action="${ippayRequestData.hostedURL}" method="POST">
          <input type="hidden" name="CustomerId" value="${ippayRequestData.customerID}">
          <input type="hidden" name="MID" value="${ippayRequestData.merchantID}">
          <input type="hidden" name="TerminalID" value="${ippayRequestData.terminalID}">
          <input type="hidden" name="SaveForFuture" value="${ippayRequestData.saveForFuture}">
          <input type="hidden" name="ReferenceId" value="${ippayRequestData.referenceID}">
          <input type="hidden" name="Amount" value="${ippayRequestData.amount}">
          <input type="hidden" name="CallbackURL" value="${
            window.location.origin + ippayRequestData.callbackURL
          }">
          <input type="hidden" name="Token" value="${ippayRequestData.token}">
          <input type="hidden" name="TransactionType" value="${ippayRequestData.transactionType}">
          <input type="hidden" name="PaymentType" value="CC">
          <input type="hidden" name="UDField3" value="${accountCode}">
          ${
            isFrench && !isEmpty(ippayRequestData.frTemplateID)
              ? '<input type="hidden" name="TemplateID" value="' +
                ippayRequestData.frTemplateID +
                '">'
              : ""
          }
        </form>
      </body>`);
    }
  }, [ippayRequestData, ippay_request_data_status, isFrench, accountCode]);

  const [qryParams, setQryParams] = useState(null);

  useEffect(() => {
    if (isDisplayPaymentResponse && amount >= 0 && !errorOnIppay) {
      dispatch(getBillingSummary(accountCode));
      dispatch(getPayments(accountCode));
      dispatch(getInvoices(accountCode));
    }
  }, [
    dispatch,
    ippayRequestData,
    ippay_request_data_status,
    accountCode,
    isDisplayPaymentResponse,
    amount,
    errorOnIppay,
  ]);

  useEffect(() => {
    const handler = event => {
      let data = null;
      try {
        data = JSON.parse(event.data);
      } catch (e) {
        return;
      }
      if (data.queryParams) {
        setQryParams(data.queryParams);
      }
    };

    window.addEventListener("message", handler, false);
  }, []);

  useEffect(() => {
    if (qryParams && Object.keys(qryParams).length > 0) {
      if (qryParams.errorMessage) {
        setErrorOnIppay(true);
        setDisplayPaymentResponse(true);
        return;
      } else if (qryParams.canceledByUser === "true") {
        onCancel();
        return;
      } else {
        setDisplayPaymentResponse(true);
      }
    }
  }, [dispatch, qryParams, accountCode, amount, onCancel]);

  const clearErrorOnIppay = () => {
    setErrorOnIppay(false);
    onCancel();
  };

  const clearErrorOnIppayOnFailed = () => {
    dispatch(setIppayRequestDataStatus(null));
    onCancel();
  };

  return (
    <>
      {iPPayForm && paymentMethod === PAYMENT_METHOD.NEW && !errorOnIppay && (
        <IFrame srcdoc={iPPayForm} />
      )}

      {/* handles success and error messages */}
      <PaymentResponseModal
        isDisplayPaymentResponse={isDisplayPaymentResponse}
        onPaymentResponseClose={errorOnIppay ? clearErrorOnIppay : onClose}
        paymentMessage={paymentMessage}
        tryAgain={clearErrorOnIppay}
        isError={errorOnIppay}
        response={qryParams}
        isPayment={amount > 0}
      />

      {/*we know for sure that only initiation of ippay communication failed*/}
      <StyledModalMessage
        isOpen={ippay_request_data_status === "failed"}
        message={
          <FormattedMessage
            id={
              card === PAYMENT_METHOD.NEW && amount
                ? "err.make_payment_not_initiated"
                : "err.cc_update_not_initiated"
            }
          />
        }
        onRequestClose={clearErrorOnIppayOnFailed}
        type="error">
        <Button onClick={clearErrorOnIppayOnFailed}>
          <FormattedMessage id="lbl.ok" />
        </Button>
      </StyledModalMessage>
    </>
  );
};

export default HostedCcOperationRequest;
