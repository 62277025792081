import React from "react";

const Inactive = ({ fill = "none", stroke = "#fff", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" {...props}>
    <g id="ok-circle" transform="translate(-1 -1)">
      <circle
        id="Ellipse_48"
        data-name="Ellipse 48"
        cx="14"
        cy="14"
        r="14"
        transform="translate(2 2)"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <g id="Group_183" data-name="Group 183" transform="translate(-1083.384 -425.701)">
        <path
          id="Path_72"
          data-name="Path 72"
          className="cls-2"
          d="m518 745.5 8.759 9"
          transform="translate(577.005 -308.3)"
          fill={fill}
          stroke={stroke}
          strokeLinecap="round"
          strokeWidth="2"
        />
        <path
          id="Path_73"
          data-name="Path 73"
          className="cls-2"
          d="m518 755.365 8.759-9"
          transform="translate(577.005 -309.164)"
          fill={fill}
          stroke={stroke}
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
);

export default Inactive;
