import React from "react";

const CheckMark = ({ color = "#318eff", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="13.908"
    viewBox="0 0 16 13.908"
    {...props}>
    <path fill={color} d="M5.538,13.908,0,8.862,2.462,6.154,5.292,8.738l8-8.738L16,2.462Z" />
  </svg>
);

export default CheckMark;
