import React from "react";
import ContentLoader from "react-content-loader";

const InvoiceSkeleton = () => (
  <ContentLoader
    speed={2}
    viewBox="0 0 317 254"
    backgroundColor="#EEEEEE"
    foregroundColor="#d6d3d3">
    <rect y="0" width="85%" height="50" />
  </ContentLoader>
);

export default InvoiceSkeleton;
