import React, { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Flex, Box, Text, Link } from "@theme-ui/components";
import { BrandingContext } from "../../contexts/BrandingContext";

const Footer = () => {
  const { config } = useContext(BrandingContext);
  const intl = useIntl();
  const currentYear = new Date().getFullYear();

  return (
    <Flex
      mt="auto"
      bg="footerBg"
      sx={{
        width: "100%",
        minHeight: "4rem",
        alignItems: "center",
      }}>
      <Box
        mx="auto"
        px={["default", "mobileModalExtraOffset"]}
        sx={{
          width: "100%",
          maxWidth: "footerContentMaxWidth",
          flexDirection: "row",
          justifyContent: "center",
        }}>
        <Flex sx={{ alignItems: "center", justifyContent: "center", gap: "default" }}>
          <Text variant="copyright">{config.copyright + " " + currentYear}</Text>
          <Link
            href={intl.formatMessage({ id: "link.terms_and_conditions" })}
            target="_blank"
            variant="nav">
            <FormattedMessage id="lbl.terms_of_use" />
          </Link>
        </Flex>
      </Box>
    </Flex>
  );
};

export default Footer;
