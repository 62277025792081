import React from "react";

const AccountSummary = ({
  face = "#e6e6e6",
  hair = "#114878",
  body = "#336eb4",
  other = "#318eff",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="47.8"
    height="55.6"
    viewBox="0 0 47.8 55.6"
    {...props}>
    <g transform="translate(0.1)">
      <path
        fill={face}
        d="M39.4,34.5c-.3-.6-.2-.5-.8-.8a78.729,78.729,0,0,0-7.4-3.2A15.84,15.84,0,0,0,27.1,26a13.143,13.143,0,0,0,2.7-5.7.6.6,0,0,0,.4-.1h.1c.1,0,.2-.1.3-.2h0c.1-.1.3-.2.4-.3l.1-.1a2.181,2.181,0,0,0,.4-.5.1.1,0,0,1,.1-.1c.1-.2.2-.3.3-.5,0-.1.1-.1.1-.2a1.854,1.854,0,0,0,.2-.7c.3-1.3.4-2.4,0-3,.8-3.3.5-7.2-1.4-9.8a7.006,7.006,0,0,0-.8-1l-.3-.3c-.2-.2-.3-.4-.5-.5-.1-.1-.3-.2-.4-.3-.2-.1-.3-.3-.5-.4a2.186,2.186,0,0,0-.5-.3c-.2-.1-.3-.2-.5-.3s-.3-.2-.5-.3-.3-.2-.5-.2c-.2-.1-.3-.1-.5-.2s-.3-.1-.5-.2-.4-.1-.5-.2c-.2,0-.3-.1-.5-.1s-.4-.1-.6-.1c0-.4-.1-.4-.3-.4H20.9c-.4.1-.9.1-1.3.2-.1,0-.3.1-.4.1a7.838,7.838,0,0,0-1.4.5c-.1,0-.1.1-.2.1l-1.2.6c-.1.1-.3.1-.4.2-.4.3-.9.6-1.3.9-3.8,2.2-5.5,5.9-4,11.2-.1,0-.2.1-.4.2h0a1.38,1.38,0,0,0-.3.4,3.822,3.822,0,0,0,0,3,2.544,2.544,0,0,1,.2.7c0,.1,0,.1.1.2a2.186,2.186,0,0,0,.3.5.1.1,0,0,0,.1.1,2.181,2.181,0,0,0,.4.5h0c.1.1.3.2.4.3h.2a.367.367,0,0,0,.3.1c.1,0,.1,0,.2.1.1,0,.3.1.4.1a13.12,13.12,0,0,0,2.8,5.8,12.393,12.393,0,0,0-4.3,4.5,77.582,77.582,0,0,0-7.2,3c-.7.3-.6.2-.9.9-1.2,2.6-1.9,5.5-3,7.9A26.87,26.87,0,0,0,21.2,52.5a28.439,28.439,0,0,0,7.2-1l-.3-.4,1-1H27.7l-.3-1.6a8.2,8.2,0,0,1-.2-2.2,16.352,16.352,0,0,1,.2-2.2l.3-1.6h1.4l-1-1,.9-1.4a11.511,11.511,0,0,1,1.4-1.7A10.481,10.481,0,0,1,32.1,37l1.4-.9,1,1V35.7l1.6-.3a8.2,8.2,0,0,1,2.2-.2,6.6,6.6,0,0,1,1.4.1A1.6,1.6,0,0,0,39.4,34.5Z"
      />
      <path
        fill={face}
        d="M32.2,14.4a1.38,1.38,0,0,0-.3-.4h0l-.1-.1h-.1c-.1,0-.2-.1-.3-.1h-.3a.367.367,0,0,0-.3.1h0a.349.349,0,0,0-.2.1h0c-.1.1-.2.1-.2.2h0c-.1.1-.1.2-.2.3-.1-.2-1.7-4.4-8.1-4.6a9.009,9.009,0,0,1-5.7-3.3,9.412,9.412,0,0,0-2.6,3.5.1.1,0,0,1-.1.1l-.3.6c-.1.2-.1.3-.2.5,0,.1-.1.2-.1.4a4.649,4.649,0,0,1-.3.9c0,.1-.1.2-.1.3h0c-.1.2-.4,1.3-.5,1.7a1.38,1.38,0,0,1-.3-.4h0c-.1-.1-.2-.2-.3-.2s-.1-.1-.2-.1-.1-.1-.2-.1h-.4c-.1,0-.2.1-.4.2h0a1.38,1.38,0,0,0-.3.4,3.822,3.822,0,0,0,0,3,2.544,2.544,0,0,1,.2.7c0,.1,0,.1.1.2a2.186,2.186,0,0,0,.3.5.1.1,0,0,0,.1.1,2.181,2.181,0,0,0,.4.5h0c.1.1.3.2.4.3h.2a.367.367,0,0,0,.3.1c.1,0,.1,0,.2.1.1,0,.3.1.4.1a13.12,13.12,0,0,0,2.8,5.8c-.2.5-.3,1.1-.5,1.8,1.8,2.8,3.9,4.4,6.2,4.4,2.4,0,4.6-1.8,6.5-4.8-.2-.7-.3-1.2-.4-1.5A13.143,13.143,0,0,0,30,20a.6.6,0,0,0,.4-.1h.1c.1,0,.2-.1.3-.2h0c.1-.1.3-.2.4-.3l.1-.1a2.181,2.181,0,0,0,.4-.5.1.1,0,0,1,.1-.1c.1-.2.2-.3.3-.5,0-.1.1-.1.1-.2a1.854,1.854,0,0,0,.2-.7A4.436,4.436,0,0,0,32.2,14.4Z"
      />
      <path
        fill={hair}
        d="M11.5,13.9c.1,0,.1,0,.2.1s.2.2.3.2h0a1.38,1.38,0,0,1,.3.4,12.2,12.2,0,0,1,.5-1.7h0c0-.1.1-.2.1-.3a9.44,9.44,0,0,0,.3-.9c0-.1.1-.2.1-.4.1-.2.1-.3.2-.5l.3-.6a.1.1,0,0,1,.1-.1,11.782,11.782,0,0,1,2.6-3.5,8.6,8.6,0,0,0,5.7,3.3c6.4.2,8,4.4,8.1,4.6.1-.1.1-.2.2-.3h0l.2-.2h0a.31.31,0,0,1,.2-.1h0c.1,0,.2-.1.3-.1h.3a.367.367,0,0,1,.3.1h.1a.1.1,0,0,1,.1.1h0a1.38,1.38,0,0,1,.3.4c.8-3.3.5-7.2-1.4-9.8a7.006,7.006,0,0,0-.8-1l-.3-.3c-.2-.2-.3-.4-.5-.5-.1-.1-.3-.2-.4-.3-.2-.1-.3-.3-.5-.4a2.186,2.186,0,0,0-.5-.3c-.2-.1-.3-.2-.5-.3s-.3-.2-.5-.3-.3-.2-.5-.2c-.2-.1-.3-.1-.5-.2s-.3-.1-.5-.2S25,.5,24.9.4c-.2,0-.3-.1-.5-.1S24,.2,23.8.2c-.1-.2-.2-.2-.4-.2H20.9c-.4.1-.9.1-1.3.2-.1,0-.3.1-.4.1a7.838,7.838,0,0,0-1.4.5c-.1,0-.1.1-.2.1l-1.2.6c-.1.1-.3.1-.4.2-.4.3-.9.6-1.3.9-3.8,2.2-5.5,5.9-4,11.2h.4C11.3,13.8,11.4,13.9,11.5,13.9Z"
      />
      <path
        fill={other}
        d="M47.5,48.4a11.019,11.019,0,0,0,.2-1.8,11.019,11.019,0,0,0-.2-1.8H46a8.488,8.488,0,0,0-.9-2.2l1-1a5.263,5.263,0,0,0-1.2-1.4A17.411,17.411,0,0,0,43.5,39l-1,1a8.488,8.488,0,0,0-2.2-.9V37.7a11.019,11.019,0,0,0-1.8-.2,11.019,11.019,0,0,0-1.8.2V39a8.488,8.488,0,0,0-2.2.9l-1-1a5.263,5.263,0,0,0-1.4,1.2,17.411,17.411,0,0,0-1.2,1.4l1,1a8.488,8.488,0,0,0-.9,2.2H29.6a11.019,11.019,0,0,0-.2,1.8,11.019,11.019,0,0,0,.2,1.8H31a3.582,3.582,0,0,0,.4,1.1c.2.4.3.7.5,1.1h0l-1,1c.1.1.2.3.3.4h0a9.016,9.016,0,0,0,.9,1,17.411,17.411,0,0,0,1.4,1.2l1-1a8.488,8.488,0,0,0,2.2.9v1.4a11.019,11.019,0,0,0,1.8.2,11.019,11.019,0,0,0,1.8-.2V54a8.488,8.488,0,0,0,2.2-.9l1,1a5.263,5.263,0,0,0,1.4-1.2,17.411,17.411,0,0,0,1.2-1.4l-1-1a8.488,8.488,0,0,0,.9-2.2h1.5ZM43,47.3a.367.367,0,0,1-.1.3c0,.1-.1.3-.1.4a4.875,4.875,0,0,0-.3.7,5.2,5.2,0,0,1-1.9,2,4.99,4.99,0,0,1-2.1.5,4.551,4.551,0,0,1-3.8-2h0a.1.1,0,0,0-.1-.1l-.3-.6c0-.1-.1-.2-.1-.3a1.689,1.689,0,0,1-.2-.5c0-.1,0-.2-.1-.2a2.2,2.2,0,0,1-.1-.8,4.653,4.653,0,0,1,4.6-4.6,2.769,2.769,0,0,1,.9.1,1.854,1.854,0,0,1,.7.2c.1,0,.1,0,.2.1a4.508,4.508,0,0,1,1.4,1h0A4.374,4.374,0,0,1,43,46.8S43,47.1,43,47.3Z"
      />
      <path
        fill={body}
        d="M28.2,51.4l1-1H27.8l-.3-1.6a8.2,8.2,0,0,1-.2-2.2,16.352,16.352,0,0,1,.2-2.2l.3-1.6h1.4l-1-1,.9-1.4a11.511,11.511,0,0,1,1.4-1.7,10.481,10.481,0,0,1,1.7-1.4l1.4-.9,1,1V36l1.6-.3a8.2,8.2,0,0,1,2.2-.2,6.6,6.6,0,0,1,1.4.1,10.376,10.376,0,0,1-.4-1c-.3-.6-.2-.5-.8-.8a78.729,78.729,0,0,0-7.4-3.2,15.84,15.84,0,0,0-4.1-4.5,7.543,7.543,0,0,1,.4,1.5c-1.8,3-4.1,4.8-6.5,4.8-2.3,0-4.4-1.6-6.2-4.4a9.465,9.465,0,0,1,.5-1.8A12.393,12.393,0,0,0,11,30.7a77.582,77.582,0,0,0-7.2,3c-.7.3-.6.2-.9.9-1.2,2.6-1.9,5.5-3,7.9A26.87,26.87,0,0,0,21.1,52.9a28.439,28.439,0,0,0,7.2-1Z"
      />
    </g>
  </svg>
);

export default AccountSummary;
