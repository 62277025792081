import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import auth from "./slices/AuthSlice";
import user from "./slices/UserSlice";
import apiStatus from "./slices/ApiStatusSlice";
import branding from "./slices/BrandingSlice";
import payment from "./slices/PaymentSlice";
import billing from "./slices/BillingSlice";
import plan from "./slices/PlanSlice";
import { createSemaphoreMiddleware } from "./semaphore";

const semaphoreMiddleware = createSemaphoreMiddleware();

const reducers = combineReducers({
  auth,
  user,
  apiStatus,
  branding,
  payment,
  billing,
  plan,
});

const store = configureStore({
  reducer: reducers,
  devTools: process.env.NODE_ENV !== "production",
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(semaphoreMiddleware),
});

export default store;
