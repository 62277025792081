import React from "react";

const Due = ({ glass = "#336eb4", sand = "#318eff", base = "#114878", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="29" viewBox="0 0 20 29" {...props}>
    <g transform="translate(15444 -570)">
      <path
        fill={glass}
        d="M18.3,29H1.7V21.8A8.252,8.252,0,0,1,6,14.6,8.7,8.7,0,0,1,1.7,7.2V0H18.2V7.2a8.252,8.252,0,0,1-4.3,7.2,8.252,8.252,0,0,1,4.3,7.2V29ZM2.8,27.9H17.3V21.7a7.366,7.366,0,0,0-4.7-6.8l-1.3-.5,1.3-.5a7.366,7.366,0,0,0,4.7-6.8V1H2.8V7.2A7.366,7.366,0,0,0,7.5,14l1.3.5L7.5,15a7.366,7.366,0,0,0-4.7,6.8v6.1Z"
        transform="translate(-15444 570)"
      />
      <path
        fill={sand}
        d="M10,15.6a6.231,6.231,0,0,0-6.2,6.2v4.8H16.2V21.8A6.167,6.167,0,0,0,10,15.6Z"
        transform="translate(-15444 570)"
      />
      <path
        fill={sand}
        d="M3.9,8.3A6.23,6.23,0,0,0,10,13.5a6.139,6.139,0,0,0,6.1-5.2Z"
        transform="translate(-15444 570)"
      />
      <rect fill={base} width="20" height="1.3" transform="translate(-15444 570)" />
      <rect fill={base} width="20" height="1.3" transform="translate(-15444 597.6)" />
    </g>
  </svg>
);

export default Due;
