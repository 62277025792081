import React, { useState } from "react";

import { Box, Flex, Text } from "@theme-ui/components";

const NumberSpinner = ({ defaultValue, minValue = 0, maxValue = 99, onNumberChange, disabled }) => {
  const [state, setState] = useState({
    number: defaultValue,
    minValue: minValue <= -1 ? 0 : minValue,
    maxValue: maxValue === -1 ? 99 : maxValue,
  });

  const doNumberChange = number => {
    if (onNumberChange) onNumberChange(number);
  };

  const increase = () => {
    if (disabled) return;

    if (state.maxValue > state.number) {
      let number = state.number + 1;
      setState({ ...state, number: number });

      doNumberChange(number);
    }
  };

  const decrease = () => {
    if (disabled) return;

    if (state.minValue < state.number) {
      let number = state.number - 1;
      setState({ ...state, number: number });

      doNumberChange(number);
    }
  };

  return (
    <Flex
      sx={{
        border: "1px solid",
        borderColor: "border",
        backgroundColor: "inactive",
        borderRadius: "circle",
        flexDirection: "row",
        alignItems: "center",
        width: "4.5rem",
        height: "1.875rem",
      }}>
      <Box
        sx={{
          flex: 1,
          alignItems: "flex-start",
          justifyContent: "center",
          textAlign: "center",
        }}
        onClick={() => decrease()}>
        <Text
          as="span"
          sx={{
            position: "relative",
            top: "-0.12rem",
            color: "note",
            fontSize: "1.75rem",
            lineHeight: "solid",
            fontWeight: "bold",
            userSelect: "none",
            ":hover": {
              cursor: "pointer",
              opacity: "70%",
            },
          }}>
          -
        </Text>
      </Box>
      <Box
        sx={{
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
          height: "1.5rem",
          width: "1.5rem",

          marginY: "0.125rem",
          backgroundColor: "primary",
          borderRadius: "circle",
        }}>
        <Text
          as="span"
          sx={{
            position: "relative",
            top: "0.1rem",
            color: "contentBg",
            fontSize: 1,
            userSelect: "none",
            lineHeight: "solid",
            fontWeight: "medium",
            textAlign: "center",
          }}>
          {state.number}
        </Text>
      </Box>
      <Box
        sx={{
          flex: 1,
          alignItems: "flex-end",
          justifyContent: "center",
          textAlign: "center",
        }}
        onClick={() => increase()}>
        <Text
          as="span"
          sx={{
            position: "relative",
            color: "primary",
            fontSize: "1.75rem",
            lineHeight: "solid",
            userSelect: "none",
            fontWeight: "bold",
            ":hover": {
              cursor: "pointer",
              opacity: "70%",
            },
          }}>
          +
        </Text>
      </Box>
    </Flex>
  );
};
export default NumberSpinner;
