import React from "react";

const GoodStatus = ({ fill = "#11ab4f", fill2 = "#fff", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <g data-name="Group 1695" transform="translate(-376 1809)">
      <circle
        data-name="Ellipse 22"
        cx="12"
        cy="12"
        r="12"
        transform="translate(376 -1809)"
        fill={fill}
      />
      <path
        d="M16.809.54a1.778 1.778 0 0 0-2.555 0L6.186 8.8l-3.1-3.176a1.778 1.778 0 0 0-2.555 0 1.88 1.88 0 0 0 0 2.615l4.38 4.482a1.778 1.778 0 0 0 2.555 0l9.346-9.564a1.882 1.882 0 0 0 0-2.615z"
        transform="translate(379.476 -1803.63)"
        fill={fill2}
        fillRule="evenodd"
      />
    </g>
  </svg>
);

export default GoodStatus;
