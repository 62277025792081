import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";

import { Flex, Button } from "@theme-ui/components";

import SharedPlanMembersTable from "../SharedPlanMembersTable/SharedPlanMembersTable";
import { MESSAGES_CODES, UDF_CATEGORIES } from "../../common/Constants";
import StatusMessage from "../StatusMessage/StatusMessage";
import { getRandomIndexes } from "../../common/Utilities";
import GetErrorDescription from "../GetErrorDescription";
import ServiceAgreement from "../ServiceAgreement";
import FUBGroup from "../../components/FUBGroup";
import { StyledModalMessage } from "../modals";
import ImageContainer from "../ImageContainer";
import SubHeader from "../SubHeader";

import { getBucketsDetails, setPlanError } from "../../redux/slices/PlanSlice";

const MasterAccountLayout = ({ accountCode }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { master, account, serviceAgreements, account_list_status, sharedMembersDisplay } =
    useSelector(state => state.user);

  const { bucketsDetails, buckets_details_status, planError } = useSelector(state => state.plan);
  const [isError, setIsError] = useState(false);
  const [bottomLeftCommercial, setBottomLeftCommercial] = useState();
  const [bottomRightCommercial, setBottomRightCommercial] = useState();
  const [showTable, setShowTable] = useState(false);
  const isMasterActiveOrSuspended = ["ACTIVE", "SUSPENDED"].includes(master?.accountStatus);

  const statusMessage =
    master && (!master.serviceAgreement ? master.statusMessage : account.statusMessage);
  const isResidential = master?.udfCategory !== UDF_CATEGORIES.COMMERCIAL;

  const isActive = [
    MESSAGES_CODES.ACTIVE,
    MESSAGES_CODES.ACTIVE_SAC,
    MESSAGES_CODES.ACTIVE_SAS,
  ].includes(statusMessage);

  useEffect(() => {
    if (
      !bucketsDetails &&
      !buckets_details_status &&
      accountCode !== undefined &&
      isMasterActiveOrSuspended
    ) {
      dispatch(getBucketsDetails(accountCode));
    }
    if (buckets_details_status === "failed") {
      //shows error msg on refresh because plans is still fetching
      setIsError(true);
    }
  }, [
    accountCode,
    account_list_status,
    bucketsDetails,
    buckets_details_status,
    dispatch,
    isMasterActiveOrSuspended,
    serviceAgreements,
  ]);

  useEffect(() => {
    setBottomLeftCommercial(getRandomIndexes(window._env_.commercials.homeBottomLeft.length, 1));
    setBottomRightCommercial(getRandomIndexes(window._env_.commercials.homeBottomRight.length, 1));
  }, [dispatch]);

  const closeError = () => {
    dispatch(setPlanError(null));
    setIsError(false);
  };
  const tryAgain = () => {
    closeError();
  };

  const Commercials = () => {
    return (
      <Flex
        py="large"
        sx={{
          width: "100%",
          gap: "large",
          justifyContent: ["center", "center", "space-between"],
          alignItems: ["center", "center", "space-between"],
          flexDirection: ["column", "column", "column", "row"],
        }}>
        {bottomLeftCommercial && (
          <ImageContainer
            mt="large"
            image={window._env_.commercials.homeBottomLeft[bottomLeftCommercial]}
          />
        )}
        {bottomRightCommercial && (
          <ImageContainer
            mt="large"
            image={window._env_.commercials.homeBottomRight[bottomRightCommercial]}
          />
        )}
      </Flex>
    );
  };

  return (
    <>
      <SubHeader isHome />

      {!isActive && <StatusMessage accountStatus={statusMessage} />}

      {sharedMembersDisplay === "default" && (
        <Flex sx={{ flexDirection: "column", mb: isResidential ? 0 : "large" }}>
          <ServiceAgreement
            mb="large"
            data={serviceAgreements}
            isLoading={account_list_status === "loading"}
            showTable={showTable}
            setShowTable={setShowTable}
          />

          {!showTable && (
            <Flex sx={{ flexDirection: ["column", "column", "row"] }}>
              <FUBGroup
                isShared
                isMaster
                title={intl.formatMessage({ id: "lbl.section_shared_heading" })}
                fubs={bucketsDetails}
                isLoading={buckets_details_status === "loading"}
              />
            </Flex>
          )}
        </Flex>
      )}

      <SharedPlanMembersTable />

      {isResidential && <Commercials />}

      <StyledModalMessage
        isOpen={isError}
        message={planError && <GetErrorDescription error={planError} />}
        onRequestClose={closeError}
        type="error">
        <Button onClick={tryAgain}>
          <FormattedMessage id="lbl.Try_Again" />
        </Button>
      </StyledModalMessage>
    </>
  );
};

export default MasterAccountLayout;
