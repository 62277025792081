import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useColorMode } from "@theme-ui/color-modes";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { useThemeUI } from "@theme-ui/core";

import { Flex, Box, Text } from "@theme-ui/components";

import { SelfcareIntlContext } from "../../contexts/SelfcareIntlContext";
import { BrandingContext } from "../../contexts/BrandingContext";
import { SUPPORTED_LOCALES } from "../../common/Localization";
import { UDF_CATEGORIES } from "../../common/Constants";
import { SelfcareIcon } from "../icons";

import { doLogout } from "../../redux/slices/AuthSlice";

const MenuOption = ({ text, fontWeight = "light", onOptionSelected = null, icon, ...props }) => {
  const { theme } = useThemeUI();

  return (
    <Flex
      px="small"
      sx={{
        width: "12rem",
        flexDirection: "column",
        textAlign: "end",
        cursor: onOptionSelected !== null ? "pointer" : "default",
        "&:hover": {
          opacity: "90%",
        },
      }}>
      <Flex
        sx={{
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
        py={["tiny", "small"]}
        onClick={onOptionSelected !== null ? () => onOptionSelected() : null}>
        {icon && (
          <SelfcareIcon name={icon} sx={{ size: "1.6rem" }} stroke={theme.colors.tableDate} />
        )}

        <Text
          variant="note"
          py="tiny"
          color="primary"
          sx={{
            fontWeight: fontWeight,
            maxWidth: "9rem",
            whiteSpace: "initial",
            "&:hover": {
              color: "secondary",
            },
            ...props.sx,
          }}
          {...props}>
          {icon === "language" ? text : <FormattedMessage id={text} />}
        </Text>
      </Flex>
    </Flex>
  );
};

MenuOption.displayName = "MenuOption";

const UserMenu = ({ children, showMenu, setShowMenu, ...props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [colorMode, setColorMode] = useColorMode();
  const { config } = useContext(BrandingContext);
  const intlParams = useContext(SelfcareIntlContext);
  const { master } = useSelector(state => state.user);
  const isResidential = master?.udfCategory !== UDF_CATEGORIES.COMMERCIAL;

  return (
    <Box {...props}>
      <Box
        display="inline-block"
        sx={{
          position: "relative",
          cursor: "pointer",
        }}>
        {children}
        {showMenu && (
          <Box
            bg="contentBg"
            sx={{
              position: "absolute",
              top: "110%",
              right: "-10%",
              boxShadow: "tooltip",
              borderRadius: "card",
              whiteSpace: "nowrap",
              px: "tiny",
            }}>
            {isResidential && (
              <Flex sx={{ display: ["inline", "inline", "none"] }}>
                <MenuOption
                  text={SUPPORTED_LOCALES[intlParams.nextLanguage(intlParams.locale)]}
                  onOptionSelected={() =>
                    intlParams.switchLanguageTo(intlParams.nextLanguage(intlParams.locale))
                  }
                  icon={"language"}
                />
              </Flex>
            )}

            <MenuOption
              text={"lbl.change_username"}
              onOptionSelected={() => {
                history.push("/changeUsername");
                setShowMenu(false);
              }}
              icon={"changeUsername"}
            />

            <MenuOption
              text={"lbl.change_password"}
              onOptionSelected={() => {
                history.push("/changePassword");
                setShowMenu(false);
              }}
              icon={"change-password"}
            />

            {config.darkMode && (
              <MenuOption
                text={colorMode === "light" ? "lbl.dark_theme" : "lbl.light_theme"}
                onOptionSelected={() => setColorMode(colorMode === "light" ? "dark" : "light")}
                icon={colorMode === "light" ? "darkTheme" : "lightTheme"}
              />
            )}

            <MenuOption
              fontWeight="semiBold"
              icon={"logout"}
              text={"lbl.log_out"}
              onOptionSelected={() => dispatch(doLogout())}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default UserMenu;
