import React from "react";

const ModalClose = ({ fill = "#e2e2e2", fill2 = "#0c3865", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" {...props}>
    <g data-name="Group 1696" transform="translate(-1419 -273)">
      <circle
        data-name="Ellipse 15"
        cx="18"
        cy="18"
        r="18"
        transform="translate(1419 273)"
        fill={fill}
        //style="fill:#e2e2e2"
      />
      <path
        data-name="Union 5"
        d="M-1987-1634v-5h-5a2 2 0 0 1-2-2 2 2 0 0 1 2-2h5v-5a2 2 0 0 1 2-2 2 2 0 0 1 2 2v5h5a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-5v5a2 2 0 0 1-2 2 2 2 0 0 1-2-2z"
        transform="rotate(45 -2606.131 3455.72)"
        fill={fill2}
        // style="fill:#0c3865"
      />
    </g>
  </svg>
);
export default ModalClose;
