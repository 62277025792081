import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router";
import qs from "qs";

import { Flex, Image, Spinner } from "@theme-ui/components";

import { OAuthCredentials, OAuthIssuerURL } from "../../common/Deployment";
import { AuthorizationHandler } from "../../components/authorization";
import { BrandingContext } from "../../contexts/BrandingContext";
import Footer from "../../components/Footer";

import { getNearestBillableAccount } from "../../redux/slices/UserSlice";
import { allowLogin } from "../../redux/slices/AuthSlice";

const Login = ({ location }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { provider, logo } = useContext(BrandingContext);

  const {
    canLogin,
    codeVerifier,
    loggedIn,
    accountCode: rootAccountCode,
  } = useSelector(state => state.auth);
  const { master, nba_account_status, defaultNavigationPath } = useSelector(state => state.user);
  const { authorizationURL, clientID, scope, redirectURI } = OAuthCredentials;

  let { code } = qs.parse(location.search, { ignoreQueryPrefix: true });

  useEffect(() => {
    if (!code) dispatch(allowLogin());
  }, [dispatch, code]);

  useEffect(() => {
    if (canLogin && rootAccountCode && loggedIn && !nba_account_status) {
      dispatch(getNearestBillableAccount(rootAccountCode));
    }
  }, [rootAccountCode, dispatch, loggedIn, nba_account_status, canLogin]);

  useEffect(() => {
    if (nba_account_status === "failed") {
      history.push("/error");
    }
  }, [history, nba_account_status]);

  let url =
    OAuthIssuerURL +
    authorizationURL +
    "?" +
    qs.stringify({
      response_type: "code",
      client_id: clientID,
      redirect_uri: window.location.origin + redirectURI,
      scope: scope,
      state: location.hash,
      provider: provider,
      code_challenge: codeVerifier,
    });

  if (!code && !loggedIn) {
    window.location.href = url;
    return null;
  }

  const errorHandler = () => {
    window.location.href = url;
  };

  return (
    <Flex
      bg="windowBg"
      sx={{
        flexDirection: "column",
        minHeight: "100vh",
      }}>
      <Flex
        bg="headerBg"
        sx={{
          alignItems: "center",
          backgroundSize: "cover",
          zIndex: 19,
          width: "100%",
          height: ["3rem", "4rem", "7rem"],
          position: "fixed",
          top: 0,
        }}
        px={["default", "default", "huge", "mobileModalExtraOffset"]}>
        <Image
          sx={{
            width: ["5rem", "5rem", "11.375rem", "12.5rem"],
            height: ["3rem", "3rem", "3.875rem"],
          }}
          src={logo}
          mr={["default", "2rem", "default", "2rem"]}
        />
      </Flex>

      <Flex mt="auto" mx="auto">
        {code && <AuthorizationHandler hideSignIn={true} errorHandler={errorHandler} />}
        {canLogin && master && <Redirect to={defaultNavigationPath} />}
        {canLogin && !master && <Spinner mt="auto" color="primary" size={90} />}
      </Flex>

      <Footer />
    </Flex>
  );
};

export default Login;
