import React, { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Layout from "../../containers/Layout";
import { Text, Flex, Heading, Link } from "@theme-ui/components";
import { SelfcareIcon } from "../../components/icons";
import { useSelector } from "react-redux";
import { UDF_CATEGORIES } from "../../common/Constants";
import { SelfcareIntlContext } from "../../contexts/SelfcareIntlContext";
import { LANGUAGE_ISOCODE } from "../../common/Localization";

const RequestAssist = () => {
  const { master } = useSelector(state => state.user);
  const intl = useIntl();
  const isResidential = master?.udfCategory !== UDF_CATEGORIES.COMMERCIAL;
  const { locale } = useContext(SelfcareIntlContext);

  const isEnglishLanguageSelected = locale == LANGUAGE_ISOCODE.English;

  return (
    <Layout>
      <Flex sx={{ width: "100%", flexDirection: "column" }}>
        <Heading>
          <FormattedMessage id={isResidential ? "lbl.how_to_reach_us" : "lbl.request_assistance"} />
        </Heading>

        {isResidential && (
          <Flex
            sx={{
              mb: ["larger", 0],
              flexDirection: "column",
              maxWidth: ["100%", "100%", "72rem", "78.75rem"],
            }}>
            <Flex
              sx={{
                pl: ["small", "small", "medium", "large"],
                width: "100%",
                flexDirection: "column",
              }}>
              <Flex sx={{ flexDirection: "column", gap: "default", fontFamily: "body" }}>
                <Text>
                  <FormattedMessage id="lbl.call_us" />
                </Text>
                {isEnglishLanguageSelected && (
                  <>
                    <Text>
                      <FormattedMessage id="lbl.chat_us" />
                    </Text>
                    <Text>
                      <FormattedMessage id="lbl.text_us" />
                    </Text>
                  </>
                )}
              </Flex>
            </Flex>
            <Heading sx={{ mt: "default" }}>
              <FormattedMessage id={"lbl.useful_links"} />
            </Heading>
            <Flex
              sx={{
                pl: ["small", "small", "medium", "large"],
                flexDirection: "column",
                alignItems: "flex-start",
              }}>
              {isEnglishLanguageSelected && (
                <Link
                  variant="support"
                  href={intl.formatMessage({ id: "link.billing" })}
                  target="_blank">
                  <FormattedMessage id="lbl.support_billing_questions" />
                </Link>
              )}

              <Link
                variant="support"
                href={intl.formatMessage({ id: "link.mobile_service" })}
                target="_blank">
                <FormattedMessage id="lbl.support_mobile_questions" />
              </Link>

              <Link
                variant="support"
                href={intl.formatMessage({ id: "link.contact_buyout" })}
                target="_blank">
                <FormattedMessage id="lbl.support_cbo" />
              </Link>

              <Link
                variant="support"
                href={intl.formatMessage({ id: "link.travel_plans" })}
                target="_blank">
                <FormattedMessage id="lbl.support_travel" />
              </Link>
            </Flex>
          </Flex>
        )}
        {!isResidential && (
          <Flex
            sx={{
              textAlign: "center",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              maxWidth: "56.25rem",
              height: "20.5em",
              border: "1px solid",
              borderColor: "border",
              whiteSpace: "pre",
              borderRadius: 9,
              boxShadow: "default",
            }}>
            <SelfcareIcon name="contactSales" sx={{ width: "10rem", height: "10rem" }} />

            <Text variant="subheadline" mt="medium" color="textDark" px={["small", "default"]}>
              <FormattedMessage id={"lbl.contact_sales"} />
            </Text>
          </Flex>
        )}
      </Flex>
    </Layout>
  );
};

export default RequestAssist;
