import React from "react";

const AutomaticPaymentMethod = ({
  edge = "#336eb4",
  field = "#318eff",
  text = "#114878",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27.6"
    height="15.867"
    viewBox="0 0 27.6 15.867"
    {...props}>
    <rect
      fill="none"
      stroke={edge}
      strokeMiterlimit="10"
      strokeWidth="1.2px"
      width="26.4"
      height="14.667"
      transform="translate(0.6 0.6)"
    />
    <path fill={edge} d="M.6,4.305A3.706,3.706,0,0,0,4.305.6H.6Z" />
    <path
      fill={edge}
      d="M.6,14.9a3.706,3.706,0,0,1,3.705,3.705H.6Z"
      transform="translate(0 -3.261)"
    />
    <path
      fill={edge}
      d="M33.705,4.305A3.706,3.706,0,0,1,30,.6h3.705Z"
      transform="translate(-6.705)"
    />
    <path
      fill={edge}
      d="M33.705,14.9A3.706,3.706,0,0,0,30,18.605h3.705Z"
      transform="translate(-6.705 -3.261)"
    />
    <g transform="translate(7.625 1.758)">
      <path
        fill={field}
        d="M15.876,2.1a6.176,6.176,0,1,0,6.176,6.176A6.194,6.194,0,0,0,15.876,2.1Z"
        transform="translate(-9.7 -2.1)"
      />
      <path
        fill={text}
        d="M17.679,8.04c-1-.772-1.312-1.312-1.312-2.007s.309-.926.772-.926.772.232.772.926v.386H19.3V6.188c0-1.312-.618-2.161-1.621-2.316V3.1H16.521v.695A2.093,2.093,0,0,0,14.9,6.111a3.4,3.4,0,0,0,1.621,2.856c1,.772,1.312,1.312,1.312,2.007s-.309.926-.772.926-.772-.232-.772-.926V10.2H14.9V10.9a2.181,2.181,0,0,0,1.7,2.393v.695h1.158V13.29a2.181,2.181,0,0,0,1.7-2.393A3.927,3.927,0,0,0,17.679,8.04Z"
        transform="translate(-10.886 -2.328)"
      />
    </g>
  </svg>
);

export default AutomaticPaymentMethod;
