import React from "react";

const ChangePassword = ({ fill = "none", stroke = "#7392bb", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17.518"
    viewBox="0 0 16 17.518"
    {...props}>
    <g id="lock" transform="translate(-4 -2.482)">
      <path
        id="Rectangle_1160"
        data-name="Rectangle 1160"
        className="cls-1"
        transform="translate(5 11)"
        d="M0 0h14v8H0z"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Path_810"
        data-name="Path 810"
        className="cls-1"
        d="M11.7 3h0a4.7 4.7 0 0 1 4.7 4.7v2.819H7V7.7A4.7 4.7 0 0 1 11.7 3z"
        transform="translate(.302 .482)"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export default ChangePassword;
