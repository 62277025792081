import React from "react";

const VoiceOverOtherMedia = ({
  mediumBlue = "#336eb4",
  darkBlue = "#114878",
  lightBlue = "#318eff",
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="43.3" height="80" viewBox="0 0 43.3 80" {...props}>
    <g id="dark_blue" data-name="dark blue">
      <rect x="13.13" y="36.76" width="1.79" height="8.07" fill={darkBlue} />
      <rect x="18.21" y="36.76" width="1.79" height="8.07" fill={darkBlue} />
      <rect x="23.29" y="36.76" width="1.79" height="8.07" fill={darkBlue} />
      <rect x="28.38" y="36.76" width="1.79" height="8.07" fill={darkBlue} />
      <rect x="13.13" y="71.93" width="1.79" height="8.07" fill={darkBlue} />
      <rect x="18.21" y="71.93" width="1.79" height="8.07" fill={darkBlue} />
      <rect x="23.29" y="71.93" width="1.79" height="8.07" fill={darkBlue} />
      <rect x="28.38" y="71.93" width="1.79" height="8.07" fill={darkBlue} />
      <rect
        x="3.17"
        y="61.96"
        width="1.79"
        height="8.07"
        transform="translate(-61.94 70.06) rotate(-90)"
        fill={darkBlue}
      />
      <rect
        x="3.17"
        y="56.88"
        width="1.79"
        height="8.07"
        transform="translate(-56.86 64.98) rotate(-90)"
        fill={darkBlue}
      />
      <rect
        x="3.17"
        y="51.8"
        width="1.79"
        height="8.07"
        transform="translate(-51.77 59.9) rotate(-90)"
        fill={darkBlue}
      />
      <rect
        x="3.17"
        y="46.72"
        width="1.79"
        height="8.07"
        transform="translate(-46.69 54.82) rotate(-90)"
        fill={darkBlue}
      />
      <rect
        x="38.34"
        y="61.96"
        width="1.79"
        height="8.07"
        transform="translate(-26.76 105.24) rotate(-90)"
        fill={darkBlue}
      />
      <rect
        x="38.34"
        y="56.88"
        width="1.79"
        height="8.07"
        transform="translate(-21.68 100.16) rotate(-90)"
        fill={darkBlue}
      />
      <rect
        x="38.34"
        y="51.8"
        width="1.79"
        height="8.07"
        transform="translate(-16.6 95.07) rotate(-90)"
        fill={darkBlue}
      />
      <rect
        x="38.34"
        y="46.72"
        width="1.79"
        height="8.07"
        transform="translate(-11.52 89.99) rotate(-90)"
        fill={darkBlue}
      />
    </g>
    <g id="medium_blue" data-name="medium blue">
      <path
        d="M9.77,7.55H6.22A1.06,1.06,0,0,0,5.16,8.62v12a1.06,1.06,0,0,0,1.06,1.07H9.77a1.08,1.08,0,0,1,.6.18l8,5.45a1.07,1.07,0,0,0,1.67-.88V2.8a1.07,1.07,0,0,0-1.67-.88l-8,5.45A1.08,1.08,0,0,1,9.77,7.55Z"
        fill={mediumBlue}
      />
      <polygon
        points="31.39 42.39 11.91 42.39 5.66 48.64 5.66 68.12 5.66 74.37 11.91 74.37 31.39 74.37 37.64 68.12 37.64 48.64 37.64 42.39 31.39 42.39"
        fill={mediumBlue}
      />
    </g>
    <g id="light_blue" data-name="light blue">
      <path
        d="M25.5,22.47l2.26,2.26a14.31,14.31,0,0,0-.52-20.22L25,6.77a11.11,11.11,0,0,1,.51,15.7Z"
        fill={lightBlue}
      />
      <path
        d="M30.08,27l2.26,2.26A20.68,20.68,0,0,0,31.82,0L29.56,2.26A17.47,17.47,0,0,1,30.08,27Z"
        fill={lightBlue}
      />
      <rect x="11.64" y="48.36" width="20.03" height="20.03" rx="3.32" fill={lightBlue} />
    </g>
  </svg>
);

export default VoiceOverOtherMedia;
