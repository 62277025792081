import React from "react";

const Disabled = ({ color = "#114878", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.3"
    height="16.4"
    viewBox="0 0 16.3 16.4"
    {...props}>
    <path
      fill={color}
      d="M14.6,13.2a7.955,7.955,0,0,0,1.7-5A8.237,8.237,0,0,0,8.1,0a8.6,8.6,0,0,0-5,1.7h0L2.8,2a3.044,3.044,0,0,0-.8.8l-.3.3h0A8.907,8.907,0,0,0,0,8.2a8.237,8.237,0,0,0,8.2,8.2,8.6,8.6,0,0,0,5-1.7h0l.3-.3a6.034,6.034,0,0,0,.8-.8l.3-.4ZM8.2,2a6.231,6.231,0,0,1,6.2,6.2,5.782,5.782,0,0,1-1.2,3.6L4.6,3.2A5.584,5.584,0,0,1,8.2,2Zm0,12.3A6.211,6.211,0,0,1,2,8.2,5.782,5.782,0,0,1,3.2,4.6l8.6,8.6A6.626,6.626,0,0,1,8.2,14.3Z"
    />
  </svg>
);

export default Disabled;
