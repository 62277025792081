import React from "react";
import { Flex, Heading } from "@theme-ui/components";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import StatusMessage from "../StatusMessage/StatusMessage";
import { MESSAGES_CODES, UDF_CATEGORIES } from "../../common/Constants";
import WelcomeSkeleton from "../Header/WelcomeSkeleton";
import BalanceItem from "../NavMenu/BalanceItem";
import PaymentItem from "../NavMenu/PaymentItem";
import PhoneDropMenu from "../PhoneDrop";

const SubHeader = ({ isHome = false }) => {
  const intl = useIntl();
  const { master, account, displayName, serviceAgreements, account_list_status } = useSelector(
    state => state.user
  );
  const isMasterTerminate = master?.accountStatus === MESSAGES_CODES.TERMINATE;
  const isCommercialLimitedAccess =
    master && master.udfCategory === UDF_CATEGORIES.COMMERCIAL && master.serviceAgreement;

  const ServiceDropDown = () => {
    if (isHome || serviceAgreements?.length === 0) return null;

    if (account_list_status === "loading") {
      return (
        <Flex ml="default" mt={["default", "default", "3rem"]} sx={{ width: "18.5rem" }}>
          <WelcomeSkeleton width="100%" height="48px" />
        </Flex>
      );
    }

    return (
      <Flex
        sx={{
          alignItems: "center",
          gridColumnEnd: ["auto", "span 3", "auto"],
          ml: [0, "auto"],
          width: "100%",
        }}>
        <PhoneDropMenu />
      </Flex>
    );
  };

  const WelcomeMessage = () => {
    const statusMessage =
      master && (!master.serviceAgreement ? master.statusMessage : account.statusMessage);

    const isActive = [
      MESSAGES_CODES.ACTIVE,
      MESSAGES_CODES.ACTIVE_SAC,
      MESSAGES_CODES.ACTIVE_SAS,
    ].includes(statusMessage);

    if (!master)
      return (
        <Flex
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            width: ["20rem", "24rem"],
          }}>
          <WelcomeSkeleton width="100%" />
        </Flex>
      );

    return (
      <Flex
        sx={{
          justifyContent: "center",
          gap: "small",
          width: ["100%", "100%", "24rem"],
          flexDirection: "column",
          overflowWrap: "break-word",
        }}>
        <Heading
          sx={{
            fontSize: [6, 6, 8, 8],
            color: "primary",
          }}>
          {displayName === null
            ? intl.formatMessage({ id: "lbl.welcome" })
            : intl.formatMessage({ id: "lbl.welcome_user" }, { name: displayName })}
        </Heading>
        {isActive && (
          <StatusMessage
            accountStatus={master.accountStatus}
            isCommercialLimitedAccess={isCommercialLimitedAccess}
          />
        )}
      </Flex>
    );
  };

  return (
    <Flex
      bg="navMenuBg"
      mb="default"
      px={["small", "default", "default", "default"]}
      py={["tiny", "default", "default"]}
      sx={{
        display: "grid",
        gridTemplateColumns: ["1fr", "1fr", "0.5fr 1fr", "0.75fr 1fr 0.75fr"],
      }}>
      <Flex>
        <WelcomeMessage />
      </Flex>

      {!master?.serviceAgreement ? (
        <Flex
          mt={["default", "default", 0]}
          sx={{
            flexDirection: ["column", "column", "column", "row"],
            display: ["flex", "grid"],
            gridTemplateColumns: ["1fr 1fr", "1fr 1fr", "1fr 1fr", "2fr 1fr"],
          }}>
          <BalanceItem />
          {!isMasterTerminate && <PaymentItem />}
        </Flex>
      ) : (
        <Flex />
      )}
      <Flex
        mt={["default", "default", 0]}
        sx={{
          mr: ["auto", 0],
          ml: "auto",
          gridColumnStart: [1, 1, 2, 3],
        }}>
        {!isMasterTerminate && <ServiceDropDown />}
      </Flex>
    </Flex>
  );
};

export default SubHeader;
