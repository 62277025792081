import React from "react";
import ReactDOM from "react-dom";
import "./setupLanguagesPolyfill";
import App from "./app/App";
import "@fontsource/work-sans/latin.css";
import "./app/resources/fonts/museo-sans/index.css";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.register();
