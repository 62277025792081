import React from "react";

const defaultState = {
  loggedIn: false,
  isLoading: false,
  error: null,
  accountCode: null,
  login: () => true,
  logout: () => true,
};

const AuthContext = React.createContext(defaultState);

export { AuthContext };
