import _ from "lodash";
import { Localization } from "../../common/Localization";
import SelfcareAmount from "../../components/base/SelfcareAmount";
import { RECURRING_PERIOD } from "../../common/Constants";

export const UNASSIGNED_SERVICE_ID_KEY = "0";
export const PRODUCT_STATUS = {
  UNASSIGNED: "UNASSIGNED",
  DISABLED: "DISABLED",
  ENABLED: "ENABLED",
  ADDED: "ADDED",
  ACTIVATED: "ACTIVATED",
  DEACTIVATED: "DEACTIVATED",
};

/**
 * Filter out the services that shouldn't be displayed.
 * @param {Array<Service>} serviceArray The services array.
 * @returns {Array<Service>} Return a new array containing only the services that should be displayed.
 **/
export function getServicesForDisplay(serviceArray) {
  let validServices = [];

  _.each(serviceArray, service => {
    if (isMultipleInstanceService(service)) {
      const nrOfInstancesLeft = getNumberOfInstancesLeft(service);

      if (
        canDisplayService(service, false) &&
        (nrOfInstancesLeft > 0 || nrOfInstancesLeft === -1)
      ) {
        validServices.push(service);
      }
    } else {
      const serviceId = _.keys(service.instances)[0];
      const isActive = service.instances[serviceId] && service.instances[serviceId].status;
      if (canDisplayService(service, isActive)) {
        validServices.push(service);
      }
    }
  });

  return validServices;
}

/**
 * Decide if a service can be displayed or not based on defined criteria.
 * @param {Object} service The service object.
 * @param {Boolean} isActive The service active flag.
 * @returns {Boolean} True if all conditions are met, so the service card can be displayed.
 **/
export function canDisplayService(service, isActive) {
  const shouldNotBeDisplayed = service.addPermission === false && service.editPermission === false;
  const notValidCombination = service.addPermission === false && service.editPermission === true;
  let now = new Date();
  let isAnExpiredService =
    (service.availableTo && new Date(service.availableTo).getTime() < now.getTime()) ||
    (service.availableFrom && new Date(service.availableFrom) > now.getTime());
  return (
    service.mandatory === false &&
    shouldNotBeDisplayed === false &&
    service.visible &&
    !(isActive === false && notValidCombination === true) &&
    !isAnExpiredService
  );
}

function isAServiceWithEditableFeatures(service) {
  const srvCantBeAddedOrEditedFromSelfcare =
    service.addPermission === false && service.editPermission === false;

  let isActiveWithEditableFeature = _.find(
    service.instances,
    srvInstanceValue =>
      srvInstanceValue.status &&
      srvInstanceValue.features.find(feature => {
        return feature.editPermission;
      })
  );

  return srvCantBeAddedOrEditedFromSelfcare && isActiveWithEditableFeature;
}

/**
 * Return services (mandatory or not), with active instances and which can not be added or edited through self care
 * @param {Array<Service>} serviceArray The filtered services array.
 * @returns {Array<Service>} a new array containing only the services with editable features
 **/
export function getServicesWithEditableFeatures(serviceArray) {
  let validServices = [];

  serviceArray.forEach(service => {
    if (isAServiceWithEditableFeatures(service)) {
      validServices.push(service);
    }
  });

  return validServices;
}

/**
 * Decide if a service cannot be disabled based on defined criteria.
 * @param {Object} service The service object.
 * @param {Boolean} wasInitiallyActive The service active flag.
 * @returns {Boolean} True if all conditions are met, so the service cannot be disabled.
 **/
export function cannotDisableService(service, wasInitiallyActive) {
  const displayAsDisabled = service.addPermission === true && service.editPermission === false;
  const notValidCombination = service.addPermission === false && service.editPermission === true;

  return wasInitiallyActive === true && (displayAsDisabled || notValidCombination);
}

/**
 * Decide if a product can be displayed or not based on defined criteria.
 * @param {Object} product The product object.
 * @returns {Boolean} True if all conditions are met, so the product card can be displayed.
 **/
export function canDisplayProduct(product) {
  return (
    product.status === PRODUCT_STATUS.ENABLED ||
    product.status === PRODUCT_STATUS.ADDED ||
    (product.status === PRODUCT_STATUS.ACTIVATED && !product.exclusivityGroup)
  );
}

/**
 * True if is a multiple instance service.
 * @param {Object} service The service object.
 * @returns {Boolean} True if is a multiple instance service.
 **/
export function isMultipleInstanceService(service) {
  return (
    !service.exclusivityGroup && (service.maxNrOfInstances === -1 || service.maxNrOfInstances > 1)
  );
}

export function getCurrentNoOfInstances(service) {
  let currentNumberOfInstances = 0;
  _.each(service.instances, (value, key) => {
    if (_.toNumber(key) > 0) currentNumberOfInstances++;
  });
  return currentNumberOfInstances;
}

/**
 * Get the number of service instances left until the maximum number will be reached.
 * @param {Object} service The service object.
 * @returns {Number} Number of service instances left.
 **/
export function getNumberOfInstancesLeft(service) {
  if (service.maxNrOfInstances === -1) return service.maxNrOfInstances;

  let currentNumberOfInstances = getCurrentNoOfInstances(service);

  return _.toNumber(service.maxNrOfInstances) - currentNumberOfInstances;
}

/**
 * Get the full service label, containing the service name and the charge amount.
 * @param {String} serviceDescription The service description.
 * @param {Number} rcFee The rc fee.
 * @param {Number} recurringPeriod The rc period.
 * @param {Number} setupFee The setup fee.
 * @returns {String}
 **/
export function getServiceLabel(serviceDescription, rcFee, recurringPeriod, setupFee) {
  return serviceDescription
    .concat(rcFee !== 0 || setupFee !== 0 ? " - " : "")
    .concat(setupFee !== 0 ? SelfcareAmount({ amount: setupFee }) : "")
    .concat(rcFee !== 0 && setupFee !== 0 ? ", " : "")
    .concat(
      rcFee !== 0
        ? SelfcareAmount({ amount: rcFee }) + " " + getRecurringPeriodLabel(recurringPeriod)
        : ""
    );
}

/**
 * Get the recurring period label.
 * @param {Number} recurringPeriod
 * @returns {String}
 **/
function getRecurringPeriodLabel(recurringPeriod) {
  switch (recurringPeriod) {
    case RECURRING_PERIOD.DAILY:
      return Localization.getString("lbl.daily");

    case RECURRING_PERIOD.WEEKLY:
      return Localization.getString("lbl.weekly");

    case RECURRING_PERIOD.BI_WEEKLY:
      return Localization.getString("lbl.bi_weekly");

    case RECURRING_PERIOD.MONTHLY:
      return Localization.getString("lbl.monthly");

    case RECURRING_PERIOD.BI_MONTHLY:
      return Localization.getString("lbl.bi_monthly");

    case RECURRING_PERIOD.QUARTERLY:
      return Localization.getString("lbl.quarterly");

    case RECURRING_PERIOD.FOUR_MONTH:
      return Localization.getString("lbl.four_months");

    case RECURRING_PERIOD.SEMI_ANNUAL:
      return Localization.getString("lbl.semi_annual");

    case RECURRING_PERIOD.ANNUAL:
      return Localization.getString("lbl.annual");

    case RECURRING_PERIOD.BIENNIAL:
      return Localization.getString("lbl.biennial");

    case RECURRING_PERIOD.TRIENNIAL:
      return Localization.getString("lbl.triennial");

    case RECURRING_PERIOD.BASED_ON_BP:
      return Localization.getString("lbl.based_on_bp");

    case RECURRING_PERIOD.UNLIMITED:
      return Localization.getString("lbl.unlimited");

    default:
      return "";
  }
}

/**
 * Get an object containing the diff product.
 * @param {Object} originalProduct The original product saved in redux store.
 * @param {Map<String, Object>} diffMap The map with the differences made by customer.
 * @param {Map<String, Object>} productsDiffMap The map containing differences regarding products (such as assigning
 * new products through mutual exclusive selection in a group) or deassigning
 * @param {String} path The current path in the product object.
 * @param {String} meProductStatus The new product status to be used, sent just for mutually exclusive products
 **/
export function getDiffProduct(
  originalProduct,
  diffMap,
  productsDiffMap,
  path = "product",
  meProductStatus = undefined
) {
  let product = _.cloneDeep(originalProduct);
  if (meProductStatus) {
    product.status = meProductStatus;
  }
  product.serviceList = [];
  product.subProductList = [];

  if (diffMap.has(path)) {
    product.serviceList = diffMap.get(path);
    _.each(product.serviceList, service => {
      if (isMultipleInstanceService(service)) {
        let newInstances = {};
        // get it before altering service instances
        let currentNoOfInstances = getCurrentNoOfInstances(service);
        //
        _.each(service.instances, (value, key) => {
          // consider just newly added instances (_.toNumber(key) < 0)
          if (key !== UNASSIGNED_SERVICE_ID_KEY && _.toNumber(key) < 0) {
            const newServiceKey = _.toString(_.toNumber(key) * -1);
            newInstances[newServiceKey] = value;
            service.instances = newInstances;
          }
        });

        // CORE adds by default for the first instance the minNrOfInstances if no instance is present
        // therefore when we have minNrOfInstances>0 we send to core just:
        // no of newly added instances - (minNrOfInstances-1-crtActiveInstances)
        if (service.minNrOfInstances > 0 && currentNoOfInstances < service.minNrOfInstances) {
          let noOfNewInstances = _.size(newInstances);

          let keysOfInstances = _.keys(newInstances);
          let instancesForCore = {};
          for (
            let i = 0;
            i < noOfNewInstances - (service.minNrOfInstances - 1 - currentNoOfInstances);
            i++
          ) {
            instancesForCore[keysOfInstances[i]] = newInstances[keysOfInstances[i]];
          }

          service.instances = instancesForCore;
        }
      } else {
        const serviceId = _.keys(service.instances)[0];
        const isActive = service.instances[serviceId] && service.instances[serviceId].status;
        if (_.toNumber(serviceId) === 0) {
          service.instances["1"] = { status: isActive };
          delete service.instances[serviceId];
        }
      }
    });
  }

  let updatedProductsList = productsDiffMap.get(path) ? productsDiffMap.get(path) : [];
  let updatedProduct = updatedProductsList.filter(p => {
    return p.code === originalProduct.code;
  })[0];

  if (updatedProduct) {
    product.status = updatedProduct.status;
  }

  if (originalProduct.subProductList && originalProduct.subProductList.length > 0) {
    // prods added/removed through ME group change

    let crtMEProductList = productsDiffMap.get(path) ? productsDiffMap.get(path) : [];
    for (let index = 0; index < originalProduct.subProductList.length; index++) {
      let crtOriginalSubProduct = originalProduct.subProductList[index];

      let meProductStatus = crtMEProductList
        .filter(p => {
          return p.code === crtOriginalSubProduct.code;
        })
        .map(p => p.status)[0];

      product.subProductList[index] = getDiffProduct(
        originalProduct.subProductList[index],
        diffMap,
        productsDiffMap,
        path + ".subProductList[" + index + "]",
        meProductStatus
      );
    }
  }

  return product;
}

export function changeToAddedStatus(subProduct) {
  switch (subProduct.status) {
    case PRODUCT_STATUS.UNASSIGNED:
      subProduct.status = PRODUCT_STATUS.ADDED; // add+activate
      break;
    case PRODUCT_STATUS.DISABLED:
      subProduct.status = PRODUCT_STATUS.ACTIVATED; // activate
      break;
    case PRODUCT_STATUS.DEACTIVATED:
      subProduct.status = PRODUCT_STATUS.ENABLED; // back to enabled
      break;
    default:
    //
  }
}

export function changeToRemovedStatus(subProduct) {
  switch (subProduct.status) {
    case PRODUCT_STATUS.ADDED:
      subProduct.status = PRODUCT_STATUS.UNASSIGNED;
      break;
    case PRODUCT_STATUS.ACTIVATED:
      subProduct.status = PRODUCT_STATUS.DISABLED;
      break;
    case PRODUCT_STATUS.ENABLED:
      subProduct.status = PRODUCT_STATUS.DEACTIVATED;
      break;
    default:
    //
  }
}

export function changeSubProductStatus(subProduct, selected) {
  selected ? changeToRemovedStatus(subProduct) : changeToAddedStatus(subProduct);
}

export function checkIfSelected(product) {
  if (product.assignedBydefault) return true;
  if (
    product.status === PRODUCT_STATUS.ENABLED ||
    product.status === PRODUCT_STATUS.ADDED ||
    product.status === PRODUCT_STATUS.ACTIVATED
  )
    return true;

  return false;
}

export function subProductHasVisibleAndSelectedServicesOrFeatures(subproduct) {
  return (
    subproduct.serviceList.find(service => {
      return (
        _.size(service.instances) &&
        service.instances[_.keys(service.instances)[0]] &&
        service.instances[_.keys(service.instances)[0]].status !== false &&
        (service.visible ||
          service.instances[_.keys(service.instances)[0]].features.find(feature => {
            return feature.selected;
          }))
      );
    }) ||
    subproduct.subProductList.find(subSubProduct => {
      return subProductHasVisibleAndSelectedServicesOrFeatures(subSubProduct);
    })
  );
}

export function subProductHasVisibleServicesOrFeatures(subproduct) {
  return (
    subproduct.serviceList.find(service => {
      return (
        _.size(service.instances) &&
        service.instances[_.keys(service.instances)[0]] &&
        (service.visible ||
          service.instances[_.keys(service.instances)[0]].features.find(feature => {
            return feature.selected;
          }))
      );
    }) ||
    subproduct.subProductList.find(subSubProduct => {
      return subProductHasVisibleServicesOrFeatures(subSubProduct);
    })
  );
}
