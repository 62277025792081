import React from "react";
const ServiceIdentifier = ({
  frame = "#346eb3",
  handle = "#114878",
  icon = "#318eff",
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="27" height="28.8" viewBox="0 0 27 28.8" {...props}>
    <path
      fill={frame}
      d="M23.2,23.6l-3.9-3.9A11.424,11.424,0,1,0,0,11.5a11.476,11.476,0,0,0,17.8,9.6l4,4ZM2,11.5A9.433,9.433,0,0,1,11.5,2,9.433,9.433,0,0,1,21,11.5a9.5,9.5,0,0,1-19,0Z"
    />
    <path
      fill={handle}
      d="M25,28.8a1.974,1.974,0,0,1-1.4-.6l-3.7-3.7a1.98,1.98,0,0,1,2.8-2.8l3.7,3.7a1.933,1.933,0,0,1,0,2.8A1.974,1.974,0,0,1,25,28.8Z"
    />
    <path
      fill={icon}
      d="M17.7,12.5V10.3H15.9A5.023,5.023,0,0,0,15.4,9l1.3-1.3L15.2,6.2,13.9,7.5A7.838,7.838,0,0,0,12.5,7V5.2H10.3V7a3.379,3.379,0,0,0-1.2.6L7.8,6.3,6.3,7.8,7.6,9.1A3.1,3.1,0,0,0,7,10.4H5.2v2.2H7a5.023,5.023,0,0,0,.5,1.3L6.2,15.2l1.5,1.5L9,15.4a5.023,5.023,0,0,0,1.3.5v1.8h2.2V15.9a5.023,5.023,0,0,0,1.3-.5l1.3,1.3,1.5-1.5-1.3-1.3a5.023,5.023,0,0,0,.5-1.3h1.9Zm-6.2,1.9a2.9,2.9,0,1,1,2.9-2.9A2.9,2.9,0,0,1,11.5,14.4Z"
    />
  </svg>
);
export default ServiceIdentifier;
