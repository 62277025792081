import React from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { Flex, Heading, Text } from "@theme-ui/components";

import WelcomeSkeleton from "../Header/WelcomeSkeleton";
import Update from "../Update";
import { MESSAGES_CODES } from "../../common/Constants";

const TravelPlanCard = ({ ...props }) => {
  const history = useHistory();

  const { account, master } = useSelector(state => state.user);
  const { travelPlan, travel_plan_status } = useSelector(state => state.plan);

  if (travel_plan_status === "loading" && travel_plan_status !== "failed") {
    return (
      <Flex sx={{ flexDirection: "column" }}>
        <Heading variant="heading2">
          <FormattedMessage id="lbl.travel_plan" />
        </Heading>
        <Flex
          variant="layout.card"
          sx={{
            height: "5.75rem",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <WelcomeSkeleton width="100%" height="48px" />
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex sx={{ flexDirection: "column" }} {...props}>
      {travel_plan_status !== "failed" && travelPlan && master && (
        <>
          <Heading variant="heading2">
            <FormattedMessage id="lbl.travel_plan" />
          </Heading>
          <Flex
            variant="layout.card"
            sx={{
              height: "5.75rem",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <Flex sx={{ width: "65%" }}>
              <Text
                variant="note"
                color="textDark"
                sx={{ width: "fit-content", wordWrap: "break-work" }}>
                {!travelPlan.easyTravel && !travelPlan.travelPack && (
                  <FormattedMessage id="lbl.no_active_plan" />
                )}

                {travelPlan.easyTravel && <FormattedMessage id="lbl.easy_travel_plan_active" />}

                {travelPlan.travelPack && <FormattedMessage id="lbl.travel_packs_plan_active" />}
              </Text>
            </Flex>

            {!master.serviceAgreement &&
              String(account.accountStatus).startsWith(MESSAGES_CODES.ACTIVE) && (
                <Update
                  mr={0}
                  mt={0}
                  onUpdate={() => history.push("/account/" + account?.accountCode + "/travelPlan")}
                />
              )}
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default TravelPlanCard;
