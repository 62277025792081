import React from "react";
import { useSelector } from "react-redux";

import { Box, Heading, Flex } from "@theme-ui/components";

import { FUBCard, FUBCardSkeleton, SAFUBCard } from "../FUBCard";
import { Localization } from "./../../common/Localization";

const FUBGroup = ({
  isShared = false,
  isMaster = false,
  title,
  fubs = [],
  isLoading,
  showMembers = true,
  ...props
}) => {
  const { account } = useSelector(state => state.user);

  if (isLoading) {
    return (
      <Flex sx={{ flexDirection: "column" }}>
        <Heading variant="heading2">{title}</Heading>
        <Flex
          sx={{
            gap: "larger",
            display: "grid",
            gridTemplateColumns: [
              "100%",
              "repeat(1, 21rem)",
              "repeat(2, 22rem)",
              "repeat(3, 24.5rem)",
            ],
          }}>
          {[...Array(3).keys()].map(index => (
            <Flex key={index} variant="layout.card" sx={{ padding: 0 }}>
              <FUBCardSkeleton />
            </Flex>
          ))}
        </Flex>
      </Flex>
    );
  }

  const displayTitle = !fubs || fubs.length > 0;
  if (fubs && fubs.length === 0) {
    return <></>;
  }

  const buildSAFUBCardDescription = fub => {
    const descr = fub.serviceInvoiceDescription ?? fub.bduTypeDescription ?? fub.description;

    if (fub.overage) {
      return descr + " - " + Localization.getString("lbl.overage");
    }
    return descr;
  };

  if (account && !isMaster && !["ACTIVE", "SUSPENDED"].includes(account.accountStatus)) {
    return null;
  }

  return (
    fubs && (
      <Box
        sx={{
          width: ["100%", "100%", "100%"],
          flexGrow: "2",
        }}>
        {displayTitle && <Heading variant="heading2">{title}</Heading>}

        <Flex
          sx={{
            gap: "larger",
            display: "grid",
            gridTemplateColumns: isShared
              ? ["100%", "repeat(2, 21rem)", "repeat(2, 22rem)", "repeat(3, 24.5rem)"]
              : ["100%", "repeat(2, 21rem)", "repeat(2, 22rem)", "repeat(4, 24.5rem)"],
          }}>
          {fubs.map((fub, index) => {
            if (isShared) {
              return <FUBCard key={index} fub={fub} showMembers={showMembers} {...props} />;
            }
            return (
              <SAFUBCard
                key={index}
                fub={fub}
                buildDescription={buildSAFUBCardDescription}
                {...props}
              />
            );
          })}
        </Flex>
      </Box>
    )
  );
};

export default FUBGroup;
