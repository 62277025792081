import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

import { Flex, Text } from "@theme-ui/components";

import { SelfcareIntlContext } from "../../contexts/SelfcareIntlContext";
import SummaryRow from "../../components/SummaryRow/SummaryRow";
import { LANGUAGE_ISOCODE } from "../../common/Localization";
import SummaryLayout from "../../containers/SummaryLayout";

const CreditCardDetails = ({
  details,
  isLoading,
  invalidCard,
  onUpdateCreditCard,
  onRemoveCreditCard,
  fieldWidth = "8rem",
}) => {
  const intl = useIntl();
  const { locale } = useContext(SelfcareIntlContext);
  const { master } = useSelector(state => state.user);
  const displayUpdate = ["ACTIVE", "SUSPENDED"].includes(master?.accountStatus);

  if (isLoading || !details) {
    return (
      <SummaryLayout
        title="lbl.credit_card_information"
        isLoading
        sx={{ width: "100%", maxWidth: "100%" }}
      />
    );
  }
  const creditCardDetails = details.creditCardDetails;
  const width = locale == LANGUAGE_ISOCODE.French ? "16.75rem" : fieldWidth;

  if (invalidCard) {
    return (
      <SummaryLayout
        title="lbl.credit_card_information"
        isAdd
        showUpdate={displayUpdate}
        onUpdate={onUpdateCreditCard}
        sx={{ width: "100%" }}>
        <Flex mb="medium">
          <Text color="note" sx={{ fontSize: 3, textAlign: "left" }}>
            {intl.formatMessage({ id: "lbl.no_credit_card" })}
          </Text>
        </Flex>
      </SummaryLayout>
    );
  }

  const parseCreditCard = number => {
    const parsed =
      number.substring(0, 4) +
      " " +
      number.substring(4, 8) +
      " " +
      number.substring(8, 12) +
      " " +
      number.substring(12, 16);

    return parsed;
  };

  return (
    <SummaryLayout
      title="lbl.credit_card_information"
      showUpdate={displayUpdate}
      onUpdate={onUpdateCreditCard}
      showRemove
      onRemove={onRemoveCreditCard}
      sx={{ width: "100%" }}>
      <SummaryRow
        tooltip={intl.formatMessage({ id: "tooltip.cardholder_name" })}
        value={creditCardDetails.cardHolderName}
        fieldWidth={width}
      />
      <SummaryRow
        tooltip={intl.formatMessage({ id: "tooltip.card_type_and_number" })}
        value={parseCreditCard(creditCardDetails.cardNumber)}
        fieldWidth={width}
      />
      <SummaryRow
        tooltip={intl.formatMessage({ id: "tooltip.expiration_date" })}
        value={creditCardDetails.cardExpirationMonth + "/" + creditCardDetails.cardExpirationYear}
        fieldWidth={width}
      />
    </SummaryLayout>
  );
};

export default CreditCardDetails;
