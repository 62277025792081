import React from "react";

const InvoiceAndPayments = ({
  page = "#e6e6e6",
  silver = "#318eff",
  text = "#114878",
  shadow = "#dcdcdc",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="45.9"
    height="55.4"
    viewBox="0 0 45.9 55.4"
    {...props}>
    <path
      fill={page}
      d="M23,0H0V47.9l4.6,3.7,3.6-2.9,3.6,2.9,3.6-2.9L19,51.6l3.6-2.9,3.6,2.9L27,51a11.764,11.764,0,0,1-.7-2,10.074,10.074,0,0,1-.3-2.5A10.977,10.977,0,0,1,36,35.6c.3,0,.7-.1,1-.1a3.4,3.4,0,0,1,1,.1V15Z"
    />
    <path fill={silver} d="M36.9,37.4a9,9,0,1,0,9,9A8.963,8.963,0,0,0,36.9,37.4Z" />
    <path
      fill={text}
      d="M37.8,45.7c-1.5-1.2-1.9-1.9-1.9-2.9s.4-1.4,1.1-1.4,1.1.4,1.1,1.4v.6h2.1V43c0-1.9-.9-3.1-2.4-3.4v-1H36.2v1c-1.5.3-2.4,1.5-2.4,3.4,0,1.6.6,2.7,2.4,4.1C37.6,48.3,38,49,38,50s-.4,1.4-1.1,1.4S35.8,51,35.8,50V48.9H33.7v1c0,1.9.9,3.1,2.4,3.4v1h1.6v-1c1.6-.3,2.4-1.5,2.4-3.4C40.2,48.3,39.6,47.2,37.8,45.7Z"
    />
    <rect fill={text} width="6" height="2" transform="translate(7 20)" />
    <rect fill={text} width="14" height="2" transform="translate(17 20)" />
    <rect fill={text} width="6" height="2" transform="translate(7 24)" />
    <rect fill={text} width="14" height="2" transform="translate(17 24)" />
    <rect fill={text} width="6" height="2" transform="translate(7 28)" />
    <rect fill={silver} width="6" height="2" transform="translate(25 32)" />
    <rect fill={text} width="14" height="2" transform="translate(17 28)" />
    <path fill={shadow} d="M23,0V15H38Z" />
  </svg>
);
export default InvoiceAndPayments;
