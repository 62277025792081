import React from "react";
import { FormattedMessage } from "react-intl";

import { Box, Flex, Heading, Text } from "@theme-ui/components";
import GoBack from "../../components/GoBack";

const EmptyUpdatePackage = ({ onBack }) => {
  return (
    <Flex
      sx={{
        display: "flex",
        flexDirection: "column",
        width: ["100%", "100%", "55.75rem", "55.75rem"],
      }}>
      <Box mt="0.5rem">
        <Heading>
          <FormattedMessage id="lbl.services_and_features" />
        </Heading>
        <Box sx={{ textAlign: ["center", "left", "left"], mt: "0.5rem" }}>
          <Text color="primary" mr="1rem">
            <FormattedMessage id="lbl.no_visible_services" />
          </Text>
        </Box>
      </Box>

      <GoBack onBack={onBack} />
    </Flex>
  );
};

export default EmptyUpdatePackage;
