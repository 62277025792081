import React from "react";

const DeviceDetails = ({ fill = "#fff", strokeWidth = "3px", stroke = "#0c3865", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42.467"
    height="77"
    viewBox="0 0 42.467 77"
    {...props}>
    <g id="Group_1833" data-name="Group 1833" transform="translate(-542.5 -526.5)">
      <g id="Group_245" data-name="Group 245" transform="translate(544 528)">
        <path
          id="Path_85"
          data-name="Path 85"
          d="M41.256 76H10.011A4.123 4.123 0 0 1 5.9 71.889V6.111A4.123 4.123 0 0 1 10.011 2h31.245a4.123 4.123 0 0 1 4.111 4.111v65.778A4.123 4.123 0 0 1 41.256 76z"
          transform="translate(-5.9 -2)"
          strokeWidth={strokeWidth}
          strokeLinejoin="round"
          fill={fill}
          stroke={stroke}
          strokeLinecap="round"
          strokeMiterlimit="10"
        />
        <path
          id="Line_26"
          data-name="Line 26"
          fill={fill}
          stroke={stroke}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth={strokeWidth}
          transform="translate(15.006 4.728)"
          d="M0 0h9.456"
        />
        <path
          id="Line_27"
          data-name="Line 27"
          fill={fill}
          stroke={stroke}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth={strokeWidth}
          transform="translate(17.472 68.656)"
          d="M0 0h4.522"
        />
        <path
          id="Line_28"
          data-name="Line 28"
          fill={fill}
          stroke={stroke}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth={strokeWidth}
          transform="translate(0 63.517)"
          d="M0 0h39.467"
        />
      </g>
    </g>
  </svg>
);

export default DeviceDetails;
