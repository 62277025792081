import React from "react";
import { SelfcareIcon } from "../icons";
import { FormattedMessage } from "react-intl";
import { Flex, Text } from "@theme-ui/components";
import { motion } from "framer-motion/dist/framer-motion";

const variants = {
  enter: direction => {
    return {
      x: direction > 0 ? 500 : -500,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: direction => {
    return {
      zIndex: 0,
      x: direction < 0 ? 500 : -500,
      opacity: 0,
    };
  },
};
/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};
const Carousel = ({
  onViewAll,
  children,
  length,
  itemsPerPage = 3,
  carouselType = "",
  isPaging = true,
  startIndex,
  setStartIndex,
  width,
  ...props
}) => {
  const [[page, direction], setPage] = React.useState([0, 0]);

  const paginate = newDirection => {
    var newPage = page + newDirection;
    if (newPage >= length) newPage = 0;
    else if (newPage < 0) newPage = length - 1;
    setPage([newPage, newDirection]);
  };

  const nextPage = () => {
    setStartIndex((startIndex + itemsPerPage) % length);
    paginate(itemsPerPage);
  };

  const prevPage = () => {
    setStartIndex(
      startIndex - itemsPerPage <= 0
        ? startIndex + length - itemsPerPage
        : startIndex - itemsPerPage
    );
    paginate(-itemsPerPage);
  };

  const ViewAll = ({ ...props }) => {
    return (
      <Text
        variant="link"
        mr={["5rem", "huge", "huge"]}
        onClick={onViewAll}
        sx={{ ...props.sx }}
        {...props}>
        <FormattedMessage
          id="lbl.view_all"
          values={{
            carouselType: carouselType,
          }}
        />
      </Text>
    );
  };

  return (
    <Flex sx={{ flexDirection: "column" }} {...props}>
      <Flex sx={{ flexDirection: "row", width: ["100%", width] }}>
        {isPaging && (
          <Flex
            mt="6.7rem"
            ml={["-0.95rem", "-1.4rem"]}
            onClick={prevPage}
            sx={{
              alignItems: "center",
              position: "absolute",
              zIndex: 2,
            }}>
            <SelfcareIcon
              name="prev"
              sx={{
                cursor: "pointer",
                height: "2.5rem",
                width: "2.5rem",
              }}
            />
          </Flex>
        )}

        <motion.div
          whileTap={isPaging && { cursor: "grabbing" }}
          style={isPaging && { cursor: "grab", width: "100%" }}
          className="carousel">
          <motion.div
            key={page}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 },
            }}
            style={{ display: "flex", width: "100%" }}
            drag={isPaging && "x"}
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x);
              if (swipe < -swipeConfidenceThreshold) {
                nextPage(itemsPerPage);
              } else if (swipe > swipeConfidenceThreshold) {
                prevPage(-itemsPerPage);
              }
            }}>
            <Flex
              sx={{
                flexDirection: "row",
                gap: "larger",
                width: "100%",
              }}>
              {children}
            </Flex>
          </motion.div>
        </motion.div>

        {isPaging && (
          <Flex
            ml={["-1.6rem", "-1.2rem"]}
            sx={{
              position: "relative",
            }}>
            <Flex
              mt="6.7rem"
              onClick={nextPage}
              sx={{
                cursor: "pointer",
                position: "absolute",
              }}>
              <SelfcareIcon
                name="next"
                sx={{
                  cursor: "pointer",
                  height: "2.5rem",
                  width: "2.5rem",
                }}
              />
            </Flex>
          </Flex>
        )}
      </Flex>
      <Flex mt="default">{onViewAll && <ViewAll />}</Flex>
    </Flex>
  );
};
export default Carousel;
