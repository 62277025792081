import React from "react";

const MABillable = ({ accent = "#318eff", paper = "#336eb4", shadow = "#114878", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="65.55"
    height="79.522"
    viewBox="0 0 65.55 79.522"
    {...props}>
    <g transform="translate(16.723 -280.017)">
      <circle
        fill={accent}
        cx="14.093"
        cy="14.093"
        r="14.093"
        transform="translate(20.641 331.353)"
      />
      <path
        fill={paper}
        d="M19.141,345.446a15.554,15.554,0,0,1,18.967-15.214V301.593L16.464,280.017l-33.187.064V349.2l6.631,5.311,5.191-4.155,5.194,4.154,5.194-4.156,5.194,4.157,5.192-4.155,5.194,4.154.64-.513A15.5,15.5,0,0,1,19.141,345.446Z"
      />
      <rect fill={accent} width="8.658" height="2.886" transform="translate(-6.623 308.876)" />
      <rect fill={accent} width="20.201" height="2.886" transform="translate(7.807 308.876)" />
      <rect fill={accent} width="8.658" height="2.886" transform="translate(-6.623 314.648)" />
      <rect fill={accent} width="20.201" height="2.886" transform="translate(7.807 314.648)" />
      <rect fill={accent} width="8.658" height="2.886" transform="translate(-6.623 320.419)" />
      <rect fill={accent} width="8.658" height="2.886" transform="translate(19.35 326.191)" />
      <rect fill={accent} width="20.201" height="2.886" transform="translate(7.807 320.419)" />
      <path fill={shadow} d="M16.464,280.017v21.644l21.644-.068Z" />
      <path
        fill={shadow}
        d="M31.911,353.509l-6.422-5.85,2.875-3.158,3.267,2.975,9.191-10.092,3.158,2.875Z"
      />
    </g>
  </svg>
);

export default MABillable;
