import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import { Heading, Flex, Button } from "@theme-ui/components";

import GetErrorDescription from "../../components/GetErrorDescription";
import SubmitButton from "../../components/SubmitButton/SubmitButton";
import { StyledModalMessage } from "../../components/modals";
import { FormInput } from "../..//components/base";
import Layout from "../../containers/Layout";

import {
  getSecurityPolicy,
  setSecurityPolicyStatus,
  changePassword,
  setChangePasswordStatus,
  setUserError,
} from "../../redux/slices/UserSlice";
import { useHistory } from "react-router-dom";
import GoBack from "../../components/GoBack";

const ChangePassword = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { accountCode } = useSelector(state => state.auth);
  const [isError, setIsError] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const history = useHistory();

  const {
    securityPolicy,
    security_policy_status,
    change_password_status,
    userError,
    defaultNavigationPath,
  } = useSelector(state => state.user);

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
  });
  const reactHookFormHandle = { register, errors };

  useEffect(() => {
    if (!securityPolicy && !isError) {
      dispatch(getSecurityPolicy());
    }
  }, [securityPolicy, dispatch, isError]);

  useEffect(() => {
    if (security_policy_status === "success") {
      dispatch(setSecurityPolicyStatus(null));
    }
    if (security_policy_status === "failed") {
      setIsError(true);
    }
  }, [dispatch, security_policy_status]);

  useEffect(() => {
    if (change_password_status === "success") {
      dispatch(setChangePasswordStatus(null));
      setIsComplete(true);
    }
    if (change_password_status === "failed") {
      setIsError(true);
    }
  }, [dispatch, change_password_status]);

  const closeError = () => {
    setSubmitClicked(false);
    setIsError(false);
    dispatch(setChangePasswordStatus(null));
    dispatch(setSecurityPolicyStatus(null));
    dispatch(setUserError(null));
  };

  const doAfterSuccessfulSave = () => {
    setSubmitClicked(false);
    setIsComplete(false);
    history.push(defaultNavigationPath);
  };

  const onChangePassword = values => {
    dispatch(
      changePassword({
        accountCode: accountCode,
        password: values.oldPassword,
        newPassword: values.newPassword,
        newPasswordHint: values.newPasswordHint,
      })
    );
    setSubmitClicked(true);
  };

  return (
    <Layout>
      <Heading>
        <FormattedMessage id="lbl.change_password" />
      </Heading>

      <Flex
        sx={{
          maxWidth: ["100%", "38.0875rem"],
          borderRadius: "card",
          border: "solid 1px ",
          borderColor: "border",
          bg: "contentBg",
          flexDirection: "column",
        }}
        py="larger"
        px="large">
        <form
          style={{ display: "flex", flexDirection: "column" }}
          onSubmit={handleSubmit(onChangePassword)}>
          <FormInput
            name="oldPassword"
            label="lbl.old_password"
            type="password"
            wrapperProps={{ mb: [0, "small"] }}
            validations={{ required: true }}
            {...reactHookFormHandle}
          />

          <FormInput
            name="newPassword"
            label="lbl.new_password"
            maxLength="50"
            type="password"
            wrapperProps={{ mb: [0, "small"] }}
            validations={{
              required: true,
              validate: {
                valid: value => {
                  return value === watch("oldPassword")
                    ? intl.formatMessage({ id: "lbl.old_new_password_cannot_be_the_same" })
                    : true;
                },
              },
              pattern: {
                value: new RegExp(securityPolicy?.subscriberPasswordRegEx),
                message: intl.formatMessage({
                  id: "err.subscriber_password_invalid",
                }),
              },
            }}
            {...reactHookFormHandle}
          />

          <FormInput
            name="confirmPassword"
            label="lbl.confirm_password"
            maxLength="50"
            type="password"
            validations={{
              required: true,
              validate: {
                confirmPassword: value => {
                  return value !== watch("newPassword")
                    ? intl.formatMessage({ id: "lbl.passwords_dont_match" })
                    : true;
                },
              },
            }}
            {...reactHookFormHandle}
          />
          <Flex sx={{ justifyContent: ["center", "flex-end"] }}>
            <SubmitButton mt="2rem" type="submit" isLoading={submitClicked} />
          </Flex>
        </form>
      </Flex>
      <GoBack onBack={() => history.goBack()} />

      {isComplete && (
        <StyledModalMessage
          isOpen={isComplete}
          message={<FormattedMessage id="lbl.change_password_success" />}
          onRequestClose={doAfterSuccessfulSave}
          type="error">
          <Button onClick={doAfterSuccessfulSave}>
            <FormattedMessage id="lbl.ok" />
          </Button>
        </StyledModalMessage>
      )}

      {isError && (
        <StyledModalMessage
          isOpen={isError}
          message={userError && <GetErrorDescription error={userError} />}
          onRequestClose={closeError}
          type="error">
          <Button onClick={closeError}>
            <FormattedMessage id="lbl.Try_Again" />
          </Button>
        </StyledModalMessage>
      )}
    </Layout>
  );
};

export default ChangePassword;
