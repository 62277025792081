import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import { Flex, Text } from "@theme-ui/components";

import { MESSAGES_CODES } from "../../common/Constants";

const StatusMessage = ({ accountStatus, isCommercialLimitedAccess = false }) => {
  let lbl_message;
  let isActive = false;
  let isActiveHbOrDc = false;

  const { eastLinkPhoneNumber, isCommercialAccount } = useSelector(state => state.user);

  switch (accountStatus) {
    case MESSAGES_CODES.SUSPENDED:
    case MESSAGES_CODES.SUSPENDED_DC:
    case MESSAGES_CODES.SUSPENDED_HB:
      lbl_message = "lbl.suspended_status";
      break;
    case MESSAGES_CODES.CLOSED:
    case MESSAGES_CODES.CLOSED_DC:
    case MESSAGES_CODES.TERMINATE:
      lbl_message = "lbl.closed_status";
      break;

    case MESSAGES_CODES.ACTIVE_HB:
      lbl_message = "lbl.active_status_hb";
      isActiveHbOrDc = true;
      break;
    case MESSAGES_CODES.ACTIVE_DC:
      lbl_message = "lbl.active_status_dc";
      isActiveHbOrDc = true;
      break;
    default:
      lbl_message = "lbl.active_status";
      isActive = true;
  }

  if (isActiveHbOrDc && isCommercialLimitedAccess) return null;

  return (
    <Flex
      sx={
        !isActive
          ? {
              flexDirection: "column",
              px: "large",
              py: "default",
              bg: "alertBg",
              borderRadius: "card",
              boxShadow: "default",
            }
          : { color: "primary" }
      }>
      <Text variant={isActive ? "note" : "error"}>
        <FormattedMessage
          id={lbl_message}
          values={{
            p: chunks => (
              <Text as="p" variant="error">
                {chunks}
              </Text>
            ),
            eastLinkPhoneNumber,
            isCommercialAccount,
          }}
        />
      </Text>
    </Flex>
  );
};
export default StatusMessage;
