import React from "react";
import { FormattedMessage } from "react-intl";

import { Flex, Text } from "@theme-ui/components";

import { SelfcareIcon } from "../icons";

const Update = ({
  onUpdate = null,
  isAdd = false,
  icon = null,
  text = null,
  values = {},
  iconSize = "22px",
  textProps = {},
  ...props
}) => {
  return (
    <Flex
      sx={{
        ml: "auto",
        position: "relative",
        justifyContent: "flex-end",
        alignItems: "center",
      }}>
      <Flex
        sx={{
          width: "fit-content",
          position: "absolute",
          cursor: "pointer",
          right: 0,
          zIndex: 111,
          ...props.sx,
        }}
        {...props}
        onClick={onUpdate}>
        <SelfcareIcon
          name={icon ?? (isAdd ? "add" : "edit")}
          sx={{
            size: iconSize,
            ":hover": {
              cursor: "pointer",
            },
          }}
        />
        <Text
          ml="0.5rem"
          variant="link"
          sx={{
            fontWeight: "semiBold",
            alignSelf: "center",
            ...textProps.sx,
          }}
          {...textProps}>
          <FormattedMessage id={text ?? (isAdd ? "lbl.add" : "lbl.edit")} values={{ values }} />
        </Text>
      </Flex>
    </Flex>
  );
};

export default Update;
