import React, { forwardRef } from "react";

import { Flex, Text } from "@theme-ui/components";

const MenuOption = forwardRef(({ text, isHighlighted, ...props }, ref) => {
  const iconHighlightedColor = isHighlighted ? "secondary" : "primary";

  return (
    <Flex ref={ref} sx={{ alignItems: "center" }} py="tiny" px="default" {...props}>
      <Text
        variant="cardtitle"
        sx={{ transition: "100ms ease-in", fontWeight: "regular", fontFeatureSettings: "'tnum'" }}
        color={iconHighlightedColor}>
        {text}
      </Text>
    </Flex>
  );
});

MenuOption.displayName = "MenuOption";

export default MenuOption;
