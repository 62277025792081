import React from "react";

import { Flex, Text } from "@theme-ui/components";

const AddButton = ({ ...props }) => {
  return (
    <Flex
      {...props}
      sx={{
        border: "1px solid",
        borderColor: "border",
        width: "3.5rem",
        height: "1.875rem",
        backgroundColor: "contentBg",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        borderRadius: "circle",
        ":hover": {
          cursor: "pointer",
        },
      }}>
      <Text
        as="span"
        color="primary"
        sx={{
          lineHeight: "solid",
          userSelect: "none",
          fontWeight: "semiBold",
        }}>
        +
      </Text>
    </Flex>
  );
};
export default AddButton;
