import React from "react";
const RequestAssist = ({ fill = "none", stroke = "#fff", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" {...props}>
    <g id="life-buoy" transform="translate(-1 -1)">
      <circle
        id="Ellipse_39"
        data-name="Ellipse 39"
        className="cls-1"
        cx="14"
        cy="14"
        r="14"
        transform="translate(2 2)"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
      />
      <circle
        id="Ellipse_40"
        data-name="Ellipse 40"
        className="cls-1"
        cx="6"
        cy="6"
        r="6"
        transform="translate(10 10)"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
      />
      <path
        id="Line_73"
        data-name="Line 73"
        className="cls-1"
        transform="translate(6.156 6.156)"
        d="m0 0 5.344 5.344"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
      />
      <path
        id="Line_74"
        data-name="Line 74"
        className="cls-1"
        transform="translate(20.5 20.5)"
        d="m0 0 5 5"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
      />
      <path
        id="Line_75"
        data-name="Line 75"
        className="cls-1"
        transform="translate(20.5 6.5)"
        d="m0 5 5-5"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
      />
      <path
        id="Line_77"
        data-name="Line 77"
        className="cls-1"
        transform="translate(6.5 20.5)"
        d="m0 5 5-5"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
      />
    </g>
  </svg>
);
export default RequestAssist;
