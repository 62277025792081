import React from "react";
const Search = ({ fill = "none", stroke = "#0c3865", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23.752"
    height="22.413"
    viewBox="0 0 23.752 22.413"
    {...props}>
    <g data-name="Group 170">
      <path
        d="M309.286 230.968a8.251 8.251 0 1 0-8.786-8.234 8.524 8.524 0 0 0 8.786 8.234z"
        transform="translate(-299.5 -213.5)"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
        fillRule="evenodd"
      />
      <path
        d="m314.553 228.157-6.3-5.905"
        transform="translate(-292.214 -207.158)"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
      />
    </g>
  </svg>
);
export default Search;
