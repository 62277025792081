import React from "react";
const Payment = ({ edge = "#346eb3", text = "#114878", field = "#318eff", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35.4"
    height="20.2"
    viewBox="0 0 35.4 20.2"
    {...props}>
    <g transform="translate(-720.4 -379.4)">
      <rect
        fill="none"
        stroke={edge}
        strokeMiterlimit="10"
        strokeWidth="1.2px"
        width="34.2"
        height="19"
        transform="translate(721 380)"
      />
      <path fill={edge} d="M.6,5.4A4.8,4.8,0,0,0,5.4.6H.6Z" transform="translate(720.4 379.4)" />
      <path fill={edge} d="M.6,14.9a4.8,4.8,0,0,1,4.8,4.8H.6Z" transform="translate(720.4 379.4)" />
      <path fill={edge} d="M34.8,5.4A4.8,4.8,0,0,1,30,.6h4.8Z" transform="translate(720.4 379.4)" />
      <path
        fill={edge}
        d="M34.8,14.9A4.8,4.8,0,0,0,30,19.7h4.8Z"
        transform="translate(720.4 379.4)"
      />
      <rect fill={text} width="18.3" height="1.4" transform="translate(728.9 381.4)" />
      <rect fill={text} width="18.3" height="1.4" transform="translate(728.9 396.3)" />
      <g transform="translate(720.4 379.4)">
        <path
          fill={field}
          d="M17.7,3.3a6.744,6.744,0,0,0-6.8,6.8,6.8,6.8,0,1,0,13.6,0A6.744,6.744,0,0,0,17.7,3.3Z"
        />
        <path
          fill={text}
          d="M18.4,9.6C17.3,8.7,17,8.2,17,7.4c0-.7.3-1,.8-1s.8.3.8,1v.4h1.6V7.5a2.365,2.365,0,0,0-1.8-2.6V4.2H17.2V5a2.365,2.365,0,0,0-1.8,2.6c0,1.2.5,2,1.8,3.1,1.1.9,1.4,1.4,1.4,2.2s-.3,1-.9,1a.871.871,0,0,1-.9-1V12H15.2v.7A2.458,2.458,0,0,0,17,15.3V16h1.2v-.8C19.4,15,20,14,20,12.6,20.2,11.5,19.7,10.7,18.4,9.6Z"
        />
      </g>
    </g>
  </svg>
);
export default Payment;
