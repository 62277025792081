import React from "react";

import { Flex, Heading } from "@theme-ui/components";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ViewDetails from "../ViewDetails";

const UsageDetailsCard = () => {
  const { account } = useSelector(state => state.user);
  const history = useHistory();

  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Heading variant="heading2">
        <FormattedMessage id="lbl.usage_details.header" />
      </Heading>
      <Flex
        variant="layout.card"
        sx={{
          height: "5.75rem",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <ViewDetails
          my="auto"
          icon="viewUsageDetails"
          label="lbl.usage_details.link"
          onClick={() =>
            account && history.push("/account/" + account.accountCode + "/usageDetails")
          }
        />
      </Flex>
    </Flex>
  );
};

export default UsageDetailsCard;
