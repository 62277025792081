import React from "react";

import { Spinner as ThemeSpinner } from "@theme-ui/components";

import TransparentModal from "./TransparentModal";

const Spinner = ({ isOpen }) => {
  return (
    <TransparentModal isOpen={isOpen}>
      <ThemeSpinner />
    </TransparentModal>
  );
};

export default Spinner;
