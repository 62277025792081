import React from "react";

const Add = ({ color = "#318eff", ...props }) => (
  <svg width="28" height="28" viewBox="0 0 28 28" {...props}>
    <defs>
      <clipPath id="clip-icon_add_small">
        <rect width="28" height="28" />
      </clipPath>
    </defs>
    <g id="icon_add_small" clipPath="url(#clip-icon_add_small)">
      <rect width="28" height="28" fill="#fff" />
      <circle
        id="Ellipse_21"
        data-name="Ellipse 21"
        cx="13"
        cy="13"
        r="13"
        transform="translate(1 1)"
        fill="none"
        stroke={color}
        strokeWidth="2"
      />
      <path
        id="Path_2497"
        data-name="Path 2497"
        d="M12011,5845.647l4.457-4.457,4.455-4.458"
        transform="translate(-4351.563 -12612.752) rotate(45)"
        fill="none"
        stroke="#332a86"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path
        id="Path_2506"
        data-name="Path 2506"
        d="M12011,5845.647l4.457-4.457,4.455-4.458"
        transform="translate(-12612.252 4379.866) rotate(-45)"
        fill="none"
        stroke="#332a86"
        strokeLinecap="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export default Add;
