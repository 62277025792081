import React from "react";
import { FormattedMessage } from "react-intl";

import { useThemeUI } from "@theme-ui/core";
import { Box, Flex, Text } from "@theme-ui/components";
import { SelfcareIcon } from "../icons";

const SelfcareTable = ({
  headers,
  gridColumns,
  gridAlign = [],
  currentPage,
  setCurrentPage,
  numRows,
  itemsPerPage,
  children,
  gridGap = ["large", "large", "huge"],
  isLoading = false,
  ...props
}) => {
  const { theme } = useThemeUI();
  const mobileTableWidth = props.sx?.width[0];

  const totalPages = Math.ceil(numRows / itemsPerPage);
  const nextPage = () => {
    setCurrentPage((currentPage % totalPages) + 1);
  };

  const prevPage = () => {
    setCurrentPage((currentPage - 1) % totalPages === 0 ? totalPages : currentPage - 1);
  };

  const getGridTextAlign = index => {
    return gridAlign.at(index) ?? "left";
  };
  const RenderHeaders = ({ headers }) => {
    return (
      <Box
        mt="medium"
        sx={{
          display: "grid",
          gridGap: gridGap,
          gridTemplateColumns: gridColumns,
        }}>
        {headers.map((item, index) => {
          return (
            <Text
              color="primary"
              data-testid="headers"
              sx={{
                whiteSpace: "wrap",
                textAlign: getGridTextAlign(index),
                fontWeight: "medium",
                mb: "small",
                alignSelf: "center",
              }}
              key={index}>
              {typeof item === "string" ? <FormattedMessage id={item} /> : item}
            </Text>
          );
        })}
      </Box>
    );
  };

  const Separator = ({ ...props }) => {
    return (
      <Flex
        {...props}
        as="hr"
        sx={{
          minWidth: [mobileTableWidth, mobileTableWidth, "100%"],
          width: "100%",
          bg: "inactive",
          border: "1px solid",
          margin: "2px 0 0",
          borderColor: "border",
          height: 0,
        }}
      />
    );
  };

  return (
    <Flex sx={{ width: "100%" }}>
      {totalPages > 1 && (
        <Flex sx={{ alignItems: "flex-end" }}>
          <Flex
            ml={["-0.95rem", "-1.4rem"]}
            mb="0.6rem"
            bg="transparent"
            data-testid="PrevButton"
            sx={{
              position: "absolute",
            }}>
            <SelfcareIcon
              stroke={theme.colors.primary}
              fill={theme.colors.navMenuBg}
              name="prev"
              onClick={prevPage}
              sx={{
                cursor: "pointer",
                height: "2.5rem",
                width: "2.5rem",
              }}
            />
          </Flex>
        </Flex>
      )}
      <Box
        bg="contentBg"
        {...props}
        sx={{
          width: "100%",
          borderRadius: "card",
          border: "solid 1px",
          borderColor: "border",
          overflowX: ["scroll", "scroll", "hidden"],
          ...props.sx,
        }}>
        <RenderHeaders headers={headers} />
        <Separator />
        <Flex sx={{ flexDirection: "column" }}>{children}</Flex>

        {!isLoading && <Separator />}

        <Flex my="medium" sx={{ justifyContent: "end" }}>
          {totalPages > 1 && (
            <Text color="primary" mx="large" data-testid="PageIndex">
              <FormattedMessage
                id="lbl.num_page"
                values={{
                  current: currentPage,
                  total: totalPages,
                }}
              />
            </Text>
          )}
        </Flex>
      </Box>
      {totalPages > 1 && (
        <Flex sx={{ alignItems: "flex-end" }}>
          <Flex
            ml={["-1.6rem", "-1.2rem"]}
            mb="0.6rem"
            bg="transparent"
            data-testid="NextButton"
            sx={{
              position: "absolute",
            }}>
            <SelfcareIcon
              fill={theme.colors.navMenuBg}
              stroke={theme.colors.primary}
              name="next"
              onClick={nextPage}
              sx={{
                cursor: "pointer",
                height: "2.5rem",
                width: "2.5rem",
              }}
            />
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default SelfcareTable;
